// Resources
import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

// Components
import CompanyWrapper from "./CompanyWrapper";
import ActivityWrapper from "./ActivityWrapper";
import TeamWrapper from "./TeamWrapper";
import DevWrapper from "./DevWrapper";
import FormWrapper from "./FormWrapper";
import LegacyWrapper from "./LegacyWrapper";
import DashboardWrapper from "./DashboardWrapper";

// Styles
import { BodyWrapperEl } from "./styles/BodyWrapper";

const mapStateToProps = function (state) {
	return state;
};

class BodyWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: {}
		};
	}

	static getDerivedStateFromProps(props, state) {
		if (props.user !== state.user) {
			return { user: props.user };
		}
		// Return null to indicate no change to state.
		return null;
	}

	generateTimeStyles = (date) => {
		if (!date) return "";
		const ONE_DAY = 60 * 60 * 1000 * 24; /* ms */
		const ONE_WEEK = 60 * 60 * 1000 * 24 * 7; /* ms */
		const ONE_MONTH = 60 * 60 * 1000 * 24 * 30; /* ms */
		const now = new Date();
		if (now - date < ONE_DAY) {
			return "green";
		} else if (now - date < ONE_WEEK) {
			return "yellow";
		} else if (now - date < ONE_MONTH) {
			return "orange";
		}
		return "";
	};

	getType(t) {
		let type = "nc";
		if (t) {
			if (t === "Targeted Tactic") {
				type = "tt";
			} else if (t === "Lead Capture") {
				type = "lc";
			} else if (t === "Email Remarketing") {
				type = "pc";
			} else if (t === "Coupon Experience") {
				type = "ce";
			}
		}
		return type;
	}

	componentDidMount() {}

	render() {
		return (
			<React.Fragment>
				<BodyWrapperEl>
					{this.props.nav.page === "app" && this.props.nav.nav === "dashboard" && (
						<DashboardWrapper
							page={this.props.nav.page}
							nav={this.props.nav.nav}
							user={this.state.user}
							search={this.props.search}
							handleCompanyClick={this.props.handleCompanyClick}
							handleAddCompany={this.props.handleAddCompany}
							getType={this.getType}
							generateTimeStyles={this.generateTimeStyles}
						/>
					)}
					{this.props.nav.page === "app" && this.props.nav.nav === "legacy" && (
						<DashboardWrapper
							page={this.props.nav.page}
							nav={this.props.nav.nav}
							search={this.props.search}
							user={this.state.user}
							handleSudoAction={this.props.handleSudoAction}
							getType={this.getType}
							generateTimeStyles={this.generateTimeStyles}
						/>
					)}
					{this.props.nav.page === "company" && (
						<CompanyWrapper
							page={this.props.nav.page}
							nav={this.props.nav.nav}
							user={this.state.user}
							search={this.props.search}
							company={this.props.company}
							handleCompanyClick={this.props.handleCompanyClick}
							handleAddCSD={this.props.handleAddCSD}
							handleCloneCSD={this.props.handleCloneCSD}
							handleAddCompany={this.props.handleAddCompany}
							handleDeleteFormClick={this.props.handleDeleteFormClick}
							getType={this.getType}
							generateTimeStyles={this.generateTimeStyles}
						/>
					)}
					{this.props.nav.nav === "activity" && (
						<ActivityWrapper
							page={this.props.nav.page}
							user={this.state.user}
							handleActivityClick={this.props.handleActivityClick}
						/>
					)}
					{this.props.nav.nav === "team" && <TeamWrapper page={this.props.nav.page} user={this.state.user} />}
					{this.props.nav.nav === "dev" && <DevWrapper page={this.props.nav.page} user={this.state.user} />}
					{this.props.nav.page === "legacy" && (
						<LegacyWrapper
							page={this.props.nav.page}
							user={this.state.user}
							legacy={this.props.legacy}
							saveEditField={this.props.saveEditField}
						/>
					)}
					{this.props.nav.page === "form" && (
						<FormWrapper
							settings={this.props.settings}
							page={this.props.nav.page}
							user={this.state.user}
							form={this.props.form}
							reader={this.props.reader}
							show={this.props.show}
							socket={this.props.socket.socket}
							forceReloadSockets={this.props.forceReloadSockets}
							handleDeleteFormvClick={this.props.handleDeleteFormvClick}
							handleAddFormvClick={this.props.handleAddFormvClick}
							redirectToFormv={this.props.redirectToFormv}
							handleFieldChanged={this.props.handleFieldChanged}
							handleFieldRevisionsClick={this.props.handleFieldRevisionsClick}
							handleImageZoom={this.props.handleImageZoom}
							handleCloneCSD={this.props.handleCloneCSD}
							handleAddCSD={this.props.handleAddCSD}
							handleAddCompany={this.props.handleAddCompany}
							handleDeleteFormClick={this.props.handleDeleteFormClick}
						/>
					)}
				</BodyWrapperEl>
			</React.Fragment>
		);
	}
}

export default connect(mapStateToProps)(BodyWrapper);

import styled from "styled-components";

export const CompanyWrapperEl = styled.div`
	display: block;
	position: relative;
	min-height: 50px;

	& .info-wrapper {
		width: 100%;
		text-align: center;
		padding: 15px 15px 20px 15px;
		overflow-y: auto;
		border-bottom: 1px solid var(--bg-d2);
	}

	.info-wrapper::-webkit-scrollbar {
		width: 0;
		background: transparent;
	}

	.info-wrapper::-webkit-scrollbar-thumb {
		background-color: transparent;
	}
  
	.info-wrapper::-webkit-scrollbar-track {
		background-color: transparent;
	}

	& .info-wrapper .favicon {
		height: 30px;
		width: 30px;
	}

	& .info-wrapper .title {
		color: white;
		font-size: 15px;
		margin-top: 6px;
		margin-bottom: 0;
	}

	& .info-wrapper .url {
		color: var(--lightblue);
		font-size: 12px;
		text-decoration: underline;
		display: inline-block;
		margin: 4px 0 4px 0;
		transition: color 0.05s, -moz-transform ease-in-out 0.05s;
	}

	& .info-wrapper .url::selection {
		color: white;
		background: var(--blue);
	}

	& .info-wrapper .url:hover {
		color: var(--blue);
	}

	& .info-wrapper .desc {
		color: var(--txt-lg);
		font-size: 11px;
		padding: 0 10px;
	}

	& .info-wrapper .dot {
		color: var(--txt-lg);
		font-size: 12px;
	}

	& .info-wrapper .created {
		color: var(--txt-lg);
		font-size: 11px;
		padding: 0 10px;
	}

	& .info-wrapper .created .name {
		color: white;
	}

	& .info-wrapper .created .date {
		color: var(--green);
	}

	& .info-wrapper .created span.green {
		color: var(--green);
	}

	& .info-wrapper .created span.yellow {
		color: var(--yellow);
	}

	& .info-wrapper .created span.orange {
		color: var(--orange);
	}

	& .info-wrapper .status-wrapper {
		margin: 0 0 8px 0;
		display: inline-block;
		width: 100%;
	}

	& .info-wrapper .status-wrapper .status-main-wrapper {
		display: inline-block;
		vertical-align: top;
		margin-bottom: 12px;
	}

	/* & .info-wrapper .status-wrapper .status-icon-wrapper {
		display: block;
		margin-bottom: 8px;
		background: var(--bg-d2);
		border: 1px solid var(--bg-d2);
		height: 32px;
		border-radius: 16px;
	} */

	/* & .info-wrapper .status-wrapper .status-icon-wrapper:hover {
		cursor: pointer;
	} */

	/* & .info-wrapper .status-wrapper .status-icon-wrapper.salesforce:hover {
		border: 1px solid var(--salesforce-blue);
	}

	& .info-wrapper .status-wrapper .status-icon-wrapper.admin:hover {
		border: 1px solid white;
	} */

	/* & .info-wrapper .status-wrapper .status-icon-wrapper p {
		display: inline-block;
		vertical-align: top;
		font-size: 12px;
		color: white !important;
	} */

	& .info-wrapper .status-wrapper .status-icon-wrapper .icon {
		display: inline-block;
		vertical-align: top;
		background-repeat: no-repeat;
		background-size: contain;
	}

	& .info-wrapper .status-wrapper .status-icon-wrapper.salesforce .icon {
		background-image: var(--icon-salesforce);
		height: 17px;
		width: 17px;
		margin-right: 0.5em;
	}

	& .info-wrapper .status-wrapper .status-icon-wrapper.admin .icon {
		height: 16px;
		width: 16px;
		margin-right: 0.5em;
	}

	& .info-wrapper .status-wrapper .status-dot {
		display: inline-block;
		vertical-align: top;
		height: 6px;
		width: 6px;
		border-radius: 3px;
		margin: 6px 5px 0 0;
		background: var(--txt-lg);
	}

	& .info-wrapper .status-wrapper.active .status-dot {
		background: var(--green);
	}

	& .info-wrapper .status-wrapper.paused .status-dot {
		background: var(--red);
	}

	& .info-wrapper .status-wrapper p {
		text-transform: capitalize;
		font-size: 11px;
		display: inline-block;
		vertical-align: top;
		color: var(--txt-lg);
	}

	& .info-wrapper .status-wrapper.active p {
		color: var(--green);
	}

	& .info-wrapper .status-wrapper.paused p {
		color: var(--red);
	}

	& .company-btn-wrapper {
		padding: 10px 0;
	}

	& .company-btn-wrapper .btn-wrapper {
		margin: 0;
		padding: 0 15px 0 17px;
	}

	& .company-btn-wrapper .btn-wrapper.inactive .btn {
		color: var(--txt-lg);
	}

	@media screen and (max-width: 1200px) {
		& .company-btn-wrapper .title {
			visibility: hidden;
		}

		& .info-wrapper .company-details-wrapper {
			display: none;
		}

		& .company-btn-wrapper {
			display: none;
		}
	}

  @media screen and (max-width: 480px) {
    & .company-btn-wrapper .title {
      visibility: visible;
    }

    & .info-wrapper .company-details-wrapper {
      display: block;
    }

    & .company-btn-wrapper {
      display: block;
    }
  }
`;

const form = (state = {}, action) => {
	switch (action.type) {
		case "FORM":
			return {
				form: action.form,
				formvs: action.formvs,
				vid: action.vid,
				fields: action.fields,
				tasks: action.tasks
			};
		case "UPDATE_FORM_FIELDS":
			return { ...state, fields: action.fields, formvs: action.formvs };
		default:
			return state;
	}
};

export default form;

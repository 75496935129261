import styled, { createGlobalStyle } from "styled-components";
export const GlobalStyle = createGlobalStyle`
:root,
:root#light {
	--accent: #1e90ff;
	--accent2: #d34598;
	--accent3: #816aae;
	--background: #f7f5fa;
	--background2: #eadaff;
	--background3: #e2d9f4;
	--color: #121212;
	--min-color: #676767;
	--border: #dcdcdc;
	--background-transparent: rgba(247, 245, 250, 0.8);
	--background-light: rgba(3, 5, 10, 0.05);
	--background-dark: rgba(3, 5, 10, 0.5);
	--box-shadow: rgba(5, 6, 10, 0.025);
	--shadow: 0.5em 0.5em 1em 0 var(--box-shadow), -0.5em -0.5em 1em 0 var(--background-transparent);
	--shadow-inset: inset -6px -3px 6px var(--box-shadow), inset 6px 3px 6px var(--background-transparent);
	--shadow-inset-active: inset 0 0 6px var(--box-shadow), inset 0 0 6px var(--background-transparent);
	color-scheme: light;
}
:root#dark {
	--accent: #1e90ff;
	--accent2: #d34598;
	--accent3: #816aae;
	--background: #050307;
	--background2: #20162d;
	--background3: #362c54;
	--color: #dcdcdc;
	--min-color: #999999;
	--border: #232323;
	--background-transparent: rgba(8, 10, 15, 0.8);
	--background-light: rgba(247, 245, 250, 0.05);
	--background-dark: rgba(247, 245, 250, 0.5);
	--box-shadow: rgba(211, 69, 152, 0.05);
	--shadow: 0.5em 0.5em 1em 0 var(--background-transparent), -0.5em -0.5em 1em 0 var(--box-shadow);
	--shadow-inset: inset -6px -3px 6px var(--background-transparent), inset 6px 3px 6px var(--box-shadow);
	--shadow-inset-active: inset 0 0 6px var(--background-transparent), inset 0 0 6px var(--box-shadow);
	color-scheme: dark;
}
#light {
	.top-navigation {
		background-color: rgb(255, 255, 255, 0.9);
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		.sub-menu ul {
			background-color: #fff;
		}
	}
}
body {
	padding-top: 40px;
}
.gradient-background {
	background: linear-gradient(145deg, rgba(211, 69, 152, 0.5), rgba(120, 113, 180, 0.5), rgba(7, 142, 205, 0.5));
}
	.header-wrapper {
		display: inline-flex;
		width: 100%;
		button {
			margin-left: auto;
		}
	}
`;
export const OpportunityWrapperEl = styled.div`
	.card {
		padding: 1em;
		border: 1px solid var(--border);
		border-radius: 1em;
		box-shadow: var(--shadow);
		background-color: var(--background);
	}
	.btn-txt{
		border-color: transparent !important;
		background-color: transparent;
	}
	.btn {
		/* background-color: var(--gray) !important; */
	}
	.active {
		/* background-color: var(--violet); */
		.btn {
			background-color: var(--violet) !important;
			color: #fff;
		}
	}
	.disabled {
		/* background-color: var(--gray); */
		&.btn {
			opacity: 0.75;
		}
	}
	.table-responsive {
		max-height: 20em;
		overflow-y: auto;
	}
	.activity-wrapper {
		width: calc(100% - 1em);
	}
	.workflow-wrapper {
		/* padding: 2em 0; */
	}
	.top-navigation {
		background-color: rgba(30, 30, 30, 0.85);
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
		backdrop-filter: blur(3px);
		position: fixed;
		top: 0;
		transition: top 500ms ease;
		z-index: 99;
		display: inline-flex;
		flex-wrap: wrap;
		justify-content: start;
		width: 100%;
		& > button {
			flex: 1 1;
			width: 100%;
		}
		.logo-button {
			padding: 1em;
		}
		/* .wiki-button {
			padding: 1em;
			.wiki-icon {
				padding: 0.5em;
				margin-right: 0.5em;
				background-image: var(--icon-wiki-lg);
				background-repeat: no-repeat;
				background-position: center;
			}
		} */
		.icon-user {
			align-self: center;
			margin: 0.5em 1em 0 auto;
			border: 2px solid;
			button {
				padding: 0 !important;
			}
			img {
				width: 30px;
				height: 30px;
			}
		}
		.profile-button {
			background-repeat: no-repeat;
			background-size: cover;
			border: 2px solid;
			height: 3em;
			padding: 1em;
			flex: 0 0 2em;
			align-self: center;
			width: 3em !important;
			border-radius: 10em !important;
			margin: 1em;
			position: relative;
		}
		.theme-button {
			background-image: var(--icon-moon-g);
			background-repeat: no-repeat;
			border: none;
			padding: 1em 1em 1em 3em;
		}
		.menu-item {
			z-index: 9999999;
			align-content: center;
			.btn {
				flex: 1 1;
				width: 100%;
				text-transform: uppercase;
				border-radius: 0;
			}
			.btn-logo a {
				opacity: 1;
				display: inline-block;
   				vertical-align: middle;
			}
			a,
			.btn {
				text-decoration: none;
				color: inherit;
				opacity: 0.75;
				letter-spacing: 1px;
				word-break: break-word;
				&:hover {
					opacity: 1;
				}
			}
			.active {
				opacity: 1;
				border-bottom: 2px solid var(--violet);
			}

			/* &:last-child .sub-menu {
				right: 0;
			} */
		}
		.sub-menu {
			min-width: 10em;
			max-width: 30em;
			font-size: 0.8em;
			position: absolute;
			left: -9999999em;
			z-index: 100;
			opacity: 0;
			transition: opacity 500ms ease;
			.icon {
				width: 1em;
				height: 1em;
				display: inline-block;
				margin-right: 0.5em;
			}
			ul {
				padding: 1em;
				box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
				border-radius: 1em;
				border: 1px solid rgba(0, 0, 0, 0.1);
				margin-top: 1em !important;
				background: rgba(25, 25, 25, 0.95);
				backdrop-filter: blur(3px);
			}
			a {
				display: block;
			}
			.btn {
				list-style-type: none;
				margin: 0;
				text-align: left;
				border-color: transparent;
			}
			li:not(:last-child) {
				border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			}
		}
		.menu-item:hover .sub-menu {
			left: auto;
			opacity: 1;
		}
		.sub-menu:hover {
			display: block;
			opacity: 1;
		}
		.sub-sub-menu {
			padding: 1em;
		}
		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			li {
				padding: 0.1em 0;
			}
		}
	}
	.task-form {
		width: 100%;
		input {
			width: 100%;
		}
		label {
			display: block;
		}
		.input-wrapper {
			width: 100%;
		}
	}
	.opportunity-wrapper {
		max-width: 100em;
		margin: auto;
		table {
			font-size: 0.8em;
			width: 100%;
			th {
				text-align: left;
				color: var(--min-color);
			}
		}
		.thumbnail-wrapper {
			flex: 1 1 46%;
			margin: 2%;
			text-align: center;
		}
		/* .ant-image {
			flex: 1 1 46%;
    		margin: 2%;
		} */
		background-color: var(--background-light);
		.opportunity-header {
			padding: 2em;
			h1,
			h2 {
				color: var(--accent3) !important;
			}
			a {
				color: var(--accent2) !important;
			}
			// position: sticky;
			// top: 0;
			// background: var(--background-transparent);
			// backdrop-filter: blur(2px);
			// filter: grayscale(1);
			// z-index: 9;
		}
		.opportunity-nav {
			margin-bottom: 1em;
			max-width: 40em;
			margin: 0 auto;
			li {
				line-height: 2;
			}
			.btn {
				padding: 0;
				text-decoration: underline;
			}
		}
		.badge {
			text-transform: uppercase;
		}
		h1,
		h2 {
			text-align: center;
		}
		h1,
		h2,
		h3,
		h4 {
			font-size: 2em;
			padding-bottom: 0.25em;
			word-wrap: break-word;
		}
		h2 {
			font-size: 2em;
		}
		h3 {
			font-size: 2em;
		}
		h4 {
			font-size: 1.125em;
			text-transform: uppercase;
			letter-spacing: 1px;
		}
		.w-tc-editor-preview {
			font-family: "Inconsolata", "Menlo", "Consolas", monospace !important;
		}
		a:not(.button) {
			color: dodgerblue;
			text-decoration-line: underline;
			text-decoration-thickness: from-font;
			text-underline-offset: 3px;
			transition: all 1000ms ease;
			white-space: break-spaces;
		}
		a:hover,
		a:active,
		a:focus {
			text-underline-offset: 6px;
		}
		.button {
			display: inline-block;
			text-transform: uppercase;
			letter-spacing: 1px;
			background: transparent;
			color: #fff;
			padding: 0.5em 1em;
			border-radius: 0.5em;
			border: 1px solid;
		}
		.button:hover {
			background: #fff;
			color: #000;
			border: 1px solid #000;
			svg {
				fill: #000;
			}
		}
		.button-list {
			padding: 0.5em 0;
			display: inline-flex;
			width: 100%;
			flex-flow: wrap;
			color: #fff;
			fill: #fff;
			.button {
				display: inline-block;
				margin: 0 0.25em 0.25em 0;
				font-size: 0.9em;
			}
		}
		#page_view {
			max-width: 340px;
			margin: auto;
			text-align: center;
			padding: 1em;
			text-transform: uppercase;
			letter-spacing: 1px;
			input {
				vertical-align: baseline;
				margin-left: 1em;
			}
		}
		.code-wrapper {
			width: 100%;
			.cm-editor {
				width: 100%;
			}
			.cm-theme {
				max-height: 90vh;
				display: inline-flex;
				width: 100%;
				&.fourth {
					width: 25%;
				}
				&.third {
					width: 33.33333%;
				}
				&.half {
					width: 50%;
				}
			}
			textarea {
				display: block;
				width: 100%;
			}
			/* label {
			display: block;
			width: 100%;
		} */
		}
		.flex-wrapper {
			display: inline-flex;
			flex-flow: wrap;
			width: 100%;
			justify-content: space-around;
			& > * {
				padding-top: 1em;
				padding-bottom: 1em;
				// margin: 1em 0;
			}
		}
		iframe {
			width: 100%;
			height: 80vh;
			display: block;
			border: none;
		}
		.badge {
			background: #000;
			border-radius: 1em;
			color: #fff;
			padding: 0.25em 0.75em;
			font-size: 0.75em;
			margin: 0 0.5em;
			text-transform: uppercase;
			font-weight: 900;
			&.tt {
				background: var(--darkgreen);
			}
			&.lc {
				background: var(--violet);
			}
			&.pc,
			&.em {
				background: var(--blue);
			}
			&.nc {
				background: var(--gray);
			}
		}
		& .salesforce-wrapper,
		& .summary-wrapper {
			font-size: 0.9em;
			padding: 1em 1em 2em 1em;
			transition: opacity 500ms ease;
			.client-information {
				padding: 1em;
				margin-top: 1em;
			}
			dl {
				color: var(--color);
				width: 100%;
				display: inline-flex;
				flex-wrap: wrap;
				div {
					display: flex;
					width: 100%;
				}
			}
			dt {
				font-weight: 600;
				padding: 0.25em;
				padding-right: 1em;
				width: 33.333%;
				text-align: left;
			}
			dd {
				padding: 0.25em;
				width: 66.666%;
				text-align: left;
			}
			h3 {
				padding: 0;
			}
			a::not(.button)::not(button) {
				display: block;
			}
			table {
				width: 100%;
				table-layout: fixed;
				td:nth-child(1):not(:last-child) {
					width: 15em;
					font-weight: 700;
				}
				tr:not(:last-child) td {
					border-bottom: 1px solid var(--border);
				}
				td {
					padding: 0.25em;
					max-width: 100%;
					text-align: left;
					div {
						word-break: break-all;
						white-space: nowrap;
						flex-direction: column;
						img {
							display: block;
							margin: 0 auto;
							max-height: 90vh;
						}
					}
				}
			}
			.admin-badge {
				background: #000;
				color: #fff;
				font-weight: 900;
				border-radius: 5px;
				padding: 0.25em;
			}
			.btn-primary {
				background-color: var(--background-transparent);
			}
			& select,
			& textarea {
				background-color: var(--background-transparent);
				display: block;
				width: 100%;
				margin-bottom: 1em;
				padding: 0.5em;
				&::placeholder {
					color: var(--min-color);
				}
			}
		}
		.client-notes {
			padding-top: 1em;
			.client-note {
				background: rgba(255, 255, 255, 0.05);
				padding: 1em;
				margin-bottom: 0.5em;
				border: 1px solid;
			}
			.current-stage {
				background: rgba(255, 255, 0, 0.5);
				padding: 1em;
			}
		}
		.implementation-guide-wrapper {
			max-width: 40em;
			width: 100%;
			.tag-step {
				border: 1px solid transparent;
				border-radius: 5px;
				transition: border 100ms ease-out;
				word-break: break-word;
				&.script:hover {
					border: 1px solid yellow;
				}
				cursor: pointer;
				&:focus {
					border: 1px solid;
					outline: none;
				}
				&.copied.script {
					border: 1px solid green;
					position: relative;
					&:after {
						content: "Copied";
						background: #333;
						color: #fff;
						padding: 0.25em;
						position: absolute;
						top: 0em;
						right: 0;
						margin: auto;
						left: 0;
						width: 5em;
						border-radius: 10em;
						text-align: center;
					}
				}
			}
			p {
				white-space: pre-line;
			}
			ol {
				& > div {
					padding: 0.5em 2em;
				}
				li {
					padding-bottom: 1em;
					img {
						max-width: 100%;
					}
				}
			}
			pre {
				margin-top: 2em;
				border-left: 1px solid;
				padding-left: 1em;
				font-size: 0.8em;
			}
		}
		.code_builder {
			font-size: 0.9em;
			padding: 1em 0.5em;
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			flex: 1 1 100%;
			input {
				vertical-align: middle;
				margin-right: 0.5em;
			}
			label {
				display: block;
			}
			fieldset {
				background: #fff;
				border: 1px solid #ccc;
				border-radius: 1em;
				padding: 1em;
				margin-bottom: 1em;
				flex: 1 1 auto;
			}
			legend {
				background: #fff;
				padding: 0 1em;
				border-radius: 1em;
				border: 1px solid #ccc;
			}
		}
		& .body-wrapper.body-wrapper.body-wrapper {
			padding: 25px !important;
			text-align: left;
			margin-top: 60px;
			max-width: 1750px;
			margin: auto;
		}
		& .body-wrapper.show {
			animation: company-body-show-ani 0.25s ease-in-out 1 forwards;
			-webkit-animation: company-body-show-ani 0.25s ease-in-out 1 forwards;
			-moz-animation: company-body-show-ani 0.25s ease-in-out 1 forwards;
			-o-animation: company-body-show-ani 0.25s ease-in-out 1 forwards;
		}
		@keyframes company-body-show-ani {
			0% {
				opacity: 0;
				top: 70px;
			}
			100% {
				opacity: 1;
				top: 60px;
			}
		}
		@media screen and (max-width: 1200px) {
			.body-wrapper {
				padding: 6em 2em 6em 6em !important;
			}
		}
	}
`;

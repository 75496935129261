// Resources
import React, { Component } from "react";
import Cookies from "universal-cookie";

// Styles
import { TopNavWrapperEl, GlobalStyle } from "./styles/TopNavWrapper";
import logo from "../../images/logo.svg";

class TopNavWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			versionName: "",
			versionNameNew: "",
			saveVersionNameAllowed: false,
			textTooLong: false
		};
	}

	_optionSelectedStatic = (e) => {
		const version = e.target.options[e.target.selectedIndex].getAttribute("data-id");
		if (version && typeof this.props.redirectToFormv === "function") {
			this.props.redirectToFormv(version);
		}
	};

	_handleDeleteVersionClicked = () => {
		if (this.props.form.vid && typeof this.props.handleDeleteFormvClick === "function") {
			this.props.handleDeleteFormvClick(this.props.form.vid);
		}
	};

	_handleAddVersionClicked = () => {
		if (this.props.form.vid && typeof this.props.handleAddFormvClick === "function") {
			if (this.state.saveVersionNameAllowed) {
				this.props.handleAddFormvClick(this.props.form.vid, this.state.versionNameNew, () => {
					this.setState({
						versionName: this.state.versionNameNew,
						saveVersionNameAllowed: false
					});
				});
			} else {
				this.props.handleAddFormvClick(this.props.form.vid);
			}
		}
	};

	_handleVersionNameFieldChanged = (evt) => {
		const text = evt.target.textContent;
		if (text.length < 64) {
			this.setState({
				textTooLong: false,
				versionNameNew: evt.target.textContent,
				saveVersionNameAllowed: this.state.versionName !== evt.target.textContent && evt.target.textContent
			});
		} else {
			// Text is too long
			this.setState({
				textTooLong: true,
				saveVersionNameAllowed: false
			});
		}
	};

	initVersionName = () => {
		if (this.props.form && this.props.form.formvs && this.props.form.vid) {
			let name = "";
			for (let i = 0; i < this.props.form.formvs.length; i++) {
				if (this.props.form.formvs[i]._id === this.props.form.vid) {
					name = this.props.form.formvs[i].name;
					break;
				}
			}
			this.setState({
				versionName: name,
				versionNameNew: name
			});
		} else {
			setTimeout(() => {
				this.initVersionName();
			}, 100);
		}
	};

	componentDidMount() {
		if (this.props.page === "form") this.initVersionName();
	}
	angleIcon = (
		<svg width="10px" height="10px" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
			<path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z" fill="currentColor" />
		</svg>
	);

	render() {
		let cookies = new Cookies();
		const origin = document.location.protocol + "//" + document.location.host;
		return (
			<React.Fragment>
				<GlobalStyle />
				<TopNavWrapperEl className="top-navigation">
					<div className="menu-item">
						<button className="btn btn-link btn-logo">
							<a href={origin + "/"}>
								<img src={logo} alt={"logo"} className={"icon"} />
							</a>{" "}
							<a
								href={origin + "/"}
								style={{ fontSize: "1.5em", paddingLeft: "1em", letterSpacing: "2px", height: "auto" }}
							>
								CSD {this.angleIcon}
							</a>
						</button>
						<div className="sub-menu">
							<ul style={{ paddingLeft: "1em" }}>
								<li>
									<a href={origin + "/wiki"} className="btn btn-link btn-block">
										Wiki
									</a>
								</li>
								<li>
									<a
										href="https://www2.upsellit.com/admin/control/edit/index.jsp"
										target="_blank"
										rel="noreferrer"
										className="btn btn-link btn-block"
									>
										Admin
									</a>
								</li>
								<li>
									<a
										href="https://upsellit.lightning.force.com/lightning"
										target="_blank"
										rel="noreferrer"
										className="btn btn-link btn-block"
									>
										Salesforce
									</a>
								</li>
							</ul>
						</div>
					</div>

					{this.props.page === "legacy" && (
						<div className={"form-header"}>
							<div className="line" />
							<a className="title legacy" href={origin + "/?nav=legacy"}>
								Legacy Dashboard
							</a>
						</div>
					)}
					<div className="form-nav-wrap">
						{/* COMPANY */}
						{this.props.form &&
							this.props.form.form &&
							this.props.form.form._company &&
							this.props.form.form._company.title && (
								<div
									className={"form-header form-header-shrink"}
									title={this.props.form.form._company.title}
								>
									<div className="line" />
									{this.props.form.form._company.favicon && (
										<img
											className="favicon"
											src={this.props.form.form._company.favicon}
											alt={this.props.form.form._company.title}
											//onError={e => e.target.src = "/static/media/clear.379f347c.svg"}
										/>
									)}
									<a
										className="title company"
										href={origin + "/company?id=" + this.props.form.form._company._id}
									>
										{this.props.form.form._company.title}
									</a>
								</div>
							)}

						{/* FORM TITLE */}
						{this.props.form && this.props.form.form && this.props.form.form.title && (
							<div className={"form-header form-header-shrink"} title={this.props.form.form.title}>
								<div className="line" />
								<p className={"type " + this.props.form.form.type}>{this.props.form.form.type}</p>
								<p
									className="title csd"
									data-tip={this.props.form.form.title + " <span>Click to Copy</span>"}
									data-html={true}
								>
									{this.props.form.form.title}
								</p>
							</div>
						)}

						{/* FORM VERSIONS */}
						{this.props.form &&
							this.props.form.formvs &&
							this.props.form.formvs.length > 0 &&
							this.props.form.vid && (
								<div className="form-header">
									<div className="line" />
									{this.state.versionName && (
										<div className={"version-name-wrapper"}>
											<div
												className={"version-name" + (this.state.textTooLong ? " long" : "")}
												contentEditable={true}
												suppressContentEditableWarning={true}
												onKeyUp={this._handleVersionNameFieldChanged}
											>
												{this.state.versionName}
											</div>
											{this.state.textTooLong && (
												<p className="version-name-warning">Max 64 Characters</p>
											)}
										</div>
									)}
									<div className="version-menu-wrapper">
										<select
											onChange={(e) => this._optionSelectedStatic(e)}
											defaultValue={this.props.form.vid}
										>
											{this.props.form.formvs.map((option) => (
												<option
													key={option._id}
													value={option._id}
													data-id={option.version_main}
												>
													{this.props.form.vid !== option._id
														? "v" +
														  option.version_main +
														  "." +
														  option.version_sub +
														  " - " +
														  option.name
														: "v" + option.version_main + "." + option.version_sub}
												</option>
											))}
										</select>
									</div>

									<div
										className={"add-btn" + (this.state.saveVersionNameAllowed ? " save" : "")}
										onClick={this._handleAddVersionClicked}
									>
										{this.state.saveVersionNameAllowed && <span>Save</span>}
										{!this.state.saveVersionNameAllowed && <span>&#43;</span>}
									</div>
									<a
										className="btn help-btn"
										target="_blank"
										href="/wiki/creating_csd_tasks_and_new_major_versions"
										rel="noreferrer"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="20px"
											height="20px"
											viewBox="0 0 24 24"
											fill="none"
										>
											<path
												d="M10 16.584V18.9996C10 20.1042 10.8954 20.9996 12 20.9996C13.1046 20.9996 14 20.1042 14 18.9996L14 16.584M12 3V4M18.3643 5.63574L17.6572 6.34285M5.63574 5.63574L6.34285 6.34285M4 12H3M21 12H20M17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12Z"
												stroke="currentColor"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</a>

									{this.props.form.formvs.length > 1 && (
										<button
											className={"delete-btn"}
											onClick={this._handleDeleteVersionClicked}
											aria-label="Delete Main Version"
											title="Delete Main Version"
										>
											<svg
												width="15px"
												height="15px"
												viewBox="0 0 24 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M10 11V17"
													stroke="currentColor"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M14 11V17"
													stroke="currentColor"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M4 7H20"
													stroke="currentColor"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z"
													stroke="currentColor"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
													stroke="currentColor"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										</button>
									)}
								</div>
							)}
					</div>
					<div style={{ marginLeft: "auto", marginRight: "1em", minWidth: "5em", display: "flex" }}>
						{this.props.form && this.props.user && (
							<>
								<div className="form-header-right-wrapper">
									<button
										className={"btn save-all-btn" + (this.props.length > 0 ? " allowed" : "")}
										onClick={this.props._handleSaveAllClick}
									>
										Save
									</button>
								</div>
								<div className="menu-hover-wrapper">
									<div className="menu-icon">&#8942;</div>
									<div
										className="menu-wrapper"
										data-id={this.props.form._id}
										data-formtype={this.props.form.type}
										data-title={this.props.form.title}
									>
										<div className="menu-btn clone" onClick={this.props._handleCloneFormClicked}>
											Clone
										</div>
										<div className="menu-btn edit" onClick={this.props._handleEditFormClicked}>
											Edit
										</div>
										{(this.props.user.type === "sudo" ||
											this.props.user.type === "admin" ||
											this.props.user._id === this.props.form._user) && (
											<div
												className="menu-btn delete"
												onClick={this.props._handleDeleteFormClicked}
											>
												Delete
											</div>
										)}
										<h4>Shortcuts</h4>
										<p>
											Save: <kbd>CMD/CTRL</kbd> + <kbd>S</kbd>
										</p>
										<p>
											Toggle Mode: <kbd>CMD/CTRL</kbd> + <kbd>E</kbd>
										</p>
										<p>
											Underline: <kbd>CMD/CTRL</kbd> + <kbd>U</kbd>
										</p>
										<p>
											Bold: <kbd>CMD/CTRL</kbd> + <kbd>B</kbd>
										</p>
										<p>
											Italic: <kbd>CMD/CTRL</kbd> + <kbd>I</kbd>
										</p>
									</div>
								</div>
							</>
						)}

						{this.props.user && (
							<div style={{ marginRight: "1em", minWidth: "5em" }}>
								<div className="menu-item">
									<div>
										<button
											className="profile-button btn btn-link"
											style={{
												backgroundImage: `url(${this.props.user.picture})`,
												backgroundSize: "cover"
											}}
										></button>{" "}
										<span style={{ marginTop: "1.25em", display: "inline-block" }}>
											{this.angleIcon}
										</span>
									</div>
									<div className="sub-menu">
										<ul>
											<li>
												<button
													className="btn btn-link btn-block"
													onClick={(e) => {
														let theme = cookies.get("theme") || "dark";
														if (theme === "light") {
															cookies.set("theme", "dark", {
																path: "/",
																maxAge: 315360000
															});
															document.documentElement.id = "dark";
														} else {
															cookies.set("theme", "light", {
																path: "/",
																maxAge: 315360000
															});
															document.documentElement.id = "light";
														}
													}}
												>
													<span
														className="icon"
														style={{
															background: "var(--icon-moon-g)"
														}}
													></span>
													Theme
												</button>
											</li>
											<li>
												<a href="/wiki/csd_overview" className="btn btn-link btn-block">
													<span className="icon" style={{}}>
														?
													</span>
													Help
												</a>
											</li>
											<li>
												{this.props.user && (
													<button
														onClick={() => this._handleLogout()}
														className="btn btn-link btn-block"
													>
														<span
															className="icon"
															style={{
																background: "var(--icon-logout-g)"
															}}
														></span>
														Logout
													</button>
												)}
											</li>
										</ul>
									</div>
								</div>
							</div>
						)}
					</div>
				</TopNavWrapperEl>
			</React.Fragment>
		);
	}
}

export default TopNavWrapper;

import styled from "styled-components";

export const AddCompanyWrapperEl = styled.div`
	position: absolute;
	top: 5%;
	width: 500px;
	left: calc(50% - 250px);
	min-height: 200px;
	max-height: 90%;
	overflow: auto;
	background: var(--bg-l1-2);
	border-radius: 3px;
	transition: height 0.15s, -moz-transform ease-in-out 0.15s;

	& .modal-header-wrapper {
		//background: red;
		outline: 1px dotted red;
		//min-height: 300px;
		background: white;
		padding: 25px 0;
	}

	& .cancel-btn {
		height: 30px;
		width: 30px;
		position: absolute;
		top: 20px;
		right: 25px;
		background: rgba(240, 63, 77, 0.25);
		text-align: center;
		border-radius: 15px;
		transition: background 0.05s, color 0.05s, -moz-transform ease-in-out 0.05s;
	}

	& .cancel-btn:hover {
		cursor: pointer;
		background: var(--red);
	}

	& .cancel-btn:hover > span {
		color: white;
	}

	& .cancel-btn span {
		font-size: 14px;
		opacity: 1;
		font-weight: 700;
		color: var(--red);
	}

	& .title-wrapper {
		display: block;
	}

	& .title-wrapper h1 {
		color: var(--txt-d);
		font-size: 16px;
		padding-left: 25px;
	}

	& .title-wrapper p {
		color: var(--txt-dg);
		font-size: 13px;
		padding-left: 25px;
		margin-top: 5px;
	}

	& .url-wrapper {
		display: block;
		padding: 15px 25px;
		position: relative;
	}

	& .url-wrapper label {
		display: block;
		font-size: 13px;
		margin-bottom: 2px;
		margin-left: 0;
	}

	& .url-wrapper p {
		display: block;
		font-size: 13px;
		margin-bottom: 10px;
		color: var(--txt-dg);
		margin-left: 0;
	}

	& .url-wrapper input {
		display: block;
		font-size: 13px;
		margin-bottom: 5px;
		width: 100%;
		padding: 15px;
		border-radius: 3px;
		border: 1px solid var(--bg-l1-3);
		background: var(--bg-l1);
		color: black;
	}

	& .url-wrapper input::selection {
		background: rgba(31, 133, 222, 0.4);
	}

	& .url-wrapper input::placeholder {
		color: var(--txt-lg);
	}

	& .url-wrapper input:focus {
		border: 1px solid var(--blue);
	}

	& .url-wrapper input.valid {
		border: 1px solid var(--lt-green) !important;
		background: var(--highlight-green-d) !important;
	}

	& .manual-entry-btn {
		//background: var(--blue);
		padding: 0 25px;
		font-size: 13px;
		color: var(--blue);
		border-radius: 3px;
		text-align: center;
		margin: 15px auto 5px auto;
		transition: background 0.05s, -moz-transform ease-in-out 0.05s;
	}

	& .manual-entry-btn:hover {
		cursor: pointer;
		text-decoration: underline;
	}

	& .login-error-wrapper {
		width: 100%;
		padding: 0 25px 0 25px;
		margin-top: 15px;
	}

	& .company-data-wrapper {
		display: block;
		padding: 5px 25px 35px 25px;
		position: relative;
		min-height: 100px;
		//background: var(--bg-l1-2);
		border-top: 1px solid var(--bg-l1-3);
	}

	& .company-data-section {
		padding-top: 10px;
	}

	& .company-data-section label {
		font-size: 12px;
		margin-left: 1px;
		display: inline-block;
		vertical-align: top;
		margin-top: 5px;
		pointer-events: none;
		color: var(--txt-d);
	}

	& .company-data-section p {
		font-size: 12px;
		margin-top: 6px;
		margin-right: 2px;
		float: right;
		color: var(--txt-dg);
		pointer-events: none;
	}

	& .company-data-section.red p {
		color: var(--darkred);
	}

	& .company-data-section.green p {
		color: var(--lt-green);
	}

	& .company-data-section.red label {
		color: var(--darkred);
	}

	& .company-data-section.green label {
		color: var(--lt-green);
	}

	& .company-data-section label a {
		pointer-events: auto;
		color: var(--darkviolet);
	}

	& .company-data-section label a:hover {
		cursor: pointer;
		text-decoration: underline;
	}

	& .company-data-section .favicon {
		margin-top: 4px;
		margin-left: 5px;
		height: 16px;
		width: 16px;
		vertical-align: top;
	}

	& .company-data-section textarea {
		font-size: 13px;
		margin-top: 6px;
		display: block;
		width: 100%;
		border-radius: 3px;
		padding: 10px;
		border: 1px solid var(--bg-l3);
		height: inherit;
		margin-bottom: 5px;
		resize: none;
		background: white;
		color: black;
	}

	& .company-data-section textarea::selection {
		background: rgba(31, 133, 222, 0.4);
	}

	& .company-data-section.red textarea {
		border: 1px solid var(--darkred);
		background: var(--highlight-red-d);
	}

	& .company-data-section.green textarea {
		border: 1px solid var(--lt-green);
		background: var(--highlight-green-d);
	}

	& .company-data-section textarea::placeholder {
		color: var(--txt-dg);
	}

	& .company-submit-btn {
		font-size: 1.25em;
	}

	& .company-redirect-btn {
		background: var(--violet);
		padding: 20px;
		font-size: 13px;
		font-weight: 700;
		color: white;
		border-radius: 3px;
		text-align: center;
		margin: -5px auto 10px auto;
		transition: background 0.05s, -moz-transform ease-in-out 0.05s;
	}

	& .company-redirect-btn:hover {
		cursor: pointer;
		background: var(--darkviolet);
	}

	@media screen and (max-height: 800px) {
		top: 0;
		max-height: 100%;
		height: 100%;
		border-radius: 0;
	}
`;

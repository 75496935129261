import styled from "styled-components";

export const SideBarWrapperEl = styled.div`
  display: block;
  background: var(--bg-d1);
  height: 100%;
  position: relative;
  border-right: 1px solid var(--bg-d2);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  justify-content: flex-start;
`;

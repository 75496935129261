const chat = (state = {}, action) => {
	if (action.type === "CHAT_INIT") {
		return {
			messages: action.messages,
			id: action.id
		};
	} else if (action.type === "CHAT_ADD") {
		return {
			messages: action.messages
		};
	} else {
		return state;
	}
};

export default chat;

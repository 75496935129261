import styled from "styled-components";
export const TeamWrapperEl = styled.div`
	display: block;
	position: relative;
	font-size: 1em;
	& .body-wrapper {
		position: absolute;
		top: 60px;
		padding: 25px 25px 100px 25px;
	}
	& .team-column {
		min-height: 500px;
		position: relative;
		width: 90%;
		margin: 0 auto 15px auto;
		min-width: 200px;
		max-width: 500px;
	}
	& .team-column h1 {
		color: var(--txt-lg);
		font-size: 14px;
		margin-bottom: 25px;
		vertical-align: top;
	}
	& .team-column h1 span {
		font-size: 13px;
	}
	& .team-column h1 img {
		height: 18px;
		width: 18px;
		margin: 0 8px 0 0;
		vertical-align: top;
	}
	& .team-column h1 img.team {
		background-image: var(--icon-team-lg);
	}
	& .user-row {
		margin: 10px auto;
		background: var(--bg-d2);
		border-radius: 3px;
		transition: background 0.05s, border 0.05s, -moz-transform ease-in-out 0.05s;
		position: relative;
		text-align: left;
		padding: 0 20px;
	}
	& .user-row.self {
		border-left: 3px solid var(--blue);
	}
	& .user-row .favicon {
		font-size: 12px;
		display: inline-block;
		vertical-align: top;
		width: 40px;
		height: 40px;
		margin: 19px 0 0 0;
		border-radius: 20px;
		overflow: hidden;
	}
	& .user-row .text {
		padding: 10px 0;
		height: auto !important;
		display: inline-block;
		vertical-align: top;
		text-align: left;
		margin-left: 12px;
	}
	& .user-row .title {
		margin-top: 5px;
		font-size: 13px;
		color: var(--txt-lg);
		margin-bottom: 4px;
	}
	& .user-row .title span {
		font-size: 13px;
		color: white;
	}
	& .user-row .title .admin-badge {
		display: inline-block;
		vertical-align: top;
		font-size: 9px;
		background: var(--bg-d1);
		border: 1px solid var(--bg-d1);
		margin: 1px 0 0 5px;
		padding: 0 6px;
		border-radius: 8px;
		height: 16px;
		color: white;
	}
	& .user-row .time {
		color: var(--txt-lg);
		font-size: 12px;
		margin-top: 4px;
	}
	& .user-row .tz {
		color: var(--txt-lg);
		font-size: 12px;
		margin-top: 4px;
	}
	& .user-row .tz span {
		color: var(--txt-lg);
	}
	& .user-row .tz span.yellow {
		color: var(--yellow);
	}
	& .user-row .time span.green {
		color: var(--green);
	}
	& .user-row .time span.yellow {
		color: var(--yellow);
	}
	& .user-row .time span.orange {
		color: var(--orange);
	}
	& .user-row .left-wrapper {
		display: inline-block;
		vertical-align: top;
	}
	& .user-row .department-wrapper {
		padding: 6px 0 6px 0;
		display: inline-block;
		vertical-align: top;
	}
	& .user-row .department {
		margin: 0 0 0 -2px;
		display: inline-block;
		vertical-align: top;
		font-size: 11px;
		font-weight: 500;
		background: var(--bg-d1);
		padding: 0.5em;
		border-radius: 12px;
		color: white;
		&.devfrontend, &.devqa, &.devbackend, &.devfrontend {
			background-color: rgb(60, 139, 217);
		}
		&.partnershipexecutive, &.clientsuccessmanager, &.projectmanager {
			background-color: rgb(188, 65, 153);
		}
		 &.salesoperations, &.strategicpartnerships, &.businessdevelopmentrep {
			background-color: rgb(188, 65, 153);
		}
		&.graphics, &.marketing {
			background-color: rgb(255, 0, 140);
		}
		&.dataanalyst, &.accounting, &.humanresources {
			background-color: rgb(255, 107, 79);
		}
		&.owner, &.executive {
			background-color: rgb(136, 103, 178);
		}
	}
	& .user-row .department-update-wrapper {
		display: inline-block;
		vertical-align: top;
		margin: -2px 10px 0 4px;
	}
	& .user-row .department-update-wrapper select {
		background: transparent;
		border: 1px solid transparent;
		outline: none;
		font-size: 12px;
		margin: 0;
		padding: 0.5em;
		width: 20px;
		text-align: center;
		position: relative;
		top: 2px;
	}
	& .user-row .upload-wrapper {
		padding-bottom: 15px;
	}
	& .user-row .dz-container {
		background: var(--bg-d1);
		border: 2px dashed var(--bg-d0-dash);
		border-radius: 3px;
		padding: 10px 0;
	}
	& .user-row .dz-container p {
		text-align: center;
		font-size: 12px;
	}
	& .user-row .dz-container p.warning {
		color: var(--txt-lg);
	}
	& .user-row .dropzone:hover {
		cursor: pointer;
	}
	& .user-row .crop-wrapper {
		margin: 10px 0;
		border-radius: 3px;
		overflow: hidden;
		width: 100%;
		height: 300px;
		position: relative;
		background: transparent;
	}
	& .user-row .save-crop-wrapper {
		width: 100%;
		background: var(--blue);
		text-align: center;
		padding: 10px 0;
		color: white;
		border-radius: 3px;
		font-size: 13px;
	}
	& .user-row .save-crop-wrapper:hover {
		cursor: pointer;
		background: var(--darkblue);
	}
	& .user-row .processing-wrapper {
		width: 100%;
		text-align: center;
		padding: 15px 0 15px 0;
		color: white;
		display: inline-block;
		margin-bottom: 20px;
		background: var(--highlight-orange);
		border: 1px solid rgba(244, 128, 36, 0.45);
		border-radius: 3px;
		font-size: 13px;
	}
	@media screen and (max-width: 1200px) {
		.body-wrapper {
			padding: 4em 2em 6em 5em !important;
		}
	}
`;

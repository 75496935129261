import styled from "styled-components";
export const LegacyWrapperEl = styled.div`
	display: block;
	position: relative;
	font-size: 1em;
	& .body-wrapper {
		position: absolute;
		top: 60px;
		padding: 0 25px 100px 25px;
		min-width: 600px;
	}
	& .body-content-wrapper::-webkit-scrollbar {
		width: 10px;
		background: transparent;
	}
	& .body-content-wrapper::-webkit-scrollbar-thumb {
		background-color: var(--bg-d2);
	}
	& .body-content-wrapper::-webkit-scrollbar-track {
		background-color: transparent;
	}
	& .body-wrapper .body-content-wrapper {
		height: 100%;
	}
	& .body-wrapper .fields-body-wrapper {
		text-align: left;
		display: inline-block;
		vertical-align: top;
		width: 100%;
		min-width: 500px;
		max-width: 1200px;
		padding: 16px 0;
		margin-top: 0;
		margin-bottom: 100px;
		position: relative;
		top: 10px;
	}
	& .body-wrapper .fields-body-wrapper .section-wrapper {
		margin-bottom: 10px;
		border: 1px solid var(--bg-d2);
		border-radius: 3px;
		position: relative;
	}
	& .body-wrapper .fields-body-wrapper .section-wrapper h1 {
		color: white;
		background: var(--bg-d2);
		padding: 0 10px;
		font-size: 12px;
		text-align: left;
		width: calc(100% + 2px);
		margin-top: -1px;
		margin-left: -1px;
		border-top-left-radius: 4px;
	}
	& .body-wrapper .fields-body-wrapper .section-wrapper .title-container {
		color: white;
		background: var(--bg-d2);
		border: 1px solid var(--bg-d2);
		padding: 0 10px 0 5px;
		font-size: 12px;
		text-align: left;
		width: calc(100% + 2px);
		margin-top: -1px;
		margin-left: -1px;
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
	}
	& .body-wrapper .fields-body-wrapper .section-wrapper h3 {
		display: inline-block;
		vertical-align: top;
		padding: 0 8px;
		position: relative;
		top: 1px;
	}
	& .section-wrapper .section-fields-wrapper {
		padding: 10px;
	}
	& .field-row {
		padding: 3px 0;
		text-align: left;
	}
	& .field-row p,
	a {
		font-size: 12px;
		display: inline-block;
		vertical-align: top;
	}
	& .field-row p.type {
		padding: 1px 5px 2px 5px;
		border-radius: 2px;
		margin-right: 6px;
		font-size: 10px;
	}
	& .field-row p.tt {
		background: var(--darkgreen2);
	}
	& .field-row p.lc {
		background: var(--violet);
	}
	& .field-row p.pc {
		background: var(--blue);
	}
	& .field-row p.sv {
		background: var(--txt-dg);
	}
	& .field-row .title-wrapper {
		display: inline-block;
		vertical-align: top;
		width: 200px;
		color: var(--txt-lg);
		padding-right: 20px;
		text-align: right;
	}
	& .field-row .title-wrapper.group {
		margin-top: 7px;
	}
	& .field-row .title-wrapper-text:hover {
		cursor: pointer;
		text-decoration: underline;
	}
	& .field-row .content-wrapper {
		display: inline-block;
		vertical-align: top;
		width: calc(100% - 200px);
		white-space: pre-line;
		height: auto;
		font-size: 12px;
	}
	& .field-row .content-wrapper a {
		color: var(--blue);
	}
	& .field-row .content-wrapper a.sf-link {
		color: var(--blue);
	}
	& .field-row .content-wrapper a.sf-opp-link {
		color: var(--teal);
	}
	& .field-row .content-wrapper a:hover {
		text-decoration: underline;
	}
	& .field-row .content-wrapper-edit {
		display: inline-block;
		vertical-align: top;
		width: calc(100% - 200px);
		white-space: pre-line;
		position: relative;
	}
	& .field-row .content-wrapper-edit .edit-legacy-textarea {
		resize: none;
		width: 100%;
		background: var(--highlight-blue-d);
		border: 1px solid var(--blue);
		border-radius: 3px;
		color: white;
		font-size: 12px;
		padding: 10px;
	}
	& .field-row .content-wrapper-edit .edit-legacy-textarea.changed {
		background: var(--highlight-orange-d);
		border: 1px solid var(--orange);
	}
	& .field-row .content-wrapper-edit .cancel-edit {
		position: absolute;
		top: 3px;
		right: 3px;
		height: 30px;
		width: 30px;
		color: var(--orange);
		text-align: center;
		font-size: 20px;
	}
	& .field-row .content-wrapper-edit .cancel-edit:hover {
		cursor: pointer;
		color: var(--white);
	}
	& .field-row .content-wrapper-edit img {
		height: 7px;
		width: auto;
		position: relative;
		top: -3px;
	}
	.group-row {
		border: 1px solid var(--bg-d2);
		border-radius: 3px;
		margin-bottom: 10px;
	}
	.group-row .group-title-wrapper {
		color: white;
		background: var(--bg-d2);
		padding: 0 12px;
		font-size: 12px;
		text-align: left;
		width: calc(100% + 2px);
		margin-top: -1px;
		margin-left: -1px;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
	}
	.group-row .group-content-wrapper {
		font-size: 12px;
		padding: 10px 10px 10px 0;
		height: auto;
	}
	.group-row .group-content-row {
		padding: 4px 0;
	}
`;

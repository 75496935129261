// Resources
import React, {Component} from "react";
import Cookies from 'universal-cookie';
// import { ChatGPT } from '../function/ChatGPT';

// Styles
import {SettingsWrapperEl} from "./styles/SettingsWrapper";

// Auth
import AuthHelperMethods from "../auth/AuthHelperMethods";

// Redux
import {connect} from "react-redux";

const mapStateToProps = function (state) {
    return state;
};

// Globals
let cookies;

class SettingsWrapper extends Component {

    Auth = new AuthHelperMethods();
    constructor(props) {
        super(props);
        this.state = {
            theme: 'light'
        };
    }

    _handleThemeButtonClicked = () => {
        // Toggle theme to be opposite of what it currently is
        this.setState({
            theme: this.state.theme === 'dark' ? 'light' : 'dark'
        }, function () {
            this.setTheme();
            this.Auth.updateUserSettings({
                setting: 'theme',
                value: this.state.theme
            }).then(res => {
                if (res.error) {
                    alert(res.error);
                } else if (res.success) {
                    console.log('[ _handleThemeButtonClicked ] Success:', res.success);
                } else {
                    console.log("[ _handleThemeButtonClicked ] Server error:", res);
                }
            }).catch(err => {
                console.log("[ _handleThemeButtonClicked ] POST error:", err);
            });
        });
    };

    setTheme = () => {
        console.log('[ SettingsWrapper - setTheme ] theme:', this.state.theme);
        cookies.set('theme', this.state.theme, {path: '/'});
		document.documentElement.id = this.state.theme === "light" ? "light" : "dark";
    };

    componentDidMount() {
        // noinspection JSValidateTypes
        cookies = new Cookies();

        // Init themes
        let theme = cookies.get('theme') || 'light'; // Default theme is light

        // Check for theme in user's profile settings
        // if (this.props['user'] && this.props['user']['settings'] && this.props['user']['settings']['theme']) {
        //     theme = this.props['user']['settings']['theme'];
        // }

        // Set theme
        this.setState({
            theme: theme
        }, function () {
            this.setTheme();
        });
    };

    render() {
        return (
            <React.Fragment>
                <SettingsWrapperEl theme={this.state.theme}>
                    <div className={this.props.page}>
                        <div className="sidebar-settings-wrapper">
                            <div className="sidebar-settings-item logout" onClick={this.props.handleLogout} title="Logout">
                                <div className="sidebar-settings-item-bg"/>
                                <div className="sidebar-settings-item-img logout"/>
                            </div>
                            {/* <ChatGPT
                                messages={[
                                    {
                                        role: "system",
                                        content:
                                        "I am a marketing assistant used for revising copy for brevity and clarity. I will revise copy and not provide other comments unless it is to clarify ambiguous wording.",
                                    }
                                ]}
                            /> */}
                            <a
                                className="btn help-btn"
                                target="_blank"
                                href="/wiki/campaign_specification_document_system"
                                rel="noreferrer"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        d="M10 16.584V18.9996C10 20.1042 10.8954 20.9996 12 20.9996C13.1046 20.9996 14 20.1042 14 18.9996L14 16.584M12 3V4M18.3643 5.63574L17.6572 6.34285M5.63574 5.63574L6.34285 6.34285M4 12H3M21 12H20M17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12Z"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </a>
                            <div className="sidebar-settings-item theme" onClick={this._handleThemeButtonClicked} title="Toggle Theme">
                                <div className="sidebar-settings-item-bg"/>
                                <div className="sidebar-settings-item-img theme"/>
                            </div>
                        </div>
                    </div>
                </SettingsWrapperEl>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(SettingsWrapper);
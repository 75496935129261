import React from "react";

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { error: null, errorInfo: null };
	}

	componentDidCatch(error, errorInfo) {
		// Catch errors in any components below and re-render with error message
		this.setState({
			error: error,
			errorInfo: errorInfo
		});
		// TODO: Log error messages to an error reporting service here
		console.log(error, errorInfo);
	}

	render() {
		if (this.state.errorInfo) {
			// Error path
			return (
				<div style={{ border: "2px solid red", padding: "1em", marginBottom: "1em", background: "#fff", color: "#000" }}>
					<h2 style={{ fontWeight: "bold" }}>Something went wrong</h2>
					<p>
						Please share a screenshot of the entire page, URL, and steps to recreate in the{" "}
						<a href="https://slack.com/app_redirect?channel=feedback" target="_blank" rel="noreferrer">
							#feedback
						</a>{" "}
						Slack channel
					</p>
					<div style={{ whiteSpace: "pre-wrap", fontSize: "0.8em", margin: "1em 0" }}>
						{this.state.error && this.state.error.toString()}
						<br />
						{this.state.errorInfo.componentStack}
					</div>
				</div>
			);
		}
		// Normally, just render children
		return this.props.children;
	}
}

export default ErrorBoundary;

import React from "react";
import SalesforceWrapper from "../body/SalesforceWrapper";

export function SalesforceInformation(props) {
	return (
		<div
			className="company-column salesforce-wrapper"
			style={{
				opacity: props.length > 0 ? "1" : "0"
			}}
		>
			<h3>Salesforce</h3>
			<SalesforceWrapper
				type="details"
				setSalesforceRecords={props.setSalesforceRecords}
				columns={{
					Name: (record) => (
						<div>
							<a
								className="company-name"
								href={`https://upsellit.lightning.force.com/lightning/r/Account/${record.Id}/view`}
								target="_blank"
								rel="noreferrer"
							>
								{record.Name}{" "}
								{record.Account_Grade__c && (
									<span
										className={"admin-badge " +
											record.Account_Grade__c.replace(" Account", "").toLowerCase()}
									>
										{record.Account_Grade__c.replace(" Account", "")}
									</span>
								)}
							</a>
						</div>
					),
					Website: (record) => (
						<div>
							{record.Website && (
								<a
									href={record.Website.indexOf("http") === 0
										? record.Website
										: "https://" + record.Website}
									target="_blank"
									rel="noreferrer"
								>
									{record.Website}
								</a>
							)}
						</div>
					),
					Billing_Method__c: (record) => record.Billing_Method__c,
					Type: (record) => record.Type
				}}
				user={props.user}
				q={`SELECT Account.Name, Account.Id, Account.Billing_Method__c, Account.Network__c, Account.Website, Account.Lead_Developer__c, Account.OwnerId, Account.Type, Account.Account_Grade__c 
				FROM Account WHERE Account.Id = '${props.company.salesforceId}' LIMIT 100`} />

			<SalesforceWrapper
				type="table"
				columns={{
					Name: (record) => (
						<div>
							<a
								className="company-name"
								href={`/opportunity?opp=${record.Id}&id=${props.company._id}`}
							>
								{record.Name}
							</a>
							<span className="badge">{record.StageName}</span>
						</div>
					),
					//Type: (record) => <div>{record.Type}</div>
				}}
				user={props.user}
				q={`SELECT Opportunity.Type, Opportunity.Name, Opportunity.Id , Opportunity.StageName 
					FROM Opportunity 
					WHERE Account.Id = '${props.company.salesforceId || ""}' AND Opportunity.StageName != 'Closed Won' AND Opportunity.StageName != 'Closed Lost'`} />
		</div>
	);
}

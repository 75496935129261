// Resources
import React, { Component } from "react";
import Cookies from "universal-cookie";

// Components
import AuthHelperMethods from "../auth/AuthHelperMethods";
// import TopNavWrapper from "./TopNavWrapper";
import { FormSummary } from "../function/FormSummary";
import { OpportunityInformation } from "../function/OpportunityInformation";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { css } from "@codemirror/lang-css";
import { darcula } from "@uiw/codemirror-theme-darcula";
import { Switch } from "antd";
// import { ChatGPT } from "../function/ChatGPT";
import { Drawer, Button } from "antd";
import Select from "react-select";

import getTemplates from "../../helpers/getTemplates";
import MjmlWrapper from "./MjmlWrapper";

import { field_data } from "../../helpers/getData";

// Styles
import { OpportunityWrapperEl, GlobalStyle } from "./styles/OpportunityWrapper";
import logo from "../../images/logo.svg";

// Globals
let cookies;

class OpportunityWrapper extends Component {
	Auth = new AuthHelperMethods();
	constructor(props) {
		super(props);
		this.state = {
			view: localStorage.getItem("opp-view") || "usi",
			showImplementationGuide: false,
			records: [],
			editors: {},
			csds: [],
			forms: []
		};
	}

	opp_data = {
		workflow: {
			workflowName: "Campaign Update",
			// description: "",
			// createdAt: "2024-01-01T00:00:00Z",
			// updatedAt: "2024-01-02T00:00:00Z",
			stages: [
				{
					stageName: "Preparation",
					// description: "",
					// nextStage: "",
					tasks: [
						{
							assignedTo: [
								{
									role: "Creator"
								}
							],
							alert: [
								{
									role: "Creator"
								}
							],
							title: "Create CSDs",
							content: "",
							type: "Planning",
							department: "Owner",
							priority: "Medium",
							dueDate: "01T00:00:00"
						},
						{
							assignedTo: [
								{
									role: "Approver"
								}
							],
							alert: [
								{
									role: "Creator"
								}
							],
							title: "Approve or Deny Opp",
							content: "",
							type: "Planning",
							department: "Owner",
							priority: "Medium",
							dueDate: "01T00:00:00"
						}
					]
				},
				{
					stageName: "Preparation Review",
					tasks: [
						{
							assignedTo: [
								{
									role: ""
								}
							],
							alert: [
								{
									role: ""
								}
							],
							title: "example1",
							content: "",
							type: "",
							department: "",
							priority: "Medium",
							dueDate: "01T00:00:00"
						},
						{
							assignedTo: [
								{
									role: ""
								}
							],
							alert: [
								{
									role: ""
								}
							],
							title: "example3",
							content: "",
							type: "",
							department: "",
							priority: "Medium",
							dueDate: "01T00:00:00"
						}
					],
					description: "",
					nextStage: ""
				},
				{
					stageName: "Development",
					tasks: [
						{
							assignedTo: [
								{
									role: ""
								}
							],
							alert: [
								{
									role: ""
								}
							],
							title: "example4",
							content: "",
							type: "",
							department: "",
							priority: "Medium",
							dueDate: "01T00:00:00"
						},
						{
							assignedTo: [
								{
									role: ""
								}
							],
							alert: [
								{
									role: ""
								}
							],
							title: "example5",
							content: "",
							type: "",
							department: "",
							priority: "Medium",
							dueDate: "01T00:00:00"
						}
					],
					description: "",
					nextStage: ""
				},
				{
					stageName: "Development Review",
					tasks: [],
					description: "",
					nextStage: ""
				},
				{
					stageName: "Owner Review",
					tasks: [],
					description: "",
					nextStage: ""
				},
				{
					stageName: "Launch",
					tasks: [],
					description: "",
					nextStage: ""
				},
				{
					stageName: "Closed",
					tasks: [],
					description: "",
					nextStage: ""
				}
			]
		},
		stage: "Launch",
		title: "Launch New Product Line",
		description: "Project to introduce a new line of consumer electronics.",
		status: "Open",
		type: "New Campaign",
		rush: "Engineering",
		value: 200000,
		createdAt: "2024-01-20T00:00:00Z",
		updatedAt: "2024-01-20T00:00:00Z",
		dueDate: "2024-06-01T00:00:00Z",
		turnaround: 10,
		owner: {
			name: "John Doe"
		},
		tasks: [
			{
				assignedTo: [
					{
						name: "John Doe"
					}
				],
				alert: [
					{
						name: "John Doe"
					}
				],
				title: "Create CSDs",
				content:
					"Create a detailed plan for the development phase, including milestones and resource allocation.",
				type: "Planning",
				status: "Pending",
				department: "Engineering",
				priority: "Medium",
				dueDate: "2024-04-20T00:00:00Z",
				createdAt: "2024-01-15T00:00:00Z",
				updatedAt: "2024-01-15T00:00:00Z",
				comments: [
					{
						text: "We need to adjust the branding colors.",
						commentedBy: "627bdbf79c8301d2be431c28",
						commentedAt: "2024-01-11T00:00:00Z"
					}
				]
			},
			{
				assignedTo: [
					{
						name: "John Doe"
					}
				],
				alert: [
					{
						name: "John Doe"
					}
				],
				title: "Approve or Deny Opp",
				content: "",
				type: "Planning",
				status: "Complete",
				department: "Engineering",
				priority: "Medium",
				dueDate: "2024-04-20T00:00:00Z",
				createdAt: "2024-01-15T00:00:00Z",
				updatedAt: "2024-01-15T00:00:00Z",
				comments: [
					{
						text: "We need to adjust the branding colors.",
						commentedBy: "627bdbf79c8301d2be431c28",
						commentedAt: "2024-01-11T00:00:00Z"
					}
				]
			}
		],
		logs: [
			{
				action: "created",
				date: "2024-01-20T00:00:00Z",
				notes: "this opportunity",
				user: {
					name: "John Doe"
				}
			},
			{
				action: "updated",
				date: "2024-01-20T00:00:00Z",
				notes: "Stage A to Stage B",
				user: {
					name: "John Doe"
				}
			}
		]
	};

	app_options = {
		cart: {
			name: "Scrape Cart",
			when: {
				"design_1+dynamic_elements": "cart",
				email_dynamic_content: "cart"
			},
			group: "Cart"
		},
		cart_monitor: {
			name: "Cart Monitor",
			group: "Cart"
		},
		ajax_cart: {
			name: "Ajax Cart",
			group: "Cart"
		},
		iframe: {
			name: "Iframed Cart",
			group: "Cart"
		},
		cart_rebuilder: {
			name: "Cart Rebuilder",
			when: {
				cart_rebuilder: true,
				title: "save your cart|rebuild"
			},
			group: "Cart"
		},
		coupon: {
			name: "Coupon Application",
			when: {
				incentive_type: "coupon"
			},
			group: "Coupon"
		},
		coupon_monitor: {
			name: "Coupon Monitor",
			group: "Coupon"
		},
		coupon_blocker: {
			name: "Coupon Blocker",
			group: "Coupon"
		},
		recommendations: {
			name: "Recommendations",
			when: {
				strategy_type: "recommend",
				"design_1+dynamic_elements": "recommend"
			},
			group: "Recommendations"
		},
		rec_reporting: {
			name: "Recommendation Reporting",
			group: "Recommendations"
		},
		boost_bar: {
			name: "Boostbar",
			when: {
				post_click_boostbar: true
			},
			group: "Features"
		},
		spa: {
			name: "Single page app",
			group: "Features"
		},
		availability: {
			name: "Availability Alert",
			when: {
				strategy_type: "stock"
			},
			group: "Features"
		},
		iframe_breakout: {
			name: "Iframe breakout",
			group: "Features"
		},
		load_pixel: {
			name: "Load pixel",
			group: "Features"
		},
		link_injection: {
			name: "Inject affiliate link",
			group: "Features"
		},
		preserve_params: {
			name: "Preserve URL parameters",
			group: "Features"
		},
		split_test: {
			name: "Lift Test",
			when: {
				desktop_is_incremental_lift: true,
				mobile_is_incremental_lift: true
			},
			group: "Engagement"
		},
		geolocation: {
			name: "Geolocation/IP suppress",
			when: {
				has_geo_restriction: true
			},
			group: "Engagement"
		},
		date_range: {
			name: "Date Range",
			when: {
				has_end_date_time: true,
				has_start_date_time: true
			},
			group: "Engagement"
		},
		page_visits: {
			name: "Record page visits",
			when: {
				stage: "first page|bounce"
			},
			group: "Engagement"
		},
		new_visitor: {
			name: "Check if logged in",
			when: {
				visitors: "logged"
			},
			group: "Engagement"
		},
		return_visitor: {
			name: "Check if visited before",
			when: {
				visitors: "new|return"
			},
			group: "Engagement"
		},
		ip_return_visitor: {
			name: "Get IPs last purchase date",
			when: {
				visitors: "new|return"
			},
			group: "Engagement"
		},
		get_weather: {
			name: "Get current weather",
			group: "Engagement"
		},
		dom_utils: {
			name: "dom_utils",
			when: {
				pages: ".*"
			},
			group: "Libraries"
		},
		ajax_utils: {
			name: "ajax_utils",
			group: "Libraries"
		},
		date_utils: {
			name: "date_utils",
			group: "Libraries"
		},
		data_utils: {
			name: "data_utils",
			group: "Libraries"
		},
		aff_utils: {
			name: "aff_utils",
			group: "Libraries"
		},
		manual_scraping: {
			name: "Scrape ID and amount",
			group: "Pixel"
		},
		get_datalayer: {
			name: "Shopify/dataLayer scraping",
			group: "Pixel"
		},
		check_vars: {
			name: "Get variable from confirmation page",
			group: "Pixel"
		},
		wait: {
			name: "Wait for variable",
			group: "Pixel"
		},
		decimal: {
			name: "Fix total missing decimal",
			group: "Pixel"
		},
		id_fallback: {
			name: "Order ID fallback",
			group: "Pixel"
		},
		anonymous: {
			name: "Anon Data: Delete user ID cookies",
			when: {
				strategy_type: "anon"
			},
			group: "Pixel"
		}
	};

	onsite_options = {
		usi_load: {
			name: "load - usi_load.jsp",
			when: {
				"design_1+design": "in-page"
			},
			group: "Type"
		},
		one_prod: {
			name: "1 Product",
			when: {
				"design_1+dynamic_elements": "top item"
			},
			group: "Products"
		},
		three_prod: {
			name: "3 Products",
			when: {
				"design_1+dynamic_elements": "3 items"
			},
			group: "Products"
		},
		recommendations_view: {
			name: "Recommendations",
			when: {
				strategy_type: "recommend",
				"design_1+dynamic_elements": "recommend"
			},
			group: "Products"
		},
		survey: {
			name: "Survey",
			when: {
				type: "tt",
				strategy_type: "survey" // ?
			},
			group: "Type"
		},
		disclaimer: {
			name: "Disclaimer/Fine-Print",
			group: "Elements"
		},
		no_thanks: {
			name: "'No Thanks' Button",
			group: "Elements"
		},
		countdown: {
			name: "Countdown Timer",
			group: "Elements"
		},
		progress_bar: {
			name: "Progress Bar",
			group: "Elements"
		},
		spinner: {
			name: "Prize Wheel Spinner",
			group: "Elements"
		},
		use_srcset: {
			name: "Use srcset",
			group: "Options"
		},
		close: {
			name: "Close [usi_load|view]",
			group: "Elements"
		},
		minicart: {
			name: "Mini-cart [usi_load]",
			group: "Elements"
		},
		modal: {
			name: "Modal [usi_load]",
			group: "Type"
		},
		lc: {
			name: "LC",
			when: {
				type: "lc"
			},
			group: "Type"
		},
		availability: {
			name: "Availability Alert",
			when: {
				type: "lc",
				strategy_type: "stock|availability"
			},
			group: "Type"
		},
		phone: {
			name: "Phone Number Capture",
			when: {
				type: "lc",
				strategy_type: "phone" // ?
			},
			group: "Type"
		},
		mobile_phone: {
			name: "Mobile Text Message",
			when: {
				type: "lc",
				strategy_type: "mobile text" // ?
			},
			group: "Type"
		},
		lc_opt_in: {
			name: "LC Opt In",
			group: "Type"
		},
		alt_p2: {
			name: "LC Alternate P2",
			group: "Type"
		},
		side_slider: {
			name: "Side Slider",
			when: {
				"design_1+design": "sidebar|side slider"
			},
			group: "Design"
		},
		bottom_slider: {
			name: "Bottom Slider",
			group: "Design"
		},
		sidebar: {
			name: "Full-Height Sidebar",
			group: "Design"
		},
		css: {
			name: "CSS based",
			group: "CSS"
		},
		mobile: {
			name: "Mobile",
			group: "CSS"
		},
		responsive: {
			name: "Responsive",
			group: "CSS"
		},
		boxshadow: {
			name: "Box Shadow",
			group: "CSS"
		},
		link_injection: {
			name: "Link injection",
			group: "CTA"
		},
		needs_link: {
			name: "Apply affiliate link later",
			group: "CTA"
		},
		pull_coupon: {
			name: "Single-use coupon (coupon list)",
			when: {
				coupon_code: "http"
			},
			group: "CTA"
		},
		set_coupon: {
			name: "Multi-use coupon",
			when: {
				incentive_type: "coupon"
			},
			group: "CTA"
		},
		shopify_coupon: {
			name: "Apply Shopify coupon - redirect",
			group: "CTA"
		},
		shopify_coupon_checkout: {
			name: "Apply Shopify coupon - checkout",
			group: "CTA"
		}
	};

	email_options = {
		anonymous: {
			name: "Anon Data: usi_user_id.activate",
			when: {
				strategy_type: "anonymous",
				stages: "anonymous"
			},
			group: "Type"
		},
		load_precapture: {
			name: "Email Remarketing: load_precapture",
			when: {
				strategy_type: "email"
			},
			group: "Type"
		},
		load_presubmit: {
			name: "Pre-submit only: load_precapture",
			when: {
				strategy_type: "presubmit"
			},
			group: "Type"
		},
		load_postsubmit: {
			name: "Post-submit only: load_precapture",
			when: {
				strategy_type: "postsubmit"
			},
			group: "Type"
		},
		visitor_id: {
			name: "Visitor ID",
			when: {
				strategy_type: "visitor"
			},
			group: "Type"
		},
		optin: {
			name: "Optin",
			when: {
				opt_in_required: true
			},
			group: "Feature"
		},
		logo: {
			name: "Logo",
			group: "Element"
		},
		email_hero: {
			name: "Regular Hero",
			when: {
				number_of_attempts: ".*"
			},
			group: "Element"
		},
		mj_hero: {
			name: "MJML Hero",
			group: "Element"
		},
		text: {
			name: "Text",
			when: {
				number_of_attempts: ".*"
			},
			group: "Element"
		},
		email_cart: {
			name: "Cart",
			when: {
				email_dynamic_content: "cart|item"
			},
			group: "Element"
		},
		subtotal: {
			name: "Subtotal",
			when: {
				email_dynamic_content: "cart|item"
			},
			group: "Element"
		},
		discount: {
			name: "Discount",
			when: {
				incentive_type: "coupon"
			},
			group: "Element"
		},
		email_recommendations: {
			name: "Recommendations",
			when: {
				"email_1+dynamic_elements": "recommend"
			},
			group: "Element"
		},
		social: {
			name: "Social",
			group: "Element"
		},
		footer: {
			name: "Footer",
			when: {
				number_of_attempts: ".*"
			},
			group: "Element"
		}
	};
	async initCSD(id, v, forms) {
		console.log("[ Form - initCSD ] id: " + id + " v:" + v);
		try {
			const res = await this.Auth.getCSD({
				id: id,
				v: v
			});
			if (res.error) {
				res.fof ? this.props.goTo404() : alert("[ Form - initCSD ] ERROR: " + res.error);
			} else if (res.form && res.formvs && res.vid && res.v && res.fields) {
				console.log("[ Form - initCSD ] CSD data fetched successfully -> res:", res);
				forms.push({
					time_estimate: this.getEstimate(res.fields),
					...res
				});
			} else {
				alert("[ Form - initCSD ] Unknown server error - Please contact administrator");
			}
		} catch (err) {
			console.log("[ Form - initCSD ] POST error:", err);
			alert("[ Form - initCSD ] Error caught while initializing CSD data");
		}
	}

	getSFData(opp, callback) {
		let fields =
			"AccountId, StageName, LastActivityDate, Days_DevCompToLive__c, Monthly_Projected_Revenue__c, Name, Id, Type, Oppty_Start_Date__c, Required_Launch_Date__c, Rush_Type__c, Sub_Type__c, Urgency__c";
		if (this.state.records.length === 0) {
			let records = [],
				csds = [],
				forms = [];
			this.Auth.querySalesforce(`SELECT ${fields} FROM Opportunity WHERE Id = '${opp}'`)
				.then((res) => {
					records = res.data.records;
					return this.Auth.querySalesforce(`SELECT URL__c FROM CSD__c WHERE Opportunity__c = '${opp}'`);
				})
				.then((res) => {
					csds = res.data.records.map((record) => {
						let a = record.URL__c.substring(record.URL__c.indexOf("id=") + 3);
						if (a.indexOf("&") !== -1) a = a.substring(0, a.indexOf("&"));
						return a;
					});
					return Promise.all(csds.map((id) => this.initCSD(id, null, forms)));
				})
				.then(() => {
					this.setState(
						{
							forms: forms,
							csds: csds,
							records: records
						},
						callback
					);
				})
				.catch((err) => {
					console.log("[ getSFData ] POST error:", err);
				});
		}
	}

	getEstimate(fields) {
		let time_estimate = 0;
		fields
			.map((curr) =>
				field_data[curr.field_type_id] && field_data[curr.field_type_id].estimate_time
					? field_data[curr.field_type_id].estimate_time(curr.content)
					: 0
			)
			.forEach((a) => (isNaN(Number(a)) ? null : (time_estimate += Number(a))));

		return time_estimate;
	}

	handleViewChange(e) {
		let t = e ? "dev" : "usi";
		localStorage.setItem("opp-view", t);
		this.setState({
			view: t
		});
	}

	toggleImplementationGuide() {
		this.setState({
			showImplementationGuide: !this.state.showImplementationGuide
		});
	}

	getTimeEstimate(forms) {
		return !forms ? 0 : forms.reduce((prev, curr) => prev + curr.time_estimate, 0);
	}

	scrollToElement = () => {
		const hash = window.location.hash;
		if (hash && document.querySelector(hash)) {
			const elem = document.querySelector(hash);
			elem.scrollIntoView();
			if (elem.tagName === "SUMMARY") {
				elem.click();
			}
		}
	};

	getCodeTemplateOptions(options, form) {
		let enabledOptions = [];
		// check all passed in form options
		for (let o in options) {
			let defaultChecked = false;
			let fieldMatches = false;
			const checkFields = (form) => {
				if (form && form.fields) {
					// build data object to test against
					let csdData = {};
					form.fields.forEach((field) => {
						if (field.content) csdData[field.field_type_id] = field.content;
					});
					// check field conditions listed in 'when'
					for (let condition in options[o].when) {
						// if the field is found test the field content with regex
						if (csdData[condition] && options[o].when[condition]) {
							fieldMatches =
								csdData[condition] === options[o].when[condition] ||
								csdData[condition].match(new RegExp(options[o].when[condition], "i")) !== null;
						} else if (form.form[condition]) {
							fieldMatches = form.form[condition] === options[o].when[condition];
						}
					}
				}
			};
			if (form && form.fields) {
				checkFields(form);
			} else if (form && form.length > 0) {
				form.forEach((form) => {
					checkFields(form);
				});
			}
			if (fieldMatches) {
				defaultChecked = true;
			}
			var show_option = true; // TODO: hide invalid options
			if (show_option) {
				//enabledOptions.push([o, options[o].name, defaultChecked, options[o].group || 0]);
				enabledOptions.push({
					opt: o,
					name: options[o].name,
					checked: defaultChecked,
					group: options[o].group || 0
				});
			}
		}
		return enabledOptions;
	}

	getUpdatedEditorContent(form_id, editors) {
		let templates = getTemplates(this.state.forms);
		let new_editors = {};
		editors.forEach((editor) => {
			let formData = {};
			if (document.getElementById(form_id)) {
				formData = new FormData(document.getElementById(form_id));
				let obj = {};
				for (const [key, value] of formData) {
					obj[key] = value;
				}
				new_editors[editor] = templates[editor.split("_")[0]](obj) || "/* N/A */";
			}
		});
		return new_editors;
	}

	handleChange(form_id) {
		let editors = Object.keys(this.state.editors).filter((e) => {
			return e.includes(form_id.split("_")[1]);
		});
		if (form_id.includes("emailform_")) {
			editors = editors.filter((e) => e.match(/email_|pcjs_/));
		} else {
			editors = editors.filter((e) => !e.match(/email_|pcjs_/));
		}
		let new_editors = this.getUpdatedEditorContent(form_id, editors);
		this.setState({
			editors: {
				...this.state.editors,
				...new_editors
			}
		});
	}

	setEditorData = () => {
		if (this.state.forms && this.state.forms[0]) {
			let editors = {
				app_main_editor: this.getUpdatedEditorContent("form_main_editor", ["app"]).app,
				pixel_main_editor: this.getUpdatedEditorContent("form_main_editor", ["pixel"]).pixel
			};
			this.state.forms.forEach((form) => {
				let onsite = this.getUpdatedEditorContent("form_" + form.form._id, ["view", "css", "testdata"]);
				let emails = this.getUpdatedEditorContent("emailform_" + form.form._id, ["email", "pcjs"]);
				let edit = {
					...onsite,
					...emails
				};
				if (form.form.type !== "em" && form.form.type !== "pc") {
					editors["view_" + form.form._id] = edit.view;
					editors["css_" + form.form._id] = edit.css;
					editors["testdata_" + form.form._id] = edit.testdata || "/* no test data */";
				}
				if (form.form.type !== "tt") {
					editors["email_" + form.form._id] = edit.email;
					if (form.form.type === "em" || form.form.type === "pc") {
						editors["pcjs_" + form.form._id] = edit.pcjs;
					}
				}
			});
			this.setState({
				editors: editors
			});
		}
	};

	angleIcon = (
		<svg width="10px" height="10px" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
			<path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z" fill="currentColor" />
		</svg>
	);

	componentDidMount() {
		this.getSFData(this.props.opp, this.setEditorData);

		// noinspection JSValidateTypes
		cookies = new Cookies();

		let theme = cookies.get("theme") || "dark";
		document.documentElement.id = theme === "light" ? "light" : "dark";

		setTimeout(this.scrollToElement, 3000);
	}

	render() {
		let record = this.state.records[0];
		const openTasks = this.opp_data.tasks.filter((item) => item.status !== "Complete");
		const completedTasks = this.opp_data.tasks.filter((item) => item.status === "Complete");
		let show_prototype = window.location.href.indexOf("test=workflow") !== -1;
		let show_dev = true; //window.location.href.indexOf("test=dev") !== -1;
		/*New Opportunity
1.Nurture Process
Nurture
Data Entry
Discovery
Pre-Demo
Post-Demo
Closed

2.Mockups Only
Strategy
Mockups
Mockups Approval
Closed

3.Campaign Processes(All Development Processes)
Preparation
Proposal
Propsal Review
Development
Dev Review
Owner Review
Launch
Closed

Type
New Account
New Account, Mocks Completed
New Campaign(s)
Campaign(s) Fix
QA Campaign(s)
Update. No Mocks. No Approval
Update. No Mocks with Approval
Update with Mocks*/
		return (
			<OpportunityWrapperEl>
				<GlobalStyle />
					<header className={"header-wrapper"}>
						<nav className="top-navigation">
							<div className="menu-item" style={{ minWidth: "10em", marginRight: "auto" }}>
								<button className="btn btn-link btn-logo">
									<a href={origin + "/"}>
										<img src={logo} alt={"logo"} className={"icon"} />
									</a>{" "}
									<a
										href={window.location.href}
										style={{ fontSize: "1.5em", paddingLeft: "1em", letterSpacing: "2px" }}
									>
										Opportunity {this.angleIcon}
									</a>
								</button>
								<div className="sub-menu">
									<ul style={{ paddingLeft: "1em" }}>
										<li>
											<a href={origin + "/wiki"} className="btn btn-link btn-block">
												Wiki
											</a>
										</li>
										<li>
											<a href={origin + "/"} className="btn btn-link btn-block">
												CSD
											</a>
										</li>
										<li>
											<a
												href="https://www2.upsellit.com/admin/control/edit/index.jsp"
												target="_blank"
												rel="noreferrer"
												className="btn btn-link btn-block"
											>
												Admin
											</a>
										</li>
										<li>
											<a
												href="https://upsellit.lightning.force.com/lightning"
												target="_blank"
												rel="noreferrer"
												className="btn btn-link btn-block"
											>
												Salesforce
											</a>
										</li>
									</ul>
								</div>
							</div>
							{this.state.user && (
								<div style={{ marginRight: "1em", minWidth: "5em" }}>
									<div className="menu-item">
										<div className="">
											<button
												className="profile-button btn btn-link"
												style={{ backgroundImage: `url(${this.state.user.profile.picture})` }}
											></button>{" "}
											<span style={{ marginTop: "1.25em", display: "inline-block" }}>
												{this.angleIcon}
											</span>
										</div>
										<div className="sub-menu">
											<ul>
												<li>
													<button
														className="btn btn-link btn-block"
														onClick={(e) => {
															let theme = cookies.get("theme") || "dark";
															if (theme === "light") {
																cookies.set("theme", "dark", { path: "/" });
																document.documentElement.id = "dark";
															} else {
																cookies.set("theme", "light", { path: "/" });
																document.documentElement.id = "light";
															}
														}}
													>
														<span
															className="icon"
															style={{
																background: "var(--icon-moon-g)"
															}}
														></span>
														Theme
													</button>
												</li>
												{/* <li>
													{this.state.settings && isEditor && (
														<a href="/admin/" className="btn btn-link btn-block">
															<span
																className="icon"
																style={{
																	background: "var(--icon-team-lg)"
																}}
															></span>
															Docs Admin
														</a>
													)}
												</li> */}
												<li>
													<a
														href="/wiki/wiki_browsing_and_editing"
														className="btn btn-link btn-block"
													>
														<span className="icon" style={{}}>
															?
														</span>
														Help
													</a>
												</li>
												<li>
													{this.state.user && (
														<button
															onClick={() => this._handleLogout()}
															className="btn btn-link btn-block"
														>
															<span
																className="icon"
																style={{
																	background: "var(--icon-logout-g)"
																}}
															></span>
															Logout
														</button>
													)}
												</li>
											</ul>
										</div>
									</div>
								</div>
							)}
						</nav>
					</header>
				{/* <div className={"header-wrapper company"}>
					<TopNavWrapper page={this.props.page} />
				</div> */}
				{
					<div className={"body-wrapper opportunity-wrapper"}>
						<div className="opportunity-header gradient-background mb">
							{record && this.props.opp && (
								<div className="flex-wrapper hide-for-print">
									<div className="card" style={{ maxWidth: "40em", textAlign: "left" }}>
										<h2>{this.props.company.title}</h2>
										<h1>
											<a
												className="company-name"
												href={`https://upsellit.lightning.force.com/lightning/r/Opportunity/${record.Id}/view`}
												target="_blank"
												rel="noreferrer"
											>
												{record.Name}
											</a>
											{/* {this.opp_data.title} */}
										</h1>
										{!show_prototype && (
											<OpportunityInformation
												getTimeEstimate={this.getTimeEstimate}
												forms={this.state.forms}
												view={this.state.view}
												record={record}
											/>
										)}
										{show_prototype && (
											<table>
												<tr>
													<td>Stage: </td>
													<td>{this.opp_data.stage}</td>
												</tr>
												<tr>
													<td>Owner: </td>
													<td>{this.opp_data.owner.name}</td>
												</tr>
												<tr>
													<td>Title: </td>
													<td>{this.opp_data.title}</td>
												</tr>
												<tr>
													<td>Description: </td>
													<td>{this.opp_data.description}</td>
												</tr>
												<tr>
													<td>Status: </td>
													<td>{this.opp_data.status}</td>
												</tr>
												<tr>
													<td>Type: </td>
													<td>{this.opp_data.type}</td>
												</tr>
												<tr>
													<td>Rush: </td>
													<td>{this.opp_data.rush}</td>
												</tr>
												<tr>
													<td>Value: </td>
													<td>
														{Intl.NumberFormat("en-US", {
															style: "currency",
															currency: "USD"
														}).format(this.opp_data.value)}
													</td>
												</tr>
												<tr>
													<td>Due: </td>
													<td>
														{new Date(this.opp_data.dueDate).toLocaleDateString("en-US", {
															year: "numeric",
															month: "short",
															day: "numeric"
														})}
													</td>
												</tr>
											</table>
										)}
										{show_prototype && (
											<Button
												className="btn"
												onMouseDown={(event) => {
													event.stopPropagation();
													this.setState({
														open_milestones: true
													});
												}}
											>
												Opp Details
											</Button>
										)}
										{show_dev && (
											<>
												<b>Dev Mode</b>
												<Switch
													defaultChecked={localStorage.getItem("opp-view") === "dev"}
													onChange={(e) => this.handleViewChange(e)}
												/>
											</>
										)}

										{show_prototype && (
											<Drawer
												title="Milestones"
												placement="right"
												open={this.state.open_milestones}
												onClose={() => {
													this.setState({
														open_milestones: false
													});
												}}
											>
												<button className="btn">Delete Opportunity</button>
												<p>Turnaround: {this.opp_data.turnaround}</p>
												<p>
													Created:{" "}
													{new Date(this.opp_data.createdAt).toLocaleDateString("en-US", {
														year: "numeric",
														month: "short",
														day: "numeric"
													})}
												</p>
												<p>
													Updated:{" "}
													{new Date(this.opp_data.updatedAt).toLocaleDateString("en-US", {
														year: "numeric",
														month: "short",
														day: "numeric"
													})}
												</p>
												<p>Oppty Start Date 4/30/2024</p>
												<p>Mockups</p>0<p>Mockup Completion Date 4/30/2024</p>
												<p>Mockup Approval 0</p>
												<p>Mockup Approval Date 4/30/2024</p>
												<p>Mockup Stage 0</p>
												<p>Pre-Dev Approval Date 4/30/2024</p>
												<p>Pre-Dev Approval 0</p>
												<p>Dev Completion Date</p>
												<p>Development</p>
												<p>Oppty Live Date</p>
												<p>Pre-Client Approval Dev Time</p>
												<p>Live</p>
												<p>Development Stage</p>
												<p>Total</p>
											</Drawer>
										)}
									</div>
									{this.state.forms.length > 1 && (
										<nav className="opportunity-nav">
											<h2>Campaigns</h2>
											<ol>
												{this.state.forms
													.sort((form) => form.form.title)
													.map((form, i) => {
														return (
															<li>
																<a
																	className="btn btn-link"
																	key={i}
																	href={"#" + form.form.type + form.form._id}
																>
																	{form.form.title}
																</a>
															</li>
														);
													})}
											</ol>
										</nav>
									)}
								</div>
							)}
						</div>

						{show_prototype && (
							<div className=" flex-wrapper">
								<div
									className="card"
									style={{ width: "calc(100% - 2em)", margin: "1em", textAlign: "center" }}
								>
									<ol className="workflow-wrapper flex-wrapper">
										{this.opp_data.workflow.stages.map((stage) => (
											<li
												key={stage.stageName}
												className={
													"workflow-stage " +
													(stage.stageName === this.opp_data.stage ? "active" : "")
												}
											>
												<button className={"btn badge"}>{stage.stageName}</button>
											</li>
										))}
									</ol>
								</div>

								<div className="card" style={{ width: "calc(100% - 2em)", margin: "1em" }}>
									<button className="btn" style={{ float: "right" }}>
										+ Add Activity
									</button>
									<h3>
										Open Activities <small>({openTasks.length})</small>
									</h3>
									<div className="table-responsive">
										<table className="activity-wrapper">
											<thead>
												<tr>
													<th>Task Name</th>
													<th>Department</th>
													<th>Assigned</th>
													<th>Alert</th>
													<th>Status</th>
													<th>Priority</th>
													<th>Due</th>
													<th>Created</th>
													<th>Updated</th>
													<th>Comments</th>
												</tr>
											</thead>
											{openTasks.map((task) => (
												<tr>
													<td className="">
														<Button
															className="btn btn-link"
															onMouseDown={(event) => {
																event.stopPropagation();
																this.setState({
																	open_task: true
																});
															}}
														>
															{task.title}
														</Button>
													</td>
													<td className="">{task.department}</td>
													<td className="">
														{task.assignedTo.map((user) => (
															<span className="badge">{user.name}</span>
														))}
													</td>
													<td className="">
														{task.alert.map((user) => (
															<span className="badge">{user.name}</span>
														))}
													</td>
													<td className="">{task.status}</td>
													<td className="">{task.priority}</td>
													<td>
														{new Date(task.dueDate).toLocaleDateString("en-US", {
															year: "numeric",
															month: "short",
															day: "numeric"
														})}
													</td>
													<td>
														{new Date(task.createdAt).toLocaleDateString("en-US", {
															year: "numeric",
															month: "short",
															day: "numeric"
														})}
													</td>
													<td>
														{new Date(task.updatedAt).toLocaleDateString("en-US", {
															year: "numeric",
															month: "short",
															day: "numeric"
														})}
													</td>
													<td className="">{task.comments.length}</td>
												</tr>
											))}
										</table>
									</div>
								</div>

								<div className="card" style={{ width: "calc(50% - 2em)", margin: "1em" }}>
									<h3>
										Completed Activities <small>({completedTasks.length})</small>
									</h3>
									<div className="table-responsive">
										<table className="activity-wrapper">
											<thead>
												<tr>
													<th>Task Name</th>
													<th>Department</th>
													<th>Assigned</th>
													<th>Status</th>
													<th>Type</th>
												</tr>
											</thead>
											{completedTasks.map((task) => (
												<tr>
													<td className="">
														<Button
															className="btn btn-link"
															onMouseDown={(event) => {
																event.stopPropagation();
																this.setState({
																	open_task: true
																});
															}}
														>
															{task.title}
														</Button>
													</td>
													<td className="">{task.department}</td>
													<td className="">
														{task.assignedTo.map((user) => (
															<span className="badge">{user.name}</span>
														))}
													</td>
													<td className="">{task.status}</td>
													<td className="">{task.priority}</td>
												</tr>
											))}
										</table>
									</div>
								</div>

								<div className="card" style={{ width: "calc(50% - 2em)", margin: "1em" }}>
									<h3>Opportunity Timeline</h3>
									<div className="table-responsive">
										<div className="activity-wrapper">
											{this.opp_data.workflow.stages
												.map((s) => s.tasks)
												.flat()
												.map((task) => (
													<div>
														<Button
															className="btn btn-link disabled"
															onMouseDown={(event) => {
																event.stopPropagation();
																this.setState({
																	open_task: true
																});
															}}
														>
															{task.title}
														</Button>
													</div>
												))}
											{this.opp_data.tasks
												.filter((item) => item.status !== "Complete")
												.map((task) => (
													<div className="active">
														<Button
															className="btn btn-link"
															onMouseDown={(event) => {
																event.stopPropagation();
																this.setState({
																	open_task: true
																});
															}}
														>
															{task.title}
														</Button>
													</div>
												))}
											{this.opp_data.logs.map((log) => (
												<div
													className="activity-item btn btn-link"
													style={{ width: "100%", textAlign: "left" }}
												>
													<a href="/">{log.user.name}</a> {log.action} {log.notes} <br />
													<time>
														{new Date(log.date).toLocaleDateString("en-US", {
															year: "numeric",
															month: "short",
															day: "numeric"
														})}
													</time>
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						)}
						{this.props.settings && this.props.settings.departments && (
							<Drawer
								title="Task"
								placement="right"
								open={this.state.open_task}
								onClose={() => {
									this.setState({
										open_task: false
									});
								}}
							>
								<div className="card">
									<button className="btn">Delete Activity</button>
									<form className="task-form" style={{ width: "100%" }}>
										<div className="input-wrapper">
											<label>Subject:</label>
											<Select options={[]} />
										</div>

										<div className="input-wrapper">
											<label>Status:</label>
											<Select options={[]} />
										</div>

										<div className="input-wrapper">
											<label>Assigned To:</label>
											<Select options={[]} />
										</div>

										<div className="input-wrapper">
											<label>Denied Follow Up:</label>
											<Select options={[]} />
										</div>

										<div className="input-wrapper">
											<label>Days to Complete:</label>
											<Select options={[]} />
										</div>

										<div className="input-wrapper">
											<label>Comments:</label>
											<Select options={[]} />
										</div>

										<div className="input-wrapper">
											<label>Alert Creator upon Completion:</label>
											<Select options={[]} />
										</div>

										<div className="input-wrapper">
											<label>Error Type:</label>
											<Select options={[]} />
										</div>

										<div className="input-wrapper">
											<label>Activity Currency:</label>
											<Select options={[]} />
										</div>

										<div className="input-wrapper">
											<label>Department:</label>
											<Select
												options={Object.keys(this.props.settings.departments).map((dept) => ({
													label: dept,
													value: dept
												}))}
											/>
										</div>

										<div className="input-wrapper">
											<label>Account:</label>
											<Select options={[]} />
										</div>

										<div className="input-wrapper">
											<label>Related To:</label>
											<Select options={[]} />
										</div>

										<div className="input-wrapper">
											<label>Opportunity Owner:</label>
											<Select options={[]} />
										</div>

										<div className="input-wrapper">
											<label>Name:</label>
											<Select options={[]} />
										</div>

										<div className="input-wrapper">
											<label>Due Date:</label>
											<Select options={[]} />
										</div>

										<div className="input-wrapper">
											<label>Priority:</label>
											<Select options={[]} />
										</div>

										<div className="input-wrapper">
											<label>Task Tag:</label>
											<Select options={[]} />
										</div>

										<div className="input-wrapper">
											<label>Created By:</label>
											<input type="text" defaultValue="Brooks Roche, 4/30/2024 11:44 AM" />
										</div>

										<div className="input-wrapper">
											<label>Last Modified By:</label>
											<input type="text" defaultValue="Michael Sanaiha, 4/30/2024 11:46 AM" />
										</div>

										<div className="input-wrapper">
											<label>Task Duration:</label>
											<input type="text" defaultValue="skip weekends + Afterhours" />
										</div>

										<div className="input-wrapper">
											<label>Completed Date:</label>
											<input type="date" />
										</div>

										<div className="input-wrapper">
											<label>Opportunity Type:</label>
											<input type="text" defaultValue="Update. No Mocks with Approval" />
										</div>

										<div className="input-wrapper">
											<label>Time to Finish:</label>
											<input type="text" placeholder="Enter time to finish" />
										</div>

										<div className="input-wrapper">
											<label>Automation Key:</label>
											<input type="text" defaultValue="DevRev-CodeReview" />
										</div>

										<div className="input-wrapper">
											<label>Original Due Date:</label>
											<input type="date" defaultValue="2024-04-30" />
										</div>

										<button className="btn" type="submit">
											Submit
										</button>
									</form>
								</div>
							</Drawer>
						)}

						{record && this.props.opp && (
							<div className="flex-wrapper">
								{this.state.forms
									.sort((form) => form.form.title)
									.map((form, i) => {
										return (
											<div
												key={i}
												className="card mb p1 code-wrapper"
												id={form.form.type + form.form._id}
											>
												<FormSummary
													key={form.form._id}
													length={this.state.records.length}
													record={form}
													view={this.state.view}
												/>
												{this.state.view === "dev" && (
													<div className="internal-information">
														{(form.form.type === "em" ||
															form.form.type === "lc" ||
															form.form.type === "nc") && (
															<div className="card flex-wrapper">
																<form
																	style={{ width: "25%", flex: "1 1 25%" }}
																	className="code_builder fourth"
																	id={"emailform_" + form.form._id}
																	onChange={(e) =>
																		this.handleChange("emailform_" + form.form._id)
																	}
																>
																	{(() => {
																		let optionsByGroup = {};
																		this.getCodeTemplateOptions(
																			this.email_options,
																			form
																		).forEach((option) => {
																			const group = option.group || "ungrouped";
																			if (!optionsByGroup[group]) {
																				optionsByGroup[group] = [];
																			}

																			optionsByGroup[group].push(option);
																		});
																		return Object.entries(optionsByGroup).map(
																			([group, options]) => (
																				<fieldset key={group}>
																					{group !== "ungrouped" && (
																						<legend>{group}</legend>
																					)}
																					{options.map((option, i) => (
																						<label key={i}>
																							<input
																								type="checkbox"
																								name={option.opt}
																								defaultChecked={
																									!!option.checked
																								}
																							/>
																							{option.name}
																						</label>
																					))}
																				</fieldset>
																			)
																		);
																	})()}
																</form>
																{this.state.editors["email_" + form.form._id] && (
																	<>
																		{(form.form.type === "em" ||
																			form.form.type === "pc") && (
																			<CodeMirror
																				name={"pcjs_" + form.form._id}
																				value={
																					this.state["pcjs_" + form.form._id]
																				}
																				extensions={[javascript({ jsx: true })]}
																				className="half"
																				theme={darcula}
																			/>
																		)}
																		<MjmlWrapper
																			value={
																				this.state.editors[
																					"email_" + form.form._id
																				]
																			}
																		/>
																	</>
																)}
															</div>
														)}
														{form.form.type.match(/tt|nc|lc/) !== null && (
															<div className="card flex-wrapper">
																<form
																	style={{ width: "25%", flex: "1 1 25%" }}
																	className="code_builder fourth"
																	id={"form_" + form.form._id}
																	onChange={(e) => {
																		this.handleChange("form_" + form.form._id);
																	}}
																>
																	{(() => {
																		let optionsByGroup = {};
																		this.getCodeTemplateOptions(
																			this.onsite_options,
																			form
																		).forEach((option) => {
																			const group = option.group || "ungrouped";
																			if (!optionsByGroup[group]) {
																				optionsByGroup[group] = [];
																			}
																			optionsByGroup[group].push(option);
																		});
																		return Object.entries(optionsByGroup).map(
																			([group, options]) => (
																				<fieldset key={group}>
																					{group !== "ungrouped" && (
																						<legend>{group}</legend>
																					)}
																					{options.map((option, i) => (
																						<label key={i}>
																							<input
																								type="checkbox"
																								name={option.opt}
																								defaultChecked={
																									!!option.checked
																								}
																							/>
																							{option.name}
																						</label>
																					))}
																				</fieldset>
																			)
																		);
																	})()}
																</form>
																<CodeMirror
																	name={"view_" + form.form._id}
																	value={this.state.editors["view_" + form.form._id]}
																	extensions={[javascript({ jsx: true })]}
																	className="fourth"
																	theme={darcula}
																/>
																<CodeMirror
																	name={"css_" + form.form._id}
																	value={this.state.editors["css_" + form.form._id]}
																	extensions={[css({})]}
																	className="fourth"
																	theme={darcula}
																/>
																<CodeMirror
																	name={"testdata_" + form.form._id}
																	value={
																		this.state.editors["testdata_" + form.form._id]
																	}
																	extensions={[javascript({ jsx: true })]}
																	className="fourth"
																	theme={darcula}
																/>
															</div>
														)}
													</div>
												)}
											</div>
										);
									})}
							</div>
						)}
						{this.state.view === "dev" && record && this.state.forms.length > 0 && this.props.opp && (
							<div className="card flex-wrapper">
								<h1 className="mb" style={{ width: "100%" }}>
									App & Pixel Files
								</h1>
								<div className="code-wrapper internal-information" style={{ width: "25%" }}>
									<form
										className="code_builder"
										onChange={(e) => this.handleChange("form_main_editor")}
										id={"form_main_editor"}
									>
										{(() => {
											let optionsByGroup = {};
											this.getCodeTemplateOptions(this.app_options, this.state.forms).forEach(
												(option) => {
													const group = option.group || "ungrouped";
													if (!optionsByGroup[group]) {
														optionsByGroup[group] = [];
													}
													optionsByGroup[group].push(option);
												}
											);
											return Object.entries(optionsByGroup).map(([group, options]) => (
												<fieldset key={group}>
													{group !== "ungrouped" && <legend>{group}</legend>}
													{options.map((option, i) => (
														<label key={i}>
															<input
																type="checkbox"
																name={option.opt}
																defaultChecked={!!option.checked}
															/>
															{option.name}
														</label>
													))}
												</fieldset>
											));
										})()}
									</form>
								</div>
								<div className="code-wrapper internal-information" style={{ width: "75%" }}>
									<CodeMirror
										name="app"
										value={this.state.editors.app_main_editor}
										extensions={[javascript({})]}
										theme={darcula}
										className={"half"}
									/>
									<CodeMirror
										name="pixel"
										value={this.state.editors.pixel_main_editor}
										extensions={[javascript({})]}
										theme={darcula}
										className={"half"}
									/>
								</div>
							</div>
						)}
					</div>
				}
			</OpportunityWrapperEl>
		);
	}
}

export default OpportunityWrapper;

// noinspection JSUnresolvedVariable

// Resources
import React, { Component } from "react";
import Moment from "react-moment";
import Switch from "react-switch"; // https://reactjsexample.com/draggable-toggle-switch-component-for-react/
import TextareaAutosize from "react-textarea-autosize";
import AuthHelperMethods from "../auth/AuthHelperMethods";
import Dropzone from "react-dropzone"; // https://github.com/react-dropzone/react-dropzone

// Redux
import { connect } from "react-redux";

// Styles
import { QAWrapperEl } from "./styles/QAWrapper";

// Images
import cat from "../../images/animated/cat.webp";

const mapStateToProps = function (state) {
	return state;
};

let getDaysAgo = (ago) => {
	const sevenDaysAgo = new Date(new Date().getTime() - 86400000 * ago);
	return sevenDaysAgo.toISOString().split("T")[0];
};

class QAWrapper extends Component {
	Auth = new AuthHelperMethods();

	constructor(props) {
		super(props);
		this.state = {
			qas: [],
			show_closed: false,
			active_doc: {},
			active_bug: null,
			performance_options: {
				companyID: "-1",
				siteID: "-1",
				configurationID: "-1",
				dateStart: getDaysAgo(29),
				dateEnd: getDaysAgo(1),
				dateUnit: 2
			},
			// Image upload
			processing: false,
			processingMsg: "",
			file: {},
			src: null
		};
	}

	_onDrop = (files) => {
		if (files && files.length > 0) {
			// Validate file formats
			for (let i = 0; i < files.length; i++) {
				if (files[i].type !== "image/jpeg" && files[i].type !== "image/png") {
					return alert("Invalid file format detected");
				}
			}

			// Show processing prompt
			this.setState(
				{
					processing: true,
					processingMsg: ""
				},
				() => {
					// Kick off recursive function to upload images
					this.uploadSingleImage(files, 0, [], (urls) => {
						let bug = this.state.active_bug;
						if (!bug.images) bug.images = [];
						bug.images = bug.images.concat(urls);
						this.setState({
							processing: false,
							active_bug: bug
						});
					});
				}
			);
		}
	};

	uploadSingleImage = (files, idx, urls, cb) => {
		// Exit condition
		if (!files[idx]) cb(urls);

		this.setState(
			{
				processingMsg:
					files.length > 1 ? "Processing (" + (idx + 1) + "/" + files.length + ")..." : "Processing..."
			},
			() => {
				const formData = new FormData();
				formData.append("image", files[idx]);
				formData.append("bug_id", this.state.active_bug._id);

				// Send file to server for upload
				this.Auth.uploadSingleImage(formData)
					.then(async (res) => {
						if (res && res.url) {
							urls.push(res.url);
							setTimeout(() => {
								// Forward progress
								this.uploadSingleImage(files, idx + 1, urls, cb);
							}, 1000);
						}
					})
					.catch((err) => {
						console.log("[ uploadSingleImage ] POST error:", err);
						alert(err);
					});
			}
		);
	};

	updateQA = (data, callback) => {
		let self = this;
		if (this.props.form && this.props.form.vid) {
			this.Auth.updateQA(data)
				.then((res) => {
					if (res.error) {
						console.error(res.error);
					} else if (res.data) {
						console.log("[ QAWrapper - updateQA ] data:", res.data);
						let activeDoc = res.data.qas.filter((q) => q._id === self.state.active_doc._id);
						let activeBug = self.state.active_bug && activeDoc && activeDoc[0] && activeDoc[0]._bugs.filter((b) => b._id === self.state.active_bug._id);
						self.setState(
							{
								active_bug: activeBug ? activeBug[0] : null,
								active_doc: activeDoc ? activeDoc[0] : {},
								qas: res.data.qas
							},
							callback
						);
					} else {
						console.error("[ QAWrapper - updateQA ] ERROR - Missing res data:", res);
					}
				})
				.catch((err) => {
					console.log("[ QAWrapper - updateQA ] POST error:", err);
				});
		}
	};

	createBug = (qid) => {
		if (this.props.form && this.props.form.vid) {
			this.Auth.createBug({
				vid: this.props.form.vid,
				qid: qid
			})
				.then((res) => {
					if (res.error) {
						console.error(res.error);
					} else if (res.data) {
						console.log("[ QAWrapper - createBug ] data:", res.data);
						this.setState(
							{
								active_bug: res.data.active_bug,
								qas: res.data.qas
							},
							function () {
								this.props.updateQAParams(this.props.params.id, qid, res.data.active_bug._id);
							}
						);
					} else {
						console.error("[ QAWrapper - createBug ] ERROR - Missing res data:", res);
					}
				})
				.catch((err) => {
					console.log("[ QAWrapper - createBug ] POST error:", err);
				});
		}
	};

	createQA = (type) => {
		if (this.props.form && this.props.form.vid) {
			this.Auth.createQA({
				vid: this.props.form.vid,
				type: type
			})
				.then((res) => {
					if (res.error) {
						console.error(res.error);
					} else if (res.data) {
						console.log("[ QAWrapper - createQA ] data:", res.data);
						this.setState(
							{
								active_doc: res.data
							},
							function () {
								this.props.updateQAParams(this.props.params.id, res.data._id);
							}
						);
					} else {
						console.error("[ QAWrapper - createQA ] ERROR - Missing res data:", res);
					}
				})
				.catch((err) => {
					console.log("[ QAWrapper - createQA ] POST error:", err);
				});
		}
	};

	initData = () => {
		if (this.props.form && this.props.form.vid) {
			this.Auth.fetchQA({
				vid: this.props.form.vid,
				qa: this.props.params.qa,
				bug: this.props.params.bug
			})
				.then((res) => {
					if (res.error) {
						console.error(res.error);
					} else if (res.data) {
						console.log("[ QAWrapper - initData ] data:", res.data);
						let active_doc = this.props.params.qa
							? res.data.qas.filter((qa) => qa._id === this.props.params.qa)[0]
							: null;
						let active_bug = this.props.params.bug
							? active_doc._bugs.filter((bug) => bug._id === this.props.params.bug)[0]
							: null;
						this.setState({
							qas: res.data.qas,
							active_doc: active_doc,
							active_bug: active_bug
						});
					} else {
						console.error("[ QAWrapper - initData ] ERROR - Missing res data:", res);
					}
				})
				.catch((err) => {
					console.log("[ QAWrapper - initData ] POST error:", err);
				});
		}
	};

	_handleNotesBlur = (e, id) => {
		this.updateQA({
			vid: this.props.form.vid,
			type: "qa",
			id: id,
			name: e.target.name,
			value: e.target.value
		});
	};

	_handleBugReportBlur = (e, id, qid) => {
		this.updateQA({
			vid: this.props.form.vid,
			qid: qid,
			type: "bug",
			id: id,
			name: e.target.name,
			value: e.target.value
		});
	};

	_handleChange = (bool) => {
		this.setState({
			show_closed: bool
		});
	};

	_openBugReport = (bug, qa) => {
		let self = this;
		this._openQA(qa, function () {
			if (!bug.images) bug.images = [];
			self.setState(
				{
					active_bug: bug
				},
				function () {
					this.props.updateQAParams(this.props.params.id, qa._id, bug._id);
				}
			);
		});
	};

	_newQA = () => {
		this.createQA(this.props.form_type);
	};

	_newCodeReview = () => {
		this.createQA("review");
	};

	_newTagQA = () => {
		this.createQA("tag");
	};

	_newPerformance = () => {
		this.createQA("performance");
	};

	_copyBugReport = (e, bug) => {
		let txt = "";
		if (bug.summary) txt += bug.summary + '\n\n';
		if (bug.browsers) txt += "Browsers: " + bug.browsers + '\n\n';
		if (window.location.href) txt += "Link: " + window.location.href;

		if (window.navigator.clipboard) {
			navigator.clipboard.writeText(txt).then(
				() => {
					console.log("Async: Copied: ", txt);
				},
				function (err) {
					console.error("Async: Could not copy text: ", err);
				}
			);
		}
	};

	_closeBugReport = () => {
		this.setState(
			{
				active_bug: null
			},
			function () {
				this.props.updateQAParams(this.props.params.id, this.state.active_doc._id);
			}
		);
	};

	_closeQA = () => {
		this.setState(
			{
				active_doc: null,
				active_bug: null
			},
			function () {
				this.props.updateQAParams(this.props.params.id);
			}
		);
	};

	_archiveQA = (id) => {
		this.updateQA(
			{
				vid: this.props.form.vid,
				type: "qa",
				id: id,
				name: "status",
				value: "archived"
			},
			function () {
				this.setState(
					{
						active_doc: null,
						active_bug: null
					},
					function () {
						this.props.updateQAParams(this.props.params.id);
					}
				);
			}
		);
	};

	_openQA = (qa, cb) => {
		this.setState(
			{
				active_doc: qa
			},
			function () {
				this.props.updateQAParams(this.props.params.id, qa._id);
				if (cb) cb();
			}
		);
	};

	_updateTest = (id, value) => {
		let currentTest = this.state.active_doc._tests.filter((test) => test._id === id);
		if (currentTest[0] && currentTest[0].state === value) {
			value = "incomplete";
		}
		this.updateQA({
			vid: this.props.form.vid,
			type: "test",
			id: id,
			name: "state",
			value: value
		});
		return false;
	};

	_updatePerformance = (event) => {
		let o = {
			performance_options: this.state.performance_options
		};
		o.performance_options.companyID = this.props.form.form._company.adminId;
		if (event && event.target && event.target.name) o.performance_options[event.target.name] = event.target.value;
		this.setState(o);
	};

	_renderQAHistory = (qa, i) => {
		if (!qa._tests)
			return (
				<div key={i} data-json={JSON.stringify(qa)}>
					no
				</div>
			);
		let total = qa._tests.filter((test) => test.state !== "archived").length;
		let bugs = qa._bugs.filter((bug) => bug.status === "open").length;
		let complete = qa._tests.filter((test) => test.state === "approved");
		let names = {
			lc: "Lead Capture",
			tt: "Precise Promotion",
			pc: "Email",
			em: "Email",
			nc: "NC",
			performance: "Performance",
			review: "Review",
			tag: "Tag QA"
		};
		return (
			<div className="qa-item" key={i}>
				<div className="qa-item-link" onClick={() => this._openQA(qa)}>
					{names[qa.type] || "?"} QA by {qa.created_by || "?"}
					<div className="badge-wrapper">
						<span className="badge">
							{complete.length}/{total}
						</span>
						<span className="badge">
							<Moment format={"MM/DD/YY, h:mm a"}>{qa.createdAt}</Moment>
						</span>
						<span className="bug-count">
							{bugs === 0 ? "  " : bugs === 1 ? "1 bug open" : bugs + " bugs open"}
						</span>
					</div>
				</div>
				<div className="bug-list">
					{qa._bugs &&
						qa._bugs
							.filter(
								(bug) => bug.status !== "archived" && (this.state.show_closed || bug.status === "open")
							)
							.map((bug, i) => {
								let title =
									bug.summary.length < 75 ? bug.summary : bug.summary.substring(0, 75) + "...";
								if (title === "") title = "[no summary]";
								return (
									<div
										className={"bug-item " + (bug.status ? bug.status : "")}
										onClick={() => this._openBugReport(bug, qa)}
										key={i}
									>
										{title}
										<small>
											{" "}
											• {bug.created_by}{" "}
											<Moment format={"MM/DD/YY, h:mm a"}>{bug.createdAt}</Moment>
										</small>
									</div>
								);
							})}
				</div>
			</div>
		);
	};

	_renderQADoc = () => {
		let qa = this.state.active_doc;
		if (!qa || !qa._tests) return null;
		let total = qa._tests.filter((test) => test.state !== "archived").length;
		let approved = qa._tests.filter((test) => test.state === "approved").length;
		let percent = ((approved / total) * 100).toFixed(0);
		let names = {
			lc: "Lead Capture",
			tt: "Precise Promotion",
			pc: "Email",
			em: "Email",
			nc: "NC",
			performance: "Performance",
			review: "Review",
			tag: "Tag QA"
		};
		let grouped_tests = {};
		qa._tests
			.map((test) => test.section)
			.filter((value, index, array) => array.indexOf(value) === index)
			.forEach((section) => (grouped_tests[section] = qa._tests.filter((test) => test.section === section)));
		let open_bugs = qa._bugs.filter((bug) => bug.status === "open").length;
		let show_bugs = qa._bugs.filter((bug) => bug.status !== "archived");
		let completed = percent === "100" && open_bugs === 0;

		return (
			this.state.active_doc && (
				<div className={"qa_form" + (completed ? " completed" : "")}>
					<div className="before" />
					<div className="after" />
					<button className="btn grey qa-close-button" onClick={this._closeQA} aria-label="Close">
						&times;
					</button>
					<h3>{names[qa.type] || "?"} QA Doc</h3>
					<button className="btn btn-block red report-bug-btn" type="button" onClick={() => this.createBug(qa._id)}>
						+ Report Bug
					</button>

					<div className="qa-totals">
						<div>
							<span className="badge-title">Approved:</span>
							<span className="badge">
								{approved}/{total} ({percent}%)
							</span>
						</div>
						<div>
							<span className="badge-title">Bugs:</span>
							<span className="badge">{open_bugs} open</span>{" "}
							<span className="badge">{show_bugs.length} found</span>
						</div>
						{completed && <span className="badge">Complete!</span>}
					</div>

					{show_bugs && (
						<ol className="qa-bug-list">
							{show_bugs.map((bug, i) => (
								<li
									key={i}
									className={"bug-item " + bug.status}
									onClick={() => this._openBugReport(bug, qa)}
								>
									<div className="qa-bug-summary">
										{bug.summary.length === 0
											? "[no summary]"
											: bug.summary.length < 100
											? bug.summary
											: bug.summary.substring(0, 100) + "..."}
									</div>
									<small className="qa-bug-by">
										By {bug.created_by} <Moment format={"MM/DD/YY, h:mm a"}>{bug.createdAt}</Moment>
									</small>
								</li>
							))}
						</ol>
					)}

					{qa.type === "performance" && this.props.form.form._company.adminId && (
						<div className="qa-performance">
							<a
								href={
									"https://www2.upsellit.com/admin/control/edit/reports_compare.jsp?companyID=" +
									this.props.form.form._company.adminId
								}
								target="_blank"
								className="btn btn-block"
								rel="noreferrer"
							>
								View Performance
							</a>
						</div>
					)}

					<div className="qa-notes-form">
						<label htmlFor="notes">Notes:</label>
						<TextareaAutosize
							className={"qa-notes"}
							placeholder={"Other tests or notes"}
							name={"notes"}
							id={"notes"}
							defaultValue={qa.notes}
							onBlur={(e) => this._handleNotesBlur(e, qa._id)}
						/>
					</div>
					<div className="qa-test-list">
						{Object.keys(grouped_tests).map((group, idx) => {
							return (
								<div key={idx} className="">
									<h4 className="">{group}</h4>
									{grouped_tests[group].map((test, i) => {
										return (
											<div className="test" key={i}>
												<div className="test-case">
													<span className="badge">{i + 1}</span>
													{test.tooltip && <div className="test-tooltip">{test.tooltip}</div>}
													<div className="test-case-text">{test.case} </div>
												</div>
												<div className="test-action">
													<input
														type="radio"
														id={"qa_approve" + i + "_" + idx}
														name={"qa_test" + i + "_" + idx}
														value="approved"
														defaultChecked={test.state === "approved"}
													/>
													<label
														className={
															"test-approve " +
															(test.state === "approved" ? "checked" : "")
														}
														htmlFor={"qa_approve" + i + "_" + idx}
														onClick={(e) => this._updateTest(test._id, "approved", e)}
													>
														&#10003;
													</label>

													<input
														type="radio"
														id={"qa_deny" + i + "_" + idx}
														name={"qa_test" + i + "_" + idx}
														value="failed"
														defaultChecked={test.state === "failed"}
													/>
													<label
														className={
															"test-deny " + (test.state === "failed" ? "checked" : "")
														}
														htmlFor={"qa_deny" + i + "_" + idx}
														onClick={(e) => this._updateTest(test._id, "failed", e)}
													>
														&times;
													</label>

													<input
														type="radio"
														id={"qa_archive" + i + "_" + idx}
														name={"qa_test" + i + "_" + idx}
														value="archived"
														defaultChecked={test.state === "archived"}
													/>
													<label
														className={
															"test-archive " +
															(test.state === "archived" ? "checked" : "")
														}
														htmlFor={"qa_archive" + i + "_" + idx}
														onClick={(e) => this._updateTest(test._id, "archived", e)}
													>
														N/A
													</label>

													<input
														type="radio"
														id={"qa_incomplete" + i + "_" + idx}
														name={"qa_test" + i + "_" + idx}
														value="incomplete"
														defaultChecked={test.state === "incomplete"}
													/>
													{/* <label className="test-archive" htmlFor={"qa_incomplete" + i + "_" + idx}
                                    onClick={(e) => this._updateTest(test._id, "incomplete", e)}>I</label> */}
												</div>
											</div>
										);
									})}
								</div>
							);
						})}
					</div>
					<button className="btn btn-block red archive-button" onClick={() => this._archiveQA(qa._id)}>
						Archive QA Doc
					</button>
				</div>
			)
		);
	};

	_renderBugReport = () => {
		let bug = this.state.active_bug;
		if (!bug) return null;
		return (
			<div className={"report " + (this.state.active_bug ? "active" : "")}>
				<button className="btn grey qa-close-button" onClick={this._closeBugReport} aria-label="Close">
					&times;
				</button>

				<h3>Bug Report</h3>
				<div style={{textAlign:"center", padding: "1em 0"}}>
					by {bug.created_by}<br />
					at <Moment format={"MM/DD/YY, h:mm a"}>{bug.createdAt}</Moment>
				</div>
				<button className="btn btn-block" onClick={e => this._copyBugReport(e, bug)}>
					Copy Report
				</button>

				<label htmlFor="status">Status</label>
				<select
					name="status"
					defaultValue={bug.status}
					id="status"
					onBlur={(e) => this._handleBugReportBlur(e, bug._id, this.state.active_doc._id)}
				>
					<option value="open">Open</option>
					<option value="closed">Closed</option>
					<option value="archived">Archived</option>
				</select>

				<label htmlFor="summary">Summary</label>
				<TextareaAutosize
					className={"bug-report-summary"}
					placeholder={"Describe the issue and steps to recreate."}
					name={"summary"}
					id={"summary"}
					minRows={3}
					defaultValue={bug.summary}
					onChange={(e) => this._handleBugReportBlur(e, bug._id, this.state.active_doc._id)}
				/>

				<label htmlFor="browsers">Browsers</label>
				<input
					className={"bug-report-browser"}
					placeholder={"Browser(s)"}
					name={"browsers"}
					id={"browsers"}
					list="browser-list"
					defaultValue={bug.browsers}
					onChange={(e) => this._handleBugReportBlur(e, bug._id, this.state.active_doc._id)}
				/>
				<datalist id="browser-list">
					<option>All</option>
					<option>Chrome</option>
					<option>Safari</option>
					<option>Firefox</option>
					<option>Edge</option>
					<option>Opera</option>
					<option>Internet Explorer</option>
				</datalist>

				<label htmlFor="files">Screencasts</label>
				<input
					className={"bug-report-file"}
					placeholder={"Paste screencast urls here"}
					name={"files"}
					id={"files"}
					defaultValue={bug.files}
					onChange={(e) => this._handleBugReportBlur(e, bug._id, this.state.active_doc._id)}
				/>

				<label htmlFor="images">Images</label>
				<div className={"upload-wrapper"}>
					{this.state.processing && (
						<div className="processing-wrapper">
							<img src={cat} alt={"cat"} />
							<p>{this.state.processingMsg}</p>
						</div>
					)}
					{!this.state.processing && (
						<Dropzone onDrop={this._onDrop}>
							{({ getRootProps, getInputProps }) => (
								<section className="dz-container">
									<div {...getRootProps({ className: "dropzone" })}>
										<input {...getInputProps()} />
										<p className="noselect">Drag & drop or click to select image file</p>
										<p className="noselect warning">jpeg and png files accepted</p>
									</div>
								</section>
							)}
						</Dropzone>
					)}
				</div>

				{bug.images && bug.images.length > 0 && (
					<div className={"images-wrapper"}>
						{bug.images.map((src) => (
							<div
								key={src}
								className="bug-image"
								onClick={() => this.props.handleImageZoom(src, bug._id, "bug")}
							>
								<div className="bug-image-inner">
									<img src={src} alt={src} />
								</div>
							</div>
						))}
					</div>
				)}

				<label htmlFor="steps">Resolution</label>
				<TextareaAutosize
					className={"bug-report-steps"}
					placeholder={"How was this bug fixed? List relevant files, sites, changes, etc."}
					name={"steps"}
					id={"steps"}
					minRows={3}
					defaultValue={bug.steps}
					onBlur={(e) => this._handleBugReportBlur(e, bug._id, this.state.active_doc._id)}
				/>
			</div>
		);
	};

	componentDidMount() {
		this.initData();
	}

	render() {
		return (
			<React.Fragment>
				<QAWrapperEl>
					<div className="qa_area">
						{!this.state.active_doc && (
							<div className="qa_wrapper">
								<button className="btn btn-block new-qa-btn" onClick={this._newQA}>
									+ QA Report
								</button>
								<button className="btn btn-block new-perf-btn" onClick={this._newPerformance}>
									+ Performance Report
								</button>
								<button className="btn btn-block new-tag-btn" onClick={this._newTagQA}>
									+ Tag QA
								</button>
								<button className="btn btn-block new-review-btn" onClick={this._newCodeReview}>
									+ Code Review
								</button>
								<div className="bugs-toggle">
									<label>
										<Switch
											onChange={this._handleChange}
											checked={!!this.state.show_closed}
											id={"show_closed"}
											height={20}
											width={40}
											className="toggle-switch"
											onColor="#1f85de"
											offColor="#9eb2c6"
										/>
										<span>Show Closed Bugs</span>
									</label>
								</div>
								{this.state.qas
									.filter((qa) => qa.status !== "archived")
									.map((a, i) => this._renderQAHistory(a, i))}
							</div>
						)}
						{this.state.active_doc ? this._renderQADoc() : ""}
						{this.state.active_doc ? this._renderBugReport() : ""}
					</div>
				</QAWrapperEl>
			</React.Fragment>
		);
	}
}

export default connect(mapStateToProps)(QAWrapper);

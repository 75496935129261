import styled from "styled-components";

export const ActivityWrapperEl = styled.div`
	width: 100%;
	min-width: 10px;
	padding: 0 10px 100px 10px;

	& .activity-row {
		display: block;
		margin: 10px 0;
		background: var(--bg-d2);
		border-radius: 3px;
		border: 1px solid transparent;
		position: relative;
		padding-left: 5px;
	}

	& .activity-row.create {
		border-left: 3px solid var(--green);
	}

	& .activity-row.update {
		border-left: 3px solid var(--blue);
	}

	& .activity-row.delete {
		border-left: 3px solid var(--red);
	}

	& .activity-row.archive {
		border-left: 3px solid var(--orange);
	}

	& .activity-row .text {
		padding: 8px 5px 8px 5px;
		height: auto !important;
		display: inline-block;
		vertical-align: top;
		width: calc(100% - 5px);
		text-align: left;
	}

	& .activity-row .title {
		font-size: 12px;
		color: var(--txt-lg);
	}

	& .activity-row .title span {
		color: var(--txt-lg);
	}

	& .activity-row .title span.name {
		color: white;
	}

	& .activity-row .title span.green {
		color: var(--green);
	}

	& .activity-row .title span.yellow {
		color: var(--yellow);
	}

	& .activity-row .title span.orange {
		color: var(--orange);
	}

	& .activity-row .link {
		text-decoration: underline;
		display: inline-block;
		transition: color 0.05s, -moz-transform ease-in-out 0.05s;
	}

	& .activity-row .link:hover {
		cursor: pointer;
		color: white;
	}
`;

// Resources
import React, {Component} from "react";
import Moment from "react-moment";

// Styles
import {RoomWrapperEl} from "./styles/RoomWrapper";

// Images
import anon from "../../images/icons/anon.svg";

class RoomWrapper extends Component {

    renderRoomUsers() {
        let items = '';
        if (this.props.users && this.props.users.length > 0) {
            items = this.props.users.map((item) => {
                if (item.deactivated) return '';
                let iconSrc = item.picture ? item.picture : anon;
                let status = item.count > 0 ? 'online' : 'offline';
                return (
                    <div className={"room-user-row " + status} key={item.name}>
                        <div className="img-wrapper">
                            <img src={iconSrc} alt={item.name}/>
                            <div className="status-dot-outer">
                                <div className="status-dot-inner">
                                </div>
                            </div>
                        </div>
                        <div className="content-wrapper">
                            <p className="name">{item.name}</p>
                            {status === 'online' &&
                                <p className="time">Online</p>}
                            {status === 'offline' &&
                                <p className="time"><Moment fromNow>{item.lastUpdated}</Moment></p>}
                        </div>
                    </div>
                );
            });
        }
        return items;
    };

    render() {
        return (
            <React.Fragment>
                {this.props.users &&
                    <RoomWrapperEl
                        companyHeight={this.props.companyWrapperHeight}
                        appHeight={this.props.appWrapperHeight}>
                        <div className={"room-wrapper " + this.props.page}>
                            <div className="body-wrapper">
                                {this.renderRoomUsers()}
                            </div>
                        </div>
                    </RoomWrapperEl>}
            </React.Fragment>
        );
    };
}

export default RoomWrapper;
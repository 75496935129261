const socket = (state = {}, action) => {
	if (action.type === "INIT_SOCKETS") {
		return {
			socket: action.socket
		};
	} else {
		return state;
	}
};

export default socket;

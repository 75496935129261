// Resources
import React, { Component } from "react";
import Moment from "react-moment";

// Components
import AuthHelperMethods from "../auth/AuthHelperMethods";

// Styles
import { ActivityWrapperEl } from "./styles/ActivityWrapper";

class ActivityWrapper extends Component {
	Auth = new AuthHelperMethods();

	constructor(props) {
		super(props);
		this.state = {
			loaded: false,
			activities: []
		};
	}

	_generateTimeStyles = (date) => {
		if (!date) return "";
		const ONE_DAY = 60 * 60 * 1000 * 24; /* ms */
		const ONE_WEEK = 60 * 60 * 1000 * 24 * 7; /* ms */
		const ONE_MONTH = 60 * 60 * 1000 * 24 * 30; /* ms */
		const now = new Date();
		if (now - date < ONE_DAY) {
			return "green";
		} else if (now - date < ONE_WEEK) {
			return "yellow";
		} else if (now - date < ONE_MONTH) {
			return "orange";
		}
		return "";
	};

	_handleActivityClicked = (e) => {
		const id = e.target.getAttribute("data-id");
		if (id) {
			const temp = id.split(":");
			if (temp.length === 2) {
				this.props.handleActivityClick(temp[0], temp[1]);
			}
		}
	};

	fetchActivities = () => {
		console.log("[ RSB - ActivityWrapper - fetchActivities ] Fetching activities for id: " + this.props.id);
		const post_data = {
			type: this.props.type,
			id: this.props.id
		};
		this.Auth.getActivitiesTypeID(post_data)
			.then((res) => {
				if (res.error) {
					console.error(res.error);
				} else if (res.activities) {
					this.setState({
						activities: res.activities
					});
				} else {
					console.error("Unknown Error");
				}
			})
			.catch((err) => {
				console.log("[ RSB - ActivityWrapper - fetchActivities ] POST error:", err);
			});
	};

	init = () => {
		console.log("[ RSB - ActivityWrapper - init ] id: " + this.props.id);
		if (!this.state.loaded && this.props.id) {
			// Load activities
			this.setState(
				{
					loaded: true
				},
				function () {
					this.fetchActivities();
				}
			);
		} else if (!this.state.loaded) {
			setTimeout(() => {
				this.init();
			}, 100);
		}
	};

	componentDidMount() {
		this.init();
	}

	render() {
		Moment.globalTimezone = Intl.DateTimeFormat()["resolvedOptions"]()["timeZone"];
		return (
			<React.Fragment>
				<ActivityWrapperEl>
					{this.state.activities.map((item) => {
						const firstDot = item.type_name && item.type_id ? " • " : "";
						const secondDot = " • ";
						return (
							<div className={"card activity-row " + item.action} key={item._id}>
								<div className="text">
									<p className="title">
										<span className={this._generateTimeStyles(new Date(item.createdAt))}>
											<Moment fromNow>{item.createdAt}</Moment>
										</span>
										{secondDot}
										<span className={"name"}>{item.user_name}</span> {item.message}
										{item.type_name && item.type_id && (
											<span>
												{firstDot}
												<span
													className="link"
													data-id={item.type + ":" + item.type_id}
													onClick={this._handleActivityClicked}
												>
													{item.type_name}
												</span>
											</span>
										)}
									</p>
								</div>
							</div>
						);
					})}
				</ActivityWrapperEl>
			</React.Fragment>
		);
	}
}

export default ActivityWrapper;

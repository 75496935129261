const room = (state = {}, action) => {
	if (action.type === "APP_ROOM") {
		return {
			users: action.users,
			online: action.online
		};
	} else {
		return state;
	}
};

export default room;

import styled from "styled-components";
export const ToolWrapperEl = styled.div`
	& nav {
		padding: 1em;
		& a {
			padding: 1em;
			color: dodgerblue;
			text-transform: uppercase;
			letter-spacing: 1px;
			font-size: 0.9em;
		}
	}
	& main {
		padding: 1em;
		h1 {
			font-size: 2em;
		}
		h2 {
			font-size: 1.5em;
		}
		& textarea {
			padding: 1em;
			margin: 1em;
			display: block;
			width: 100%;
			min-height: 70vh;
		}
		.button-group {
			button {
				padding: 0.5em 1em;
				background: dodgerblue;
				border: 1px solid #fff;
				color: #fff;
				text-transform: uppercase;
				letter-spacing: 1px;
				font-size: 0.9em;
			}
		}
	}
`;

// Resources
import React, { Component } from "react";
import Moment from "react-moment";
import "moment-timezone";
import Dropzone from "react-dropzone"; // https://github.com/react-dropzone/react-dropzone
import Cropper from "react-easy-crop"; // https://github.com/ricardo-ch/react-easy-crop
import getCroppedImg from "../../helpers/cropImage";

// Components
import AuthHelperMethods from "../auth/AuthHelperMethods";
import TopNavWrapper from "./TopNavWrapper";

// Styles
import { TeamWrapperEl } from "./styles/TeamWrapper";

// Images
import clear from "../../images/icons/v1/clear.svg";
import anon from "../../images/icons/anon.svg";

class TeamWrapper extends Component {
	Auth = new AuthHelperMethods();

	constructor(props) {
		super(props);
		this.state = {
			users: [],
			theme: "",
			// Image upload
			file: {},
			imageSrc: null,
			crop: { x: 0, y: 0 },
			croppedAreaPixels: null,
			zoom: 1,
			aspect: 1,
			rotation: 0,
			processing: false
		};
	}

	_onCropChange = (crop) => {
		this.setState({ crop });
	};

	_onCropComplete = (croppedArea, croppedAreaPixels) => {
		// console.log(croppedArea, croppedAreaPixels)
		this.setState({ croppedAreaPixels: croppedAreaPixels });
	};

	_onDrop = (files) => {
		if (files && files[0] && files[0].type && (files[0].type === "image/jpeg" || files[0].type === "image/png")) {
			this.setState({
				file: files[0],
				imageSrc: URL.createObjectURL(files[0])
			});
		} else {
			alert("Invalid file format");
		}
	};

	_saveCroppedImage = async () => {
		try {
			this.setState(
				{
					processing: true
				},
				async function () {
					const croppedImage = await getCroppedImg(
						this.state.imageSrc,
						this.state.croppedAreaPixels,
						this.state.rotation
					);
					this.Auth.getS3Url()
						.then(async (res) => {
							if (res && res.url) {
								// Post the image directly to the s3 bucket
								await fetch(res.url, {
									method: "PUT",
									headers: {
										"Content-Type": "multipart/form-data"
									},
									body: croppedImage
								});
								// Save image url to user profile
								const imageUrl = res.url.split("?")[0];
								this.Auth.updateUserProfileImage({
									imageUrl: imageUrl
								})
									.then(async (res) => {
										if (res && res.success) {
											window.location.reload();
										} else {
											alert("Failed to update profile image");
										}
									})
									.catch((err) => {
										console.log("[ _saveCroppedImage - getS3Url ] POST error:", err);
									});
							} else {
								alert("Failed to get S3 url");
							}
						})
						.catch((err) => {
							console.log("[ _saveCroppedImage - getS3Url ] POST error:", err);
						});
				}
			);
		} catch (err) {
			console.error(err);
		}
	};

	_departmentSelected = (e) => {
		const target = e.target;
		if (target) {
			const id = target.getAttribute("data-id");
			const value = target.value;
			console.log("[ _departmentSelected ] id: " + id + " value: " + value);
			if (id && value) {
				this.Auth.updateUserDepartment({ id: id, value: value })
					.then((res) => {
						if (res.error) {
							console.error(res.error);
						} else if (res.success) {
							// Update user on page
							let users = this.state.users;
							for (let i = 0; i < users.length; i++) {
								if (users[i]._id === id) {
									users[i].profile.department = value;
									break;
								}
							}

							this.setState({
								users: users
							});
						} else {
							console.error("Unknown Error");
						}
					})
					.catch((err) => {
						console.log("[ fetchUsers ] POST error:", err);
					});
			}
		}
	};

	fetchUsers = () => {
		this.Auth.getUsers()
			.then((res) => {
				if (res.error) {
					console.error(res.error);
				} else if (res.users) {
					// Place self user to the front of the list
					let sortedUsers = [];
					for (let i = 0; i < res.users.length; i++) {
						res.users[i]._id === this.props.user._id
							? sortedUsers.unshift(res.users[i])
							: sortedUsers.push(res.users[i]);
					}
					this.setState({
						users: sortedUsers
					});
				} else {
					console.error("Unknown Error");
				}
			})
			.catch((err) => {
				console.log("[ fetchUsers ] POST error:", err);
			});
	};

	componentDidMount() {
		if (this.props.page === "app") {
			this.initUsers();
		}
	}

	initUsers() {
		if (this.props.user && this.props.user.settings) {
			this.setState(
				{
					theme: this.props.user.settings.theme
				},
				function () {
					this.fetchUsers();
				}
			);
		} else {
			setTimeout(() => {
				this.initUsers();
			}, 1000);
		}
	}

	render() {
		Moment.globalTimezone = Intl.DateTimeFormat()["resolvedOptions"]()["timeZone"];
		return (
			<React.Fragment>
				<TeamWrapperEl>
					<div className="body-wrapper">
						<div className={"header-wrapper team" + (this.props.page === "app" ? " app" : "")}>
							<TopNavWrapper page={this.props.page} />
						</div>
						<div className="content-wrapper">
							<div className="team-column user-wrapper">
								<h1>
									<img className="team" src={clear} alt={"team"} />
									Team Members • <span>{this.state.users.length}</span>
								</h1>
								{this.state.users.map((item) => {
									let icon_src = item.profile.picture ? item.profile.picture : anon;
									return (
										<div
											className={
												"card user-row" + (this.props.user._id === item._id ? " self" : "")
											}
											key={item._id}
										>
											<div className={"left-wrapper"}>
												<img className="favicon" src={icon_src} alt={""} />
												<div className="text">
													<p className="title">
														<span>
															{item.profile.given_name + " " + item.profile.family_name} •{" "}
														</span>{" "}
														{item.profile.email}
														{(item.profile.type === "sudo" ||
															item.profile.type === "admin") && (
															<span className="admin-badge">{item.profile.type}</span>
														)}
													</p>
													<div className="department-wrapper">
														<p
															className={
																"department " +
																item.profile.department
																	.toLowerCase()
																	.replace(/[^a-z]/g, "")
															}
														>
															{item.profile.department}
														</p>
														{(this.props.user.type === "sudo" ||
															this.props.user._id === item._id) && (
															<div className="department-update-wrapper">
																<select
																	onChange={this._departmentSelected}
																	value={item.profile.department}
																	data-id={item._id}
																>
																	<option value="N/A">N/A</option>
																	<option value="Dev: Front-End">
																		Dev: Front-End
																	</option>
																	<option value="Dev: QA">Dev: QA</option>
																	<option value="Dev: Back-end">Dev: Back-end</option>
																	<option value="Partnership Executive">
																		Partnership Executive
																	</option>
																	<option value="Project Manager">
																		Project Manager
																	</option>
																	<option value="Business Development Rep">
																		Business Development Rep
																	</option>
																	<option value="Sales Operations">
																		Sales Operations
																	</option>
																	<option value="Strategic Partnerships">
																		Strategic Partnerships
																	</option>
																	<option value="Client Success Manager">
																		Client Success Manager
																	</option>
																	<option value="Graphics">Graphics</option>
																	<option value="Marketing">Marketing</option>
																	<option value="Data Analyst">Data Analyst</option>
																	<option value="Accounting">Accounting</option>
																	<option value="Human Resources">
																		Human Resources
																	</option>
																	<option value="Executive">Executive</option>
																	<option value="Owner">Owner</option>
																	<option value="Deactivated">Deactivated</option>
																</select>
															</div>
														)}
													</div>
													{item.profile.timezone && (
														<p className="tz">
															<span
																className={
																	item.profile.timezone !== "Pacific Standard Time"
																		? "yellow"
																		: ""
																}
															>
																{item.profile.timezone} • UTC Offset{" "}
																{item.profile.offset}
															</span>
														</p>
													)}
													<p className="time">
														Account Created:{" "}
														<Moment format={"llll"}>{item.createdAt}</Moment> •{" "}
														<Moment fromNow>{item.createdAt}</Moment>
													</p>
												</div>
											</div>
											{this.props.user._id === item._id && !this.state.processing && (
												<div className={"upload-wrapper"}>
													<Dropzone onDrop={this._onDrop}>
														{({ getRootProps, getInputProps }) => (
															<section className="dz-container">
																<div {...getRootProps({ className: "dropzone" })}>
																	<input {...getInputProps()} />
																	<p className="noselect">
																		Drag & drop your new profile image here, or
																		click to select file
																	</p>
																	<p className="noselect warning">
																		Only .jpeg and .png files will be accepted
																	</p>
																</div>
															</section>
														)}
													</Dropzone>
													{this.state.imageSrc && (
														<div className={"crop-wrapper"}>
															<Cropper
																image={this.state.imageSrc}
																crop={this.state.crop}
																zoom={this.state.zoom}
																aspect={this.state.aspect}
																cropShape="round"
																showGrid={false}
																onCropChange={this._onCropChange}
																onCropComplete={this._onCropComplete}
															/>
														</div>
													)}
													{this.state.imageSrc && (
														<div
															className={"save-crop-wrapper"}
															onClick={this._saveCroppedImage}
														>
															Upload Image
														</div>
													)}
												</div>
											)}
											{this.props.user._id === item._id && this.state.processing && (
												<div className={"processing-wrapper"}>Processing...</div>
											)}
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</TeamWrapperEl>
			</React.Fragment>
		);
	}
}

export default TeamWrapper;

// noinspection JSUnresolvedVariable

// Resources
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import queryString from "query-string"; // https://github.com/sindresorhus/query-string
import { io } from "socket.io-client";
import { confirmAlert } from "react-confirm-alert"; // https://github.com/GA-MO/react-confirm-alert
import "react-confirm-alert/src/react-confirm-alert.css";
import Cookies from "universal-cookie";
import moment from "moment";

// Components
import SideBarWrapper from "./components/sideBar/SideBarWrapper";
import BodyWrapper from "./components/body/BodyWrapper";
import RightSideBarWrapper from "./components/sideBar/RightSideBarWrapper";
import AddCSDWrapper from "./components/forms/AddCSDWrapper";

// Auth
import AuthHelperMethods from "./components/auth/AuthHelperMethods";
import withAuth from "./components/auth/withAuth";

// Import Redux
import { createStore } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./reducers";

// Globals
let cookies;
let socket;
const store = createStore(rootReducer);

class Form extends Component {
	Auth = new AuthHelperMethods();

	constructor(props) {
		super(props);
		this.state = {
			// Title
			title: "Form",
			id: "",
			siteId: "",
			// User
			userId: "",
			profile: {},
			form: {},
			formvId: "",
			fieldRevisionsId: "",
			mainVersion: 1,
			settings: {},
			// Sockets
			chatId: "",
			messages: [],
			isConnected: false,
			// Url Params
			reader: false,
			show: false,
			// Misc
			logout: false,
			// S3
			imageZoomSrc: "",
			fileKey: "",
			bugId: "",
			imageType: "",
			imageObj: undefined,
			imageCopyText: "Copy External Link"
		};
		this.params = "";
	}

	// <editor-fold desc="* * * EXIT METHODS * * *">
	componentWillUnmount() {
		if (this.state.logout) this.Auth.logout();
		this._leaveRoom();
	}

	_leaveRoom = () => {
		console.log("=========================");
		console.log("=== LEAVE ROOM (FORM) ===");
		console.log("=========================");
		// Clear data from redux
		store.dispatch({ type: "UPDATE_FORM_FIELDS", fields: undefined, formvs: undefined });
		store.dispatch({ type: "APP_ROOM", users: [], online: 0 });
		store.dispatch({ type: "CHAT_INIT", messages: undefined, id: undefined });
		store.dispatch({ type: "CHAT_ADD", messages: undefined });
		store.dispatch({ type: "INIT_SOCKETS", socket: undefined });
		store.dispatch({ type: "NAV", nav: undefined, page: undefined });
		store.dispatch({ type: "FORM", form: undefined, formvs: undefined, vid: undefined, fields: undefined });
		this.setState({ isConnected: false });
		if (socket) socket.disconnect();
	};

	_handleLogout = () => {
		this.setState({ logout: true }, function () {
			this.props.history.replace("/login");
		});
	};

	goTo404 = () => {
		const destination = cookies.get("forceRedirectHomepage") ? "/" : "/404";
		cookies.remove("forceRedirectHomepage");
		this.props.history.push({
			pathname: destination,
			search: ""
		});
	};
	// </editor-fold>

	_handleDeleteFormvClick = (formv_id, name, cb) => {
		if (!formv_id) return;
		confirmAlert({
			title: "Delete major version?",
			message: "Are you sure?",
			overlayClassName: "confirm-alert-bg-wrapper delete",
			buttons: [
				{
					label: "Yes, Continue",
					onClick: () => {
						this.Auth.deleteFormv({
							form_id: this.state.form._id,
							formv_id: formv_id
						})
							.then((res) => {
								if (res.data.error) {
									alert(res.error);
								} else if (res.data.success) {
									this.props.history.push({
										pathname: "/form",
										search: "?id=" + this.state.form._id
									});
									window.location.reload();
								} else {
									// Unknown error
									console.log(res);
								}
							})
							.catch((err) => {
								console.log(err);
							});
					},
					className: "green"
				},
				{
					label: "No, Cancel",
					onClick: () => console.log("Cancel delete main version")
				}
			]
		});
	};

	_handleAddFormvClick = (formv_id, name, cb) => {
		if (!formv_id) return;
		if (name && typeof cb === "function") {
			this.Auth.updateFormvName({
				formv_id: formv_id,
				name: name
			})
				.then((res) => {
					if (res.error) {
						alert(res.error);
					} else if (res.success) {
						cb();
					} else {
						// Unknown error
						console.log(res);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			confirmAlert({
				title: "Create new CSD version?",
				message:
					"Remember to delete or update fields that have changed. Also update any in-progress Opportunity links with the correct CSD version",
				overlayClassName: "confirm-alert-bg-wrapper clone",
				buttons: [
					{
						label: "Yes, Continue",
						onClick: () => this.createFormv(formv_id),
						className: "green"
					},
					{
						label: "No, Cancel",
						onClick: () => console.log("Cancel new main version")
					}
				]
			});
		}
	};

	createFormv = (formv_id) => {
		if (formv_id && this.state.form._id) {
			this.Auth.createFormv({
				form_id: this.state.form._id,
				formv_id: formv_id
			})
				.then((res) => {
					if (res.error) {
						alert(res.error);
					} else if (res.success) {
						this.props.history.push({
							pathname: "/form",
							search: "?id=" + this.state.form._id
						});
						window.location.reload();
					} else {
						// Unknown error
						console.log(res);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	_redirectToFormv = (version) => {
		this.props.history.push({
			pathname: "/form",
			search: queryString.exclude(this.props.history.location.search, ["v"]) + "&v=" + version
		});
		window.location.reload();
	};

	_handleFieldRevisionsClick = (fieldId) => {
		if (!fieldId) return;
		this.setState(
			{
				fieldRevisionsId: fieldId
			},
			function () {
				this.Auth.getFieldRevisions({
					field_id: fieldId
				})
					.then((res) => {
						if (res.error) {
							alert(res.error);
						} else if (res.fields) {
							store.dispatch({
								type: "FIELD_REVISION",
								fields: res.fields
							});
						} else {
							// Unknown error
							console.log(res);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			}
		);
	};

	_handleFieldRevisionsClear = () => {
		this.setState(
			{
				fieldRevisionsId: ""
			},
			function () {
				store.dispatch({
					type: "FIELD_REVISION",
					fields: undefined
				});
			}
		);
	};

	_handleChatMessageSubmit = (message) => {
		if (message && socket) {
			socket.emit("submitPublicChatMessage", {
				user_id: this.state.userId,
				chat_id: this.state.chatId,
				room: this.state.form._id,
				profile: this.state.profile,
				content: message
			});
		} else {
			console.error("[ Form - _handleChatMessageSubmit ] ERROR: socket is undefined!", socket);
		}
	};

	_handleFieldChanged = (fields, formvs, field_id) => {
		if (fields) {
			store.dispatch({
				type: "UPDATE_FORM_FIELDS",
				fields: fields,
				formvs: formvs
			});
		}
		// Update revisions sidebar if it's open for the updated field
		if (field_id && this.state.fieldRevisionsId && field_id === this.state.fieldRevisionsId) {
			this._handleFieldRevisionsClick(field_id);
		}
	};

	_handleSidebarToggleChanged = (id, checked) => {
		if (id) {
			// Update state
			this.setState(
				{
					[id]: checked
				},
				function () {
					// Update url param
					let newSearch = queryString.exclude(this.props.history.location.search, [id]);
					if (checked) newSearch += "&" + id + "=1";
					this.props.history.push({ search: newSearch });
				}
			);
		}
	};

	_handlePrimarySiteIdSave = (siteId, cb) => {
		this.Auth.updatePrimarySiteId({
			form_id: this.state.form._id,
			site_id: siteId
		})
			.then((res) => {
				if (res.error) {
					alert(res.error);
				} else if (res.success) {
					if (typeof cb === "function") cb(res);
				} else {
					// Unknown error
					console.log(res);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	_handleImageZoom = (img, id, type) => {
		if (type === "bug") {
			this.setState(
				{
					imageZoomSrc: img,
					fileKey: img.split("/s3/images/")[1],
					bugId: id,
					imageType: type,
					imageObj: undefined
				},
				function () {
					let newSearch = queryString.exclude(this.props.history.location.search, ["img"]);
					newSearch += "&img=" + this.state.fileKey + "|b|" + id;
					this.props.history.push({ search: newSearch });
				}
			);
		} else if (type === "formv") {
			this.setState(
				{
					imageZoomSrc: img.url,
					fileKey: img.url.split("/s3/images/")[1],
					imageType: type,
					imageObj: img
				},
				function () {
					// Add image file key to url
					let newSearch = queryString.exclude(this.props.history.location.search, ["img"]);
					newSearch += "&img=" + this.state.fileKey;
					this.props.history.push({ search: newSearch });
				}
			);
		}
	};

	_handleImageZoomDismissed = () => {
		this.setState(
			{
				imageZoomSrc: "",
				fileKey: "",
				bugId: "",
				imageType: "",
				imageObj: undefined
			},
			function () {
				// Remove image file key from url
				this.props.history.push({ search: queryString.exclude(this.props.history.location.search, ["img"]) });
			}
		);
	};

	_handleDeleteImage = (e) => {
		e.stopPropagation();
		if (this.state.imageZoomSrc) {
			this.setState(
				{
					imageZoomSrc: ""
				},
				function () {
					// Delete image
					if (this.state.fileKey) {
						let post_data = {
							file_key: this.state.fileKey
						};

						if (this.state.imageType === "bug") {
							post_data["bug_id"] = this.state.bugId;
						} else if (this.state.imageType === "formv") {
							post_data["formv_id"] = this.state.formvId;
						}

						// Make network request to delete image
						this.Auth.deleteSingleImage(post_data)
							.then((res) => {
								if (res.error) {
									alert(res.error);
								} else if (res.success) {
									// Image successfully deleted. Reload page!
									window.location.reload();
								} else {
									// Unknown error
									console.log(res);
								}
							})
							.catch((err) => {
								console.log(err);
							});
					} else {
						// Only able to delete bug images for now
						alert("Error: Missing required params");
					}
				}
			);
		}
	};

	_forceReloadPage = () => {
		window.location.reload();
	};

	_forceReloadSockets = () => {
		this.initSockets(function () {
			console.log("[ Form - _forceReloadSockets ] Sockets re-connected");
		});
	};

	_updateQAParams = (id, qa, bug) => {
		let search = `?id=${id}&v=${this.state.mainVersion}`;
		if (qa) search += `&qa=${qa}`;
		if (bug) search += `&bug=${bug}`;
		this.props.history.push({
			pathname: "/form",
			search: search
		});
	};

	componentDidMount() {
		// Init cookies
		// noinspection JSValidateTypes
		cookies = new Cookies();

		// Init params
		this.params = queryString.parse(this.props.history.location.search);
		const reader = this.params && this.params.reader ? this.params.reader : "";
		const show = this.params && this.params.show ? this.params.show : "";

		// Set form id, display version, and page settings
		const id = this.params && this.params.id ? this.params.id : "";
		const v = parseInt(this.params && this.params.v ? this.params.v : "0");
		if (!id) return this.goTo404();

		// Set initial states
		this.setState(
			{
				reader: !!reader,
				show: !!show,
				id: id,
				isConnected: false
			},
			function () {
				// Init redux
				store.dispatch({
					type: "NAV",
					nav: "",
					page: "form"
				});

				// Fetch data
				if (this.Auth.getToken()) {
					this.initCSD(id, v);
					this.fetchAdminAll();
				} else {
					console.log("[ Form - componentDidMount ] Token not found, kicking user to login page");
					this._handleLogout();
				}
			}
		);
	}

	/**
	 * This method will make 1 call to the back end and return all data needed to load the CSD
	 * @param id
	 * @param v
	 */
	initCSD = (id, v) => {
		console.log("[ Form - initCSD ] id: " + id + " v:" + v);
		this.Auth.initCSD({
			id: id,
			v: v
		})
			.then((res) => {
				if (res.error) {
					res.fof ? this.goTo404() : alert("[ Form - initCSD ] ERROR: " + res.error);
				} else if (res.user && res.user.id && res.form && res.formvs && res.vid && res.v && res.fields) {
					console.log("-------------------------------------------------------------");
					console.log("-------------------------------------------------------------");
					console.log("[ Form - initCSD ] CSD data fetched successfully -> res:", res);

					// Save profile in redux
					store.dispatch({
						type: "PROFILE",
						profile: res.user.profile
					});

					this.setState(
						{
							profile: res.user.profile,
							userId: res.user.id,
							title: "Form | " + res.form._company.title + " • " + res.form.title,
							form: res.form,
							formvId: res.vid,
							mainVersion: res.v,
							// Sockets
							chatId: "", // TBD
							messages: [] // TBD
						},
						function () {
							// Reverse order of tasks
							if (res.tasks && res.tasks.length > 1) res.tasks.reverse();

							// Save company data in redux
							store.dispatch({
								type: "FORM",
								form: res.form,
								formvs: res.formvs,
								vid: res.vid,
								fields: this.processFields(res.fields),
								tasks: res.tasks
							});

							// Init sockets
							this.initSockets(() => {
								console.log("[ Form - initCSD ] Socket initialization completed");
								console.log("-------------------------------------------------------------");
								console.log("-------------------------------------------------------------");
							});
						}
					);
				} else {
					alert("[ Form - initCSD ] Unknown server error - Please contact administrator");
				}
			})
			.catch((err) => {
				console.log("[ Form - initCSD ] POST error:", err);
				alert("[ Form - initCSD ] Error caught while initializing CSD data");
			});
	};

	processFields = (fields) => {
		let fieldsObj = {};
		if (fields && fields.length > 0) {
			// Iterate thru fields array
			fields.forEach(function (field) {
				// Init fields
				if (typeof fieldsObj[field.section] === "undefined") {
					fieldsObj[field.section] = {
						title: field.section,
						lastUpdated: new Date(field.createdAt),
						lastUpdatedBy: field.created_by,
						fields: []
					};
				}

				// Populate fields object
				fieldsObj[field.section].fields.push(field);
				if (new Date(field.createdAt) > fieldsObj[field.section].lastUpdated) {
					fieldsObj[field.section].lastUpdated = new Date(field.createdAt);
					fieldsObj[field.section].lastUpdatedBy = field.created_by;
				}
			});

			// Sort fields
			for (let prop in fieldsObj) {
				if (Object.prototype.hasOwnProperty.call(fieldsObj, prop)) {
					fieldsObj[prop].fields.sort((a, b) => a.order - b.order);
				}
			}
		}
		return fieldsObj;
	};

	initSockets = (cb) => {
		if (this.state.profile && this.state.profile.email && this.state.form && this.state.form._id) {
			console.log("[ Form - initSockets ] Init -> profile:", this.state.profile);
			socket = io({ transports: ["polling"] });

			// Connect
			socket.on("connected", (data) => {
				console.log("[ Form - initSockets - connected ] message:", data.message);
				if (!this.state.isConnected) {
					this.setState(
						{
							isConnected: true
						},
						function () {
							// Enter room each time a connection is established
							socket.emit("enterRoom", {
								initChat: true,
								room: this.state.formvId,
								url: window.location.href,
								profile: this.state.profile,
								pageType: "Form",
								companyName: this.state.form._company.title,
								formName: this.state.form.title
							});
						}
					);
				}
			});

			socket.on("initConnect", (data) => {
				console.log("[ Form - initSockets - initConnect ] success:", data);
				// Save socket data in redux
				store.dispatch({
					type: "INIT_SOCKETS",
					socket: socket
				});
				// Perform on connect action
				if (typeof cb === "function") cb();
			});

			// Getting room info
			socket.on("handleRoomUpdate", (data) => {
				console.log("[ Form - initSockets - handleRoomUpdate ] Room data:", data);
				store.dispatch({
					type: "APP_ROOM",
					users: data.users || [],
					online: data.online || 0
				});
			});

			// Getting initial chat room messages
			socket.on("initMessages", (data) => {
				console.log("[ Form - initSockets - messages ] data:", data);
				let messages = data.messages;
				if (messages) {
					messages = messages.reverse();
					this.setState({ chatId: data.id, messages: messages });
					store.dispatch({
						type: "CHAT_INIT",
						messages: messages,
						id: data.id
					});
				}
			});

			// New message added to this room's chat
			socket.on("handlePublicChatMessageAdded", (data) => {
				console.log("[ Form - initSockets - handlePublicChatMessageAdded ] data:", data);
				if (data.error) {
					console.error(data.error);
				} else if (data.newMessage) {
					let messages = this.state.messages;
					messages.push(data.newMessage);
					this.setState({ messages: messages }, function () {
						store.dispatch({
							type: "CHAT_ADD",
							messages: messages
						});
					});
				}
			});
		}
	};

	copyImageLink = (e) => {
		e.stopPropagation();
		if (e && e.target && window.navigator.clipboard) {
			const url = document.location.protocol + "//" + document.location.host + "/image?key=" + this.state.fileKey;
			navigator.clipboard.writeText(url).then(
				() => {
					this.setState({ imageCopyText: "Link Copied!" });
					setTimeout(() => {
						this.setState({ imageCopyText: "Copy External Link" });
					}, 2000);
				},
				function (err) {
					console.error("Async: Could not copy text: ", err);
				}
			);
		}
	};

	fetchAdminAll = () => {
		this.Auth.fetchAdmin({ user: this.props.user })
			.then((res) => {
				if (res.error) {
					alert(res.error);
				} else if (res.data.settings) {
					let obj = {};
					res.data.settings.forEach((item) => (obj[item.title] = item.value));
					obj.tags = obj.tags.split("\n");
					obj.departments = JSON.parse(obj.departments);
					obj.copy_data = JSON.parse(obj.copy_data);
					this.setState({ settings: obj });
				}
			})
			.catch((err) => console.error("[ fetchAdminAll ] POST error:", err));
	};

	_handleCloneCSD = (id, type, title) => {
		if (id && type && title) {
			this.setState({
				showMask: true,
				addCSD: false,
				editCSD: false,
				cloneCSD: {
					type: type,
					id: id,
					title: title
				}
			});
		}
	};

	_handleAddCSD = (id, title) => {
		if (id && title) {
			// Edit CSD
			this.setState({
				showMask: true,
				addCSD: false,
				editCSD: id,
				cloneCSD: false,
				origTitle: title
			});
		} else {
			// Add CSD
			this.setState({
				showMask: true,
				addCSD: true,
				editCSD: false,
				cloneCSD: false,
				origTitle: ""
			});
		}
	};

	_handleAddCSDCancel = (reload, id) => {
		this.setState(
			{
				showMask: false,
				addCSD: false,
				editCSD: false,
				cloneCSD: false,
				origTitle: ""
			},
			function () {
				if (id) {
					this._handleFormClick(id);
				} else if (reload) {
					window.location.reload();
				}
			}
		);
	};

	_handleDeleteFormClick = (form) => {
		this.Auth.deleteForm({ id: form._id })
			.then((res) => {
				if (res.error) {
					alert(res.error);
				} else {
					window.location.href = "/company?id=" + form._company._id;
				}
			})
			.catch((err) => {
				console.log("[ _handleDeleteFormClick ] POST error:", err);
			});
	};

	_handleMaskClick = () => {
		// console.log('[ Company - _handleMaskClick ] Mask clicked');
	};

	_handleFormClick = (id, redirect) => {
		if (id) {
			window.location.href = "/form?id=" + id;
		} else if (redirect) {
			const win = window.open(redirect, "_blank");
			win.focus();
		}
	};

	_handleCloneCSDSubmit = (type, title, company_id) => {
		const postData = {
			form_id: this.state.cloneCSD.id,
			clone_type: type,
			title: title,
			company_id: company_id
		};
		console.log("[ Form - _handleCloneCSDSubmit ] postData:", postData);
		this.setState(
			{
				showMask: false,
				cloneCSD: false
			},
			function () {
				this.Auth.cloneCSD(postData)
					.then((res) => {
						console.log(res);
						if (res.error) {
							// TODO: add displayPrompt
							alert(res.error);
						} else if (res.id) {
							this._handleFormClick(res.id);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			}
		);
	};

	// Render protected component
	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>CSD • {this.state.title}</title>
				</Helmet>
				<Provider store={store}>
					<React.StrictMode>
						{this.state.imageZoomSrc && (
							<div className="image-zoom-wrapper" onClick={this._handleImageZoomDismissed}>
								<img src={this.state.imageZoomSrc} alt={this.state.imageZoomSrc} />
								<div className="image-zoom-options-wrapper">
									<div className="image-zoom-delete-btn" onClick={(e) => this._handleDeleteImage(e)}>
										Delete Image
									</div>
								</div>
								{this.state.imageObj && this.state.imageObj.user && (
									<div className="image-zoom-info-wrapper">
										<p>
											<span>Uploaded by</span> {this.state.imageObj.user}
											<span> on</span> {moment(this.state.imageObj.date).format("LLLL")} •
										</p>
										<p className="ex-link" onClick={this.copyImageLink}>
											{this.state.imageCopyText}
										</p>
									</div>
								)}
							</div>
						)}
						{this.state.profile && this.state.profile.email && (
							<div className="hide-for-print" id="sidebar">
								<SideBarWrapper
									reader={this.state.reader}
									show={this.state.show}
									form={this.state.form}
									handleSidebarToggleChanged={this._handleSidebarToggleChanged}
									handleLogout={this._handleLogout}
									forceReloadPage={this._forceReloadPage}
									siteId={this.state.siteId}
									handlePrimarySiteIdSave={this._handlePrimarySiteIdSave}
									mainVersion={this.state.mainVersion}
								/>
							</div>
						)}
						<div id="body" className="form-body">
							{this.state.settings && (
								<BodyWrapper
									reader={this.state.reader}
									show={this.state.show}
									handleDeleteFormvClick={this._handleDeleteFormvClick}
									handleAddFormvClick={this._handleAddFormvClick}
									redirectToFormv={this._redirectToFormv}
									handleFieldChanged={this._handleFieldChanged}
									forceReloadSockets={this._forceReloadSockets}
									handleFieldRevisionsClick={this._handleFieldRevisionsClick}
									handleAddCSD={this._handleAddCSD}
									handleCloneCSD={this._handleCloneCSD}
									handleDeleteFormClick={this._handleDeleteFormClick}
									handleImageZoom={this._handleImageZoom}
									id={this.state.id}
									settings={this.state.settings}
								/>
							)}
						</div>
						<div className="hide-for-print" id="right-sidebar">
							<RightSideBarWrapper
								type={"form"}
								params={this.params}
								form_type={this.state.form.type}
								id={this.state.id}
								handleImageZoom={this._handleImageZoom}
								updateQAParams={this._updateQAParams}
								handleChatMessageSubmit={this._handleChatMessageSubmit}
								handleFieldRevisionsClear={this._handleFieldRevisionsClear}
							/>
						</div>
						{this.state.showMask && (
							<div id="mask" onClick={this._handleMaskClick}>
								{(this.state.editCSD || this.state.cloneCSD) && (
									<AddCSDWrapper
										form={this.state.form}
										title={this.state.title}
										addCSD={this.state.addCSD}
										editCSD={this.state.editCSD}
										cloneCSD={this.state.cloneCSD}
										handleCloneCSDSubmit={this._handleCloneCSDSubmit}
										handleAddCSDCancel={this._handleAddCSDCancel}
									/>
								)}
							</div>
						)}
					</React.StrictMode>
				</Provider>
			</React.Fragment>
		);
	}
}

// In order for this component to be protected, we must wrap it with what we call a 'Higher Order Component' or HOC.
export default withAuth(Form);

import styled from "styled-components";
export const SalesforceWrapperEl = styled.div`
font-size: 0.9em;
padding: 1em 1em 2em 1em;
transition: opacity 500ms ease;
dl {
	color: var(--txt-lg);
	width: 100%;
	display: inline-flex;
	flex-wrap: wrap;
	div {
		display: flex;
		width: 100%;
	}
}
dt {
	padding: 0.25em;
	padding-right: 1em;
	width: 33.333%;
	text-align: left;
}
dd {
	padding: 0.25em;
	width: 66.666%;
	text-align: left;
}
table {
	color: var(--txt-lg);
	width: 100%;
	table-layout: fixed;
}
h3 {
	color: var(--txt-lg);
	text-align: center;
	padding: 1em 0;
}
a {
	color: var(--salesforce-blue);
	/* height: 1.2em; */
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
}
table th {
}
table td {
	padding: 0.25em;
	max-width: 100%;
	text-align: left;
}
table td div {
	/* overflow: hidden;
	text-overflow: ellipsis;
	word-break: break-all;
	white-space: nowrap; */
}
.admin-badge {
	background: #000;
	color: #fff;
	font-weight: 900;
    border-radius: 5em;
    padding: 0em 0.5em;
    display: inline-block;
	&.key {
		background: rebeccapurple;
	}
	&.a {
		background: dodgerblue;
	}
	&.b {
		background: forestgreen;
	}
	&.c {
		background: yellowgreen;
	}
	&.d {
		background: darkorange;
	}
	&.f {
		background: darkred;
	}
}
`;

import React from "react";

export function OpportunityInformation(props) {
	return (
		props.record &&
		props.record.StageName && (
			<div
				className="opportunity-information salesforce-wrapper"
				style={{
					opacity: props.record ? "1" : "0"
				}}
			>
				<div className="internal-information">
					{/* <a
						className="company-name"
						href={`https://upsellit.lightning.force.com/lightning/r/Opportunity/${props.record.Id}/view`}
						target="_blank"
						rel="noreferrer"
					>
						{props.record.Name}
					</a> */}
					<p className=""><b>Stage</b>: {props.record.StageName}</p>
					<p className=""><b>MRR</b>: {props.record.Monthly_Projected_Revenue__c || "--"}</p>
					<p className=""><b>Type</b>: {props.record.Type}</p>
					<p className=""><b>Sub Type</b>: {props.record.Sub_Type__c}</p>
					<p className=""><b>Last Activity</b>: {props.record.LastActivityDate}</p>
					<p className=""><b>Urgency</b>: {props.record.Urgency__c || "--"}</p>
					<p className="time_estimate">
						<b>Total Time Estimate</b>: {(props.getTimeEstimate(props.forms) / 8).toFixed(1)} business days
					</p>
					{/* {JSON.stringify(props.record, null, "  ")} */}
				</div>
			</div>
		)
	);
}

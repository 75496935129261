// noinspection JSUnresolvedVariable

// Resources
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import { io } from "socket.io-client";
import Cookies from "universal-cookie";

// Components
import SideBarWrapper from "./components/sideBar/SideBarWrapper";
import BodyWrapper from "./components/body/BodyWrapper";
import AddCompanyWrapper from "./components/forms/AddCompanyWrapper";
import AddCSDWrapper from "./components/forms/AddCSDWrapper";
import RightSideBarWrapper from "./components/sideBar/RightSideBarWrapper";

// Auth
import AuthHelperMethods from "./components/auth/AuthHelperMethods";
import withAuth from "./components/auth/withAuth";

// Redux
import { createStore } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./reducers";

// Globals
let cookies;
let socket;
const store = createStore(rootReducer);

// Export - Protected
class Company extends Component {
	Auth = new AuthHelperMethods();

	constructor(props) {
		super(props);
		this.state = {
			// Title
			title: "Company",
			id: "",
			// User
			userId: "",
			profile: {},
			company: {},
			// Add / Edit
			showMask: false,
			editCompany: false,
			addCSD: false,
			editCSD: false,
			cloneCSD: false,
			origTitle: "",
			// Sockets
			ws: "",
			isConnected: false,
			// Misc
			logout: false
		};
	}

	// <editor-fold desc="* * * EXIT METHODS * * *">
	componentWillUnmount() {
		if (this.state.logout) this.Auth.logout();
		this._leaveRoom();
	}

	_leaveRoom = () => {
		console.log("============================");
		console.log("=== LEAVE ROOM (COMPANY) ===");
		console.log("============================");
		store.dispatch({ type: "INIT_SOCKETS", socket: undefined });
		store.dispatch({ type: "APP_ROOM", users: [], online: 0 });
		store.dispatch({ type: "NAV", nav: undefined, page: undefined });
		store.dispatch({ type: "COMPANY", company: {} });
		this.setState({ isConnected: false });
		if (socket) socket.disconnect();
	};

	_handleLogout = () => {
		this.setState({ logout: true }, function () {
			this.props.history.replace("/login");
		});
	};

	goTo404 = () => {
		const destination = cookies.get("forceRedirectHomepage") ? "/" : "/404";
		cookies.remove("forceRedirectHomepage");
		this.props.history.push({
			pathname: destination,
			search: ""
		});
	};

	_handleFormClick = (id, redirect) => {
		if (id) {
			this.props.history.push({
				pathname: "/form",
				search: "?id=" + id
			});
		} else if (redirect) {
			const win = window.open(redirect, "_blank");
			win.focus();
		}
	};

	_handleActivityClick = (type, id) => {
		if (type === "company") {
			console.log("[ _handleActivityClick ] Already on the company page");
		} else if (type === "form") {
			this.props.history.push({
				pathname: "/form",
				search: "?id=" + id
			});
		} else {
			console.log("[ _handleActivityClick ] Unknown type:", type);
		}
	};

	_handleAdminClick = (id) => {
		if (id) {
			const url = "https://www2.upsellit.com/admin/control/edit/company.jsp?companyID=" + id;
			const win = window.open(url, "_blank");
			win.focus();
		}
	};
	// </editor-fold>

	// <editor-fold desc="* * * PAGE METHODS * * *">
	_handleDeleteClick = (id) => {
		if (id) {
			this.Auth.deleteCompany({ id: id })
				.then((res) => {
					if (res.error) {
						alert(res.error);
					} else {
						this.props.history.push({
							pathname: "/",
							search: "?nav=dashboard"
						});
					}
				})
				.catch((err) => {
					console.log("[ _handleDeleteClick ] POST error:", err);
				});
		}
	};

	_handleEditCompany = () => {
		this.setState({
			showMask: true,
			editCompany: true
		});
	};

	_handleAddCompanyCancel = (reload) => {
		// We are always editing when on the company page
		this.setState(
			{
				showMask: false,
				editCompany: false
			},
			function () {
				if (reload) {
					console.log("[ _handleAddCompanyCancel ] reload!");
					window.location.reload();
				}
			}
		);
	};

	_handleDeleteFormClick = (id) => {
		this.Auth.deleteForm({ id: id })
			.then((res) => {
				if (res.error) {
					alert(res.error);
				} else {
					window.location.reload();
				}
			})
			.catch((err) => {
				console.log("[ _handleDeleteFormClick ] POST error:", err);
			});
	};

	_handleCloneCSD = (id, type, title) => {
		if (id && type && title) {
			this.setState({
				showMask: true,
				addCSD: false,
				editCSD: false,
				cloneCSD: {
					type: type,
					id: id,
					title: title
				}
			});
		}
	};

	_handleCloneCSDSubmit = (type, title, company_id) => {
		const postData = {
			form_id: this.state.cloneCSD.id,
			clone_type: type,
			title: title,
			company_id: company_id
		};
		console.log("[ Company - _handleCloneCSDSubmit ] postData:", postData);
		this.setState(
			{
				showMask: false,
				cloneCSD: false
			},
			function () {
				this.Auth.cloneCSD(postData)
					.then((res) => {
						if (res.error) {
							// TODO: add displayPrompt
							alert(res.error);
						} else if (res.id) {
							this._handleFormClick(res.id);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			}
		);
	};

	_handleAddCSD = (id, title) => {
		if (id && title) {
			// Edit CSD
			this.setState({
				showMask: true,
				addCSD: false,
				editCSD: id,
				cloneCSD: false,
				origTitle: title,
				title: title
			});
		} else {
			// Add CSD
			this.setState({
				showMask: true,
				addCSD: true,
				editCSD: false,
				cloneCSD: false,
				origTitle: ""
			});
		}
	};

	_handleAddCSDCancel = (reload, id) => {
		this.setState(
			{
				showMask: false,
				addCSD: false,
				editCSD: false,
				cloneCSD: false,
				origTitle: ""
			},
			function () {
				if (id) {
					this._handleFormClick(id);
				} else if (reload) {
					window.location.reload();
				}
			}
		);
	};

	_forceReloadPage = () => {
		window.location.reload();
	};

	_handleMaskClick = () => {
		// console.log('[ Company - _handleMaskClick ] Mask clicked');
	};
	// </editor-fold>

	// <editor-fold desc="* * * SOCKET METHODS * * *">
	initSockets = () => {
		if (this.state.profile && this.state.profile.email && this.state.company && this.state.company._id) {
			console.log("[ Company - initSockets ] Init -> profile:", this.state.profile);
			socket = io({ transports: ["polling"] });

			// Connect
			socket.on("connected", (data) => {
				console.log("[ Company - initSockets - connected ] message:", data.message);

				// Enter room
				socket.emit("enterRoom", {
					initChat: false,
					room: this.state.company._id,
					url: window.location.href,
					profile: this.state.profile,
					pageType: "Company",
					companyName: this.state.company.title,
					formName: ""
				});
			});

			// Process room info
			socket.on("handleRoomUpdate", (data) => {
				console.log("[ Company - initSockets - handleRoomUpdate ] Room data:", data);
				store.dispatch({
					type: "APP_ROOM",
					users: data.users || [],
					online: data.online || 0
				});
				this.setState({ isConnected: true });
			});
		}
	};
	// </editor-fold>

	// <editor-fold desc="* * * COMPONENT METHODS * * *">
	componentDidMount() {
		// Init cookies
		// noinspection JSValidateTypes
		cookies = new Cookies();

		store.dispatch({
			type: "NAV",
			nav: "",
			page: "company"
		});

		// Set company id
		const params = queryString.parse(this.props.history.location.search);
		const id = params && params.id ? params.id : "";
		const adminId = params && params.adminId ? params.adminId : "";
		if (!id && !adminId) {
			this.goTo404();
			return;
		}

		this.setState({
			id: id,
			company: {},
			isConnected: false
		});

		if (this.Auth.getToken()) {
			this.Auth.getProfile()
				.then((res) => {
					if (res.success && res.data && res.data.profile) {
						// Save profile data in redux
						res.data.profile._id = res.data.id;
						store.dispatch({
							type: "PROFILE",
							profile: res.data.profile
						});

						// Save profile data in state
						this.setState(
							{
								profile: res.data.profile,
								userId: res.data.id,
								ws: res.data.ws
							},
							function () {
								// Get company data
								this.Auth.getCompany({ id: id, adminId: adminId })
									.then((res) => {
										if (res.error) {
											console.error("[ componentDidMount ] err:", res.error);
											this.goTo404();
										} else if (res.company) {
											// Set redux
											store.dispatch({
												type: "COMPANY",
												company: res.company
											});

											// Set state
											this.setState(
												{
													company: res.company,
													title: "Company | " + res.company.title
												},
												function () {
													// Init socket connection
													this.initSockets();
												}
											);
										} else {
											console.error("[ componentDidMount ] Company not found:", res);
											this.goTo404();
										}
									})
									.catch((err) => {
										console.log("[ componentDidMount ] POST error:", err);
									});
							}
						);
					} else {
						console.log("[ componentDidMount ] Server error:", res);
						this._handleLogout();
					}
				})
				.catch((err) => {
					console.log("[ componentDidMount ] POST error:", err);
					this._handleLogout();
				});
		} else {
			console.log("[ componentDidMount ] Token not found");
			this._handleLogout();
		}
	}

	// </editor-fold>

	// Render protected component
	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>CSD • {this.state.title}</title>
				</Helmet>
				<Provider store={store}>
					<React.StrictMode>
						{this.state.profile && this.state.profile.email && (
							<div id="sidebar">
								<SideBarWrapper
									handleLogout={this._handleLogout}
									handleEditCompany={this._handleEditCompany}
									handleDeleteClick={this._handleDeleteClick}
									handleAdminClick={this._handleAdminClick}
									forceReloadPage={this._forceReloadPage}
								/>
							</div>
						)}
						<div id="body" className="company-body">
							<BodyWrapper
								handleAddCSD={this._handleAddCSD}
								handleCloneCSD={this._handleCloneCSD}
								handleDeleteFormClick={this._handleDeleteFormClick}
								company={this.state.company}
							/>
						</div>
						<div id="right-sidebar">
							<RightSideBarWrapper
								handleActivityClick={this._handleActivityClick}
								type={"company"}
								id={this.state.id}
							/>
						</div>
						{this.state.showMask && (
							<div id="mask" onClick={this._handleMaskClick}>
								{this.state.editCompany && (
									<AddCompanyWrapper handleAddCompanyCancel={this._handleAddCompanyCancel} />
								)}
								{(this.state.addCSD || this.state.editCSD || this.state.cloneCSD) && (
									<AddCSDWrapper
										company={this.state.company}
										title={this.state.title}
										addCSD={this.state.addCSD}
										editCSD={this.state.editCSD}
										cloneCSD={this.state.cloneCSD}
										handleCloneCSDSubmit={this._handleCloneCSDSubmit}
										handleAddCSDCancel={this._handleAddCSDCancel}
									/>
								)}
							</div>
						)}
					</React.StrictMode>
				</Provider>
			</React.Fragment>
		);
	}
}

// In order for this component to be protected, we must wrap it with a 'Higher Order Component'
export default withAuth(Company);

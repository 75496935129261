const legacy = (state = {}, action) => {
	switch (action.type) {
		case "LEGACY":
			return {
				data: action.data
			};
		default:
			return state;
	}
};

export default legacy;

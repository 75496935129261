import styled from "styled-components";
export const ExampleWrapperEl = styled.div`
	display: block;
	position: relative;
	font-size: 1em;
	min-height: 500px;
	text-align: left;
	min-width: 800px;
	& .ex-body-left-wrapper {
		display: inline-block;
		vertical-align: top;
		width: 50%;
		padding-bottom: 100px;
		border-right: 1px solid var(--bg-d2);
		height: calc(100vh - 60px) !important;
		overflow-y: auto;
	}
	& .ex-body-left-wrapper::-webkit-scrollbar {
		width: 0;
	}
	& .ex-body-left-wrapper::-webkit-scrollbar-thumb {
		background-color: rgba(255, 255, 255, 0.2);
	}
	& .ex-body-left-wrapper::-webkit-scrollbar-track {
		background-color: rgba(255, 255, 255, 0);
	}
	& .ex-body-left-wrapper .ex-title-wrapper {
		display: block;
		height: 60px;
		position: relative;
	}
	& .ex-title-wrapper .ex-search-wrapper {
		position: relative;
		display: inline-block;
		vertical-align: top;
		width: 50%;
		height: 100%;
		margin-top: 6px;
	}
	& .ex-title-wrapper .ex-search-wrapper input {
		background: var(--dark2);
		border: 1px solid var(--bg-d2);
		height: 40px;
		border-radius: 20px;
		padding-left: 40px;
		padding-right: 40px;
		margin: 10px 15px;
		width: calc(100% - 24px);
		outline: none;
		font-size: 13px;
		transition: border 0.15s, -moz-transform ease-in-out 0.15s;
	}
	& .ex-title-wrapper .ex-search-wrapper input:focus {
		border: 1px solid rgba(31, 133, 222, 1);
	}
	& .ex-title-wrapper .ex-search-wrapper input::placeholder {
		color: var(--txt-lg);
	}
	& .ex-title-wrapper .ex-search-wrapper .search-icon {
		position: absolute;
		top: calc(50% - 8px);
		left: 30px;
		height: 16px;
		width: 16px;
		background-image: var(--icon-search2-lg);
	}
	& .ex-title-wrapper .ex-search-wrapper .clear-ex-search-button {
		position: absolute;
		top: calc(50% - 15px);
		right: 16px;
		height: 30px;
		width: 30px;
		text-align: center;
	}
	& .ex-title-wrapper .ex-search-wrapper .clear-ex-search-button:hover {
		cursor: pointer;
	}
	& .ex-title-wrapper .ex-search-wrapper .clear-ex-search-button span {
		color: var(--txt-lg);
		font-size: 21px;
		transition: color 0.15s, -moz-transform ease-in-out 0.15s;
	}
	& .ex-title-wrapper .ex-search-wrapper .clear-ex-search-button:hover > span {
		color: white;
	}
	& .ex-title-wrapper .ex-num-wrapper {
		display: inline-block;
		vertical-align: top;
		height: 60px;
		padding: 0 5px;
		text-align: center;
		margin-top: 6px;
		margin-left: 2px;
		margin-right: 4px;
	}
	& .ex-title-wrapper .ex-num-wrapper p {
		color: var(--txt-lg);
		text-align: center;
		font-size: 13px;
	}
	& .create-btn-wrapper {
		height: 30px;
		width: 30px;
		margin: 20px 0 0 0;
		display: inline-block;
		vertical-align: top;
		text-align: center;
		position: relative;
	}
	& .create-btn-wrapper span {
		color: var(--blue);
		font-size: 24px;
		text-align: center;
		pointer-events: none;
		transition: color 0.15s, -moz-transform ease-in-out 0.15s;
	}
	& .create-btn-wrapper:hover {
		cursor: pointer;
	}
	& .create-btn-wrapper:hover > span {
		color: white;
	}
	& .create-btn-wrapper span.selected {
		color: white;
	}
	& .create-btn-wrapper .prompt-wrapper {
		position: absolute;
		bottom: -45px;
		z-index: 100;
		width: 200px;
		left: calc(50% - 100px);
		text-align: center;
		height: 30px;
		display: none;
	}
	& .create-btn-wrapper .prompt-wrapper p {
		position: relative;
		font-size: 12px;
		color: var(--txt-d);
		height: 30px;
		padding: 0 15px;
		display: inline-block;
		vertical-align: top;
		background: white;
		border-radius: 15px;
		z-index: 91;
	}
	& .create-btn-wrapper .prompt-wrapper .tip {
		position: absolute;
		top: -3px;
		height: 20px;
		width: 20px;
		left: calc(50% - 10px);
		transform: rotate(45deg);
		background: white;
		z-index: 90;
	}
	& .create-btn-wrapper:hover > .prompt-wrapper {
		display: block;
		animation: prompt-wrapper-show-ani 0.35s forwards 1;
	}
	@keyframes prompt-wrapper-show-ani {
		0% {
			bottom: -50px;
			opacity: 0;
		}
		50% {
			bottom: -50px;
			opacity: 0;
		}
		100% {
			bottom: -45px;
			opacity: 1;
		}
	}
	& .create-btn-wrapper .prompt-wrapper.hide {
		display: none !important;
	}
	& .create-btn-wrapper .filter-dropdown-wrapper {
		position: absolute;
		top: 100%;
		left: 0;
		width: 150px;
		padding: 5px;
		background: white;
		border-radius: 3px;
		z-index: 1000;
		display: none;
	}
	& .create-btn-wrapper .filter-dropdown-wrapper.visible {
		display: block;
	}
	& .create-btn-wrapper .filter-icon {
		background-image: var(--icon-filter-lg);
		opacity: 0.7;
		height: 20px;
		width: 20px;
		margin: 6px auto 0 auto;
	}
	& .create-btn-wrapper .filter-dropdown-wrapper .filter-option-button {
		display: block;
		font-size: 13px;
		text-align: left;
		color: black;
		padding-left: 10px;
		border-radius: 3px;
	}
	& .create-btn-wrapper .filter-dropdown-wrapper .filter-option-button:hover {
		cursor: pointer;
		background: var(--blue);
		color: white;
	}
	& .tags-main-wrapper {
		min-height: 60px;
		border-bottom: 1px solid var(--bg-d2);
	}
	& .tags-sub-wrapper {
		min-height: 60px;
		border-bottom: 1px solid var(--bg-d2);
	}
	& .tags-title-wrapper {
		display: block;
		height: 60px;
		position: relative;
	}
	& .tags-title-wrapper .tags-search-wrapper {
		position: relative;
		display: inline-block;
		vertical-align: top;
		width: 50%;
		height: 100%;
		margin-top: 6px;
	}
	& .tags-title-wrapper .tags-search-wrapper input {
		background: var(--dark2);
		border: 1px solid var(--bg-d2);
		height: 40px;
		border-radius: 20px;
		padding-left: 40px;
		padding-right: 40px;
		margin-top: 10px;
		margin-left: 15px;
		width: calc(100% - 24px);
		outline: none;
		font-size: 13px;
		transition: border 0.15s, -moz-transform ease-in-out 0.15s;
	}
	& .tags-title-wrapper .tags-search-wrapper input:focus {
		border: 1px solid rgba(31, 133, 222, 1);
	}
	& .tags-title-wrapper .tags-search-wrapper input::placeholder {
		color: var(--txt-lg);
	}
	& .tags-title-wrapper .tags-search-wrapper .search-icon {
		position: absolute;
		top: calc(50% - 8px);
		left: 30px;
		height: 16px;
		width: 16px;
		background-image: var(--icon-search2-lg);
	}
	& .tags-title-wrapper .tags-search-wrapper .clear-tag-search-button {
		position: absolute;
		top: calc(50% - 15px);
		right: 16px;
		height: 30px;
		width: 30px;
		text-align: center;
	}
	& .tags-title-wrapper .tags-search-wrapper .clear-tag-search-button:hover {
		cursor: pointer;
	}
	& .tags-title-wrapper .tags-search-wrapper .clear-tag-search-button span {
		color: var(--txt-lg);
		font-size: 21px;
		transition: color 0.15s, -moz-transform ease-in-out 0.15s;
	}
	& .tags-title-wrapper .tags-search-wrapper .clear-tag-search-button:hover > span {
		color: white;
	}
	& .tags-title-wrapper .clear-tags-btn {
		display: inline-block;
		vertical-align: top;
		background: transparent;
		color: var(--red);
		font-size: 13px;
		padding: 0 8px;
		margin-top: 11px;
		height: 50px;
		transition: color 0.15s, -moz-transform ease-in-out 0.15s;
	}
	& .tags-title-wrapper .clear-tags-btn:hover {
		cursor: pointer;
		text-decoration: underline;
	}
	& .tags-list-wrapper {
		display: block;
		padding: 11px 10px 10px 12px;
		min-height: 60px;
	}
	& .ex-body-right-wrapper {
		position: absolute;
		top: 0;
		right: 0;
		width: calc(50% + 1px);
		height: calc(100vh - 60px);
		overflow-y: auto;
		z-index: 10;
	}
	& .ex-body-right-wrapper::-webkit-scrollbar {
		width: 0;
	}
	& .ex-body-right-wrapper::-webkit-scrollbar-thumb {
		background-color: rgba(255, 255, 255, 0.2);
	}
	& .ex-body-right-wrapper::-webkit-scrollbar-track {
		background-color: rgba(255, 255, 255, 0);
	}
	& .ex-body-right-wrapper .delete-btn {
		height: 60px;
		width: 60px;
		position: absolute;
		top: 0;
		left: 0;
		text-align: center;
		border-right: 1px solid var(--bg-d2);
		padding: 0;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	& .ex-body-right-wrapper .delete-btn:hover {
		cursor: pointer;
	}
	& .ex-body-right-wrapper .delete-btn img {
		height: 30%;
		width: 30%;
		text-align: center;
		background-image: var(--icon-delete-lg);
	}
	& .ex-body-right-wrapper .delete-btn:hover > img {
		background-image: var(--icon-delete-r);
	}
	& .ex-body-right-wrapper .close-btn {
		height: 60px;
		width: 60px;
		position: absolute;
		top: 0;
		right: 0;
		text-align: center;
		font-size: 24px;
		color: var(--txt-lg);
		border-left: 1px solid var(--bg-d2);
		transition: color 0.15s, -moz-transform ease-in-out 0.15s;
	}
	& .ex-body-right-wrapper .close-btn:hover {
		cursor: pointer;
		color: white;
	}
	& .ex-body-right-wrapper .create-wrapper {
		position: relative;
		border: 4px solid var(--darkblue);
		box-shadow: 0 0 37px 0 rgba(31, 133, 222, 0.25);
	}
	& .ex-body-right-wrapper .create-wrapper.valid {
		border: 4px solid rgba(0, 150, 97, 1);
		box-shadow: 0 0 37px 0 rgba(0, 150, 97, 0.25);
	}
	& .ex-body-right-wrapper .create-wrapper.example {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: auto;
		background: var(--bg-d1);
	}
	& .ex-body-right-wrapper .create-wrapper h1 {
		font-size: 13px;
		color: var(--txt-lg);
		text-align: center;
		border-bottom: 1px solid var(--bg-d2);
	}
	& .ex-body-right-wrapper .create-wrapper .type-select-wrapper {
		border-bottom: 1px solid var(--bg-d2);
	}
	& .ex-body-right-wrapper .create-wrapper .input-title-wrapper {
		display: inline-block;
		vertical-align: top;
		width: 120px;
		font-size: 13px;
		color: var(--txt-lg);
		text-align: center;
		border-right: 1px solid var(--bg-d2);
	}
	& .ex-body-right-wrapper .create-wrapper .input-title-wrapper.area {
		border-right: unset;
	}
	& .ex-body-right-wrapper .create-wrapper .type-btn-wrapper {
		display: inline-block;
		vertical-align: top;
		width: calc(100% - 120px);
		padding: 10px 0 0 10px;
	}
	& .ex-body-right-wrapper .create-wrapper .input-body-wrapper {
		border-bottom: 1px solid var(--bg-d2);
	}
	& .ex-body-right-wrapper .create-wrapper .input-wrapper {
		display: inline-block;
		vertical-align: top;
		width: calc(100% - 120px);
		height: 60px;
		padding: 10px 0 0 10px;
		position: relative;
		background: rgba(7, 9, 13, 0.4);
	}
	& .ex-body-right-wrapper .create-wrapper .input-wrapper input {
		background: transparent;
		border: none;
		height: 40px;
		color: white;
		font-size: 13px;
		margin-left: 10px;
		width: 90%;
	}
	& .ex-body-right-wrapper .create-wrapper .input-wrapper-area {
		display: inline-block;
		vertical-align: top;
		width: calc(100% - 120px);
		position: relative;
		border-left: 1px solid var(--bg-d2);
		background: rgba(7, 9, 13, 0.4);
	}
	& .ex-body-right-wrapper .create-wrapper .input-wrapper-area.tags {
		padding: 10px;
		background: rgba(7, 9, 13, 0.4);
	}
	& .ex-body-right-wrapper .create-wrapper .input-wrapper-area textarea {
		background: transparent;
		border: none;
		min-height: 60px;
		color: white;
		font-size: 13px;
		padding: 20px;
		width: 100%;
		display: inline-block;
		vertical-align: top;
		resize: none;
	}
	& .ex-body-right-wrapper .create-wrapper .add-image-body-wrapper {
		border-bottom: 1px solid var(--bg-d2);
		min-height: 60px;
		padding: 10px 10px 0 10px;
	}
	& .ex-body-right-wrapper .create-wrapper .add-image-body-wrapper .upload-wrapper {
		width: 240px;
		height: 100px;
		margin-bottom: 10px;
		position: relative;
		display: inline-block;
		vertical-align: top;
	}
	& .ex-body-right-wrapper .create-wrapper .add-image-body-wrapper .processing-wrapper {
		height: 100%;
		width: 100%;
		text-align: center;
		background: rgba(244, 128, 36, 0.1);
		border: 1px solid rgba(244, 128, 36, 0.45);
		border-radius: 3px;
		position: relative;
		padding: 0;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	& .ex-body-right-wrapper .create-wrapper .add-image-body-wrapper .processing-wrapper p {
		text-align: center;
		font-size: 12px;
		color: white;
		background: rgba(0, 0, 0, 0.9);
		position: relative;
		z-index: 2;
		padding: 0 12px;
		height: 24px;
		border-radius: 12px;
	}
	& .ex-body-right-wrapper .create-wrapper .add-image-body-wrapper .processing-wrapper img {
		position: absolute;
		bottom: 0;
		left: 0;
		max-height: 100%;
		max-width: 100%;
		z-index: 1;
	}
	& .ex-body-right-wrapper .create-wrapper .add-image-body-wrapper .dz-container {
		background: rgba(7, 9, 13, 0.4);
		border: 1px dashed var(--bg-d0-dash);
		border-radius: 3px;
		padding: 10px 20px 0 20px;
		height: 100%;
		transition: border 0.15s, -moz-transform ease-in-out 0.15s;
	}
	& .ex-body-right-wrapper .create-wrapper .add-image-body-wrapper .dz-container:hover {
		border: 1px dashed var(--blue) !important;
	}
	& .ex-body-right-wrapper .create-wrapper .add-image-body-wrapper .dz-container p {
		text-align: center;
		font-size: 12px;
	}
	& .ex-body-right-wrapper .create-wrapper .add-image-body-wrapper .dz-container p.warning {
		color: var(--txt-lg);
	}
	& .ex-body-right-wrapper .create-wrapper .add-image-body-wrapper .dropzone:hover {
		cursor: pointer;
	}
	& .ex-body-right-wrapper .create-wrapper .add-image-body-wrapper .images-wrapper {
		width: calc(100% - 250px);
		display: inline-block;
		vertical-align: top;
		min-height: 100px;
		margin-left: 10px;
		text-align: left;
	}
	& .ex-body-right-wrapper .create-wrapper .add-image-body-wrapper .images-wrapper .create-ex-image {
		width: 100px;
		height: 100px;
		position: relative;
		display: inline-block;
		vertical-align: top;
		background: var(--bg-d1);
		border: 1px solid var(--bg-d0-dash);
		margin-right: 10px;
		margin-bottom: 10px;
		border-radius: 3px;
		overflow: hidden;
		transition: border 0.15s, -moz-transform ease-in-out 0.15s;
	}
	& .ex-body-right-wrapper .create-wrapper .add-image-body-wrapper .images-wrapper .create-ex-image .inner {
		height: 100%;
		width: 100%;
		padding: 0;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
	}
	& .ex-body-right-wrapper .create-wrapper .add-image-body-wrapper .images-wrapper .create-ex-image img {
		max-width: 100%;
		max-height: 100%;
	}
	& .ex-body-right-wrapper .create-wrapper .add-image-body-wrapper .images-wrapper .create-ex-image:hover {
		cursor: pointer;
		border: 1px solid var(--blue);
	}
	& .ex-body-right-wrapper .create-wrapper .add-image-body-wrapper .images-wrapper .create-ex-image .img-delete-btn {
		background: rgba(244, 72, 91, 0.65);
		text-align: center;
		height: 24px;
		width: 24px;
		position: absolute;
		border-top-left-radius: 3px;
		right: -1px;
		bottom: -1px;
		transition: background 0.15s, -moz-transform ease-in-out 0.15s;
	}
	&
		.ex-body-right-wrapper
		.create-wrapper
		.add-image-body-wrapper
		.images-wrapper
		.create-ex-image
		.img-delete-btn:hover {
		cursor: pointer;
		background: rgba(244, 72, 91, 1);
	}
	&
		.ex-body-right-wrapper
		.create-wrapper
		.add-image-body-wrapper
		.images-wrapper
		.create-ex-image
		.img-delete-btn:hover
		~ .ex-body-right-wrapper
		.create-wrapper
		.add-image-body-wrapper
		.images-wrapper
		.create-ex-image {
		border: 1px solid var(--red);
	}
	&
		.ex-body-right-wrapper
		.create-wrapper
		.add-image-body-wrapper
		.images-wrapper
		.create-ex-image
		.img-delete-btn
		p {
		color: white;
		font-size: 21px;
		pointer-events: none;
	}
	& .create-submit-btn {
		width: 100%;
		height: 60px;
		text-align: center;
		background: rgba(31, 133, 222, 0.05);
		transition: background 0.15s, -moz-transform ease-in-out 0.15s;
	}
	& .create-submit-btn.valid {
		background: rgba(0, 150, 97, 0.2);
	}
	& .create-submit-btn:hover {
		cursor: not-allowed;
	}
	& .create-submit-btn.valid:hover {
		cursor: pointer;
		background: rgba(0, 150, 97, 0.25);
	}
	& .create-submit-btn p {
		text-align: center;
		font-size: 13px;
		color: var(--txt-lg);
	}
	& .create-submit-btn.valid p {
		color: white;
	}
	& .tag-btn {
		display: inline-block;
		vertical-align: top;
		margin: 4px;
		padding: 0.25em 0.5em;
		border-radius: 15px;
		background: rgba(255, 255, 255, 0.03);
		border: 1px solid rgba(255, 255, 255, 0.1);
		transition: background 0.15s, border 0.15s, -moz-transform ease-in-out 0.15s;
	}
	& .tag-btn.dim {
		opacity: 0.5;
	}
	& .tag-btn.user {
		background: rgba(222, 120, 31, 0.05);
		border: 1px solid rgba(222, 120, 31, 0.3);
	}
	& .tag-btn.example {
		margin: 0 5px 5px 0;
		height: 22px;
		background: rgba(255, 255, 255, 0.03);
		border: 1px solid rgba(255, 255, 255, 0.1);
		pointer-events: none;
	}
	& .tag-btn:hover {
		cursor: pointer;
		background: rgba(255, 255, 255, 0.08);
	}
	& .tag-btn.user:hover {
		background: rgba(222, 120, 31, 0.1);
	}
	& .tag-btn.example:hover {
		cursor: unset;
		background: rgba(255, 255, 255, 0.05);
	}
	& .tag-btn p {
		font-size: 13px;
		color: white;
		text-align: center;
		padding: 0 15px;
	}
	& .tag-btn.gradient-border {
		--border-width: 3px;
		border: 1px solid transparent;
		position: relative;
		background: var(--bg-d1);
		border-radius: 15px;
		&::after {
			position: absolute;
			content: "";
			top: calc(-1 * var(--border-width));
			left: calc(-1 * var(--border-width));
			z-index: -1;
			width: calc(100% + var(--border-width) * 2);
			height: calc(100% + var(--border-width) * 2);
			background: linear-gradient(
				60deg,
				hsl(224, 85%, 66%),
				hsl(269, 85%, 66%),
				hsl(314, 85%, 66%),
				hsl(359, 85%, 66%),
				hsl(44, 85%, 66%),
				hsl(89, 85%, 66%),
				hsl(134, 85%, 66%),
				hsl(179, 85%, 66%)
			);
			background-size: 300% 300%;
			background-position: 0 50%;
			border-radius: 17px;
			animation: moveGradient 4s alternate infinite;
			box-shadow: 0 0 12px 0 rgba(8, 19, 28, 0.55);
		}
	}
	@keyframes moveGradient {
		50% {
			background-position: 100% 50%;
		}
	}
	& .tag-btn.gradient-border:hover {
		background: transparent;
	}
	& .tag-btn.gradient-border:hover > p {
		color: black;
	}
	& .tag-btn.gradient-border:hover > p span {
		color: black;
	}
	& .tag-btn.selected {
		background: rgba(255, 255, 255, 1);
		border: 1px solid rgba(255, 255, 255, 1);
		opacity: 1;
	}
	& .tag-btn.gradient-border.selected:hover {
		border: 1px solid rgba(0, 0, 0, 1);
	}
	& .tag-btn.user.selected {
		background: rgba(255, 255, 255, 1);
		border: 1px solid rgba(255, 255, 255, 1);
	}
	& .tag-btn.selected p {
		color: black;
	}
	& .tag-btn.example p {
		padding: 0 10px;
		color: white;
		font-size: 11px !important;
	}
	& .tag-btn p span {
		font-size: 10px;
		color: var(--txt-lg);
		font-weight: 900;
	}
	& .tag-btn.selected p span {
		color: var(--txt-dg);
	}
	& .examples-body-wrapper {
		width: 100%;
		overflow-x: hidden;
	}
	& .examples-body-wrapper .example-wrapper {
		display: block;
		position: relative;
		border-bottom: 1px solid var(--bg-d2);
		transition: background 0.15s, -moz-transform ease-in-out 0.15s;
	}
	& .examples-body-wrapper .example-wrapper.selected {
		background: rgba(74, 129, 255, 0.05);
	}
	& .examples-body-wrapper .example-wrapper .img-wrapper {
		display: inline-block;
		vertical-align: top;
		width: 150px;
		height: 150px;
		position: relative;
	}
	& .examples-body-wrapper .example-wrapper .img-wrapper .inner {
		height: 80%;
		width: 80%;
		padding: 0;
		margin: 10%;
		display: flex;
		align-items: center;
		justify-content: center;
		background: var(--dark2);
		border: 1px solid var(--bg-d2);
		border-radius: 3px;
		overflow: hidden;
		transition: border 0.15s, -moz-transform ease-in-out 0.15s;
	}
	& .examples-body-wrapper .example-wrapper .img-wrapper .inner:hover {
		cursor: pointer;
		border: 1px solid rgba(74, 129, 255, 1);
	}
	& .examples-body-wrapper .example-wrapper .img-wrapper .inner img {
		width: 100%;
		height: 100%;
		pointer-events: none;
		transition: height 0.15s, width 0.15s, margin 0.15s, -moz-transform ease-in-out 0.15s;
		object-fit: contain;
		font-size: 0;
	}
	& .examples-body-wrapper .example-wrapper .img-wrapper .img-count-widget {
		background: var(--dark2);
		border: 1px solid var(--bg-d2);
		color: white;
		font-size: 11px;
		text-rendering: optimizeLegibility;
		padding: 0 12px;
		height: 20px;
		border-radius: 10px;
		position: absolute;
		right: 10px;
		bottom: 8px;
		transition: border 0.15s, -moz-transform ease-in-out 0.15s;
	}
	& .examples-body-wrapper .example-wrapper .img-wrapper .inner:hover + .img-count-widget {
		border: 1px solid rgba(74, 129, 255, 1);
	}
	& .examples-body-wrapper .example-wrapper .img-wrapper .inner:hover > img {
		width: 90%;
		height: 90%;
		margin: 5%;
	}
	& .examples-body-wrapper .example-wrapper .info-wrapper {
		display: inline-block;
		vertical-align: top;
		width: calc(100% - 200px);
		min-height: 150px;
		padding: 15px 0 15px 0;
	}
	& .examples-body-wrapper .example-wrapper .info-wrapper p {
		font-size: 13px;
	}
	& .examples-body-wrapper .example-wrapper .info-wrapper .tag-wrapper {
	}
	& .examples-body-wrapper .example-wrapper .info-wrapper .name-wrapper {
		display: inline-block;
		vertical-align: top;
		margin-left: 3px;
		color: var(--txt-lg);
	}
	& .examples-body-wrapper .example-wrapper .info-wrapper .name-wrapper p {
		font-size: 12px;
		color: var(--txt-lg);
		margin-top: 1px;
	}
	& .examples-body-wrapper .example-wrapper .info-wrapper .name-wrapper span {
		color: white;
	}
	& .examples-body-wrapper .example-wrapper .info-wrapper .desc-wrapper {
		margin-top: 7px;
		margin-bottom: 10px;
		padding-left: 3px;
		padding-bottom: 2px;
		padding-top: 2px;
	}
	& .examples-body-wrapper .example-wrapper .info-wrapper .desc-wrapper span {
		white-space: pre-line;
		font-size: 18px;
		color: white;
	}
	& .desc-wrapper .highlight {
		background: yellow;
	}
	& .examples-body-wrapper .example-wrapper .info-wrapper .links-show-wrapper {
		display: inline-block;
		vertical-align: top;
		padding: 0.5em 1em;
		border-radius: 16px;
		background: var(--dark2);
		border: 1px solid var(--bg-d2);
		font-size: 13px;
		color: var(--txt-lg);
		text-align: center;
		transition: color 0.15s, -moz-transform ease-in-out 0.15s;
	}
	& .examples-body-wrapper .example-wrapper .info-wrapper .links-show-wrapper:hover {
		cursor: pointer;
		color: white;
	}
	& .examples-body-wrapper .example-wrapper .info-wrapper .links-wrapper {
		height: auto;
		white-space: pre-line;
		padding: 20px;
		border-radius: 16px;
		background: var(--dark2);
		border: 1px solid var(--bg-d2);
	}
	& .examples-body-wrapper .example-wrapper .info-wrapper .links-wrapper p {
		color: white;
	}
	& .examples-body-wrapper .example-wrapper .info-wrapper .links-wrapper a {
		color: var(--green);
		transition: color 0.15s, -moz-transform ease-in-out 0.15s;
	}
	& .examples-body-wrapper .example-wrapper .info-wrapper .links-wrapper a:hover {
		cursor: pointer;
		text-decoration: underline;
	}
	& .examples-body-wrapper .example-wrapper .info-wrapper .links-wrapper a::selection {
		background: rgba(0, 150, 97, 0.1);
	}
	& .examples-body-wrapper .example-wrapper .actions-wrapper {
		position: absolute;
		top: 0;
		right: 0;
		width: 50px;
		height: 100%;
		border-left: 1px solid transparent;
	}
	& .examples-body-wrapper .example-wrapper .actions-wrapper .btn-wrapper {
		height: 50px;
		width: 50px;
		padding: 0;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	& .examples-body-wrapper .example-wrapper .actions-wrapper .btn-wrapper .btn {
		height: 30px;
		width: 30px;
		position: relative;
		border-radius: 3px;
		padding: 0;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	& .examples-body-wrapper .example-wrapper .actions-wrapper .btn-wrapper .btn:hover {
		cursor: pointer;
	}
	& .examples-body-wrapper .example-wrapper .actions-wrapper .btn-wrapper .btn .edit-img {
		height: 60%;
		width: 60%;
		background-image: var(--icon-edit-lg);
		position: relative;
		left: 1px;
	}
	& .examples-body-wrapper .example-wrapper.selected .btn-wrapper.edit .btn {
		background: var(--blue) !important;
	}
	& .examples-body-wrapper .example-wrapper .actions-wrapper .btn-wrapper .btn:hover > .edit-img {
		background-image: var(--icon-edit-l);
	}
	& .examples-body-wrapper .example-wrapper.selected .btn-wrapper.edit .btn .edit-img {
		background-image: var(--icon-edit-l);
	}
	& .examples-body-wrapper .example-wrapper .actions-wrapper .btn-wrapper.clap {
		height: calc(100% - 50px);
		display: block;
		position: relative;
		text-align: center;
	}
	& .examples-body-wrapper .example-wrapper .actions-wrapper .btn-wrapper.clap .btn {
		position: absolute;
		bottom: 15px;
		left: calc(50% - 15px);
		transition: background 0.15s, -moz-transform ease-in-out 0.15s;
	}
	& .examples-body-wrapper .example-wrapper .actions-wrapper .btn-wrapper .btn .clap-img {
		height: 20px;
		width: 20px;
		background-image: var(--icon-clap-lg);
	}
	& .examples-body-wrapper .example-wrapper .actions-wrapper .btn-wrapper.clap .btn:hover > .clap-img {
		background-image: var(--icon-clap-l);
	}
	& .examples-body-wrapper .example-wrapper .actions-wrapper .btn-wrapper .clap-count {
		height: calc(100% - 42px);
		position: relative;
	}
	& .examples-body-wrapper .example-wrapper .actions-wrapper .btn-wrapper .clap-count:hover {
		cursor: pointer;
	}
	& .examples-body-wrapper .example-wrapper .actions-wrapper .btn-wrapper .clap-count p {
		font-size: 12px;
		color: var(--txt-lg);
		text-align: center;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
	}
`;

import styled from "styled-components";

export const QAWrapperEl = styled.div`
	color: #fefefe;
	font-size: 12px;
	min-height: 100%;

	& .qa_wrapper {
		padding-top: 20px;
		button {
			width: 90%;
		}
	}

	& ol {
		list-style: none;
	}

	& .new-qa-btn {
		color: var(--orange);
		background: var(--highlight-orange-d);
		border: 1px solid var(--highlight-orange);
	}

	& .new-qa-btn:hover {
		background: var(--highlight-orange);
		border: 1px solid var(--orange);
	}

	& .new-perf-btn {
		color: var(--green);
		background: var(--highlight-green-d);
		border: 1px solid var(--highlight-green);
	}

	& .new-perf-btn:hover {
		background: var(--highlight-green);
		border: 1px solid var(--green);
	}

	& .new-tag-btn {
		color: var(--blue);
		background: var(--highlight-blue-d);
		border: 1px solid var(--highlight-blue);
	}

	& .new-tag-btn:hover {
		background: var(--highlight-blue);
		border: 1px solid var(--highlight-blue);
	}

	& .new-review-btn {
		color: var(--highlight-red-l);
		background: var(--highlight-red-d);
		border: 1px solid var(--highlight-red);
	}

	& .new-review-btn:hover {
		background: var(--highlight-red);
		border: 1px solid var(--highlight-red);
	}

	& .icon.copy {
		display: inline-block;
		vertical-align: middle;
		background-image: var(--icon-link-l);
		height: 18px;
		width: 18px;
		margin-top: -3px;
		margin-right: 6px;
	}

	& .qa-item {
		border-bottom: 1px solid var(--bg-d2);
	}

	& .qa-item-link {
		cursor: pointer;
		padding: 1em;
	}

	& #copy-image {
		width: 100%;
		cursor: pointer;
	}

	& .bugs-toggle {
		margin-top: 20px;
		padding: 10px;
		border-top: 1px solid var(--bg-d2);
		border-bottom: 1px solid var(--bg-d2);
		text-align: center;

		& .toggle-switch {
			vertical-align: middle;
			margin-right: 8px;
			font-size: 12px;
		}
	}

	& .bugs-toggle label span {
		position: relative;
		top: 1px;
	}

	& .bugs-toggle input {
		margin-right: 0.5em;
	}

	& .qa-item-link:hover {
	}

	& .qa-item:hover {
		background: var(--bg-d2);
		border-right: 4px solid var(--blue);
	}

	& .qa_form textarea,
	& .qa_form input {
		display: block;
		width: 100%;
		background: var(--bg-d2);
		margin-top: 7px;
		border: 1px solid transparent;
		border-radius: 3px;
		color: white;
		font-size: 12px;
		padding: 8px 10px;
		resize: vertical;
		overflow: hidden;
	}

	& .qa-notes::placeholder {
		color: var(--txt-lg);
	}

	& .qa-totals {
		margin-top: 25px;
		font-size: 12px;
		padding: 0 10px;
	}

	& .qa-notes-form {
		margin-top: 5px;
		padding: 7px 10px;
	}

	& .badge-title {
		font-size: 12px;
		margin-right: 5px;
	}

	& .badge {
		font-size: 11px;
		border-radius: 10px;
		font-weight: 900;
		padding: 0.5em 1em;
		margin: 0.5em 0.15em 0 0;
		display: inline-block;
		text-align: center;
		background: rgba(31, 133, 222, 0.5);
	}

	& .qa-performance {
		padding: 5px 10px 0 10px;
		background: rgba(255, 255, 255, 0.02);
		border-top: 1px solid var(--bg-d2);
		border-bottom: 1px solid var(--bg-d2);
	}

	& .qa_form .qa-performance label {
		width: 60%;
		display: inline-block;
		padding: 0.5em 0;
		margin-bottom: 1em;
	}

	& .qa-performance .button {
		display: block;
		width: calc(100% + 20px) !important;
		height: 30px;
		text-align: center;
		background: var(--highlight-blue-d);
		color: white;
		margin-left: -10px;
	}

	& .qa-performance .button:hover {
		background: var(--highlight-blue);
	}

	& .qa_form .qa-performance label[for="qa_before"],
	& .qa_form .qa-performance label[for="qa_after"] {
		width: 18%;
		margin-left: 4px;
	}

	& .qa-performance input {
		width: 100%;
		padding: 0.5em;
		height: 2.5em;
	}

	& .qa_form label {
		font-size: 12px;
		display: block;
		width: 100%;
	}

	& #qa_date {
		resize: none;
	}

	& .qa_form {
		position: relative;
		margin: 0;
		padding: 40px 0;
	}

	& .qa_form h3,
	& .report h3 {
		font-size: 1em;
		text-transform: uppercase;
		letter-spacing: 1px;
		padding: 1em 0;
		text-align: center;
	}

	& .qa_form h4,
	& .report h4 {
		font-size: 1em;
		text-transform: uppercase;
		letter-spacing: 1px;
		padding: 1em 0;
	}

	& .qa_form h4 {
		margin-bottom: 10px;
		background: rgba(255, 255, 255, 0.02);
		padding: 0;
		height: 30px;
	}

	& .bug-list {
		background: var(--highlight-red-d);
	}

	& .qa-bug-list {
		padding-top: 10px;
	}

	& .report {
		background: #181217;
		padding: 35px 10px 0 10px;
		position: absolute;
		top: 60px;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		max-height: 100vh;
		overflow-y: auto;
		transform: translate(0, 100%);
		transition: transform 500ms ease;
		-webkit-box-shadow: 0 0 37px 0 rgba(0, 0, 0, 0.75);
		-moz-box-shadow: 0 0 37px 0 rgba(0, 0, 0, 0.75);
		box-shadow: 0 0 37px 0 rgba(0, 0, 0, 0.75);
	}

	& .report.active {
		border: 1px solid var(--red);
		transform: translate(0, 0vh);
	}

	& .report.active h3 {
		padding: 0;
		margin-top: 20px;
	}

	& .report.active .badge {
		background: transparent;
		margin-top: 2px;
	}

	& .report .badge {
		display: block;
		margin: 0 auto 10px auto;
		padding: 0.5em;
		border-radius: 15px;
		font-weight: 900;
		font-size: 12px;
		background: rgba(255, 255, 255, 0.05);
		position: relative;
		top: -5px;
	}

	& .report select {
		color: var(--txt-dg);
		display: block;
		width: 100%;
		border: 1px solid var(--bg-l1-3);
		margin-bottom: 1em;
		padding: 0.5em;
	}

	& .report textarea,
	& .report input,
	& .report select {
		display: block;
		width: 100%;
		background: var(--bg-d2);
		margin-bottom: 1em;
		border: 1px solid transparent;
		border-radius: 3px;
		color: white;
		font-size: 12px;
		padding: 8px 10px;
		resize: none;
		overflow: hidden;
	}

	& .report textarea::placeholder,
	& .report input::placeholder,
	& .report select::placeholder {
		color: var(--txt-lg);
	}

	& .report label {
		font-size: 12px;
		display: block;
		width: 100%;
		margin-bottom: 5px;
	}

	& .qa-test-list {
		padding-bottom: 10em;
		border-top: 1px solid var(--bg-d2);
		margin-top: 10px;
	}

	& .qa-test-list h4 {
		text-align: center;
		color: var(--txt-lg);
		padding-bottom: 0;
		border-bottom: 1px solid var(--bg-d2);
	}

	& .test {
		font-size: 12px;
		border-bottom: 1px solid var(--bg-d2);
		padding: 1em;
		position: relative;
	}

	& .test-tooltip {
		display: none;
		position: absolute;
		width: calc(100% - 20px);
		background: #1f85dd;
		bottom: 100%;
		min-height: 2em;
		z-index: 1;
		border-radius: 3px;
		padding: 10px;
		color: white;

		-webkit-box-shadow: 0 0 37px 0 rgba(0, 0, 0, 0.75);
		-moz-box-shadow: 0 0 37px 0 rgba(0, 0, 0, 0.75);
		box-shadow: 0 0 37px 0 rgba(0, 0, 0, 0.75);
	}

	& .test-case .badge:hover {
		cursor: help;
	}

	& .test-tooltip::after {
		top: 100%;
		position: absolute;
		left: 8px;
		content: "";
		width: 0;
		height: 0;
		border-left: 0.5em solid transparent;
		border-right: 0.5em solid transparent;
		border-top: 0.5em solid #1f85dd;
	}

	& .badge:hover + .test-tooltip {
		display: block;
	}

	& .test-action {
		text-align: right;
	}

	& .test-action input {
		display: none;
	}

	& .test-action label {
		display: inline-block;
		border: 1px solid rgba(255, 255, 255, 0.05);
		width: 3em;
		height: 26px;
		text-align: center;
	}

	& .test-action label:hover {
		box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
	}

	& label.test-approve {
		background: rgba(255, 255, 255, 0.03);
		font-weight: 700;
		color: var(--green);
		border-top-left-radius: 1em;
		border-bottom-left-radius: 1em;
		position: relative;
		left: 1px;
	}

	& label.test-deny {
		background: rgba(255, 255, 255, 0.03);
		font-weight: 700;
		color: var(--red);
		border-top-right-radius: 1em;
		border-bottom-right-radius: 1em;
	}

	& label.test-archive {
		background: rgba(255, 255, 255, 0.03);
		color: #fff;
		border: 1px solid rgba(255, 255, 255, 0.05);
		text-align: center !important;
		height: 26px;
		width: 50px;
		border-radius: 13px;
		margin-left: 5px;
		font-size: 10px;
	}

	& .test-approve.checked {
		background: #00a13d;
		color: #fff;
	}

	& .test-deny.checked {
		background: #fa4b4b;
		color: #fff;
	}

	& .test-archive.checked {
		background: var(--blue);
	}

	& .test-case .badge {
		float: left;
		margin-top: 0;
	}

	& .test-case-text {
		padding-bottom: 0.5em;
		padding-left: 40px;
	}

	& .bug-item {
		font-size: 12px;
		padding: 10px;
		color: var(--txt-lg);
		background: var(--highlight-red-d);
		border-bottom: 1px solid var(--highlight-red);
		&.open {
			color: #fff;
			border-left: 4px solid var(--red);
		}
	}

	& .bug-item small {
		font-size: 12px;
		color: var(--txt-lg);
	}

	& .bug-item:hover {
		background: var(--highlight-red);
		cursor: pointer;
	}

	& .qa-item-link .badge-wrapper {
		margin-top: 5px;
	}

	& .qa-item-link .badge {
		margin-right: 3px;
		background: var(--highlight-blue);
	}

	& .qa-item-link .bug-count {
		display: block;
		padding-top: 5px;
		position: relative;
		top: 3px;
		color: var(--red);
	}

	& .qa-close-button {
		position: absolute;
		top: 0;
		right:0;
	}

	// DROP ZONE
	& .qa_area .upload-wrapper {
		width: 100%;
		margin-bottom: 20px;
		height: 100px;
		position: relative;
	}

	& .qa_area .upload-wrapper .processing-wrapper {
		height: 100%;
		width: 100%;
		text-align: center;
		background: rgba(244, 128, 36, 0.1);
		border: 1px solid rgba(244, 128, 36, 0.45);
		border-radius: 3px;
		position: relative;

		padding: 0;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	& .qa_area .upload-wrapper .processing-wrapper p {
		text-align: center;
		font-size: 12px;
		color: white;
		background: rgba(0, 0, 0, 0.9);
		position: relative;
		z-index: 2;
		padding: 0 12px;
		height: 24px;
		border-radius: 12px;
	}

	& .qa_area .upload-wrapper .processing-wrapper img {
		position: absolute;
		bottom: 0;
		left: 0;
		max-height: 100%;
		max-width: 100%;
		z-index: 1;
	}

	& .qa_area .upload-wrapper .dz-container {
		background: var(--bg-d2);
		border: 1px dashed var(--bg-d0-dash);
		border-radius: 3px;
		padding: 10px 20px 0 20px;
		height: 100%;
	}

	& .qa_area .upload-wrapper .dz-container p {
		text-align: center;
		font-size: 12px;
	}

	& .qa_area .upload-wrapper .dz-container p.warning {
		color: var(--txt-lg);
	}

	& .qa_area .upload-wrapper .dropzone:hover {
		cursor: pointer;
	}

	& .qa_area .images-wrapper {
		width: 100%;
		margin-top: -10px;
		margin-bottom: 10px;
		text-align: center;
	}

	& .qa_area .images-wrapper .bug-image {
		width: 80px;
		height: 80px;
		position: relative;
		display: inline-block;
		vertical-align: top;
		background: var(--bg-d2);
		border: 1px solid var(--bg-d2);
		margin: 10px;
		border-radius: 3px;
		overflow: hidden;
	}

	& .qa_area .images-wrapper .bug-image .bug-image-inner {
		height: 100%;
		width: 100%;
		padding: 0;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	& .qa_area .images-wrapper .bug-image img {
		max-width: 100%;
		max-height: 100%;
	}

	& .qa_area .images-wrapper .bug-image:hover {
		cursor: pointer;
		border: 1px solid var(--blue);
	}
`;

import { combineReducers } from "redux";
import user from "./user";
import nav from "./nav";
import company from "./company";
import form from "./form";
import legacy from "./legacy";
import room from "./room";
import chat from "./chat";
import socket from "./socket";
import revision from "./revision";

export default combineReducers({
	user,
	nav,
	company,
	form,
	legacy,
	room,
	chat,
	socket,
	revision
});

// noinspection JSUnresolvedVariable

// Resources
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import { io } from "socket.io-client";
import Cookies from "universal-cookie";

// Components
import SideBarWrapper from "./components/sideBar/SideBarWrapper";

// Auth
import AuthHelperMethods from "./components/auth/AuthHelperMethods";
import withAuth from "./components/auth/withAuth";

// Import Redux
import { createStore } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./reducers";
import BodyWrapper from "./components/body/BodyWrapper";

// Globals
let cookies;
const store = createStore(rootReducer);

class Legacy extends Component {
	Auth = new AuthHelperMethods();

	constructor(props) {
		super(props);
		this.state = {
			// Title
			title: "Legacy",
			formId: "",
			// User
			userId: "",
			profile: {},
			legacy: {},
			// Sockets
			isConnected: false,
			// Misc
			logout: false
		};
		this.params = "";
		this.skt = undefined;
	}

	componentWillUnmount() {
		if (this.state.logout) this.Auth.logout();
		// this._leaveRoom();
	}

	goTo404 = () => {
		const destination = cookies.get("forceRedirectHomepage") ? "/" : "/404";
		cookies.remove("forceRedirectHomepage");
		this.props.history.push({
			pathname: destination,
			search: ""
		});
	};

	_handleLogout = () => {
		this.setState({ logout: true }, function () {
			this.props.history.replace("/login");
		});
	};

	_openExternalLink = (url) => {
		if (url) {
			const win = window.open(url, "_blank");
			win.focus();
		}
	};

	_saveEditField = (editObj) => {
		if (editObj && editObj.id) {
			editObj.formId = this.state.formId;
			this.Auth.editLegacy(editObj)
				.then((res) => {
					if (res.error) {
						console.error("[ Legacy - _saveEditField ] err:", res.error);
						this.goTo404();
					} else if (res.legacy) {
						console.log("------------------------");
						console.log("----- DATA: legacy -----");
						console.log("------------------------");
						console.log(res.legacy);
						this.setState(
							{
								title: "Legacy | " + res.legacy.companyName + " • " + res.legacy.title,
								legacy: res.legacy
							},
							function () {
								// Save legacy data in redux
								store.dispatch({
									type: "LEGACY",
									data: res.legacy
								});
							}
						);
					} else {
						console.error("[ Legacy - _saveEditField ] Legacy CSD not found:", res);
						this.goTo404();
					}
				})
				.catch((err) => {
					console.log("[ Legacy - _saveEditField ] POST error:", err);
					alert("POST Error");
				});
		}
	};

	componentDidMount() {
		// Init cookies
		// noinspection JSValidateTypes
		cookies = new Cookies();

		// Init params
		this.params = queryString.parse(this.props.history.location.search);
		if (this.params && this.params.id) {
			// Init redux
			store.dispatch({
				type: "NAV",
				nav: "",
				page: "legacy"
			});

			// Fetch data
			if (this.Auth.getToken()) {
				this.initData();
			} else {
				console.log("[ Legacy - componentDidMount ] Token not found, kicking user to login page");
				this._handleLogout();
			}
		} else {
			// No id found in url params
			this.goTo404();
		}
	}

	initData = () => {
		this.Auth.getProfile()
			.then((res) => {
				if (res.success && res.data && res.data.profile) {
					// Save profile in redux
					res.data.profile._id = res.data.id;
					store.dispatch({
						type: "PROFILE",
						profile: res.data.profile
					});

					// Save profile in state
					this.setState(
						{
							formId: this.params.id,
							profile: res.data.profile,
							userId: res.data.id,
							legacy: {},
							isConnected: false
						},
						function () {
							// Get form data
							this.Auth.getLegacy({ formId: this.state.formId })
								.then((res) => {
									if (res.error) {
										console.error("[ Legacy - initData ] err:", res.error);
										this.goTo404();
									} else if (res.legacy) {
										console.log("------------------------");
										console.log("----- DATA: legacy -----");
										console.log("------------------------");
										console.log(res.legacy);
										this.setState(
											{
												title: "Legacy | " + res.legacy.companyName + " • " + res.legacy.title,
												legacy: res.legacy
											},
											function () {
												this.initSockets(function () {
													// Save legacy data in redux
													store.dispatch({
														type: "LEGACY",
														data: res.legacy
													});
												});
											}
										);
									} else {
										console.error("[ Legacy - initData ] Legacy CSD not found:", res);
										this.goTo404();
									}
								})
								.catch((err) => {
									console.log("[ Legacy - initData ] POST error:", err);
									alert("POST Error");
								});
						}
					);
				} else {
					console.log("[ Legacy - initData ] Server error:", res);
					alert("Server Error: Failed to get user profile");
					this._handleLogout();
				}
			})
			.catch((err) => {
				console.log("[ Legacy - initData ] POST error:", err);
				alert("POST Error");
			});
	};

	initSockets = (cb) => {
		if (this.state.profile && this.state.profile.email && this.state.legacy && this.state.legacy.formId) {
			console.log("[ Legacy - initSockets ] Init -> profile:", this.state.profile);
			this.skt = io({ transports: ["polling"] });

			// Connect
			this.skt.on("connected", (data) => {
				console.log("[ Legacy - initSockets - connected ] message:", data.message);
				// Enter room each time a connection is established
				this.skt.emit("enterRoom", {
					initChat: false,
					room: this.state.formId,
					url: window.location.href,
					profile: this.state.profile,
					pageType: "Legacy",
					companyName: this.state.legacy.companyName,
					formName: this.state.legacy.title
				});
			});

			// Getting room info
			this.skt.on("handleRoomUpdate", (data) => {
				console.log("[ Legacy - initSockets - handleRoomUpdate ] Room data:", data);

				store.dispatch({
					type: "APP_ROOM",
					users: data.users || [],
					online: data.online || 0
				});

				if (!this.state.isConnected) {
					this.setState(
						{
							isConnected: true
						},
						function () {
							// Perform on connect action
						}
					);
				}
			});

			store.dispatch({
				type: "INIT_SOCKETS",
				socket: this.skt
			});

			// Run callback to set redux data (this will trigger the form to display)
			cb();
		}
	};

	// Render protected component
	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>CSD • {this.state.title}</title>
				</Helmet>
				<Provider store={store}>
					{this.state.profile && this.state.profile.email && (
						<div id="sidebar" className="legacy">
							<SideBarWrapper
								openExternalLink={this._openExternalLink}
								handleLogout={this._handleLogout}
							/>
						</div>
					)}
					<div id="body" className="legacy-body">
						<BodyWrapper saveEditField={this._saveEditField} />
					</div>
				</Provider>
			</React.Fragment>
		);
	}
}

// In order for this component to be protected, we must wrap it with what we call a 'Higher Order Component' or HOC.
export default withAuth(Legacy);

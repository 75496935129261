import styled from "styled-components";

export const LegacyWrapperEl = styled.div`
  display: block;
  position: relative;

  & .info-wrapper {
    padding: 15px 20px;
    height: 500px;
    //outline: 2px dotted red;
  }

  & .info-wrapper .section-wrapper {
    //outline: 1px dotted red;
    margin-bottom: 10px;
  }

  & .info-wrapper .section-wrapper p {
    color: var(--txt-lg);
    font-size: 11px;
    margin-bottom: 4px;
  }

  & .info-wrapper .section-wrapper h4 {
    color: white;
    font-size: 12px;
  }

  & .info-wrapper .section-wrapper h4.link {
    color: var(--blue);
  }

  & .info-wrapper .section-wrapper h4.link:hover {
    cursor: pointer;
    text-decoration: underline;
  }

`;
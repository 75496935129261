// Resources
import React, { Component } from "react";

class StyleGuide extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div className="style_guide">
				{/* Typography Section */}
				<section id="typography">
					<h2>Typography</h2>
					<div>
						<h3>Headings</h3>
						<p>Headings are used to organize and structure the content.</p>
						<h1>Heading 1 (h1)</h1>
						<h2>Heading 2 (h2)</h2>
						<h3>Heading 3 (h3)</h3>
						<h4>Heading 4 (h4)</h4>
						<h5>Heading 5 (h5)</h5>
						<h6>Heading 6 (h6)</h6>
					</div>
					<div>
						<h3>Body Text</h3>
						<p>
							This is a paragraph. Use this style for general content. Paragraphs are styled to provide a
							comfortable reading experience.
						</p>
						<p>
							You can use the mark tag to <mark>highlight</mark> text.
						</p>
						<p>
							<del>This line of text is meant to be treated as deleted text.</del>
						</p>
						<p>
							<s>This line of text is meant to be treated as no longer accurate.</s>
						</p>
						<p>
							<ins>This line of text is meant to be treated as an addition to the document.</ins>
						</p>
						<p>
							<u>This line of text will render as underlined</u>
						</p>
						<p>
							<small>This line of text is meant to be treated as fine print.</small>
						</p>
						<p>
							<strong>This line rendered as bold text.</strong>
						</p>
						<p>
							<em>This line rendered as italicized text.</em>
						</p>
					</div>
					<div>
						<h3>Lists</h3>
						<p>Lists are used to present multiple related items.</p>
						<h4>Unordered List</h4>
						<ul>
							<li>List Item 1</li>
							<li>List Item 2</li>
							<li>List Item 3</li>
						</ul>
						<h4>Ordered List</h4>
						<ol>
							<li>First Item</li>
							<li>Second Item</li>
							<li>Third Item</li>
						</ol>
					</div>
					<div>
						<h3>Code</h3>
						<p>
							Inline <code>code</code> elements are styled distinctly from the text.
						</p>
						<p>
							Block level code is presented within a <code>pre</code> element:
						</p>
						<pre>
							{`<code>
function helloWorld() {
  console.log("Hello, world!");
}
</code>`}
						</pre>
					</div>
					<div>
						<h3>Blockquotes</h3>
						<blockquote>
							This is a blockquote. It can be used to highlight quotes from external sources or emphasize
							a section of text.
						</blockquote>
					</div>
				</section>

				{/* Button Styles Section */}
				<section id="buttons">
					<div>
						<h2>Button Styles</h2>
						<p>Buttons guide users towards actions we want them to take.</p>
						<button className="btn">Default Button</button>
						<button className="btn btn-primary">Primary Button</button>
						<button className="btn btn-secondary">Secondary Button</button>
						<button className="btn btn-link">Link Button</button>
						<button className="btn help-btn">Help Button</button>
						<button className="btn btn-block">Block Button</button>
					</div>
					<div>
						<h2>Badge Styles</h2>
						<p>
							<span className="badge">Badge</span>
						</p>
					</div>
				</section>

				{/* Form Elements Section */}
				<section id="forms">
					<div>
						<h2>Form Elements</h2>
						<p>Form elements are designed for efficient data entry and interaction.</p>
						<form>
							<div>
								<label htmlFor="textInput">Text Input</label>
								<input id="textInput" type="text" placeholder="Text Input" />
							</div>
							<div>
								<label htmlFor="selectOption">Select Option</label>
								<select id="selectOption">
									<option>Select Option</option>
									<option>Option 1</option>
									<option>Option 2</option>
								</select>
							</div>
							<div>
								<label htmlFor="textArea">Textarea</label>
								<textarea id="textArea" placeholder="Textarea"></textarea>
							</div>
							<button type="submit" className="btn">
								Submit Button
							</button>
						</form>
						<table class="table">
							<thead>
								<tr>
									<th scope="col">#</th>
									<th scope="col">First</th>
									<th scope="col">Last</th>
									<th scope="col">Handle</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th scope="row">1</th>
									<td>Mark</td>
									<td>Otto</td>
									<td>@mdo</td>
								</tr>
								<tr>
									<th scope="row">2</th>
									<td>Jacob</td>
									<td>Thornton</td>
									<td>@fat</td>
								</tr>
								<tr>
									<th scope="row">3</th>
									<td>Larry</td>
									<td>the Bird</td>
									<td>@twitter</td>
								</tr>
							</tbody>
						</table>
					</div>
				</section>
			</div>
		);
	}
}

export default StyleGuide;

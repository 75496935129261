// Resources
// noinspection JSValidateTypes,RequiredAttributes

// Resources
import React, { Component } from "react";

// Styles
import { ToolWrapperEl } from "./styles/ToolWrapper";
class ToolWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			text: "testing"
		};
	}
	encode = () => {
		try {
			this.setState({ text: encodeURIComponent(this.state.text) });
		} catch (e) {
			alert(e);
		}
	};
	decode = () => {
		try {
			this.setState({ text: decodeURIComponent(this.state.text) });
		} catch (e) {
			alert(e);
		}
	};
	toUpperCase = () => {
		this.setState({ text: this.state.text.toUpperCase() });
	};
	toLowerCase = () => {
		this.setState({ text: this.state.text.toLowerCase() });
	};
	formatJson = () => {
		try {
			// TODO: new lines, etc?
			this.setState({
				text: JSON.stringify(this.state.text, null, "\t")
			});
		} catch (e) {
			alert(e);
		}
	};
	handleChange = (event) => {
		//(editor, data, value)
		let value = event.target.value;
		this.setState({ text: value });
	};
	// TODO: use package or move to utility?
	toUnicode = () => {
		try {
			function dec2hex4(textString) {
				var hexequiv = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E", "F"];
				return (
					hexequiv[(textString >> 12) & 0xf] +
					hexequiv[(textString >> 8) & 0xf] +
					hexequiv[(textString >> 4) & 0xf] +
					hexequiv[textString & 0xf]
				);
			}
			function convertCharStr2jEsc(str) {
				// Converts a string of characters to JavaScript escapes
				// str: sequence of Unicode characters
				var highsurrogate = 0;
				var suppCP;
				var pad;
				var outputString = "";
				for (var i = 0; i < str.length; i++) {
					var cc = str.charCodeAt(i);
					if (cc < 0 || cc > 0xffff) {
						outputString += "!Error in convertCharStr2UTF16: unexpected charCodeAt result, cc=" + cc + "!";
					}
					if (highsurrogate !== 0) {
						// this is a supp char, and cc contains the low surrogate
						if (0xdc00 <= cc && cc <= 0xdfff) {
							suppCP = 0x10000 + ((highsurrogate - 0xd800) << 10) + (cc - 0xdc00);
							suppCP -= 0x10000;
							outputString +=
								"\\u" + dec2hex4(0xd800 | (suppCP >> 10)) + "\\u" + dec2hex4(0xdc00 | (suppCP & 0x3ff));
							highsurrogate = 0;
							continue;
						} else {
							outputString += "Error in convertCharStr2UTF16: low surrogate expected, cc=" + cc + "!";
							highsurrogate = 0;
						}
					}
					if (0xd800 <= cc && cc <= 0xdbff) {
						// start of supplementary character
						highsurrogate = cc;
					} else {
						// this is a BMP character
						//outputString += dec2hex(cc) + ' ';
						switch (cc) {
							case 0:
								outputString += "\\0";
								break;
							case 8:
								outputString += "\\b";
								break;
							case 9:
								outputString += "\t";
								break;
							case 10:
								outputString += "\n";
								break;
							case 13:
								outputString += "\\r";
								break;
							case 11:
								outputString += "\\v";
								break;
							case 12:
								outputString += "\\f";
								break;
							case 34:
								outputString += '"';
								break;
							case 39:
								outputString += "'";
								break;
							case 92:
								outputString += "\\\\";
								break;
							default:
								if (cc > 0x1f && cc < 0x7f) {
									outputString += String.fromCharCode(cc);
								} else {
									pad = cc.toString(16).toUpperCase();
									while (pad.length < 4) {
										pad = "0" + pad;
									}
									outputString += "\\u" + pad;
								}
						}
					}
				}
				return outputString;
			}

			this.setState({ text: convertCharStr2jEsc(this.state.text) });
		} catch (e) {
			alert(e);
		}
	};
	render() {
		return (
			<React.Fragment>
				<ToolWrapperEl>
					<nav>
						<a
							href="https://www2.upsellit.com/admin/control/edit/index.jsp"
							target="_blank"
							rel="noreferrer"
						>
							Dashboard
						</a>
						<a
							href="https://www.toptal.com/developers/javascript-minifier"
							target="_blank"
							rel="noreferrer"
						>
							Minifier
						</a>
						<a href="https://regexr.com/" target="_blank" rel="noreferrer">
							RegExr
						</a>
						<a href="https://app.emailonacid.com/app/cp/dashboard/" target="_blank" rel="noreferrer">
							EOA
						</a>
						<a href="https://github.com/Upsellit" target="_blank" rel="noreferrer">
							Github
						</a>
					</nav>
					<main>
						{/* <div className="list_of_ideas">
                            <h1>Ideas for this page</h1>
                            <p>- Minify JS/CSS etc</p>
                            <p>- Alphabetize</p>
                            <p>- Remove duplicates</p>
                            <p>- Lines to Array</p>
                        </div> */}
						<div className="button-group">
							<button onClick={this.encode}>Encode</button>
							<button onClick={this.decode}>Decode</button>
							<button onClick={this.toUpperCase}>Uppercase</button>
							<button onClick={this.toLowerCase}>Lowercase</button>
							<button onClick={this.formatJson}>Format JSON</button>
							<button onClick={this.toUnicode}>Convert Unicode</button>
						</div>
						<textarea value={this.state.text} onChange={this.handleChange} />
					</main>
				</ToolWrapperEl>
			</React.Fragment>
		);
	}
}

export default ToolWrapper;

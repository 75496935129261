import styled from "styled-components";

export const SettingsWrapperEl = styled.div`
  width: 100%;
  margin-top: auto;

  // SIDEBAR SETTINGS
  // SIDEBAR SETTINGS
  // SIDEBAR SETTINGS
  & .sidebar-settings-wrapper {
    text-align: center;
    padding: 15px 0;
  }

  & .sidebar-settings-item {
    height: 36px;
    width: 36px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    position: relative;
  }

  & .sidebar-settings-item.theme {
    float: right;
    margin-bottom: 15px;
    margin-right: 15px;
  }

  & .sidebar-settings-item.logout {
    float: left;
    margin-bottom: 15px;
    margin-left: 15px;
  }

  & .sidebar-settings-item-img {
    position: absolute;
    height: 18px;
    width: 18px;
    top: 9px;
    left: calc(50% - 9px);
  }

  & .sidebar-settings-item-img.theme {
    background-image: ${props => props.theme === 'dark' ? 'var(--icon-moon-g)' : 'var(--icon-sun-g)'};
  }

  & .sidebar-settings-item-img.logout {
    background-image: var(--icon-logout-g);
  }

  //& .sidebar-settings-item-bg {
  //  height: 36px;
  //  width: 36px;
  //  border-radius: 18px;
  //  background: var(--bg-d2);
  //  position: absolute;
  //  -moz-transition: border 0.05s, -moz-transform ease-in-out 0.05s;
  //  -webkit-transition: border 0.05s, -moz-transform ease-in-out 0.05s;
  //  -o-transition: border 0.05s, -moz-transform ease-in-out 0.05s;
  //  transition: border 0.05s, -moz-transform ease-in-out 0.05s;
  //}

  & .sidebar-settings-item:hover {
    cursor: pointer;
  }

  & .sidebar-settings-item:hover > .sidebar-settings-item-img.theme {
    background-image: ${props => props.theme === 'dark' ? 'var(--icon-moon-l)' : 'var(--icon-sun-l)'};
  }

  & .sidebar-settings-item:hover > .sidebar-settings-item-img.logout {
    background-image: var(--icon-logout-l);
  }

  @media screen and (max-width: 1200px) {
    & .sidebar-settings-item.theme {
      display: none;
    }

    & .legacy .sidebar-settings-item.theme {
      display: block;
    }

    & .sidebar-settings-item.logout {
      margin-left: 22px;
    }

    & .legacy .sidebar-settings-item.logout {
      margin-left: 15px;
    }
  }

@media screen and (max-width: 480px) {
  & .sidebar-settings-item.theme {
    display: block;
  }

  & .legacy .sidebar-settings-item.theme {
    display: block;
  }

  & .sidebar-settings-item.logout {
    margin-left: 22px;
  }

  & .legacy .sidebar-settings-item.logout {
    margin-left: 15px;
  }
}
  
`;
import styled from "styled-components";
export const EditorWrapperEl = styled.div`
	.RichEditor-editor {
		cursor: text;
		margin-top: 10px;
	}
	blockquote.RichEditor-blockquote {
		border-left: 2px solid;
		padding: 1em;
		margin: 1em 0;
	}
	.RichEditor-editor .public-DraftEditorPlaceholder-root,
	.RichEditor-editor .public-DraftEditor-content {
		margin: 0 -15px -15px;
		padding: 15px;
	}
	.RichEditor-editor .public-DraftEditor-content {
		min-height: 100px;
	}
	.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
		display: none;
	}
	.public-DraftStyleDefault-block {
		padding-bottom: 0.5em;
	}
	.RichEditor-editor .RichEditor-blockquote {
		border-left: 5px solid #eee;
		color: #666;
		font-style: italic;
		margin: 16px 0;
		padding: 10px 20px;
	}
	.public-DraftStyleDefault-pre {
		font-size: 0.8em;
		color: #4AF626;
		line-height: 1.4;
		background: var(--bg-dg);
		padding: 1em;
		border-radius: 0.5em;
		font-family: "Inconsolata", "Menlo", "Consolas", monospace;
		margin-top: 1em;
	}
	.RichEditor-controls {
		font-size: 14px;
		margin-bottom: 5px;
		user-select: none;
	}
	.RichEditor-styleButton {
		color: #999;
		cursor: pointer;
		margin-right: 16px;
		padding: 2px 0;
		display: inline-block;
	}
	.RichEditor-activeButton {
		color: #5890ff;
	}
	.mnw6qvm.m1ymsnxd.m126ak5t {
		color: #000;
	}
	.tukdd6b {
		color: #000;
	}
`;

import styled from "styled-components";

export const RoomWrapperEl = styled.div`

  & .room-wrapper {
    display: block;
    position: relative;
    font-size: 1em;
    margin: 25px 0;
    overflow-y: auto;
    overflow-x: hidden;
    border-top: 1px solid var(--bg-d2);
    border-bottom: 1px solid var(--bg-d2);
    max-height: 20em;
  }

  & .room-wrapper.app {
    //outline: 1px dotted yellow;
   // height: calc(100vh - ${props => props['appHeight']});
  }

  & .room-wrapper.company {
    margin: auto;
    text-align: center;
  }

  & .room-wrapper.form {
    //outline: 1px dotted yellow;
    margin: 0;
    //height: calc(100vh - 331px);
  }

  & .room-wrapper.legacy {
    margin: 0;
    height: calc(100vh - 625px);
  }

  .room-wrapper::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  .room-wrapper::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  .room-wrapper::-webkit-scrollbar-track {
    background-color: transparent;
  }

  & .body-wrapper {
    padding: 10px;
  }

  & .room-user-row {
    padding: 0 0 2px 0;
    margin: 0;
    position: relative;
  }

  & .room-user-row.offline {
    opacity: 0.5;
  }

  & .room-user-row .img-wrapper {
    display: inline-block;
    vertical-align: top;
    width: 50px;
    height: 50px;
    text-align: center;
    position: relative;
    top: 1px;
  }

  & .room-user-row .img-wrapper img {
    height: 60%;
    width: 60%;
    margin: 20%;
    border-radius: 50%;
    font-size: 0;
  }

  & .room-user-row .img-wrapper .status-dot-outer {
    position: absolute;
    bottom: 6px;
    right: 4px;
    height: 16px;
    width: 16px;
    border-radius: 8px;
    background: var(--bg-d1);
  }

  & .room-user-row .img-wrapper .status-dot-inner {
    position: absolute;
    background: var(--txt-lg);
    height: 8px;
    width: 8px;
    top: calc(50% - 4px);
    left: calc(50% - 4px);
    border-radius: 4px;
  }

  & .room-user-row.online .img-wrapper .status-dot-inner {
    background: var(--blue);
  }

  & .room-user-row .content-wrapper {
    display: inline-block;
    vertical-align: top;
    min-height: 50px;
    width: calc(100% - 50px);
    padding: 0 0 0 2px;
    text-align: left;
  }

  & .room-user-row .content-wrapper p {
    font-size: 12px;
    color: white;
  }

  & .room-user-row .content-wrapper .name {
    margin: 13px 0 0 0;
  }

  & .room-user-row .content-wrapper .time {
    margin: 0;
    font-size: 10px;
    display: inline-block;
    vertical-align: top;
    height: 16px;
    color: var(--txt-lg);
  }

  & .room-user-row.online .content-wrapper .time {
    color: var(--blue);
  }

  @media screen and (max-width: 1200px) {

    & .room-user-row {
      padding: 0 5px 2px 5px;
      text-align: center;
    }

    & .room-wrapper.legacy .room-user-row {
      padding: 0 10px 2px 10px;
      text-align: left;
    }
    
    & .room-user-row .content-wrapper {
      display: none;
    }

    & .room-wrapper.legacy .content-wrapper {
      display: inline-block;
    }

  }

@media screen and (max-width: 480px) {

  
  & .room-user-row .content-wrapper {
    display: inline-block;
  }

}


`;

import React, { useState } from "react";
//import TextareaAutosize from "react-textarea-autosize";
import Switch from "react-switch";
import Moment from "react-moment";
import DraftWrapper from "../body/DraftWrapper";

export function Tasks(props) {
	let defaultDisplayCompletedTasks = window.localStorage.getItem("displayCompletedTasks") !== "false";
	const [displayCompletedTasks, setDisplayCompletedTasks] = useState(defaultDisplayCompletedTasks);
	const datesAreOnSameDay = (first, second) =>
		first.getFullYear() === second.getFullYear() &&
		first.getMonth() === second.getMonth() &&
		first.getDate() === second.getDate();

	window.localStorage.setItem("displayCompletedTasks", String(displayCompletedTasks));
	const displayTasks = displayCompletedTasks ? props.tasks : props.tasksPending;
	return (
		<div className="task-wrapper">
			<div className={"task-body-wrapper" + (props.reader ? " reader" : "")}>
				{props.tasks.length === 0 && (
					<div>
						{!props.reader && (
							<button className="btn add-task-wrapper" onClick={props._createTask}>
								Create Task
							</button>
						)}
						<span className="task-prompt">
							Tasks can be bugs, affiliate discrepancies, updates or questions.
						</span>
					</div>
				)}
				{props.tasks.length > 0 && !props.reader && (
					<div className={"task-menu-wrapper"}>
						<div className={"task-completed-wrapper"}>
							{!props.reader && (
								<button className="btn add-task-wrapper" onClick={props._createTask}>
									Create Task
								</button>
							)}

							<p className={"pending"}>
								Pending: <span>{props.tasksPendingCount}</span>
							</p>
							<p className={"completed"}>
								Completed: <span>{props.tasksCompletedCount}</span>
							</p>
						</div>
						<div className={"task-toggle-wrapper"}>
							<label htmlFor="normal-switch">
								<p className={"task-toggle-label noselect"}>Show Completed Tasks</p>
								<Switch
									onChange={() => setDisplayCompletedTasks((prev) => !prev)}
									checked={displayCompletedTasks}
									height={20}
									width={40}
									className="toggle-switch"
									onColor="#1f85de"
									offColor="#9eb2c6"
								/>
							</label>
						</div>
					</div>
				)}
				{displayTasks &&
					displayTasks.map((task, idx) => {
						return (
							<div
								className={"card task-row" + (idx === props.tasks.length - 1 ? " last" : "")}
								key={task._id}
								id={task._id}
							>
								{!props.reader && (
									<div className={"type-wrapper"}>
										<p>Task Type:</p>
										<div className="task-select-wrapper">
											<select
												value={task.type}
												onChange={props._handleTaskTypeChange}
												data-id={task._id}
											>
												<option key={"General To Do"} value={"General To Do"}>
													General To Do
												</option>
												<option key={"Campaign Fix"} value={"Campaign Fix"}>
													Campaign Fix
												</option>
												<option key={"Campaign Update"} value={"Campaign Update"}>
													Campaign Update
												</option>
												<option key={"QA Bug Report"} value={"QA Bug Report"}>
													QA Bug Report
												</option>
												<option key={"Affiliate Discrepancy"} value={"Affiliate Discrepancy"}>
													Affiliate Discrepancy
												</option>
												<option key={"Question"} value={"Question"}>
													Question
												</option>
												<option key={"Other"} value={"Other"}>
													Other
												</option>
											</select>
										</div>
									</div>
								)}
								{props.reader && (
									<div className="reader-type-wrapper">
										<p>
											<span>Task Type:</span> {task.type}
										</p>
									</div>
								)}
								{!props.reader && (
									<div className={"status-wrapper"}>
										<div className="status-select-wrapper">
											<p>Status:</p>
											<div className="task-select-wrapper">
												<select
													value={task.status}
													onChange={props._handleTaskStatusChange}
													data-id={task._id}
													className={task.status.replace(/ /g, "_")}
												>
													<option key={"Pending"} value={"Pending"}>
														Pending
													</option>
													<option key={"Ready to test"} value={"Ready to test"}>
														Ready to test
													</option>
													<option key={"Completed"} value={"Completed"}>
														Completed
													</option>
												</select>
											</div>
										</div>
									</div>
								)}
								{props.reader && (
									<div className="reader-status-wrapper">
										<p className={task.status}>
											<span>Status:</span> {task.status}
										</p>
									</div>
								)}
								<div className="task-content-wrapper">
									<DraftWrapper
										item={task}
										editorState={props.editorState}
										reportDraftChange={(value) => props._handleTaskContentChange(task._id, value)}
										readOnly={props.reader}
										content={task.content}
										className={props.reader ? "reader" : ""}
										placeholder={"Enter task details"}
									/>
								</div>
								<div className="task-breadcrumb-wrapper">
									{task.breadcrumb.map((text, idx) => {
										let dates = task.breadcrumb_date;
										return (
											<h6 key={task._id + "_bc_" + idx} className="task-breadcrumb">
												{text} •{" "}
												<span className={props._generateTimeStyles(new Date(dates[idx]))}>
													{datesAreOnSameDay(new Date(), new Date(dates[idx])) ? (
														<Moment fromNow>{dates[idx]}</Moment>
													) : (
														<Moment format={"MM/DD/YY, h:mm a"}>{dates[idx]}</Moment>
													)}
												</span>
											</h6>
										);
									})}
								</div>
								{!props.reader && (
									<h5
										className={"delete-task-btn"}
										data-id={task._id}
										onClick={props._handleTaskDelete}
									>
										Delete
									</h5>
								)}
							</div>
						);
					})}
			</div>
		</div>
	);
}

// Resources
import React, { Component } from "react";

// Components
import TopNavWrapper from "./TopNavWrapper";
import ExampleWrapper from "./ExampleWrapper";
import MjmlWrapper from "./MjmlWrapper";
import ToolWrapper from "./ToolWrapper";

// Styles
import { DevWrapperEl, GlobalStyle } from "./styles/DevWrapper";

// Redux
import { connect } from "react-redux";

const mapStateToProps = function (state) {
	return state;
};

class DevWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			navSelected: "examples",
			// Image Zoom
			imageZoomSrc: "",
			imageZoomSrcNext: "",
			imageZoomSrcArr: [],
			imageZoomSrcIdx: 0
		};
	}

	_setNav = (nav) => {
		this.setState({ navSelected: nav }, function () {
			console.log("[ _setNav ] nav set to:", nav);
		});
	};

	_handleImageZoom = (imgs) => {
		if (imgs && imgs.length > 0) {
			this.setState({
				imageZoomSrc: imgs[0],
				imageZoomSrcNext: typeof imgs[1] !== "undefined" ? imgs[1] : "",
				imageZoomSrcArr: imgs,
				imageZoomSrcIdx: 0
			});
		}
	};

	_handleImageZoomDismissed = () => {
		this.setState({
			imageZoomSrc: "",
			imageZoomSrcNext: "",
			imageZoomSrcArr: [],
			imageZoomSrcIdx: 0
		});
	};

	_handleImageZoomLeftNav = (e) => {
		e.stopPropagation();
		this.setState({
			imageZoomSrc: this.state.imageZoomSrcArr[this.state.imageZoomSrcIdx - 1],
			imageZoomSrcNext: this.state.imageZoomSrcArr[this.state.imageZoomSrcIdx],
			imageZoomSrcIdx: this.state.imageZoomSrcIdx - 1
		});
	};

	_handleImageZoomRightNav = (e) => {
		e.stopPropagation();
		this.setState({
			imageZoomSrc: this.state.imageZoomSrcArr[this.state.imageZoomSrcIdx + 1],
			imageZoomSrcNext:
				typeof this.state.imageZoomSrcArr[this.state.imageZoomSrcIdx + 2] !== "undefined"
					? this.state.imageZoomSrcArr[this.state.imageZoomSrcIdx + 2]
					: "",
			imageZoomSrcIdx: this.state.imageZoomSrcIdx + 1
		});
	};

	render() {
		return (
			<React.Fragment>
				<GlobalStyle />
				{this.state.imageZoomSrc && (
					<div className="image-zoom-wrapper" onClick={this._handleImageZoomDismissed}>
						<img src={this.state.imageZoomSrc} alt={this.state.imageZoomSrc} />
						{this.state.imageZoomSrcNext && (
							<img
								className={"image-zoom-next"}
								src={this.state.imageZoomSrcNext}
								alt={this.state.imageZoomSrcNext}
							/>
						)}
						{typeof this.state.imageZoomSrcArr[this.state.imageZoomSrcIdx - 1] !== "undefined" && (
							<div className={"image-arrow left"} onClick={this._handleImageZoomLeftNav}>
								<span>&#10094;</span>
							</div>
						)}
						{typeof this.state.imageZoomSrcArr[this.state.imageZoomSrcIdx + 1] !== "undefined" && (
							<div className={"image-arrow right"} onClick={this._handleImageZoomRightNav}>
								<span>&#10095;</span>
							</div>
						)}
					</div>
				)}
				<DevWrapperEl>
					<div className="body-wrapper">
						<div className={"header-wrapper dev" + (this.props.page === "app" ? " app" : "")}>
							<TopNavWrapper page={this.props.page} />
						</div>
						<div className="nav-menu-wrapper">
							<div
								onClick={() => this._setNav("examples")}
								className={
									"nav-menu-item examples " +
									(this.state.navSelected === "examples" ? "active" : " inactive")
								}
							>
								<p className={"noselect"}>Examples</p>
								{this.state.navSelected === "examples" && <div className={"underline"} />}
							</div>
							<div
								onClick={() => this._setNav("mjml")}
								className={
									"nav-menu-item mjml " + (this.state.navSelected === "mjml" ? "active" : " inactive")
								}
							>
								<p className={"noselect"}>MJML</p>
								{this.state.navSelected === "mjml" && <div className={"underline"} />}
							</div>
							<div
								onClick={() => this._setNav("tools")}
								className={
									"nav-menu-item tools " +
									(this.state.navSelected === "tools" ? "active" : " inactive")
								}
							>
								<p className={"noselect"}>Tools</p>
								{this.state.navSelected === "tools" && <div className={"underline"} />}
							</div>
						</div>
						<div className="content-wrapper">
							{this.state.navSelected === "examples" && (
								<ExampleWrapper user={this.props.user} handleImageZoom={this._handleImageZoom} />
							)}
							{this.state.navSelected === "mjml" && <MjmlWrapper user={this.props.user} />}
							{this.state.navSelected === "tools" && <ToolWrapper user={this.props.user} />}
						</div>
					</div>
				</DevWrapperEl>
			</React.Fragment>
		);
	}
}

export default connect(mapStateToProps)(DevWrapper);

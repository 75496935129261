// Resources
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Cookies from "universal-cookie";
import { withRouter, Redirect, BrowserRouter as Router, Switch, Route } from "react-router-dom"; //, Link

// Components
import Article from "./Article";
// import ReaderWrapper from "./components/body/ReaderWrapper";
import DraftWrapper from "./components/body/DraftWrapper";
// import Select from "react-select";

// Auth
import AuthHelperMethods from "./components/auth/AuthHelperMethods";
import withAuth from "./components/auth/withAuth";

// Redux
import { createStore } from "redux";
//import { Provider } from "react-redux";
import rootReducer from "./reducers";

// Styles
import { WikiWrapperEl, GlobalStyle } from "./components/body/styles/WikiWrapper";
import logo from "./images/logo.svg";

// Globals
const store = createStore(rootReducer);

class WikiWrapper extends Component {
	Auth = new AuthHelperMethods();
	constructor(props) {
		super(props);
		this.state = {
			logout: false,
			editMode: localStorage["editMode"] === "true" || false,
			sort: localStorage["wikiSort"] ? localStorage["wikiSort"] : "default",
			filter: localStorage["wikiFilter"] ? localStorage["wikiFilter"] : "All",
			search: new URLSearchParams(window.location.search).get("s") || "",
			articlesVisible: [],
			nonExactMatches: [],
			articles: [],
			redirect: "",
			navigation_open: localStorage.getItem("navigationOpen") !== "false",
			navigation: {},
			previous_navigation: [],
			top_navigation: []
		};
	}
	categories = ["All"];

	handleStorageChange = (event) => {
		if (event.key === "refreshWikiAll" && event.newValue === "true") {
			// Another tab has requested to refresh the articles
			this.fetchWikiAll();
			// Reset the flag
			localStorage.setItem("refreshWikiAll", "false");
		}
	};
	fetchWikiAll = () => {
		this.fetchAdminAll();
		this.Auth.fetchWikiAll({ user: this.state.user })
			.then((res) => {
				if (res.error) {
					alert(res.error);
				} else if (res.data.articles) {
					// Breadcrumb trail logic
					var currentArticle =
						res.data.articles.find((a) => a.slug === window.location.pathname.replace("/wiki/", "")) ||
						res.data.articles.find((a) => a.title === "Navigation" || a.title === "Home");

					const breadcrumbs = res.data.articles
						.filter((a) => a.child_articles && a.child_articles.includes(currentArticle?.slug))
						.map((a) => {
							let breadcrumb = [a];
							let c = a;

							const getParentArticle = (slug) =>
								res.data.articles.find(
									(parent) => parent.child_articles && parent.child_articles.includes(slug)
								);

							// Traverse up the hierarchy to build the breadcrumb trail
							while (c && c.child_articles) {
								const parentArticle = getParentArticle(c.slug);
								if (parentArticle) {
									breadcrumb.unshift(parentArticle);
									c = parentArticle; // Move to the next level up
								} else {
									break; // Break if no further parent is found
								}
							}
							return breadcrumb;
						});

					// Find the longest breadcrumb trail
					const longestBreadcrumb = breadcrumbs.reduce((longest, current) => {
						return current.length > longest.length ? current : longest;
					}, []);

					this.setState(
						{
							articlesVisible: this.filterAndSortArticles(
								res.data.articles,
								this.state.search,
								this.state.sort,
								this.state.filter
							),
							articles: res.data.articles,
							navigation: currentArticle,
							previous_navigation: longestBreadcrumb
						},
						function () {
							console.log(this.state.navigation);
						}
					);
				}
			})
			.catch((err) => {
				console.log("[ fetchWikiAll ] POST error:", err);
			});
	};
	fetchAdminAll = () => {
		this.Auth.fetchAdmin({ user: this.state.user._id })
			.then((res) => {
				if (res.error) {
					alert(res.error);
				} else if (res.data.settings) {
					let obj = {};
					res.data.settings.forEach((item) => (obj[item.title] = item.value));
					obj.tags = obj.tags.split("\n");
					obj.departments = JSON.parse(obj.departments);
					obj.copy_data = JSON.parse(obj.copy_data);
					this.setState({ settings: obj });
				}
			})
			.catch((err) => console.error("[ fetchAdminAll ] POST error:", err));
	};
	addArticle = (post_data) => {
		// TODO: Check for other open tabs and run fetchWikiAll
		this.Auth.addArticle(post_data)
			.then((res) => {
				if (res.error) {
					alert(res.error);
				} else {
					this.setState({
						redirect: "/wiki/" + res.article.slug,
						editMode: true
					});
					// this.props.history.push({
					// 	pathname: "/wiki/" + res.article.slug
					// });
					// get new list of articles
					localStorage.setItem("refreshWikiAll", "true");
					//this.fetchWikiAll();
				}
			})
			.catch((err) => {
				console.log("[ addArticle ] POST error:", err);
			});
	};

	toggleMode() {
		this.setState(
			{
				editMode: !this.state.editMode
			},
			function () {
				localStorage.setItem("editMode", this.state.editMode);
			}
		);
	}

	toggleNavigation() {
		this.setState(
			{
				navigation_open: !this.state.navigation_open
			},
			function () {
				localStorage.setItem("navigationOpen", this.state.navigation_open);
			}
		);
	}

	getColor(dept) {
		return (this.state.settings && this.state.settings.departments[dept]) || { color: "100, 100, 100" };
	}

	calculateMatchScore(item, searchTerm) {
		try {
			const check = (term) => {
				try {
					// If matches full word
					if (term.match(new RegExp(`\\b${searchTerm}\\b`, "i"))) {
						return 3;
					}
					// If matches start of word
					if (term.match(new RegExp(`^${searchTerm}`, "i"))) {
						return 2;
					}
					if (term.match(new RegExp(searchTerm, "i"))) {
						return 1;
					}
				} catch (e) {
					return term.indexOf(searchTerm) !== -1 ? 1 : 0;
				}
				return 0;
			};
			if (item.title && check(item.title)) {
				return 1000 * check(item.title);
			}
			if (item.tags && check(item.tags.join(","))) {
				return 100 * check(item.tags.join(","));
			}
			if (item && check(JSON.stringify(Object.values(item)))) {
				return 10 * check(JSON.stringify(Object.values(item)));
			}
		} catch (e) {}
		return 0;
	}

	searchTermMatches(string, term) {
		if (!term.match(/AND|OR|NOT|\?|\*|\(|\)/)) {
			return string.toLowerCase().indexOf(term.toLowerCase()) !== -1;
		}

		function parseSearchTerm(term) {
			const operators = ["AND", "OR", "NOT"];
			const stack = [];
			let currentTerm = "";
			let inQuotes = false;

			for (let i = 0; i < term.length; i++) {
				const char = term[i];

				if (char === '"') {
					inQuotes = !inQuotes;
					currentTerm += char;
				} else if (inQuotes) {
					currentTerm += char;
				} else if (char === " " && operators.includes(currentTerm.toUpperCase())) {
					stack.push({ type: currentTerm.toUpperCase(), value: null });
					currentTerm = "";
				} else if (char === "(" || char === ")") {
					if (currentTerm.length > 0) {
						stack.push({ type: "TERM", value: currentTerm.trim() });
						currentTerm = "";
					}
					stack.push({ type: char, value: null });
				} else if (char === " " && currentTerm.length > 0) {
					stack.push({ type: "TERM", value: currentTerm.trim() });
					currentTerm = "";
				} else if (char === " ") {
					continue;
				} else {
					currentTerm += char;
				}
			}

			if (currentTerm.length > 0) {
				stack.push({ type: "TERM", value: currentTerm.trim() });
			}

			// Check for mismatched quotes
			if (inQuotes) {
				throw new Error("Mismatched quotes in search term");
			}

			// Combine consecutive terms not separated by operators into single terms
			const combinedStack = [];
			let buffer = "";

			for (let i = 0; i < stack.length; i++) {
				const token = stack[i];

				if (token.type === "TERM") {
					if (buffer.length > 0) {
						buffer += " " + token.value;
					} else {
						buffer = token.value;
					}
				} else {
					if (buffer.length > 0) {
						combinedStack.push({ type: "TERM", value: buffer });
						buffer = "";
					}
					combinedStack.push(token);
				}
			}

			if (buffer.length > 0) {
				combinedStack.push({ type: "TERM", value: buffer });
			}

			return combinedStack;
		}

		function evaluateTerm(string, term) {
			function escapeRegexCharacters(str) {
				return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
			}

			const escapedTerm = escapeRegexCharacters(term)
				.replace(/\\\*/g, ".*") // Convert \* to .* for wildcard
				.replace(/\\\?/g, "."); // Convert \? to . for single character wildcard

			const regex = new RegExp(escapedTerm, "i");
			return regex.test(string);
		}

		function evaluateSearchTerms(string, terms) {
			const stack = [];
			let i = 0;

			while (i < terms.length) {
				const term = terms[i];

				if (term.type === "TERM") {
					stack.push(evaluateTerm(string, term.value));
				} else if (term.type === "AND") {
					const left = stack.pop();
					const right = evaluateTerm(string, terms[++i].value);
					stack.push(left && right);
				} else if (term.type === "OR") {
					const left = stack.pop();
					const right = evaluateTerm(string, terms[++i].value);
					stack.push(left || right);
				} else if (term.type === "NOT") {
					const right = evaluateTerm(string, terms[++i].value);
					stack.push(!right);
				} else if (term.type === "(") {
					const subTerms = [];
					let depth = 1;
					i++;
					while (depth > 0 && i < terms.length) {
						if (terms[i].type === "(") depth++;
						else if (terms[i].type === ")") depth--;
						if (depth > 0) subTerms.push(terms[i]);
						i++;
					}
					stack.push(evaluateSearchTerms(string, subTerms));
					i--;
				}

				i++;
			}

			return stack.length > 0 ? stack[0] : false;
		}

		const parsedTerms = parseSearchTerm(term);
		return evaluateSearchTerms(string, parsedTerms);
	}

	filterAndSortArticles(articles, search, sort, filter) {
		let articleList = articles
			.filter((a) => {
				var matches = true;
				if (
					this.state.settings &&
					!this.state.user.profile.email.match(this.state.settings.managers) &&
					a.category.indexOf("Executive") !== -1
				) {
					return false;
				}
				if (a.status === "archived") {
					return filter.indexOf("Archived") !== -1;
				}
				if (sort === "title") {
					matches = this.searchTermMatches(a.title, search);
				} else if (search !== "" && search.length > 1) {
					try {
						if (a.tags && search.indexOf("TAG:") === 0) {
							matches = a.tags
								.map((i) => i.toUpperCase())
								.includes(search.replace("TAG:", "").toUpperCase());
						} else {
							matches = this.searchTermMatches(Object.values(a).join("|"), search);
						}
					} catch (e) {}
				}
				if (filter !== "All") {
					if (filter === "Unpublished") {
						return matches && a.status === "unpublished";
					}
					if (filter === "My Published") {
						return matches && a.status === "published" && a.created_by === this.state.user.profile.email;
					}
					return matches && a.category.indexOf(filter) !== -1;
				}
				return matches && a.status === "published";
			})
			.map((a) => {
				return {
					...a,
					score: this.calculateMatchScore(a, search)
				};
			})
			.sort((a, b) => {
				if (sort === "default") {
					if (!search || search.length < 2) {
						return a.views < b.views ? 1 : -1;
					} else if (search && a.tags && b.tags) {
						return a.score < b.score ? 1 : -1;
					}
				}
				if (sort === "title") return a.score < b.score ? 1 : -1;
				if (sort === "alpha-asc") return a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1;
				if (sort === "alpha-desc") return a.title.toLowerCase() > b.title.toLowerCase() ? -1 : 1;
				if (sort === "new") return a.created_on > b.created_on ? -1 : 1;
				if (sort === "recently-updated") return a.updatedAt > b.updatedAt ? -1 : 1;
				if (sort === "most-viewed") return a.views > b.views ? -1 : 1;
				if (sort === "recently-viewed" && localStorage["wikiHistory"]) {
					return localStorage["wikiHistory"].indexOf(a.slug) > localStorage["wikiHistory"].indexOf(b.slug)
						? -1
						: 1;
				}
				return 0;
			});
		return articleList;
	}

	handleFormChange = (e) => {
		let opts = {};
		if (e && e.target) {
			opts[e.target.name] = e.target.value;
			if (e.target.name === "search") {
				document.getElementById("wikiSearch").value = e.target.value;
			}
		}
		this.setState(opts, () => {
			localStorage["wikiSort"] = this.state.sort;
			localStorage["wikiFilter"] = this.state.filter;
			this.updateArticleList();
		});
	};

	updateArticleList = () => {
		const wikiSearch = document.getElementById("wikiSearch");
		const searchTerm = wikiSearch.value;
		let nonExactMatches = [];
		if (searchTerm.split(" ").length > 1) {
			const words = searchTerm.split(/\s+/);
			for (let i = 1; i < words.length; i++) {
				const variation = words.slice(0, i).join(" ") + " AND " + words.slice(i).join(" ");
				const currentMatches = this.filterAndSortArticles(
					this.state.articles,
					variation,
					this.state.sort,
					this.state.filter
				);
				if (
					nonExactMatches.length === 0 ||
					(currentMatches.length > 0 && nonExactMatches.length > currentMatches.length)
				) {
					nonExactMatches = currentMatches;
				}
			}
			nonExactMatches = this.filterAndSortArticles(
				this.state.articles,
				searchTerm.replace(/ +/g, " AND "),
				this.state.sort,
				this.state.filter
			);
		}

		this.setState({
			search: searchTerm,
			articlesVisible: this.filterAndSortArticles(
				this.state.articles,
				searchTerm,
				this.state.sort,
				this.state.filter
			),
			nonExactMatches: nonExactMatches
		});
	};

	generateDocumentOutline = (content) => {
		let outline = [];
		if (!content) return;
		try {
			let d = JSON.parse(content);
			if (d.blocks) {
				d.blocks.forEach((block) => {
					if ((block.type === "header-one" || block.type === "header-two") && block.text) {
						outline.push(block);
					}
				});
			}
		} catch (e) {}
		return outline;
	};

	toSlug = (str) =>
		str
			.toLowerCase()
			.trim()
			.replace(/\s+/g, "_")
			.replace(/[^a-z0-9_]/gi, "");

	angleIcon = (
		<svg width="10px" height="10px" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
			<path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z" fill="currentColor" />
		</svg>
	);

	_handleLogout = () => {
		if (window.location.href.indexOf("localhost") !== -1) return;
		this.setState({ logout: true }, function () {
			this.props.history.replace("/login");
		});
	};

	componentWillUnmount() {
		window.removeEventListener("storage", this.handleStorageChange);
		if (this.state.logout) this.Auth.logout();
	}

	componentDidMount() {
		let cookies = new Cookies();
		let theme = cookies.get("theme") || "dark";
		document.documentElement.id = theme === "light" ? "light" : "dark";

		window.addEventListener("storage", this.handleStorageChange);

		if (this.Auth.getToken()) {
			this.Auth.getProfile()
				.then((res) => {
					if (res.success && res.data && res.data.profile) {
						// Save profile data in redux
						res.data.profile._id = res.data.id;
						store.dispatch({
							type: "PROFILE",
							profile: res.data.profile
						});

						// Save profile data in state
						this.setState(
							{
								user: res.data,
								profile: res.data.profile,
								userId: res.data.id,
								ws: res.data.ws
							},
							this.fetchWikiAll
						);
					} else {
						console.log("[ componentDidMount ] Server error:", res);
						this._handleLogout();
					}
				})
				.catch((err) => {
					console.log("[ componentDidMount ] POST error:", err);
					this._handleLogout();
				});
		} else {
			console.log("[ componentDidMount ] Token not found");
			this._handleLogout();
		}
	}

	renderArticlePreview(article, nonexact) {
		return (
			<article key={article._id} className="article-preview">
				<div className="article-head">
					<h2>
						{/* <Link to={"/wiki/" + article.slug + (this.state.search ? "?search=" + this.state.search : "")}>
							{article.title}
						</Link> */}
						<a href={"/wiki/" + article.slug + (this.state.search ? "?search=" + this.state.search : "")}>
							{article.title}
						</a>
					</h2>
					{this.state.sort === "recently-updated" && (
						<small className="badge">
							Last updated <time>{new Date(article.updatedAt).toLocaleDateString()}</time>
						</small>
					)}
					{this.state.sort === "new" && article.created_on && (
						<small className="badge">
							Created <time>{new Date(article.created_on).toLocaleDateString()}</time>
						</small>
					)}
					{this.state.sort === "recently-viewed" &&
						localStorage["wikiHistory"] &&
						localStorage["wikiHistory"].indexOf(article.slug) !== -1 && (
							<small className="badge">Viewed</small>
						)}
					{article.status === "unpublished" && <small className="badge">Unpublished</small>}
					{this.state.sort === "most-viewed" && <small className="badge">{article.views} Views</small>}
				</div>
				<div className="article-body">
					<DraftWrapper
						plainText={true}
						maxLength={420}
						item={article}
						content={article.content}
						search={
							this.state.search && this.state.search.length > 1
								? nonexact
									? this.state.search.replace(/ +/g, " AND ")
									: this.state.search
								: ""
						}
						className={"dont-break-out"}
					/>
				</div>
				<footer className="article-footer">
					<div className="article-categories">
						<div className="article-categories-list">
							{article.category.split(", ").map((cat) => (
								<button
									key={cat}
									onClick={(e) => {
										this.setState(
											{
												filter: cat
											},
											this.handleFormChange
										);
									}}
									style={{
										background: "rgb(" + this.getColor(cat).color + ")"
									}}
								>
									{cat}
								</button>
							))}
							{article.tags &&
								article.tags.map((t) => (
									<button
										className="tag-btn"
										name="search"
										value={"TAG:" + t}
										key={t}
										onClick={this.handleFormChange}
									>
										{t}
									</button>
								))}
						</div>
					</div>
				</footer>
			</article>
		);
	}

	renderOutline = (outlineItems, article) => {
		return (
			<div>
				{outlineItems && outlineItems.length > 0 && (
					<ul>
						{outlineItems.map((item, index) => (
							<li className={"outline-item " + item.type} key={index}>
								<a
									className={"btn btn-link " + 
										(window.location.hash === "#goto_" + this.toSlug(item.text) ? "active" : "")
									}
									href={"#goto_" + this.toSlug(item.text)}
								>
									{item.text}
								</a>
							</li>
						))}
					</ul>
				)}
			</div>
		);
	};

	render() {
		let cookies = new Cookies();
		if (this.state.redirect !== "") {
			const redirectPath = this.state.redirect;
			this.setState({ redirect: "" });
			return <Redirect to={redirectPath} />;
		}
		const isManager = this.state.settings && this.state.user.profile.email.match(this.state.settings.managers);
		const isEditor = this.state.settings && this.state.user.profile.email.match(this.state.settings.editors);

		let departments = [];
		if (this.state.settings) {
			departments = this.state.settings.departments;
		}
		if (this.state.user && this.state.user.profile && isEditor && this.categories.indexOf("Unpublished") === -1) {
			this.categories.push("Unpublished");
			this.categories.push("My Published");
			departments["Navigation"] = {
				color: "136,103,178"
			};
			if (isManager) {
				departments["Executive"] = {
					color: "136,103,178"
				};
				this.categories.push("Archived");
			}
		}

		return (
			<React.Fragment>
				<Helmet>
					<title>Upsellit Wiki</title>
				</Helmet>
				<WikiWrapperEl>
					<GlobalStyle />
					<header className={"header-wrapper"}>
						<nav className="top-navigation">
							<div className="menu-item" style={{ minWidth: "10em", marginRight: "auto" }}>
								<button className="btn btn-link btn-logo">
									<a href={origin + "/wiki"}>
										<img src={logo} alt={"logo"} className={"icon"} />
									</a>{" "}
									<a
										href={origin + "/wiki"}
										style={{
											fontSize: "1.5em",
											paddingLeft: "1em",
											letterSpacing: "2px",
											height: "auto"
										}}
									>
										Wiki {this.angleIcon}
									</a>
								</button>
								<div className="sub-menu">
									<ul style={{ paddingLeft: "1em" }}>
										<li>
											<a href={origin + "/"} className="btn btn-link btn-block">
												CSD
											</a>
										</li>
										<li>
											<a
												href="https://www2.upsellit.com/admin/control/edit/index.jsp"
												target="_blank"
												rel="noreferrer"
												className="btn btn-link btn-block"
											>
												Admin
											</a>
										</li>
										<li>
											<a
												href="https://upsellit.lightning.force.com/lightning"
												target="_blank"
												rel="noreferrer"
												className="btn btn-link btn-block"
											>
												Salesforce
											</a>
										</li>
									</ul>
								</div>
							</div>
							<div className="menu-item hide-mobile" style={{ marginLeft: "auto" }}>
								<a
									href={"/wiki"}
									className="btn text-btn"
									title="search"
									style={{
										fontSize: "1em",
										padding: "1em",
										backgroundImage: "var(--icon-search-lg)",
										border: "none",
										backgroundRepeat: "no-repeat",
										backgroundPosition: "center",
										minWidth: "5em"
									}}
								>
									{" "}
								</a>
							</div>
							{this.state.user && (
								<div style={{ marginRight: "1em", minWidth: "5em" }}>
									<div className="menu-item">
										<div className="">
											<button
												className="profile-button btn btn-link"
												style={{ backgroundImage: `url(${this.state.user.profile.picture})` }}
											></button>{" "}
											<span style={{ marginTop: "1.25em", display: "inline-block" }}>
												{this.angleIcon}
											</span>
										</div>
										<div className="sub-menu">
											<ul>
												<li>
													<button
														className="btn btn-link btn-block"
														onClick={(e) => {
															let theme = cookies.get("theme") || "dark";
															if (theme === "light") {
																cookies.set("theme", "dark", {
																	path: "/",
																	maxAge: 315360000
																});
																document.documentElement.id = "dark";
															} else {
																cookies.set("theme", "light", {
																	path: "/",
																	maxAge: 315360000
																});
																document.documentElement.id = "light";
															}
														}}
													>
														<span
															className="icon"
															style={{
																background: "var(--icon-moon-g)"
															}}
														></span>
														Theme
													</button>
												</li>
												{this.state.user && isEditor && (
													<li>
														<button
															onClick={() => this.addArticle(this.state.user)}
															className="btn btn-link btn-block"
															title="Admin only"
														>
															<span className="icon" style={{}}>
																+
															</span>
															Add Article
														</button>
													</li>
												)}
												{this.state.user && isEditor && (
													<li>
														<button
															onClick={() => this.toggleMode()}
															className="btn btn-link btn-block"
															title="Admin only"
														>
															<span
																className="icon"
																style={{
																	background: "var(--icon-wiki-lg)"
																}}
															></span>
															{this.state.editMode ? "Read" : "Edit"} Mode
														</button>
													</li>
												)}
												{this.state.settings && isEditor && (
													<li>
														<a href="/admin/" className="btn btn-link btn-block">
															<span
																className="icon"
																style={{
																	background: "var(--icon-team-lg)"
																}}
															></span>
															Docs Admin
														</a>
													</li>
												)}
												<li>
													<a
														href="/wiki/wiki_browsing_and_editing"
														className="btn btn-link btn-block"
													>
														<span className="icon" style={{}}>
															?
														</span>
														Help
													</a>
												</li>
												<li>
													{this.state.user && (
														<button
															onClick={() => this._handleLogout()}
															className="btn btn-link btn-block"
														>
															<span
																className="icon"
																style={{
																	background: "var(--icon-logout-g)"
																}}
															></span>
															Logout
														</button>
													)}
												</li>
											</ul>
										</div>
									</div>
								</div>
							)}
						</nav>
					</header>
					<nav
						className={
							"article-navigation " +
							(this.state.navigation_open ? "article-navigation-open" : "article-navigation-closed")
						}
						style={{ left: this.state.navigation_open ? "0" : "-17em" }}
					>
						<button
							style={{
								position: "absolute",
								right: "0",
								top: "0",
								border: "none",
								fontSize: "2em",
								padding: "0.25em",
								background: "none"
							}}
							onClick={() => this.toggleNavigation()}
							className="article-navigation-close-btn"
						>
							{this.state.navigation_open ? (
								<>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="15px"
										height="15px"
										viewBox="0 0 24 24"
										fill="none"
									>
										<path
											d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z"
											fill="currentColor"
										/>
									</svg>
								</>
							) : (
								<>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20px"
										height="20px"
										viewBox="0 0 24 24"
										fill="none"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L10.5858 12L4.29289 18.2929C3.90237 18.6834 3.90237 19.3166 4.29289 19.7071C4.68342 20.0976 5.31658 20.0976 5.70711 19.7071L12.7071 12.7071C13.0976 12.3166 13.0976 11.6834 12.7071 11.2929L5.70711 4.29289ZM12.7071 4.29289C12.3166 3.90237 11.6834 3.90237 11.2929 4.29289C10.9024 4.68342 10.9024 5.31658 11.2929 5.70711L17.5858 12L11.2929 18.2929C10.9024 18.6834 10.9024 19.3166 11.2929 19.7071C11.6834 20.0976 12.3166 20.0976 12.7071 19.7071L19.7071 12.7071C20.0976 12.3166 20.0976 11.6834 19.7071 11.2929L12.7071 4.29289Z"
											fill="currentColor"
										/>
									</svg>
								</>
							)}
						</button>
						<div className="menu-header">
							{this.state.navigation &&
								this.state.previous_navigation &&
								this.state.navigation.title !== "Navigation" &&
								this.state.previous_navigation.length > 0 && (
									<button
										style={{
											position: "absolute",
											left: "0",
											top: "0",
											border: "none",
											fontSize: "2em",
											padding: "0.25em",
											background: "none"
										}}
										onClick={() =>
											this.setState({
												previous_navigation: this.state.previous_navigation.slice(0, -1),
												navigation:
													this.state.previous_navigation.length > 0
														? this.state.previous_navigation[
																this.state.previous_navigation.length - 1
														  ]
														: {}
											})
										}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="15px"
											height="15px"
											viewBox="0 0 16 16"
										>
											<path
												fill="currentColor"
												fill-rule="evenodd"
												d="M10.707085,3.70711 C11.097605,3.31658 11.097605,2.68342 10.707085,2.29289 C10.316555,1.90237 9.683395,1.90237 9.292865,2.29289 L4.292875,7.29289 C3.902375,7.68342 3.902375,8.31658 4.292875,8.70711 L9.292865,13.7071 C9.683395,14.0976 10.316555,14.0976 10.707085,13.7071 C11.097605,13.3166 11.097605,12.6834 10.707085,12.2929 L6.414185,8 L10.707085,3.70711 Z"
											/>
										</svg>
									</button>
								)}
							<div
								style={{
									padding: "1em 2em",
									textTransform: "none",
									textAlign: "center"
								}}
							>
								{this.state.navigation && (
									<a href={"/wiki/" + this.state.navigation.slug}>
										{this.state.navigation.title === "Navigation"
											? "Home"
											: this.state.navigation.title}
									</a>
								)}
							</div>
						</div>
						{this.state.navigation &&
							this.state.navigation.slug &&
							this.state.navigation.slug === window.location.pathname.replace("/wiki/", "") && (
								<div style={{ padding: "1em" }}>
									{this.renderOutline(
										this.generateDocumentOutline(this.state.navigation.content),
										this.state.navigation
									)}
								</div>
							)}
						{this.state.navigation &&
							this.state.navigation.child_articles &&
							this.state.navigation.child_articles
								.map((slug) => this.state.articles.find((a) => a.slug === slug))
								.map(
									(art) =>
										art &&
										art.slug && (
											<div key={art.slug} className="menu-item">
												{art.child_articles && art.child_articles.length > 0 && (
													<button
														className="menu-icon btn btn-link"
														style={{
															position: "absolute",
															right: "0",
															border: "none",
															fontSize: "2em",
															padding: "0.25em"
														}}
														onClick={() =>
															this.setState({
																previous_navigation:
																	this.state.previous_navigation.concat([
																		this.state.navigation
																	]),
																navigation: art
															})
														}
													>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															fill="currentColor"
															width="20px"
															height="20px"
															viewBox="-12 0 32 32"
															version="1.1"
														>
															<title>angle-right</title>
															<path d="M0.88 23.28c-0.2 0-0.44-0.080-0.6-0.24-0.32-0.32-0.32-0.84 0-1.2l5.76-5.84-5.8-5.84c-0.32-0.32-0.32-0.84 0-1.2 0.32-0.32 0.84-0.32 1.2 0l6.44 6.44c0.16 0.16 0.24 0.36 0.24 0.6s-0.080 0.44-0.24 0.6l-6.4 6.44c-0.2 0.16-0.4 0.24-0.6 0.24z" />
														</svg>
													</button>
												)}
												{art.child_articles && art.child_articles.length === 0 && (
													<a
														className="menu-icon btn btn-link"
														href={"/wiki/" + art.slug}
														style={{
															position: "absolute",
															right: "0",
															top: "1em",
															textTransform: "none",
															border: "none"
														}}
													>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															version="1.1"
															viewBox="0 0 192.8 244.1"
															width={"10px"}
														>
															<g>
																<g id="Layer_1">
																	<path
																		fill="currentColor"
																		d="M179.4,85.3v120.5c0,15.3-12.4,27.8-27.8,27.8H40.4c-15.3,0-27.8-12.4-27.8-27.8V39.1c0-15.3,12.4-27.8,27.8-27.8h64.9c7.4,0,14.4,2.9,19.7,8.2l46.2,46.2c5.2,5.2,8.1,12.2,8.1,19.6ZM165.5,94.7h-48.6c-11.5,0-20.8-9.3-20.8-20.8V25.2h-55.6c-7.7,0-13.9,6.2-13.9,13.9v166.7c0,7.7,6.2,13.9,13.9,13.9h111.2c7.7,0,13.9-6.2,13.9-13.9v-111.2ZM61.3,122.5h69.5c3.8,0,6.9,3.1,6.9,6.9s-3.1,6.9-6.9,6.9H61.3c-3.8,0-6.9-3.1-6.9-6.9s3.1-6.9,6.9-6.9ZM137.7,157.2c0,3.8-3.1,6.9-6.9,6.9H61.3c-3.8,0-6.9-3.1-6.9-6.9s3.1-6.9,6.9-6.9h69.5c3.8,0,6.9,3.1,6.9,6.9ZM137.7,185c0,3.8-3.1,6.9-6.9,6.9H61.3c-3.8,0-6.9-3.1-6.9-6.9s3.1-6.9,6.9-6.9h69.5c3.8,0,6.9,3.1,6.9,6.9ZM109.9,73.8c0,3.8,3.1,6.9,6.9,6.9h47.7c-.7-2-1.7-3.8-3.2-5.3l-46.2-46.2c-1.5-1.5-3.3-2.5-5.3-3.2v47.7Z"
																	/>
																</g>
															</g>
														</svg>
													</a>
												)}
												{art.child_articles && art.child_articles.length > 0 ? (
													<button
														className="menu-link"
														onClick={() =>
															this.setState({
																previous_navigation:
																	this.state.previous_navigation.concat([
																		this.state.navigation
																	]),
																navigation: art
															})
														}
													>
														{art.title}
													</button>
												) : (
													<a
														className="menu-link"
														href={"/wiki/" + art.slug}
														style={{
															textTransform: "none",
															paddingRight: "3em"
														}}
													>
														{art.title}
													</a>
												)}
											</div>
										)
								)}
					</nav>
					<Router>
						<Switch>
							<Route path="/wiki/:slug">
								{this.state.settings && (
									<Article
										departments={departments}
										editMode={this.state.editMode}
										user={this.state.user}
										articles={this.state.articles}
										tags={this.state.settings.tags}
										editors={this.state.settings.editors}
										managers={this.state.settings.managers}
										getColor={this.getColor}
										toSlug={this.toSlug}
										generateDocumentOutline={this.generateDocumentOutline}
									/>
								)}
							</Route>
							<Route path="/wiki/">
								<div className={"body-wrapper"}>
									<header className="wiki-header">
										<nav className="search-wrapper">
											<div className="sort-wrapper">
												<label htmlFor="sortList">Sort</label>
												<select
													name="sort"
													value={this.state.sort}
													id="sortList"
													onChange={this.handleFormChange}
												>
													<option value="default">Best Match</option>
													<option value="title">Title Search</option>
													<option value="most-viewed">Most Viewed</option>
													{localStorage["wikiHistory"] && (
														<option value="recently-viewed">Recently Viewed</option>
													)}
													<option value="new">Newest</option>
													<option value="recently-updated">Recently Updated</option>
													<option value="alpha-asc">A-Z</option>
													<option value="alpha-desc">Z-A</option>
												</select>
											</div>
											<div className="filter-wrapper">
												<label htmlFor="filterList">Filter</label>
												<select
													name="filter"
													value={this.state.filter}
													id="filterList"
													onChange={this.handleFormChange}
												>
													{this.categories.map((c) => (
														<option key={c} value={c}>
															{c}
														</option>
													))}
													{Object.keys(departments).map((c) => (
														<option key={c} value={c}>
															{c}
														</option>
													))}
												</select>
											</div>

											<div className="input-wrapper">
												<label htmlFor="wikiSearch">Search</label>
												<input
													list="tags"
													type="search"
													placeholder="Enter search term"
													id="wikiSearch"
													name="search"
													defaultValue={this.state.search}
													onChange={(e) => {
														if (e.target.value === "") {
															this.updateArticleList();
														}
													}}
													onKeyDown={(e) => e.key === "Enter" && this.updateArticleList()}
													autoFocus={true}
												/>
												<button
													onClick={() => this.updateArticleList()}
													className="btn"
													style={{
														fontSize: "1em",
														padding: "1em",
														backgroundImage: "var(--icon-search-lg)",
														border: "none",
														backgroundRepeat: "no-repeat"
													}}
													title="Search"
												></button>
											</div>

											{this.state.articles.length > 0 && (
												<div className="article-count">
													Showing {this.state.articlesVisible.length} of{" "}
													{this.state.articles.length}
												</div>
											)}
										</nav>
									</header>

									<main className="article-list">
										{!this.state.articlesVisible || this.state.articlesVisible.length === 0 ? (
											<div>
												{this.state.articles.length > 0 && (
													<p
														style={{
															textAlign: "center",
															fontStyle: "italic",
															marginBottom: "1em"
														}}
													>
														- No matching articles -
													</p>
												)}
												{this.state.nonExactMatches.length > 0 && (
													<div style={{ opacity: "0.75" }}>
														<h4>Partial Matches</h4>
														<div className="article-count">
															Showing {this.state.nonExactMatches.length} of{" "}
															{this.state.articles.length}
														</div>
														{this.state.nonExactMatches.map((a) =>
															this.renderArticlePreview(a, true)
														)}
													</div>
												)}
											</div>
										) : (
											this.state.articlesVisible.map((a) => this.renderArticlePreview(a))
										)}
										<datalist id="tags">
											{this.state.settings &&
												this.state.settings.tags.map((t) => (
													<option
														key={t}
														value={t}
														className={t.indexOf(".") !== -1 ? "disabled" : ""}
													>
														{t}
													</option>
												))}
										</datalist>
									</main>
								</div>
							</Route>
							<Route path="*">
								<p>Route not found</p>
							</Route>
						</Switch>
					</Router>
				</WikiWrapperEl>
			</React.Fragment>
		);
	}
}

export default withRouter(withAuth(WikiWrapper));

// Resources
import React, { Component } from "react";
import Moment from "react-moment";
import "moment-timezone";

// Styles
import { CompanyWrapperEl } from "./styles/CompanyWrapper";

// Images
import logo from "../../images/logo.svg";

class CompanyWrapper extends Component {
	_generateTimeStyles = () => {
		return "green";
	};

	_handleAdminClick = () => {
		if (this.props.company.adminId) {
			this.props.handleAdminClick(this.props.company.adminId);
		} else {
			alert("Admin ID not set. Click 'Edit Company' to add the corresponding Admin ID for this company.");
		}
	};

	_handleDeleteClick = () => {
		if (this.props.company._id) {
			this.props.handleDeleteClick(this.props.company._id);
		}
	};

	componentDidMount() {
		window.addEventListener("load", this._handleLoad);
	}

	componentWillUnmount() {
		window.removeEventListener("load", this._handleLoad);
	}

	_handleLoad = () => {
		setTimeout(() => {
			this.props.setHeight("company", this.companyWrapper.clientHeight + 125);
		}, 200);
	};

	render() {
		Moment.globalTimezone = Intl.DateTimeFormat()["resolvedOptions"]()["timeZone"];
		return (
			<React.Fragment>
				<CompanyWrapperEl ref={(ref) => (this.companyWrapper = ref)}>
					{this.props.company.title && (
						<div className="info-wrapper">
							{this.props.company.favicon && (
								<img className="favicon" src={this.props.company.favicon} alt={"Company Icon"} />
							)}
							<div className="company-details-wrapper">
								<p className="title">{this.props.company.title}</p>
								<a className="url" href={this.props.company.url} target="_blank" rel="noreferrer">
									{this.props.company.host}
								</a>
								<div className={"status-wrapper " + this.props.company.status}>
									<div className="status-main-wrapper">
										<div className={"status-dot"} />
										<p>{this.props.company.status}</p>
									</div>
									{this.props.company.adminId && (
										<div className="status-icon-wrapper noselect admin">
											<a
												href={
													"https://www2.upsellit.com/admin/control/edit/company.jsp?companyID=" +
													this.props.company.adminId
												}
												target="_blank"
												rel="noreferrer"
												className="btn btn-block"
											>
												<img src={logo} alt={"logo"} className={"icon"} />
												Admin - {this.props.company.adminId} &#x02197;
											</a>
										</div>
									)}
									{this.props.company.salesforceId && (
										<div className="status-icon-wrapper noselect salesforce">
											<a
												href={
													"https://upsellit.lightning.force.com/lightning/r/Account/" +
													this.props.company.salesforceId +
													"/view"
												}
												target="_blank"
												rel="noreferrer"
												className="btn btn-block"
											>
												<div className="icon" />
												Salesforce &#x02197;
											</a>
										</div>
									)}
								</div>
								<p className="created">
									Last updated{" "}
									<span className={this._generateTimeStyles(new Date(this.props.company.updatedAt))}>
										<Moment fromNow>{this.props.company.updatedAt}</Moment>
									</span>
								</p>
								<p className="created">
									Created by <span className="name">{this.props.company.createdBy}</span> on{" "}
									<Moment format="llll" className="date">
										{this.props.company.createdAt}
									</Moment>
								</p>
								{this.props.company.description && <p className="dot">•</p>}
								<p className="desc">{this.props.company.description}</p>
							</div>
						</div>
					)}
					{this.props.company.title && (
						<div className="company-btn-wrapper">
							<div className="btn-wrapper">
								<button className="btn btn-block" onClick={this.props.handleEditCompany}>
									Edit Company
								</button>
							</div>
							<div
								className={
									"btn-wrapper delete" +
									(this.props.company._forms && this.props.company._forms.length === 0
										? ""
										: " inactive")
								}
							>
								<button className="btn btn-block" onClick={this._handleDeleteClick}>
									Delete Company
								</button>
							</div>
						</div>
					)}
				</CompanyWrapperEl>
			</React.Fragment>
		);
	}
}

export default CompanyWrapper;

import styled from "styled-components";

export const NavWrapperEl = styled.div`
    display: block;
    position: relative;
    padding-top: 10px;

    & > p {
        color: white;
    }
    
`;

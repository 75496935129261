// Resources
// noinspection JSValidateTypes,RequiredAttributes

// Resources
import React, { Component } from "react";

// Auth
import AuthHelperMethods from "../auth/AuthHelperMethods";
import withAuth from "../auth/withAuth";

// Styles
import { MjmlWrapperEl } from "./styles/MjmlWrapper";

import CodeMirror from "@uiw/react-codemirror";
import { xml } from '@codemirror/lang-xml';
import { darcula } from "@uiw/codemirror-theme-darcula";
const default_mjml = `<mjml>
<mj-head>
	<!--Optional title element-->
	<mj-title></mj-title>
	<!--Optional hidden preview text-->
	<mj-preview></mj-preview>
	<!--Fix for iphone 10-->
	<mj-raw><meta name="x-apple-disable-message-reformatting" /></mj-raw>
	<!--Set a custom breakpoint for the mobile size-->
	<mj-breakpoint width="480px" />
	<mj-font name="Open Sans" href="https://fonts.googleapis.com/css?family=Open+Sans" />
	<mj-attributes>
		<!--Any default attribute can be overwritten here-->
		<mj-text align="center" color="#333333" font-size="18px" line-height="140%" />
		<mj-body background-color="#eeeeee" />
		<mj-section background-color="#ffffff" />
		<mj-all font-family="Open Sans, Helvetica, Arial, sans-serif" />
	</mj-attributes>
	<mj-style inline="inline">
		/*Fix so that alt text shows for images*/
		img {
			color: #000000;
			font-size: 14px;
			line-height: 1;
			text-align: center;
		}
		.product-price div {
			font-size: 12px !important;
			text-align:right !important;
		}
		.product-name div {
			font-size: 12px !important;
		}
	</mj-style>
</mj-head>
<mj-body>
	
	<mj-section padding="0">
		<mj-column>
			<mj-image alt="" src="https://plchldr.co/i/600x300?text=hero&bg=000" href="{{LINK1}}" padding="0" />
		</mj-column>
	</mj-section>

	<mj-section>
		<mj-column>
			<mj-text align="left">
				<p>Hi {{NAME}},</p>
				<p><a href="{{LINK1}}">Return to our site</a> to complete your purchase.</p>
				<p>Sincerely,<br/>
				<b>COMPANY</b></p>
			</mj-text>
			<mj-divider border-width="1px" border-color="#ccc"></mj-divider>
			<mj-button background-color="#333" href="{{LINK1}}">Checkout Now</mj-button>
		</mj-column>
	</mj-section>
	
	<mj-section background-color="#eee">
		<mj-column>
			<mj-text align="center">
				<a href="" style="text-decoration: none;padding: 15px;">
					<img alt="" src="https://plchldr.co/i/25?text=S&bg=000" width="25px" />
				</a>
				<a href="" style="text-decoration: none;padding: 15px;">
					<img alt="" src="https://plchldr.co/i/25?text=S&bg=000" width="25px" />
				</a>
				<a href="" style="text-decoration: none;padding: 15px;">
					<img alt="" src="https://plchldr.co/i/25?text=S&bg=000" width="25px" />
				</a>
				<a href="" style="text-decoration: none;padding: 15px;">
					<img alt="" src="https://plchldr.co/i/25?text=S&bg=000" width="25px" />
				</a>
			</mj-text>
		</mj-column>
	</mj-section>

	<mj-section background-color="#eeeeee" padding="0">
		<mj-column>
			<mj-text font-size="12px" css-class="footer_info">
				<p>&copy; {{date_now:yyyy}} COMPANY | ADDRESS<br />
				<a href="https://www.upsellit.com/hound/nomore.jsp?{{SESSIONID}}" style="color:#333">Unsubscribe or Delete Data</a></p>
			</mj-text>
		</mj-column>
	</mj-section>

</mj-body>
</mjml>`;
class MjmlWrapper extends Component {
	Auth = new AuthHelperMethods();

	constructor(props) {
		super(props);
		this.state = {
			html: "",
			view: "",
			mjml: ""
		};
	}

	view = (event) => {
		this.setState({ view: event.target.getAttribute("data-type") });
	};

	clear = () => {
		this.setState({ mjml: "", html: "" });
	};

	convertMjml = (data) => {
		if (!data) {
			return this.setState({ html: "" });
		}
		this.Auth.convertMjml({ data: data })
			.then((res) => {
				if (res.error) alert(res.error);
				this.setState({ html: res.data.html });
			})
			.catch((err) => {
				console.log("[ ExampleWrapper - convertMjml ] POST error:", err);
			});
	};

	handleChange = (value) => {
		this.setState({ mjml: value });
		this.setState({ html: this.convertMjml(value) });
	};

	componentDidUpdate() {
		const mjml = this.props.value || default_mjml;
		if (mjml !== this.state.mjml) {
			this.setState({
				mjml: mjml,
				html: this.convertMjml(mjml)
			});
		}
	}

	render() {
		return (
			<React.Fragment>
				<MjmlWrapperEl>
					{/* <nav>
						<a href="https://mjml.io/resources" target="_blank" rel="noreferrer">
							MJML Resources
						</a>
					</nav> */}
					<main>
						{/* <div>
							<h1>Ideas for this page</h1>
							<p>- Create form with options for MJML that generates code</p>
							<p>- Integrate with EmailOnAcid</p>
							<p>- Toggle left or right view</p>
							<p>- Show image library (company images)</p>
							<p>- Insert snippets</p>
							<p>- Check for spammy words and phrases</p>
							<p>- Spellcheck</p>
							<p>- Report missing alt tags, accessibility issues</p>
							<p>- Convert unicode characters</p>
							<p>- mjml2html(inputMJML, { validationLevel: 'strict' }) //https://documentation.mjml.io/#inside-node-js</p>
							<p>- Drag and drop block editor like https://www.mailjet.com/demo/ or https://brooksr.github.io/design_tool/</p>
							<p>- Show MJML tips or documentation</p>
						</div> */}
						<div className="flex-row">
							<div className="code-container">
								<h2>Code</h2>
								{/* <div className="button-group">
									<button onClick={this.clear}>Clear</button>
									<button>Beautify MJML</button>
								</div> */}
								<div className="code-editor">
									<CodeMirror
										value={this.state.mjml}
										onChange={this.handleChange}
										extensions={[xml({})]}
										theme={darcula}
										readOnly={true}
									/>
								</div>
							</div>
							<div className="preview-container">
								<h2>Preview</h2>
								{/* <div className="button-group">
									<button onClick={this.view} data-type="html">HTML</button>
									<button>Hide Images (alt text test)</button>
									<button>Copy HTML</button>
									<button>Send Test</button>
									<button>EmailOnAcid</button>
								</div> */}
								<div className="button-group">
									<button onClick={this.view} data-type="desktop">
										Desktop
									</button>
									<button onClick={this.view} data-type="mobile">
										Mobile
									</button>
								</div>
								<div className="iframe-container">
									<iframe
										title="Email Preview"
										srcDoc={this.state.html}
										style={{
											width: this.state.view === "mobile" ? "400px" : "100%"
										}}
									/>
								</div>
							</div>
						</div>
					</main>
				</MjmlWrapperEl>
			</React.Fragment>
		);
	}
}

export default withAuth(MjmlWrapper);

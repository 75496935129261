import styled from "styled-components";
export const ActivityWrapperEl = styled.div`
	display: block;
	position: relative;
	font-size: 1em;
	& .body-wrapper {
		position: absolute;
		top: 60px;
		padding: 4em 4em 4em 19em;
		text-align: center;
	}
	& .dashboard-column {
		display: inline-block;
		vertical-align: top;
		min-height: 500px;
		position: relative;
		width: calc(50% - 20px);
		margin: 0 5px 15px 5px;
		max-width: 400px;
		min-width: 200px;
	}
	& .dashboard-column h1 {
		color: var(--txt-lg);
		font-size: 14px;
		margin-bottom: 25px;
		vertical-align: top;
	}
	& .dashboard-column h1 span {
		font-size: 13px;
	}
	& .dashboard-column h1 img {
		height: 18px;
		width: 18px;
		margin: 0 8px 0 0;
		vertical-align: top;
	}
	& .dashboard-column h1 img.activity {
		background-image: var(--icon-activity-lg);
	}
	& .dashboard-column h1 img.globe {
		background-image: var(--icon-globe-lg);
	}
	& .dashboard-column .section-prompt {
		color: var(--txt-lg);
		text-align: center;
		font-size: 12px;
		margin-top: 10px;
	}
	& .activity-row {
		display: block;
		margin: 10px 0;
		background: var(--bg-d2);
		border-radius: 3px;
		border: 1px solid transparent;
		position: relative;
	}
	& .activity-row.create {
		border-left: 3px solid var(--green);
	}
	& .activity-row.update {
		border-left: 3px solid var(--blue);
	}
	& .activity-row.delete {
		border-left: 3px solid var(--red);
	}
	& .activity-row.archive {
		border-left: 3px solid var(--orange);
	}
	& .activity-row .icon-wrapper {
		display: inline-block;
		vertical-align: top;
	}
	& .activity-row .icon {
		width: 18px;
		height: 18px;
		margin-top: 10px;
	}
	& .activity-row .icon.activity {
		background-image: var(--icon-activity-l);
	}
	& .activity-row .icon.company {
		background-image: var(--icon-company-l);
	}
	& .activity-row .icon.profile {
		background-image: var(--icon-profile-l);
	}
	& .activity-row .icon.form {
		background-image: var(--icon-form-l);
	}
	& .activity-row .text {
		padding: 8px 5px 8px 5px;
		height: auto !important;
		display: inline-block;
		vertical-align: top;
		width: calc(100% - 60px);
		text-align: left;
		margin-left: 5px;
	}
	& .activity-row .title {
		font-size: 12px;
		color: var(--txt-lg);
	}
	& .activity-row .title span {
		color: var(--txt-lg);
	}
	& .activity-row .title span.name {
		color: white;
	}
	& .activity-row .title span.green {
		color: var(--green);
	}
	& .activity-row .title span.yellow {
		color: var(--yellow);
	}
	& .activity-row .title span.orange {
		color: var(--orange);
	}
	& .activity-row .link {
		text-decoration: underline;
		display: inline-block;
		transition: color 0.05s, -moz-transform ease-in-out 0.05s;
	}
	& .activity-row .link:hover {
		cursor: pointer;
		color: white;
	}
	@media screen and (max-width: 1200px) {
		& .dashboard-column {
			width: 100%;
			display: block;
			margin: 25px auto 0 auto;
		}
		.body-wrapper {
			padding: 4em 2em 6em 5em !important;
		}
	}
`;

// Resources
import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

// Components
import AuthHelperMethods from "../auth/AuthHelperMethods";
import ErrorBoundary from "./ErrorBoundary";

// Styles
import { SalesforceWrapperEl } from "./styles/SalesforceWrapper";

const mapStateToProps = function (state) {
	return state;
};

class SalesforceWrapper extends Component {
	Auth = new AuthHelperMethods();
	constructor(props) {
		super(props);
		this.state = {
			records: [],
		};
	}

	componentDidMount() {
		this.Auth.querySalesforce(this.props.q)
			.then((res) => {
				console.log(res);
				if (this.props.setSalesforceRecords) {
					this.props.setSalesforceRecords(res.data.records);
				}
				this.setState({
					records: res.data.records
				});
			})
			.catch((err) => {
				console.log("[ componentDidMount ] POST error:", err);
				//this.Auth.logout();
				//this.props.history.replace('/login');
			});
	}

	render() {
		return (
			<ErrorBoundary>
				<SalesforceWrapperEl 
				style={{ opacity: this.state.records.length > 0 ? "1" : "0" }}>
					{this.props.type === 'details' && <dl>
						{this.state.records
						.sort((a, b) => (a.Name < b.Name ? -1 : 1))
						.map((record) => {
							return Object.keys(this.props.columns).map((c, i) => <div key={i}>
							<dt>{c.replace('__c', '').replace(/_/g, ' ')}</dt>
							<dd>{typeof this.props.columns[c] === 'function' ? this.props.columns[c](record) : record[c]}</dd>
						</div>)
						})}
					</dl>}

					{this.props.type === 'table' && this.state.records.length > 0 && <table>
						{this.props.header !== false && (
							<thead>
								<tr>
									{Object.keys(this.props.columns).map((head, i) => (
										<th key={i}>{head}</th>
									))}
								</tr>
							</thead>
						)}
						<tbody>
							{this.state.records
								.sort((a, b) => (a.Name < b.Name ? -1 : 1))
								.map((record) => {
									return (
										<tr key={record.Id}>
											{Object.values(this.props.columns).map((c, i) => (
												<td key={i}>{c(record)}</td>
											))}
										</tr>
									);
								})}
						</tbody>
					</table>}
				</SalesforceWrapperEl>
			</ErrorBoundary>
		);
	}
}

export default connect(mapStateToProps)(SalesforceWrapper);

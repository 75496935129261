// Resources
import React, { Component } from "react";

// Styles
import { ProfileWrapperEl } from "./styles/ProfileWrapper";

// Images
import anon from "../../images/icons/anon.svg";

class ProfileWrapper extends Component {
	_handleSudoBadgeClicked = () => {
		if (this.props.user.type === "sudo" && typeof this.props.printRooms === "function") {
			this.props.printRooms();
		}
	};

	render() {
		const src = this.props.user.picture ? this.props.user.picture : anon;
		return (
			<React.Fragment>
				<ProfileWrapperEl>
					<div className={"profile-img-body-wrapper " + this.props.page}>
						{this.props.user.email && (
							<div className="profile-img-wrapper">
								<img className="profile-img" src={src} alt="profile_img" />
							</div>
						)}
					</div>
					<div className={"profile-info-body-wrapper noselect " + this.props.page}>
						<div className="name-wrapper">
							<p>
								{this.props.user.given_name} {this.props.user.family_name}
							</p>
							{(this.props.user.type === "sudo" || this.props.user.type === "admin") && (
								<div
									className={"admin-badge " + this.props.user.type}
									onClick={this._handleSudoBadgeClicked}
								>
									{this.props.user.type}
								</div>
							)}
						</div>
						<div className="email-wrapper">
							<p>{this.props.user.email}</p>
						</div>
					</div>
				</ProfileWrapperEl>
			</React.Fragment>
		);
	}
}

export default ProfileWrapper;

const company = (state = {}, action) => {
	let newState = state;
	switch (action.type) {
		case "COMPANY":
			return action.company;
		case "UPDATE_COMPANY_TITLE":
			newState.title = action.title;
			return newState;
		default:
			return state;
	}
};

export default company;

const nav = (state = {}, action) => {
	switch (action.type) {
		case "NAV":
			return {
				nav: action.nav,
				page: action.page
			};
		case "PAGE":
			return action.page;
		default:
			return state;
	}
};

export default nav;

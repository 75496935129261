// Resources
import React, { Component } from "react";
import queryString from "query-string";

class ImagePage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			url: "",
			errorMessage: ""
		};
	}

	componentDidMount() {
		// Init cookies
		// noinspection JSValidateTypes
		const params = queryString.parse(this.props.history.location.search);
		const fileKey = params && params.key ? params.key : "";
		if (fileKey) {
			console.log("[ Image - componentDidMount ] fileKey:", fileKey);
			this.setState({
				url: document.location.protocol + "//" + document.location.host + "/s3/images/" + fileKey
			});
		} else {
			// No file key found
			this.setState({ errorMessage: "404 Image Not Found" });
		}
	}

	render() {
		return (
			<React.Fragment>
				<div id="img">
					{this.state.errorMessage && (
						<div className="error-wrapper">
							<h1>OOF!</h1>
							<h2>{this.state.errorMessage}</h2>
						</div>
					)}
					{this.state.url && (
						<div className={"img-wrapper"}>
							<img src={this.state.url} alt={this.state.url} />
						</div>
					)}
				</div>
			</React.Fragment>
		);
	}
}

export default ImagePage;

// Resources
import React, { Component } from "react";
import Moment from "react-moment";
import "moment-timezone";
import Switch from "react-switch"; // https://reactjsexample.com/draggable-toggle-switch-component-for-react/

// Styles
import { FormWrapperEl } from "./styles/FormWrapper";

class FormWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toggle_reader: false,
			toggle_show: false
		};
	}

	componentDidMount() {
		window.addEventListener("load", this._handleLoad);
		this.setState({
			toggle_reader: this.props.reader,
			toggle_show: this.props.show
		});
	}

	componentWillUnmount() {
		window.removeEventListener("load", this._handleLoad);
	}

	_handleLoad = () => {
		setTimeout(() => {
			this.props.setHeight("form", this.formWrapper.clientHeight + 125);
		}, 200);
	};

	_toggleChanged = (checked, e, id) => {
		this.setState(
			{
				["toggle_" + id]: !!checked
			},
			function () {
				this.props.handleSidebarToggleChanged(id, checked);
			}
		);
	};

	renderToggle(id, label, label2) {
		return (
			<div className={"toggle-switch-wrapper"}>
				<label htmlFor="normal-switch">
					<Switch
						onChange={this._toggleChanged}
						checked={!!this.state["toggle_" + id]}
						id={id}
						height={20}
						width={40}
						className="toggle-switch"
						onColor="#1f85de"
						offColor="#9eb2c6"
					/>
					<p className="toggle-label">{!!this.state["toggle_" + id] ? label : label2}</p>
				</label>
			</div>
		);
	}

	render() {
		Moment.globalTimezone = Intl.DateTimeFormat()["resolvedOptions"]()["timeZone"];
		return (
			<React.Fragment>
				<FormWrapperEl ref={(ref) => (this.formWrapper = ref)}>
					<div className="info-wrapper">
						{this.props.form && this.props.form._company && (
							<div className="company-wrapper">
								{typeof this.state.toggle_reader !== "undefined" &&
									typeof this.state.toggle_show !== "undefined" && (
										<div className={"company-toggle-wrapper"}>
											{this.renderToggle("reader", "Reader Mode", "Edit Mode")}
											{/* {this.renderToggle("show", "All Fields", "Suggested Fields")} */}
										</div>
									)}
							</div>
						)}
					</div>
				</FormWrapperEl>
			</React.Fragment>
		);
	}
}

export default FormWrapper;

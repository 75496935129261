import styled from "styled-components";

export const FormWrapperEl = styled.div`
	display: block;
	position: relative;
	font-size: 1em;

	& .company-wrapper {
		padding: 25px 20px 20px 20px;
	}

	& .company-wrapper .sidebar-btn {
		display: block;
		margin-bottom: 25px;
		background: var(--bg-d2);
		border: 1px solid var(--bg-d2);
		height: 34px;
		border-radius: 16px;
		text-align: center;
	}

	& .company-wrapper .sidebar-btn .icon {
		display: inline-block;
		vertical-align: top;
		background-repeat: no-repeat;
		background-size: contain;
		pointer-events: none;
	}

	.company-wrapper .sidebar-btn:hover {
		cursor: pointer;
	}

	& .company-wrapper .sidebar-btn .icon.copy {
		background-image: var(--icon-link-l);
		height: 18px;
		width: 18px;
		margin-top: 7px;
		margin-right: 7px;
	}

	& .company-wrapper .sidebar-btn .mask {
		position: absolute;
		top: 1px;
		left: 1px;
		height: calc(100% - 2px);
		width: calc(100% - 2px);
		background: rgba(0, 0, 0, 0.85);
		border-radius: 16px;
		z-index: 9;
		pointer-events: none;
	}

	& .company-wrapper .sidebar-btn p {
		display: inline-block;
		vertical-align: top;
		font-size: 12px;
		color: rgba(255, 255, 255, 1);
		pointer-events: none;
		position: relative;
		z-index: 10;
	}

	.company-wrapper .sidebar-btn:hover > p {
		color: white;
	}

	& .sidebar-btn.gradient-border {
		--border-width: 1px;
		border: 1px solid transparent;
		position: relative;
		background: var(--bg-d2);
		border-radius: 17px;

		&::after {
			position: absolute;
			content: "";
			top: calc(-1 * var(--border-width));
			left: calc(-1 * var(--border-width));
			z-index: -1;
			width: calc(100% + var(--border-width) * 2);
			height: calc(100% + var(--border-width) * 2);
			background: linear-gradient(
				60deg,
				hsl(224, 85%, 66%),
				hsl(269, 85%, 66%),
				hsl(314, 85%, 66%),
				hsl(359, 85%, 66%),
				hsl(44, 85%, 66%),
				hsl(89, 85%, 66%),
				hsl(134, 85%, 66%),
				hsl(179, 85%, 66%)
			);
			background-size: 300% 300%;
			background-position: 0 50%;
			border-radius: 17px;
			animation: moveGradient 4s alternate infinite;
			box-shadow: 0 0 12px 0 rgba(8, 19, 28, 0.55);
		}
	}

	@keyframes moveGradient {
		50% {
			background-position: 100% 50%;
		}
	}

	& .sidebar-btn.gradient-border:hover {
		&::after {
			z-index: 1;
		}
	}

	& .company-wrapper .sidebar-btn.green.gradient-border {
		&::after {
			z-index: -1;
		}
	}

	& .company-toggle-wrapper {
		padding: 0 5px;
	}

	& .company-toggle-wrapper .toggle-switch-wrapper {
		width: 100%;
		margin-bottom: 10px;
	}

	& .company-toggle-wrapper .toggle-switch-wrapper .toggle-label {
		display: inline-block;
		vertical-align: top;
		color: white;
		font-size: 12px;
	}

	& .company-toggle-wrapper .toggle-switch {
		margin: 0 12px 0 0;
	}

	@media screen and (max-width: 1200px) {
		& .company-toggle-wrapper .toggle-switch-wrapper {
			position: relative;
			left: -5px;
		}

		& .company-wrapper .sidebar-btn {
			display: none;
		}

		& .company-wrapper .sidebar-btn .icon.copy {
			margin-right: 1px;
		}
	}
`;

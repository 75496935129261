// noinspection HttpUrlsUsage
// Resources
import React, { Component } from "react";
import TextareaAutosize from "react-textarea-autosize";

// Components
import TopNavWrapper from "./TopNavWrapper";

// Styles
import { LegacyWrapperEl } from "./styles/LegacyWrapper";

// Globals
let sectionOrder = [
	"Account",
	"Solution Overview",
	"Incentive Type",
	"Conversion Tracking & Links", // Old
	"Tracking & Links",
	"Desktop Launch",
	"Mobile Launch",
	"Exposure", // Old
	"Engagement",
	"Modal Design",
	"Email",
	"Copy & Graphics",
	"QA & Dev",
	"Documents & Testing" // Old
];

const linkify = (inputText) => {
	if (inputText) {
		// noinspection JSUnusedLocalSymbols
		let replacedText, replacePattern1, replacePattern2;
		// URLs starting with http://, https://, or ftp://
		replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
		replacedText = inputText.replace(
			replacePattern1,
			'<!--suppress ALL --><a href="$1" target="_blank" rel="noreferrer">$1</a>'
		);
		// URLs starting with "www." (without // before it, or it'd re-link the ones done above).
		replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
		replacedText = replacedText.replace(
			replacePattern2,
			'$1<a href="http://$2" target="_blank" rel="noreferrer">$2</a>'
		);
		return replacedText;
	} else {
		return "";
	}
};

class LegacyWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editObj: {},
			legacyEditContent: "",
			allowLegacySave: false
		};
		this.cancelSave = false;
	}

	_handleGroupTitleClicked = (e) => {
		if (e && e.target && !this.state.editObj.id) {
			const id = e.target.getAttribute("data-id");
			const key = e.target.getAttribute("data-key");
			const sectionName = e.target.getAttribute("data-section");
			const content = e.target.getAttribute("data-content") || "";
			if (id && key && sectionName) {
				// Enter edit mode
				this.setState({
					editObj: {
						section: sectionName,
						id: id,
						key: key,
						content: content,
						legacyEditContent: content,
						allowLegacySave: false
					}
				});
			}
		}
	};

	_handleFieldTitleClicked = (e) => {
		if (e && e.target && !this.state.editObj.id) {
			const id = e.target.getAttribute("data-id");
			const field = e.target.getAttribute("data-field");
			const sectionName = e.target.getAttribute("data-section");
			if (id && sectionName) {
				// Find the content of this field
				let content = "";
				const fields = this.props.legacy.data.formData[sectionName];
				for (let i = 0; i < fields.length; i++) {
					if (fields[i]["fieldID"].toString() === id) {
						content = fields[i]["displayValue"];
						break;
					}
				}
				// Enter edit mode
				this.setState({
					editObj: {
						section: sectionName,
						id: id,
						field: field,
						key: undefined,
						content: content,
						legacyEditContent: content,
						allowLegacySave: false
					}
				});
			}
		}
	};

	renderSections(sectionName) {
		const fields = this.props.legacy.data.formData[sectionName];
		if (fields && fields.length > 0) {
			const sectionId = sectionName.toLowerCase().replaceAll(" ", "_");
			return (
				<div className="card section-wrapper" key={sectionId}>
					<div className="title-container">
						<h3>{sectionName}</h3>
					</div>
					<div className={"section-fields-wrapper"}>{this.renderFields(fields, sectionName)}</div>
				</div>
			);
		}
		return "";
	}

	_handleEditLegacyFieldChanged = (e) => {
		if (e && e.target) {
			this.setState({
				legacyEditContent: e.target.value,
				allowLegacySave: e.target.value !== this.state.editObj.content
			});
		}
	};

	_handleEditLegacyFieldBlur = () => {
		setTimeout(() => {
			// Save changes
			if (!this.cancelSave && this.state.allowLegacySave) {
				this.props.saveEditField({
					section: this.state.editObj.section,
					id: this.state.editObj.id,
					field: this.state.editObj.field,
					key: this.state.editObj.key,
					content: this.state.legacyEditContent
				});
			}
			// Exit edit mode
			this.setState(
				{
					editObj: {},
					legacyEditContent: "",
					allowLegacySave: false
				},
				function () {
					this.cancelSave = false;
				}
			);
		}, 100);
	};

	renderFields(fields, sectionName) {
		return fields.map((item) => {
			if (
				item["UIType"] === "multifield" &&
				item["afterData"] &&
				item["afterData"].items &&
				item["afterData"].items.length > 0
			) {
				return (
					<div className={"field-row"} key={item["fieldID"]}>
						<div className={"title-wrapper group"}>
							<p>{item["fieldLabel"]}</p>
						</div>
						<div className={"content-wrapper"}>
							{this.renderGroups(item["afterData"].items, sectionName)}
						</div>
					</div>
				);
			} else {
				const edit =
					this.state.editObj && this.state.editObj.id && this.state.editObj.id === item["fieldID"].toString();
				return (
					<div className={"field-row"} key={item["fieldID"]}>
						<div className={"title-wrapper"}>
							<p
								className={"title-wrapper-text"}
								data-field={item["fieldLabel"]}
								data-id={item["fieldID"]}
								data-section={sectionName}
								onClick={this._handleFieldTitleClicked}
							>
								{item["fieldLabel"]} &#x02198;
							</p>
						</div>

						{edit && (
							<div className={"content-wrapper-edit"}>
								<TextareaAutosize
									className={"edit-legacy-textarea" + (this.state.allowLegacySave ? " changed" : "")}
									ref={(ref) => ref && ref.focus()}
									onFocus={(e) =>
										e.currentTarget.setSelectionRange(
											e.currentTarget.value.length,
											e.currentTarget.value.length
										)
									}
									name={this.state.editObj.id}
									defaultValue={this.state.editObj.content}
									onKeyUp={this._handleEditLegacyFieldChanged}
									onPaste={this._handleEditLegacyFieldChanged}
									onBlur={this._handleEditLegacyFieldBlur}
								/>
								{this.state.allowLegacySave && (
									<div className={"cancel-edit"} onClick={() => (this.cancelSave = true)}>
										&#215;
									</div>
								)}
							</div>
						)}

						{!edit && (
							<div className={"content-wrapper"}>
								{/* CSD Type */}
								{item["fieldLabel"] === "Solution" && item.displayValue === "Targeted Tactic" && (
									<p className={"type tt"}>TT</p>
								)}
								{item["fieldLabel"] === "Solution" && item.displayValue === "Lead Capture" && (
									<p className={"type lc"}>LC</p>
								)}
								{item["fieldLabel"] === "Solution" && item.displayValue === "Email Remarketing" && (
									<p className={"type pc"}>PC</p>
								)}
								{item["fieldLabel"] === "Solution" &&
									(item.displayValue === "Survey" || item.displayValue === "Chat") && (
										<p className={"type sv"}>{item.displayValue}</p>
									)}
								{/* Regular Content */}
								{item["fieldLabel"] !== "Account Name" && item["fieldLabel"] !== "Opportunity Name" && (
									<p
										className={"dont-break-out"}
										dangerouslySetInnerHTML={{ __html: linkify(item.displayValue) }}
									/>
								)}
								{/* Account Name Link */}
								{item["fieldLabel"] === "Account Name" &&
									item["afterData"] &&
									item["afterData"].text &&
									item["afterData"].value && (
										<a
											className="sf-link"
											target="_blank"
											rel="noreferrer"
											href={
												"https://upsellit.lightning.force.com/lightning/r/Account/" +
												item["afterData"].value +
												"/view"
											}
										>
											{item["displayValue"]} &#x02197;
										</a>
									)}
								{/* Opportunity Name Link */}
								{item["fieldLabel"] === "Opportunity Name" &&
									item["afterData"] &&
									item["afterData"].text &&
									item["afterData"].value && (
										<a
											className="sf-opp-link"
											target="_blank"
											rel="noreferrer"
											href={
												"https://upsellit.lightning.force.com/lightning/r/Opportunity/" +
												item["afterData"].value +
												"/view"
											}
										>
											{item["displayValue"]} &#x02197;
										</a>
									)}
							</div>
						)}
					</div>
				);
			}
		});
	}

	renderGroups(items, sectionName) {
		return items.map((item) => {
			return (
				<div className={"group-row"} key={item.id}>
					<div className={"group-title-wrapper"}>{item.name}</div>
					<div className={"group-content-wrapper"}>
						{Object.keys(item.data).map((keyName) => {
							// Check edit
							const edit =
								this.state.editObj &&
								this.state.editObj.id &&
								this.state.editObj.id === item.id.toString() &&
								this.state.editObj.key === keyName;
							// Set display text
							let displayText = "";
							if (item.data[keyName]) {
								if (typeof item.data[keyName].displayValue !== "undefined") {
									displayText = item.data[keyName].displayValue;
								} else if (
									typeof item.data[keyName].units !== "undefined" &&
									typeof item.data[keyName].value !== "undefined"
								) {
									displayText = item.data[keyName].value + " " + item.data[keyName].units;
								} else if (typeof item.data[keyName].checked !== "undefined") {
									displayText = item.data[keyName].checked ? "Yes" : "No";
								} else if (typeof item.data[keyName].text !== "undefined") {
									displayText = item.data[keyName].text;
								} else if (typeof item.data[keyName].value !== "undefined") {
									displayText = item.data[keyName].value;
								}
							}
							return (
								<div className={"group-content-row"} key={keyName}>
									<div className={"title-wrapper"}>
										<p
											className={"title-wrapper-text"}
											data-id={item.id}
											data-key={keyName}
											data-section={sectionName}
											data-content={displayText}
											onClick={this._handleGroupTitleClicked}
										>
											{keyName} &#x02198;
										</p>
									</div>
									{edit && (
										<div className={"content-wrapper-edit"}>
											<TextareaAutosize
												className={
													"edit-legacy-textarea" +
													(this.state.allowLegacySave ? " changed" : "")
												}
												ref={(ref) => ref && ref.focus()}
												onFocus={(e) =>
													e.currentTarget.setSelectionRange(
														e.currentTarget.value.length,
														e.currentTarget.value.length
													)
												}
												name={this.state.editObj.id}
												defaultValue={this.state.editObj.content}
												onKeyUp={this._handleEditLegacyFieldChanged}
												onPaste={this._handleEditLegacyFieldChanged}
												onBlur={this._handleEditLegacyFieldBlur}
											/>
											{this.state.allowLegacySave && (
												<div className={"cancel-edit"} onClick={() => (this.cancelSave = true)}>
													&#215;
												</div>
											)}
										</div>
									)}
									{!edit && (
										<div className={"content-wrapper"}>
											<p
												className={"dont-break-out"}
												dangerouslySetInnerHTML={{ __html: linkify(displayText) }}
											/>
										</div>
									)}
								</div>
							);
						})}
					</div>
				</div>
			);
		});
	}

	componentDidMount() {
		setTimeout(() => {
			console.log("[ Body - LegacyWrapper - componentDidMount ] props:", this.props);
		}, 1000);
	}

	render() {
		return (
			<React.Fragment>
				<LegacyWrapperEl>
					<div className="header-wrapper legacy">
						<TopNavWrapper page={this.props.page} />
					</div>
					<div className="body-wrapper legacy">
						<div className="body-content-wrapper">
							{this.props.legacy && this.props.legacy.data && this.props.legacy.data.formData && (
								<div className={"fields-body-wrapper"}>
									{sectionOrder.map((sectionName) => {
										return (
											<div key={sectionName + "_wrapper"}>{this.renderSections(sectionName)}</div>
										);
									})}
								</div>
							)}
						</div>
					</div>
				</LegacyWrapperEl>
			</React.Fragment>
		);
	}
}

export default LegacyWrapper;

// Resources
import React, { Component } from "react";
import Moment from "react-moment";
import "moment-timezone";

// Styles
import { LegacyWrapperEl } from "./styles/LegacyWrapper";

class LegacyWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		setTimeout(() => {
			console.log("[ SideBar - LegacyWrapper ] props:", this.props);
		}, 1000);
	}

	render() {
		Moment.globalTimezone = Intl.DateTimeFormat()["resolvedOptions"]()["timeZone"];
		return (
			<React.Fragment>
				<LegacyWrapperEl>
					<div className="info-wrapper">
						{this.props.legacy && this.props.legacy.data && this.props.legacy.data.companyName && (
							<div className="section-wrapper">
								<p>Company Name</p>
								<h4 className="dont-break-out">{this.props.legacy.data.companyName}</h4>
							</div>
						)}
						{this.props.legacy && this.props.legacy.data && this.props.legacy.data.title && (
							<div className="section-wrapper">
								<p>CSD Title</p>
								<h4 className="dont-break-out">{this.props.legacy.data.title}</h4>
							</div>
						)}
						{this.props.legacy && this.props.legacy.data && this.props.legacy.data.type && (
							<div className="section-wrapper">
								<p>CSD Type</p>
								<h4 className="dont-break-out">{this.props.legacy.data.type}</h4>
							</div>
						)}
						{this.props.legacy && this.props.legacy.data && this.props.legacy.data.username && (
							<div className="section-wrapper">
								<p>Created By</p>
								<h4 className="dont-break-out">{this.props.legacy.data.username}</h4>
							</div>
						)}
						{this.props.legacy && this.props.legacy.data && this.props.legacy.data.createdDateUTC && (
							<div className="section-wrapper">
								<p>Created At</p>
								<h4 className="dont-break-out">
									<Moment format={"M/D/YY, h:mm a"}>{this.props.legacy.data.createdDateUTC}</Moment> •{" "}
									<Moment fromNow>{this.props.legacy.data.createdDateUTC}</Moment>
								</h4>
							</div>
						)}
						{this.props.legacy && this.props.legacy.data && this.props.legacy.data.lastUpdatedBy && (
							<div className="section-wrapper">
								<p>Last Updated By</p>
								<h4 className="dont-break-out">{this.props.legacy.data.lastUpdatedBy}</h4>
							</div>
						)}
						{this.props.legacy && this.props.legacy.data && this.props.legacy.data.lastUpdatedBy && (
							<div className="section-wrapper">
								<p>Last Updated</p>
								<h4 className="dont-break-out">
									<Moment format={"M/D/YY, h:mm a"}>{this.props.legacy.data.updatedAt}</Moment> •{" "}
									<Moment fromNow>{this.props.legacy.data.updatedAt}</Moment>
								</h4>
							</div>
						)}
					</div>
				</LegacyWrapperEl>
			</React.Fragment>
		);
	}
}

export default LegacyWrapper;

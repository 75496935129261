// Resources
import React, { Component } from "react";
import Moment from "react-moment";
import "moment-timezone";
import "react-confirm-alert/src/react-confirm-alert.css";

// Components
import AuthHelperMethods from "../auth/AuthHelperMethods";
import TopNavWrapper from "./TopNavWrapper";

// Styles
import { DashboardWrapperEl, GlobalStyle } from "./styles/DashboardWrapper";

// Images
import clear from "../../images/icons/v1/clear.svg";

class DashboardWrapper extends Component {
	Auth = new AuthHelperMethods();

	constructor(props) {
		super(props);
		this.state = {
			// Titles
			searchTitle: "",
			searchPlaceholder: "",
			addTitle: "",
			// Companies
			companiesSearch: [],
			companiesHistory: [],
			companiesLatest: [],
			companyCount: -1,
			// Legacy
			legacySearch: [],
			legacyHistory: [],
			// Search
			search: "",
			searchSiteID: "",
			typing: false,
			displaySearchLoader: false,
			typingTimeout: 0,
			searchPrompt: "",
			historyPrompt: "",
			activityPrompt: "",
			// Misc
			showDisplay: true,
			slideUp: false
		};
		this._changeSearch = this._changeSearch.bind(this);
	}

	initFetch = () => {
		this.Auth.getCompaniesInit()
			.then((res) => {
				if (res.error) {
					console.error(res.error);
				} else if (res.companies && res.histories) {
					const sortedHistories = res.histories.sort((a, b) => b.lastUpdated - a.lastUpdated);
					this.setState(
						{
							search: this.props.search,
							companiesLatest: res.companies,
							companyCount: res.company_count,
							activityPrompt:
								res.companies.length === 0 ? "Newly created companies will be listed here" : "",
							companiesHistory: sortedHistories,
							historyPrompt:
								res.histories.length === 0
									? "Company pages you've visited recently will be listed here"
									: ""
						},
						function () {
							if (this.state.search !== "") {
								this.searchFetch();
							}
						}
					);
				} else {
					console.error("[ initFetch ] ERROR - Missing res data:", res);
				}
			})
			.catch((err) => {
				console.log("[ initFetch ] POST error:", err);
			});
	};

	fetchMore = () => {
		if (this.state.companyCount !== -1) {
			// Fetch more companies
			this.Auth.getMoreCompanies({
				loaded: this.state.companiesLatest.length
			})
				.then((res) => {
					if (res.error) {
						console.error(res.error);
					} else if (res.companies) {
						this.setState({
							companiesLatest: this.state.companiesLatest.concat(res.companies)
						});
					} else {
						console.error("[ fetchMore ] ERROR - Missing res data:", res);
					}
				})
				.catch((err) => {
					console.log("[ fetchMore ] POST error:", err);
				});
		}
	};

	initFetchLegacy = () => {
		this.Auth.getLegacyInit()
			.then((res) => {
				if (res.error) {
					console.error(res.error);
				} else if (res.histories) {
					const sortedHistories = res.histories.sort((a, b) => b.lastUpdated - a.lastUpdated);
					console.log("[ initFetchLegacy ] sortedHistories:", sortedHistories);
					this.setState({
						legacyHistory: sortedHistories,
						historyPrompt:
							res.histories.length === 0 ? "Legacy CSDs you've visited recently will be listed here" : ""
					});
				} else {
					console.error("[ initFetchLegacy ] ERROR - Missing res data:", res);
				}
			})
			.catch((err) => {
				console.error("[ initFetchLegacy ] POST error:", err);
			});
	};

	searchFetch = () => {
		if (this.props.page === "app" && this.props.nav === "dashboard") {
			this.Auth.getCompaniesSearch({ search: this.state.search })
				.then((res) => {
					this.setState(
						{
							displaySearchLoader: false,
							companiesSearch: res,
							searchPrompt: res.length === 0 ? "No results found" : ""
						},
						function () {
							if (this.props.search !== "" && res.length === 1) {
								this.props.handleCompanyClick(res[0]._id);
							}
						}
					);
				})
				.catch((err) => {
					console.log("[ searchFetch ] POST error:", err);
				});
		} else if (this.props.page === "app" && this.props.nav === "legacy") {
			this.Auth.getLegacySearch({ search: this.state.search })
				.then((res) => {
					this.setState({
						displaySearchLoader: false,
						legacySearch: res,
						searchPrompt: res.length === 0 ? "No results found" : ""
					});
				})
				.catch((err) => {
					console.log("[ searchFetch ] POST error:", err);
				});
		}
	};

	_changeSearchAll = (e) => {
		if (this.state.typingTimeout) clearTimeout(this.state.typingTimeout);
		this.setState({
			displaySearchAllLoader: e.target.value.length > 0,
			searchAll: e.target.value,
			typing: false,
			typingTimeout: setTimeout(() => {
				if (e.target.value.length >= 2) {
					this.Auth.searchAll({ search: this.state.searchAll })
						.then((res) => {
							this.setState(
								{
									displaySearchAllLoader: false,
									results: res.results
								},
								function () {
									//
								}
							);
						})
						.catch((err) => {
							console.log("[ searchFetch ] POST error:", err);
						});
				} else if (e.target.value.length === 0) {
					this.setState({
						displaySearchAllLoader: false,
						results: []
					});
				}
			}, 1000)
		});
	};

	_changeSearch = (e) => {
		if (this.state.typingTimeout) clearTimeout(this.state.typingTimeout);
		this.setState({
			displaySearchLoader: e.target.value.length > 0,
			search: e.target.value,
			typing: false,
			typingTimeout: setTimeout(() => {
				if (e.target.value.length >= 2) {
					this.searchFetch();
				}
			}, 1000)
		});
	};

	_handleAddClicked = () => {
		if (
			this.props.page === "app" &&
			this.props.nav === "dashboard" &&
			typeof this.props.handleAddCompany === "function"
		) {
			this.props.handleAddCompany();
		} else if (
			this.props.page === "app" &&
			this.props.nav === "legacy" &&
			typeof this.props.handleSudoAction === "function"
		) {
			// Sudo user action: Clears all legacy page visit history (for ALL users - use with caution!)
			// this.props.handleSudoAction("clear_legacy_history");
		}
	};

	componentDidMount() {
		if (this.props.page === "app" && this.props.nav === "dashboard") {
			this.setState(
				{
					searchTitle: "Search Companies",
					searchPlaceholder: "Autodesk, Samsung, 4321... etc",
					addTitle: "New Company"
				},
				this.initFetch
			);
		} else if (this.props.page === "app" && this.props.nav === "legacy") {
			this.setState(
				{
					searchTitle: "Search Legacy CSDs & SiteIDs",
					searchPlaceholder: "Autodesk, Samsung, 4321... etc",
					addTitle: "Clear Legacy History"
				},
				this.initFetchLegacy
			);
		}
	}

	renderMenuItems(type) {
		Moment.globalTimezone = Intl.DateTimeFormat()["resolvedOptions"]()["timeZone"];
		const defaultIcon = clear;
		let items = "";
		if (type === "search") {
			if (this.props.page === "app" && this.props.nav === "dashboard") {
				items = this.state.companiesSearch.map((item) => {
					return (
						<div className="dashboard-row" key={item._id}>
							<div className="click-zone">
								<a
									className="noselect"
									href={
										document.location.protocol +
										"//" +
										document.location.host +
										"/company?id=" +
										item._id
									}
								>
									{item.title}
								</a>
							</div>
							<div className={"favicon-wrapper"}>
								<img
									className={"favicon"}
									src={item.favicon || defaultIcon}
									alt={""}
									//onError={(e) => (e.target.src = "/static/media/clear.379f347c.svg")}
								/>
								<div className="status-wrapper">
									<div className={"status " + item.status}></div>
									{item._forms && item._forms.length > 0 && (
										<div className="form-count">{item._forms.length}</div>
									)}
								</div>
							</div>
							<div className="text">
								<p className="title">
									{item.title}
									{item.adminId && <span className="admin-id">{item.adminId}</span>}
									{item.salesforceId && <span className="salesforce-icon" />}
								</p>
								<p className="user">
									Created by {item.createdBy} •{" "}
									<span className={this.props.generateTimeStyles(new Date(item.createdAt))}>
										<Moment fromNow>{item.createdAt}</Moment>
									</span>
								</p>
							</div>
						</div>
					);
				});
			} else if (this.props.page === "app" && this.props.nav === "legacy") {
				items = this.state.legacySearch.map((item) => {
					let type = this.props.getType(item.type);
					return (
						<div className="dashboard-row" key={item.formId}>
							<div className="click-zone">
								<a
									className="noselect"
									href={
										document.location.protocol +
										"//" +
										document.location.host +
										"/legacy?id=" +
										item.formId
									}
								>
									{item.title}
								</a>
							</div>
							<div className={"favicon-wrapper"}>
								<p className={"form-type " + type}>{type}</p>
							</div>
							<div className="text">
								<p className="title">{item.companyName}</p>
								<p className="title legacy">{item.title}</p>
								{item.siteID && (
									<p className="title siteid">
										<span>{item.siteID}</span>
									</p>
								)}
								<p className="user">
									Created by {item.username} •{" "}
									<span
										className={this.props.generateTimeStyles(
											new Date(item.createdDateUTC.toString())
										)}
									>
										<Moment format={"MM/DD/YY, h:mm a"}>{item.createdDateUTC}</Moment> •{" "}
										<Moment fromNow>{item.createdDateUTC}</Moment>
									</span>
								</p>
								{item.lastUpdatedBy && (
									<p className="user">
										Last updated by {item.lastUpdatedBy} •{" "}
										<span className={this.props.generateTimeStyles(new Date(item.updatedAt))}>
											<Moment format={"MM/DD/YY, h:mm a"}>{item.updatedAt}</Moment> •{" "}
											<Moment fromNow>{item.updatedAt}</Moment>
										</span>
									</p>
								)}
							</div>
						</div>
					);
				});
			}
		} else if (type === "history") {
			if (this.props.page === "app" && this.props.nav === "dashboard") {
				items = this.state.companiesHistory.map((item) => {
					if (item._company) {
						return (
							<div className="dashboard-row" key={item._company._id}>
								<div className="click-zone">
									<a
										className="noselect"
										href={
											document.location.protocol +
											"//" +
											document.location.host +
											"/company?id=" +
											item._company._id
										}
									>
										{item._company.title}
									</a>
								</div>
								<div className={"favicon-wrapper"}>
									<img
										className={"favicon"}
										src={item._company.favicon || defaultIcon}
										alt={""}
										//={(e) => (e.target.src = "/static/media/clear.379f347c.svg")}
									/>
									<div className="status-wrapper">
										<div className={"status " + item._company.status}></div>
										{item._company._forms && item._company._forms.length > 0 && (
											<div className="form-count">{item._company._forms.length}</div>
										)}
									</div>
								</div>
								<div className="text">
									<p className="title">
										{item._company.title}
										{item._company.adminId && <span>{item._company.adminId}</span>}
										{item._company.salesforceId && <span className="salesforce-icon" />}
									</p>
									<p className="user">
										Last viewed •{" "}
										<span className={this.props.generateTimeStyles(new Date(item.lastUpdated))}>
											<Moment fromNow>{item.lastUpdated}</Moment>
										</span>
									</p>
								</div>
							</div>
						);
					} else {
						return "";
					}
				});
			} else if (this.props.page === "app" && this.props.nav === "legacy") {
				items = this.state.legacyHistory.map((item) => {
					if (!item._legacy) return "";
					let type = this.props.getType(item._legacy.type);
					return (
						<div className="dashboard-row" key={item._legacy.formId}>
							<div className="click-zone">
								<a
									className="noselect"
									href={
										document.location.protocol +
										"//" +
										document.location.host +
										"/legacy?id=" +
										item._legacy.formId
									}
								>
									{item._legacy.title}
								</a>
							</div>
							<div className={"favicon-wrapper"}>
								<p className={"form-type " + type}>{type}</p>
							</div>
							<div className="text">
								<p className="title">{item._legacy.companyName}</p>
								<p className="title legacy">{item._legacy.title}</p>
								{item._legacy.siteID && (
									<p className="title siteid">
										<span>{item._legacy.siteID}</span>
									</p>
								)}
								<p className="user">
									Last viewed •{" "}
									<span className={this.props.generateTimeStyles(new Date(item.lastUpdated))}>
										<Moment fromNow>{item.lastUpdated}</Moment>
									</span>
								</p>
								{item._legacy.lastUpdatedBy && (
									<p className="user">
										Last updated by {item._legacy.lastUpdatedBy} •{" "}
										<span
											className={this.props.generateTimeStyles(new Date(item._legacy.updatedAt))}
										>
											<Moment format={"MM/DD/YY, h:mm a"}>{item._legacy.updatedAt}</Moment> •{" "}
											<Moment fromNow>{item._legacy.updatedAt}</Moment>
										</span>
									</p>
								)}
							</div>
						</div>
					);
				});
			}
		} else if (type === "activity") {
			if (this.props.page === "app" && this.props.nav === "dashboard") {
				items = this.state.companiesLatest.map((item) => {
					return (
						<div className="dashboard-row" key={item._id}>
							<div className="click-zone">
								<a
									className="noselect"
									href={
										document.location.protocol +
										"//" +
										document.location.host +
										"/company?id=" +
										item._id
									}
								>
									{item.title}
								</a>
							</div>
							<div className={"favicon-wrapper"}>
								<img
									className={"favicon"}
									src={item.favicon || defaultIcon}
									alt={""}
									//onError={(e) => (e.target.src = "/static/media/clear.379f347c.svg")}
								/>
								<div className="status-wrapper">
									<div className={"status " + item.status}></div>
									{item._forms && item._forms.length > 0 && (
										<div className="form-count">{item._forms.length}</div>
									)}
								</div>
							</div>
							<div className="text">
								<p className="title">
									{item.title}
									{item.adminId && <span>{item.adminId}</span>}
									{item.salesforceId && <span className="salesforce-icon" />}
								</p>
								<p className="user">
									Created by {item.createdBy} •{" "}
									<span className={this.props.generateTimeStyles(new Date(item.createdAt))}>
										<Moment fromNow>{item.createdAt}</Moment>
									</span>
								</p>
							</div>
						</div>
					);
				});
			}
		}
		return items;
	}

	render() {
		const defaultIcon = clear;
		return (
			<React.Fragment>
				<GlobalStyle />
				<DashboardWrapperEl>
					{!this.state.showDisplay && (
						<div className="lds-ellipsis">
							<div />
							<div />
							<div />
							<div />
						</div>
					)}
					<div className={"header-wrapper company app"}>
						<TopNavWrapper page={this.props.page} user={this.props.user} />
					</div>
					{this.state.showDisplay && (
						<div
							className={
								"body-wrapper" +
								(this.props.nav === "legacy" ? " two-col" : "") +
								(this.state.showDisplay && this.state.slideUp ? " show" : "")
							}
						>
						{this.props.nav !== "legacy" && (
							<div className="mb">
								<button className="btn add-btn noselect" onClick={this._handleAddClicked}>
									{this.state.addTitle}
								</button>
							</div>
						)}
							<div className="dashboard-column search-wrapper">
								<h3>
									<img className="search" src={clear} alt={"search"} />
									{this.state.searchTitle}
								</h3>
								<input
									id="company-search-field"
									className="search-field"
									placeholder={this.state.searchPlaceholder}
									name="search"
									type="text"
									onChange={this._changeSearch}
									autoFocus={true}
									autoComplete={"off"}
								/>
								{this.state.displaySearchLoader && (
									<div className="lds-ellipsis search">
										<div />
										<div />
										<div />
										<div />
									</div>
								)}
								{this.renderMenuItems("search")}
								{this.state.searchPrompt.length > 0 && !this.state.displaySearchLoader && (
									<div className="section-prompt">{this.state.searchPrompt}</div>
								)}
							</div>
							<div className="dashboard-column history-wrapper">
								<h3>
									<img className="history" src={clear} alt={"history"} />
									My History •{" "}
									<span>{this.state.companiesHistory.length || this.state.legacyHistory.length}</span>
								</h3>
								{this.renderMenuItems("history")}
								{this.state.historyPrompt.length > 0 && (
									<div className="section-prompt">{this.state.historyPrompt}</div>
								)}
							</div>
							{this.props.nav !== "legacy" && (
								<div className="dashboard-column activity-wrapper">
									{/* COMPANIES */}
									{this.state.companyCount !== -1 && (
										<h3>
											<img className="activity" src={clear} alt={"activity"} />
											Newly Created •{" "}
											<span>
												{this.state.companiesLatest.length} / {this.state.companyCount}
											</span>
										</h3>
									)}
									{this.renderMenuItems("activity")}
									{this.state.activityPrompt.length > 0 && (
										<div className="section-prompt">{this.state.activityPrompt}</div>
									)}
									{/* LOAD MORE */}
									{this.state.companyCount !== -1 &&
										this.state.companiesLatest.length !== this.state.companyCount && (
											<div className="btn btn-block" onClick={this.fetchMore}>
												Load More Companies
											</div>
										)}
								</div>
							)}
							<div className="dashboard-column search-all-wrapper">
								<h3>
									<img className="search" src={clear} alt={"search"} />
									Search All Fields
								</h3>
								<input
									id="all-search-field"
									className="search-field"
									placeholder={"active cart AND incentive AND (PC OR LC)"}
									name="search"
									type="text"
									onChange={this._changeSearchAll}
									autoComplete={"off"}
								/>
								{this.state.displaySearchAllLoader && (
									<div className="lds-ellipsis search">
										<div />
										<div />
										<div />
										<div />
									</div>
								)}
								{this.state.results && (
									<div>
										{this.state.results.map((result) => (
											<div className="dashboard-row" key={result._id}>
												{result.form.company && result.form.company[0] && <p className="title">
													<div className={"favicon-wrapper"}>
														<img
															className={"favicon"}
															src={result.form.company[0].favicon || defaultIcon}
															alt={""}
														/>
													</div>
													{result.form.company[0].title}
													{result.form.company[0].adminId && (
														<span className={"badge"}>{result.form.company[0].adminId}</span>
													)}
												</p>}
												<p className="title">
													<a href={"/form?id=" + result._id}>{result.form.title}</a>
													<span className={"badge " + result.form.type}>
														{result.form.type}
													</span>
													<span className={"badge"}>{result.count}</span>
												</p>
											</div>
										))}
									</div>
								)}
								{this.state.results === 0 && !this.state.displaySearchAllLoader && (
									<div className="section-prompt">{this.state.searchPrompt}</div>
								)}
							</div>
						</div>
					)}
				</DashboardWrapperEl>
			</React.Fragment>
		);
	}
}

export default DashboardWrapper;

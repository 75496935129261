import styled, { createGlobalStyle } from "styled-components";
export const GlobalStyle = createGlobalStyle`
	#light {
		color: var(--txt-dg);
		input {
			background: #fff;
			color: var(--txt-dg);
		}
		.tag-btn {
			background: #fff;
			p{
				color: var(--txt-dg);
			}
		}
		.desc-wrapper span {
			color: var(--txt-dg);
		}
		.links-show-wrapper {
			background: #fff;
			color: var(--txt-dg);
		}
		.nav-menu-wrapper .nav-menu-item.inactive:hover > p {
			color: var(--txt-dg);
		}
		.nav-menu-item .underline {
			background: #000;
		}
	}
`;
export const DevWrapperEl = styled.div`
	min-height: 100px;
	padding: 2em;
	.nav-menu-wrapper {
		height: 60px;
		text-align: center;
		z-index: 999999;
		position: fixed;
		top: 0;
		right: 0;
	}
	.nav-menu-wrapper .nav-menu-item {
		display: inline-block;
		vertical-align: top;
		height: 34px;
		margin: 14px 10px;
		padding: 0 20px;
		position: relative;
		border-radius: 3px;
	}
	.nav-menu-wrapper .nav-menu-item:hover {
		cursor: pointer;
	}
	.nav-menu-wrapper .nav-menu-item p {
		font-size: 13px;
		pointer-events: none;
	}
	.nav-menu-wrapper .nav-menu-item.inactive {
		background: transparent;
	}
	.nav-menu-wrapper .nav-menu-item.inactive p {
		color: var(--txt-lg);
	}
	.nav-menu-wrapper .nav-menu-item.inactive:hover > p {
		color: white;
	}
	.nav-menu-wrapper .nav-menu-item .underline {
		position: absolute;
		bottom: 6px;
		left: calc(50% - 10px);
		width: 20px;
		height: 2px;
		border-radius: 1px;
		background: white;
	}
	.content-wrapper {
		min-height: 100px;
	}
	@media screen and (max-width: 1200px) {
		.nav-menu-wrapper {
			width: calc(100% - 80px);
		}
		.body-wrapper {
			padding: 4em 2em 6em 5em !important;
		}
	}
`;

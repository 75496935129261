import React from "react";
import { Image, Tabs } from "antd";
import linkifyHtml from "linkify-html";
import * as linkify from "linkifyjs";
import DOMPurify from "dompurify";
import DraftWrapper from "../body/DraftWrapper";
import { field_data } from "../../helpers/getData";

let client_visible = [
	"strategy_type",
	"desktop_launch_method",
	"desktop_launch_action",
	"mobile_launch_method",
	"mobile_launch_action",
	"desktop_traffic_exposure_percentage",
	"mobile_traffic_exposure_percentage",
	"pages",
	"products",
	"stages",
	"visitors",
	"start_date",
	"start_time",
	"end_date",
	"end_time",
	"has_geo_restriction",
	"geo_locations",
	"has_traffic_restriction",
	"traffic_sources",
	"sale_window",
	"mockups",
	"copy",
	"testing_instructions"
];
const onChange = (key) => {
	console.log(key);
};
function FigmaPreview(url, title) {
	return (
		<div className="thumbnail-wrapper">
			<iframe
				className="card"
				src={"https://www.figma.com/embed?embed_host=share&url=" + encodeURIComponent(url)}
				title={title}
			/>
			<a href={url} target="_blank" rel="noreferrer" className="btn">
				Open
			</a>
		</div>
	);
}
function DropboxPreview(url, title) {
	return (
		<div className="thumbnail-wrapper">
			<Image
				src={url.replace("dl=0", "dl=1&raw=0")}
				alt={title}
				style={{
					backgroundPosition: "0 0, 10px 10px",
					backgroundSize: "20px 20px",
					backgroundImage:
						"linear-gradient(45deg, #333 25%, transparent 25%, transparent 75%, #333 75%, #333 100%),linear-gradient(45deg, #333 25%, #555 25%, #555 75%, #333 75%, #333 100%)"
				}}
			/>
			<a href={url} target="_blank" rel="noreferrer" className="btn">
				Open
			</a>
		</div>
	);
}
function MockupPreview(props) {
	const links = linkify.find(props.field.content);
	const dropbox_regex = new RegExp("http.*dropbox.com/s/.*(png|jpg|jpeg|gif)?dl=0", "g");
	const images = props.field.content.match(dropbox_regex);
	const figmas = links.map((l) => l.value).filter((l) => l.match("https://www.figma.com/file/"));
	return (
		<div className="flex-wrapper card" id={props.field.field_type_id + props.record.form._id}>
			{figmas.length > 1 ? (
				<div className={"flex-wrapper"}>
					<Tabs
						defaultActiveKey="1"
						style={{ width: "100%" }}
						items={figmas.map((url, ind) => ({
							key: ind,
							label: `Mockup ${ind + 1}`,
							children: FigmaPreview(url, props.field.field_type_id)
						}))}
						onChange={onChange}
					/>
				</div>
			) : figmas.length === 1 ? (
				<div className={"flex-wrapper"}>{FigmaPreview(figmas[0], props.field.field_type_id)}</div>
			) : props.field.content.match("dl=0") && images ? (
				<Image.PreviewGroup
					preview={{
						onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`)
					}}
				>
					{images.map((img) => DropboxPreview(img, props.field.title))}
				</Image.PreviewGroup>
			) : (
				""
			)}
		</div>
	);
}

export function FormSummary(props) {
	const should_display = (str) => {
		return (
			str &&
			(str.length > 135 || str.indexOf('"text":""') === -1) && // TODO: better check for blank draft
			str !== "100%" &&
			str !== "- TBD -" &&
			str !== "00:00" &&
			str.toLowerCase().indexOf("(default)") === -1
		);
	};
	const dropbox_regex = new RegExp("http.*dropbox.com/s/.*(png|jpg|jpeg|gif)?dl=0", "g");
	return (
		<div
			className="card summary-wrapper full-width-for-print mb"
			style={{
				opacity: props.length > 0 ? "1" : "0"
			}}
		>
			<h3 className="mb">
				<span className={"badge " + props.record.form.type}>{props.record.form.type}</span>
				<a href={"/form?id=" + props.record.form._id}>{props.record.form.title}</a>
			</h3>

			<div className="flex-wrapper">
				<div style={{ width: "50%" }}>
					<table className="table">
						<tbody>
							<tr className="internal-information hide-for-print">
								<td>Time Estimate</td>
								<td>{props.record.time_estimate} hours</td>
							</tr>
							{props.record.fields &&
								client_visible
									.map((fid) =>
										props.record.fields.find((f) => {
											return f.field_type_id === fid;
										})
									)
									.filter(
										(field) =>
											field &&
											field.content &&
											should_display(field.content) &&
											!field.content.match(dropbox_regex) &&
											!field.content.match("https://www.figma.com/file/")
									)
									.map((field) => {
										return (
											<tr key={field._id} id={field.field_type_id + props.record.form._id}>
												<td
													style={{
														width: "15em"
													}}
												>
													{field_data[field.field_type_id].title}
												</td>
												{field.content.indexOf('{"blocks":') === 0 ? (
													<td>
														<DraftWrapper
															item={field}
															content={field.content}
															className={"dont-break-out"}
															readOnly={true}
															reportDraftChange={() => {
																console.log(arguments);
															}}
														/>
													</td>
												) : (
													<td
														style={{
															wordBreak: "break-word",
															whiteSpace: "pre-line"
														}}
														dangerouslySetInnerHTML={{
															__html: DOMPurify.sanitize(
																linkifyHtml(field.content, {
																	defaultProtocol: "https"
																}),
																{ USE_PROFILES: { html: true } }
															)
														}}
													></td>
												)}
											</tr>
										);
									})}
						</tbody>
					</table>
				</div>
				<div style={{ width: "50%" }}>
					{props.record.fields &&
						client_visible
							.map((fid) =>
								props.record.fields.find((f) => {
									return f.field_type_id === fid;
								})
							)
							.filter(
								(field) =>
									field &&
									field.content &&
									should_display(field.content) &&
									(field.content.match(dropbox_regex) ||
										field.content.match("https://www.figma.com/file/"))
							)
							.map((field) => {
								return <MockupPreview key={field._id} field={field} record={props.record} />;
							})}
				</div>
			</div>
		</div>
	);
}

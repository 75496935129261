// Resources
import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

// Components
import ProfileWrapper from "./ProfileWrapper";
import NavWrapper from "./NavWrapper";
import SettingsWrapper from "./SettingsWrapper";
import CompanyWrapper from "./CompanyWrapper";
import FormWrapper from "./FormWrapper";
import RoomWrapper from "./RoomWrapper";
import LegacyWrapper from "./LegacyWrapper";

// Styles
import { SideBarWrapperEl } from "./styles/SideBarWrapper";

const mapStateToProps = function (state) {
	return state;
};

class SideBarWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: {},
			company: {},
			companyWrapperHeight: "200px",
			appWrapperHeight: "200px"
		};
	}

	static getDerivedStateFromProps(props, state) {
		if (props.user !== state.user) {
			return {
				user: props.user
			};
		} else if (props.company !== state.company) {
			return {
				company: props.company
			};
		}
		return null;
	}

	_setHeight = (page, height) => {
		if (page === "company") {
			this.setState({
				companyWrapperHeight: height + "px"
			});
		} else if (page === "app") {
			this.setState({
				appWrapperHeight: height + "px"
			});
		}
	};

	render() {
		return (
			<React.Fragment>
				<SideBarWrapperEl>
					<ProfileWrapper
						user={this.state.user}
						page={this.props.nav.page}
						printRooms={this.props.printRooms}
					/>
					{this.props.nav.page === "app" && (
						<>
							<NavWrapper
								user={this.props.user}
								nav={this.props.nav}
								handleNavChange={this.props.handleNavChange}
								setHeight={this._setHeight}
							/>
							<RoomWrapper
								appWrapperHeight={this.state.appWrapperHeight}
								page={this.props.nav.page}
								users={this.props.room.users}
								online={this.props.room.online}
							/>
						</>
					)}
					{this.props.nav.page === "company" && (
						<div>
							<CompanyWrapper
								company={this.state.company}
								handleEditCompany={this.props.handleEditCompany}
								handleAdminClick={this.props.handleAdminClick}
								handleDeleteClick={this.props.handleDeleteClick}
								setHeight={this._setHeight}
							/>
							<RoomWrapper
								companyWrapperHeight={this.state.companyWrapperHeight}
								page={this.props.nav.page}
								users={this.props.room.users}
								online={this.props.room.online}
							/>
						</div>
					)}
					{this.props.nav.page === "form" && (
						<div>
							<FormWrapper
								form={this.props.form.form}
								reader={this.props.reader}
								show={this.props.show}
								handleSidebarToggleChanged={this.props.handleSidebarToggleChanged}
								handleCompanyClick={this.props.handleCompanyClick}
								siteId={this.props.siteId}
								handlePrimarySiteIdSave={this.props.handlePrimarySiteIdSave}
								mainVersion={this.props.mainVersion}
								setHeight={this._setHeight}
							/>
							<RoomWrapper
								page={this.props.nav.page}
								users={this.props.room.users}
								online={this.props.room.online}
							/>
						</div>
					)}
					{this.props.nav.page === "legacy" && (
						<div>
							<LegacyWrapper openExternalLink={this.props.openExternalLink} legacy={this.props.legacy} />
							<RoomWrapper
								page={this.props.nav.page}
								users={this.props.room.users}
								online={this.props.room.online}
							/>
						</div>
					)}
					<SettingsWrapper
						page={this.props.nav.page}
						handleLogout={this.props.handleLogout}
						forceReloadPage={this.props.forceReloadPage}
					/>
				</SideBarWrapperEl>
			</React.Fragment>
		);
	}
}

export default connect(mapStateToProps)(SideBarWrapper);

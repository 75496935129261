import React from "react";
import SalesforceWrapper from "../body/SalesforceWrapper";

export function CompanyInfo(props) {
	return (
		<div className="company-info-wrapper">
			<div className="title-container">
				<h3>About {props.form._company.title}</h3>
			</div>
			<div className={"section-fields-wrapper"} id={"company_field_wrapper"}>
				<div className="company-info-row">
					<p className="desc-wrapper">{props.form._company.description}</p>
				</div>
				<div className="company-info-row">
					<p className="title-wrapper">
						{props.form._company.adminId && (
							<a
								className="btn"
								target="_blank"
								rel="noreferrer"
								href={
									"https://www2.upsellit.com/admin/control/edit/company.jsp?companyID=" +
									props.form._company.adminId
								}
							>
								Admin
							</a>
						)}
						{props.form._company.salesforceId && (
							<a
								className="btn"
								target="_blank"
								rel="noreferrer"
								href={
									"https://upsellit.lightning.force.com/lightning/r/Account/" +
									props.form._company.salesforceId +
									"/view"
								}
							>
								Salesforce
							</a>
						)}
						{props.form._company.title && (
							<a
								className="btn"
								target="_blank"
								rel="noreferrer"
								href={
									"https://www.dropbox.com/search/work?file_categories=folder&path=%2F&query=" +
									props.form._company.title
								}
							>
								Dropbox
							</a>
						)}
						{props.form._company.url && (
							<a className="btn btn-link" target="_blank" rel="noreferrer" href={props.form._company.url}>
								{props.form._company.url.indexOf("//") !== -1
									? props.form._company.url.split("//")[1]
									: props.form._company.url}
							</a>
						)}
					</p>
				</div>
				<details className="company-info-row related-opp-wrapper" style={{margin: "1em 0"}}>
					<summary><h4>Related Opportunities &rsaquo;</h4></summary>
					{props.form._id && (
						<SalesforceWrapper
							type="table"
							columns={{
								Name: (record) => (
									<div>
										<a
											className="company-name"
											href={`/opportunity?opp=${record.Id}&id=${props.form._company._id}`}
										>
											{record.Name}
										</a>
									</div>
								),
								Type: (record) => <div>{record.Type}</div>,
								StageName: (record) => <div>{record.StageName}</div>,
								Action: (record) => (
									<div style={{maxWidth: window.location.href.indexOf("test=create_campaign") !== -1 ? "20em" : "5em"}}>
										<a
											style={{ float: "right" }}
											className="badge salesforce"
											href={`https://upsellit.lightning.force.com/lightning/r/Opportunity/${record.Id}/view`}
											target="_blank"
											rel="noreferrer"
										>
											↗
										</a>
										{window.location.href.indexOf("test=create_campaign") !== -1 && (
											<a
												href={
													"https://www2.upsellit.com/admin/control/edit/create_campaign.jsp" +
													`?oppId=${record.Id}&companyId=${props.form._company._id}`
												}
												target="_blank"
												className="btn"
												rel="noreferrer"
											>
												Create&nbsp;Campaigns&nbsp;<span className="badge">WIP</span>
											</a>
										)}
									</div>
								)
							}}
							user={props.user}
							q={`SELECT Type, Name, Id, StageName 
								FROM Opportunity 
								WHERE Id IN (SELECT Opportunity__c FROM CSD__c WHERE URL__c LIKE '%${props.form._id}%')`}
						/>
					)}
				</details>
			</div>
		</div>
	);
}

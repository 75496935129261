// Resources
import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

// Components
import AuthHelperMethods from "../auth/AuthHelperMethods";
import { Prompt } from "./Prompt";

// Styles
import { AddCSDWrapperEl } from "./styles/AddCSDWrapper";

const mapStateToProps = function (state) {
	return state;
};

class AddCSDWrapper extends Component {
	Auth = new AuthHelperMethods();

	constructor(props) {
		super(props);
		this.state = {
			// Prompt
			prompt: "",
			promptColor: "red",
			// Form
			company_id: "",
			title: "",
			type: "",
			redirect: "",
			// Validations
			loading: false,
			isValidForm: false
		};
	}

	_handleAddCSDCancel = () => {
		if (this.state.loading) {
			alert("CSD creation in progress. Unable to close.");
		} else {
			this.props.handleAddCSDCancel();
		}
	};

	_handleCloneSubmit = () => {
		this.props.handleCloneCSDSubmit(this.state.type, this.state.title, this.state.company_id);
	};

	_handleCSDSubmit = () => {
		this.setState({ loading: true });
		if (this.props.editCSD) {
			// Update CSD
			this.Auth.updateCSD({
				id: this.props.editCSD,
				title: this.state.title
			})
				.then((res) => {
					this.setState({ loading: false, isValidForm: false }, function () {
						if (res.error) {
							this.displayPrompt(res.error);
						} else if (res.id) {
							this.props.handleAddCSDCancel(true, undefined);
						}
					});
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			// Create CSD
			this.Auth.createCSD({
				title: this.state.title,
				type: this.state.type,
				redirect: this.state.redirect,
				companyID: this.props.company._id
			})
				.then((res) => {
					this.setState({ loading: false, isValidForm: false }, function () {
						if (res.error) {
							this.displayPrompt(res.error);
						} else if (res.id) {
							this.setState({ loading: false });
							this.props.handleAddCSDCancel(false, res.id);
						}
					});
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	displayPrompt = (prompt, color) => {
		this.setState({
			prompt: prompt,
			promptColor: color || "red"
		});
	};

	_handleCompanyChange = (e) => {
		this.setState(
			{
				company_id: e.target.value
			},
			this.validateForm
		);
	};

	_handleChange = (e) => {
		this.setState(
			{
				title: e.target.value
			},
			this.validateForm
		);
	};

	_handleTypeClick = (e) => {
		let id = e.target.getAttribute("data-id");
		if (!id) id = e.target.parentNode.getAttribute("data-id");
		this.setState(
			{
				type: id
			},
			this.validateForm
		);
	};

	validateForm = () => {
		if (this.props.editCSD) {
			this.setState({
				isValidForm: this.state.title
			});
		} else {
			this.setState({
				isValidForm: this.state.title && this.state.type
			});
		}
	};

	componentDidMount() {
		let state = {};
		if (this.props.company && this.props.company._id) {
			state.company_id = this.props.company._id;
		} else if (this.props.form && this.props.form.form) {
			state.company_id = this.props.form.form._company._id;
		}
		if (this.props.form && this.props.form.form) {
			state.title = this.props.form.form.title;
		}
		if (this.props.cloneCSD) {
			state.type = this.props.cloneCSD.type;
			state.title = this.props.cloneCSD.title + " [CLONE]";
		}
		this.setState(state);
	}

	render() {
		return (
			<React.Fragment>
				<AddCSDWrapperEl>
					<div className="cancel-btn" onClick={this._handleAddCSDCancel}>
						<span>&#10005;</span>
					</div>
					{this.props.addCSD && (
						<div className="title-wrapper">
							<h1>
								Create CSD for <span>{this.props.company.title}</span>
							</h1>
						</div>
					)}
					{this.props.editCSD && (
						<div className="title-wrapper">
							<h1>Edit CSD Title</h1>
						</div>
					)}
					{this.props.cloneCSD && (
						<div className="title-wrapper">
							<h1>Select Clone Type</h1>
						</div>
					)}

					{/* ----- TITLE ----- */}
					<div className="csd-title-wrapper">
						<label>CSD Title</label>
						<p>e.g. Checkout Page Upsells - In Page</p>
						<input
							className={"form-item" + (this.state.title ? " valid" : "")}
							value={this.state.title}
							name="title"
							type="text"
							onChange={this._handleChange}
							autoComplete={"false"}
							autoFocus={true}
						/>
					</div>

					{/* ----- TYPE ----- */}
					{!this.props.editCSD && (
						<div className="csd-type-wrapper">
							<div
								className={"csd-type-btn" + (this.state.type === "tt" ? " tt sel" : " nsel")}
								data-id={"tt"}
								onClick={this._handleTypeClick}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										e.preventDefault();
										this._handleTypeClick(e);
									}
									console.log(e);
								}}
								tabIndex={0}
							>
								<h1 className="noselect">TT</h1>
								<p className="noselect">Targeted Tactic</p>
							</div>
							<div
								className={"csd-type-btn" + (this.state.type === "lc" ? " lc sel" : " nsel")}
								data-id={"lc"}
								onClick={this._handleTypeClick}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										e.preventDefault();
										this._handleTypeClick(e);
									}
									console.log(e);
								}}
								tabIndex={0}
							>
								<h1 className="noselect">LC</h1>
								<p className="noselect">Lead Capture</p>
							</div>
							<div
								className={"csd-type-btn" + (this.state.type === "em" ? " em sel" : " nsel")}
								data-id={"em"}
								onClick={this._handleTypeClick}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										e.preventDefault();
										this._handleTypeClick(e);
									}
									console.log(e);
								}}
								tabIndex={0}
							>
								<h1 className="noselect">EM</h1>
								<p className="noselect">Email</p>
							</div>


							<div
								className={"csd-type-btn" + (this.state.type === "ce" ? " ce sel" : " nsel")}
								data-id={"ce"}
								onClick={this._handleTypeClick}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										e.preventDefault();
										this._handleTypeClick(e);
									}
									console.log(e);
								}}
								tabIndex={0}
							>
								<h1 className="noselect">CE</h1>
								<p className="noselect">Coupon Experience</p>
							</div>

							<div
								className={"csd-type-btn" + (this.state.type === "nc" ? " nc sel" : " nsel")}
								data-id={"nc"}
								onClick={this._handleTypeClick}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										e.preventDefault();
										this._handleTypeClick(e);
									}
									console.log(e);
								}}
								tabIndex={0}
							>
								<h1 className="noselect">NC</h1>
								<p className="noselect">Not Classified</p>
							</div>
						</div>
					)}

					{/* ----- COMPANY ----- */}
					{this.props.cloneCSD && (
						<div className="csd-title-wrapper">
							<details>
								<summary>Other</summary>
								<label>Company</label>
								<p>To move to another company, copy the "id" URL parameter from the new company page.</p>
								<input
									className={"form-item" + (this.state.company_id ? " valid" : "")}
									value={this.state.company_id}
									name="company_id"
									type="text"
									onChange={this._handleCompanyChange}
									autoComplete={"false"}
								/>
							</details>
						</div>
					)}

					{/* ----- SUBMIT ----- */}
					{this.state.isValidForm && !this.props.cloneCSD && (
						<button
							className={"btn btn-block submit-csd-btn" + (this.state.loading ? " loading" : "")}
							onClick={this._handleCSDSubmit}
							disabled={this.state.loading}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									e.preventDefault();
									this._handleCSDSubmit(e);
								}
								console.log(e);
							}}
						>
							{/* <img
								className={"loading-img" + (this.state.loading ? " show" : "")}
								src={loading}
								alt={"loading"}
							/> */}
							{this.state.loading ? "One moment..." : this.props.editCSD ? "Update CSD Title" : "Create New CSD"}
						</button>
					)}
					{this.props.cloneCSD && (
						<button 
							className={"btn btn-block submit-csd-btn"} 
							onClick={this._handleCloneSubmit} 
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									e.preventDefault();
									this._handleCloneSubmit(e);
								}
								console.log(e);
							}}
						>
							Clone CSD
						</button>
					)}

					{/* ----- PROMPT ----- */}
					<div className="login-error-wrapper">
						{this.state.prompt.length > 0 && (
							<Prompt message={this.state.prompt} color={this.state.promptColor} />
						)}
					</div>
				</AddCSDWrapperEl>
			</React.Fragment>
		);
	}
}

export default connect(mapStateToProps)(AddCSDWrapper);

const user = (state = {}, action) => {
	let newState = state;
	switch (action.type) {
		case "PROFILE":
			return action.profile;
		case "UPDATE_DISPLAY_NAME":
			newState.display_name = action.text;
			return newState;
		default:
			return state;
	}
};

export default user;

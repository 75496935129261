export default function getTemplates(forms) {
	let is = (bool, str) => (bool ? str : usi_code.DEL);
	// TODO
	let c = {
		affiliateLink: "AFFILIATE_LINK_HERE",
		cartUrl: "CART_URL",
		companyID: forms.length > 0 && forms[0].form ? forms[0].form._company.adminId : "COMPANY_ID",
		companyName: "COMPANY",
		emptyCart: 'document.querySelector(".EMPTY_CART") != null',
		domain: "ELIGIBLE_DOMAINS",
		recommendation_site_cart: "CART_SITE_ID",
		recommendation_site_product: "PDP_SITE_ID",
		is_product: 'usi_app.url.match("PRODUCT") != null',
		is_cart: 'usi_app.url.match("CART") != null',
		is_checkout: 'usi_app.url.match("CHECKOUT") != null',
		is_confirmation: 'usi_app.url.match("CONFIRMATION") != null'
	};
	let get_lines = (forms, inpage) => {
		return forms
			.map((obj, index) => {
				const field_json = JSON.stringify(obj.fields);
				let is_lift = obj.fields.filter(f => f.field_type_id.match('_is_incremental_lift') && f.content === "1").length > 0;
				let has_recs = field_json.match('recommendation');
				let is_in_page = inpage;
				let is_active_cart = field_json.match('active.*cart');

				let pid = 'usi_app.product.pid || "popular"';
				if (is_active_cart) pid = "usi_cookies.get(\"usi_prod_pid_1\")";

				let loader = obj.form.type === "pc" || obj.form.type === "em"
				? `usi_commons.load_precapture("", "");`
				: obj.form.type === "tt" || obj.form.type === "lc" || obj.form.type === "nc"
				? is_in_page ? `usi_commons.load("", "", usi_commons.device);` : `usi_commons.load_view("", "", usi_commons.device);`
				: "";
				// TODO: 
				// load_presubmit, load_postsubmit (optin and visitor id?)
				// start and end time
				// language configs/keys
				// check strategy, on add to cart, traffic_exposure_percentage
				// pages, stages, visitors, geo, traffic
				// check if a/b test, number of variations
				// dynamic elements, check for required cookies
				let launch_rules = `usi_app.is_enabled${is_active_cart ? " && usi_cookies.value_exists(\"usi_prod_pid_1\")" : ""}${is_lift ? " && usi_split_test.get_group(control_site_id) === '1'" : ""}`;

				let campaign_comment = `${obj.form.title} - ${obj.form.type.toUpperCase()}`;
				let before_load = has_recs && is_active_cart ? `
					var nomatch = "";
					for (var i = 1; i <= 3; i++) {
						if (usi_cookies.get("usi_prod_pid_" + i)) {
							nomatch += usi_cookies.get("usi_prod_pid_" + i) + ",";
						}
					}` : '';
				let load = has_recs ? `usi_app.load_product_data({
						siteID: usi_app.recommendation_site_product,
						association_siteID: usi_app.recommendation_site_cart,
						pid: ${pid},
						nomatch: "OUTOFSTOCK,"${is_active_cart ? " + nomatch" : ""},
						callback: function(){
							if (typeof (usi_app.product_rec.product3) != "undefined") {
								${loader}
							}
						}
					});` : loader;
				let connector = "else if";
				if (index === 0 || is_in_page) connector = "if"
				//if (index > 0 && is_in_page) connector = "\n				";
				return `${connector} (${launch_rules}) {
					/* ${campaign_comment} */${before_load}
					${load}
				}`;
			})
			.join("\n				") || "// none";
	};
	let usi_code = {
		DEL: "###",
		zone: "-08:00",
		today: (function () {
			var date = new Date();
			return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
		})(),
		month: (function () {
			var date = new Date();
			return `${date.getFullYear()}-${date.getMonth() + 2}-${date.getDate()}`;
		})(),
		update: (str) => str.replace(/(.*###\n)|###/g, ""),
		add_tabs: function (str, s) {
			var lines = str.split("\n");
			return lines
				.map(function (l, index) {
					if (index !== 0) return "\t".repeat(s) + l;
					return l;
				})
				.join("\n");
		},
		get_component: (i, o, s) => {
			s = s || 2;
			return usi_code.add_tabs(localStorage.getItem(c.companyID + "_" + i) || usi_code.component[i](o), s) + "\n";
		},
		app: (o) => {
			return usi_code.update(`<%@ page contentType="application/x-javascript" %><%@ include file="/utility/app_header.jsp" %><%
response.setHeader("Content-type", "application/x-javascript");
if (localIPAddress) {
	response.addHeader("Pragma", "No-cache");
	response.setHeader("Cache-Control", "no-cache,no-store");
	response.setDateHeader("Expires",0);
} else {
	long usi_expire_hours = 24;
	response.setDateHeader("Expires", System.currentTimeMillis() + usi_expire_hours * 60 * 60 * 1000);
	response.setHeader("Cache-Control", "max-age=" + usi_expire_hours * 60 * 60);
}
%>
<%@ include file="/utility/app_commons.jsp" %>
if (typeof usi_app === 'undefined') {
	try {
		<% staticInclude(pageContext, utilityFile("cookie_functions", true)); %>
		${is(o.dom_utils, `<% staticInclude(pageContext, utilityFile("dom_utils", true)); %>`)}
		${is(
			o.ajax_utils || o.ajax_cart || o.cart_rebuilder,
			`<% staticInclude(pageContext, utilityFile("ajax_utils", true)); %>`
		)}
		${is(
			o.date_utils || o.date_range || o.return_visitor,
			`<% staticInclude(pageContext, utilityFile("date_utils", true)); %>`
		)}
		${is(o.data_utils || o.cart_rebuilder, `<% staticInclude(pageContext, utilityFile("data_utils", true)); %>`)}
		${is(o.split_test, `<% staticInclude(pageContext, utilityFile("split_test_code", true)); %>`)}
		${is(o.rec_reporting, `<% staticInclude(pageContext, utilityFile("upsell_prodrec", false)); %>`)}
		${is(o.anonymous, `<%@ include file="/utility/user_id.jsp" %>`)}
		${is(o.coupon_monitor, `<%@ include file="/utility/upsell_couponv4.js" %>`)}
		${is(o.coupon_blocker, `<%@ include file="/utility/coupon_blocker.jsp" %>`)}
		${is(o.aff_utils, `<%@ include file="/utility/aff_utils.js" %>`)}
		usi_app = {};
		usi_app.main = function () {
			try {
				usi_app.url = location.href.toLowerCase();
				usi_app.force_date = usi_commons.gup_or_get_cookie('usi_force_date');
				${is(
					o.coupon,
					`usi_app.coupon = usi_cookies.value_exists("usi_coupon_applied") ? "" : usi_commons.gup_or_get_cookie("usi_coupon", usi_cookies.expire_time.week, true);`
				)}
				${is(
					o.recommendations,
					`usi_app.recommendation_site_cart = "${c.recommendation_site_cart}";
				usi_app.recommendation_site_product = "${c.recommendation_site_product}";`
				)}
				${is(o.recommendations, `usi_app.product = {};`)}
				${is(
					o.availability || o.ip_return_visitor || o.load_presubmit || c.companyID,
					`usi_app.company_id = "${c.companyID}";`
				)}
				${is(o.page_visits, `usi_app.page_visits = usi_app.get_page_visits();`)}
				${is(o.availability, `usi_app.fake_in_stock = usi_commons.gup("usi_fake_in_stock") != "";`)}
				${is(o.split_test, `usi_app.force_group = usi_commons.gup_or_get_cookie('usi_force_group');`)}
				${is(o.get_weather, `usi_app.current_weather = usi_cookies.get("usi_weather") || usi_app.get_weather();`)}
				
				// Pages
				${is(o.recommendations || o.availability, `usi_app.is_product_page = ${c.is_product};`)}
				usi_app.is_cart_page = ${c.is_cart};
				usi_app.is_checkout_page = ${c.is_checkout};
				usi_app.is_confirmation_page = ${c.is_confirmation};
	
				// Booleans
				${is(
					o.date_range,
					`usi_app.is_valid_date = usi_date.is_between('${usi_code.today}T00:00-${usi_code.zone}', '${usi_code.month}T23:59:59-${usi_code.zone}');`
				)}
				${is(o.new_visitor, `usi_app.is_logged_in = usi_app.check_if_logged_in();`)}
				${is(o.return_visitor, `usi_app.is_return_visitor = usi_app.check_if_return_visitor();`)}
				usi_app.is_enabled = usi_commons.gup_or_get_cookie("usi_enable", usi_cookies.expire_time.hour, true) != "";
				usi_app.is_forced = usi_commons.gup_or_get_cookie("usi_force", usi_cookies.expire_time.hour, true) != "";
				usi_app.is_suppressed = !usi_app.is_forced && (usi_app.url.match("${c.domain}") == null || 
					usi_app.is_confirmation_page || 
					usi_cookies.value_exists("usi_sale") ||
					!usi_app.is_enabled);
					
				// Suppress on 404 pages
				if (document.title.indexOf('404') != -1) return;
	
				${is(o.coupon_blocker, `usi_blocker.init();`)}
				${is(
					o.post_purchase,
					`// Load campaign on confirmation page
				if (usi_app.is_enabled && usi_app.is_confirmation_page) {
					usi_app.load_post_purchase(function(){
							usi_commons.load_view("", "", usi_commons.device);
					});
				}
				`
				)}
				${is(
					o.load_pixel,
					`// Load pixel on confirmation page
				if (usi_app.is_confirmation_page && typeof USI_orderID == "undefined") {
					usi_commons.load_script("//www.upsellit.com${
						window.c
							? c.custom_tags.split("\n")[0].trim().replace(".jsp", "_pixel.jsp")
							: "/active/" + c.companyName + "_pixel.jsp"
					}");
				}
				`
				)}
				${is(o.aff_utils, `/*usi_aff.fix_cf(), usi_aff.fix_pj(), usi_aff.fix_sas(), usi_aff.fix_cj(), usi_aff.fix_linkshare(), usi_aff.fix_ir("1111"), usi_aff.fix_awin("11111")*/
				usi_aff.monitor_affiliate_pixel(function(){
					if (typeof USI_orderID == "undefined") {
						usi_commons.load_script("//www.upsellit.com/active/COMPANY_pixel.jsp");
					}
				});
				`)}
				${is(o.recommendations || o.availability, `// Collect product page data
				if (usi_app.is_product_page) {
					usi_app.product = usi_app.scrape_product_page();
					${is(o.recommendations, `
					var real_time = usi_app.product.extra && usi_app.product.extra.indexOf('OUTOFSTOCK') !== -1;
					usi_commons.send_prod_rec(usi_app.recommendation_site_product, usi_app.product, real_time);`)}
					${is(o.availability, `if (usi_app.fake_in_stock) usi_app.product.in_stock = true;
					usi_commons.load_script('//www.upsellit.com/utility/usi_pid.jsp?companyID=' + usi_app.company_id +
						"&avail=" + (product.in_stock ? 1 : 0) +
						"&pid=" + usi_app.product.pid);`)}
					usi_commons.log(usi_app.product);
				}
				`)}
				${is(
					o.preserve_params,
					`// Save and reapply query string parameters
				usi_app.preserve_original_qs();
				`
				)}
				${is(
					o.iframe_breakout,
					`// Attempt to break out of iframe
				if (window !== window.parent) {
					return usi_commons.load_script("https://www.upsellit.com/active/${c.companyName}.jsp", null, true);
				}
				`
				)}
				if (usi_app.is_suppressed) {
					return usi_commons.log("usi_app.main is suppressed");
				}
				${is(
					o.anonymous,
					`if (usi_app.is_enabled) {
					usi_user_id.activate('DATA_SITE_ID');
				}`
				)}
				
				${is(
					o.coupon_monitor,
					`// Monitor coupons
				usi_app.monitor_coupons();`
				)}
				
				${is(
					o.coupon && !o.boost_bar,
					`// Apply coupons
				if (usi_app.is_cart_page && usi_app.coupon != "") {
					usi_app.apply_coupon();
					return;
				}
				`
				)}
				${is(
					o.link_injection,
					`// Load affiliate link
				if (usi_app.is_checkout_page && usi_cookies.value_exists("usi_needs_link")) {
					usi_cookies.del("usi_needs_link");
					usi_app.link_injection("${c.affiliateLink}");
				}
				`
				)}
				${is(
					o.boost_bar && !o.coupon,
					`// Load boost bar
				if (usi_cookies.value_exists("usi_boostbar")) {
					usi_app.boostbar.load();
				}
				`
				)}
				${is(
					o.boost_bar && o.coupon,
					`// Apply coupons
				if (usi_app.coupon != "") {
					if (usi_app.is_cart_page) {
						return usi_app.apply_coupon();
					}
					// load boost bar
					usi_app.boostbar.load();
				}
				`
				)}
				${is(
					o.ip_return_visitor,
					`// Check if we've seen a purchase from the user's IP
				if (!usi_cookies.value_exists("usi_ip_checked")) {
					usi_cookies.set("usi_ip_checked", "1", usi_cookies.expire_time.day);
					// Callback is usi_app.customer_ip, if user hasn't purchased it calls usi_app.main
					usi_commons.load_script("https://www.upsellit.com/utility/customer_ip.jsp?companyID=" + usi_app.company_id);
					return;
				}
				`
				)}
				${is(
					o.cart,
					`// Save cart data
				if (usi_app.is_cart_page) {
					usi_app.save_cart();
				}
				`
				)}
				${is(
					o.cart_rebuilder,
					`// Rebuild cart - add usi_rebuild={{SESSIONID}} on email destination link
				if (usi_commons.gup("usi_rebuild") != "" && ${c.emptyCart}) {
					usi_data.get_session_data(usi_commons.gup("usi_rebuild"), usi_app.rebuild.start);
					return;
				}
				`
				)}
				${is(
					o.ajax_cart,
					`// Listen for cart posts
				usi_app.post_listen("${c.cartUrl}", usi_app.handle_ajax_response);
				`
				)}
				${is(
					o.cart_monitor,
					`// Monitor for cart
				if (!usi_app.cart_timeout) usi_app.start_cart_monitor();
				`
				)}
				${is(
					o.iframe,
					`// Send message to parent window, do not load campaigns in iframe
				if (window !== window.parent) {
					usi_app.iframe.send_message();
					return;
				}
	
				// Load cart page after item is added to cart
				var form = document.querySelector(".ADD_TO_CART_FORM")
				if (form != null) {
					usi_dom.attach_event("submit", function(){
						setTimeout(function(){
							usi_app.iframe.load("${c.cartUrl}");
						}, 1000);
					}, form);
				}
				`
				)}
				// Load campaigns
				usi_app.load();
			} catch(err) {
				usi_commons.report_error(err);
			}
		};
		
		usi_app.load = function () {
			try {
				usi_commons.log("usi_app.load()");
	
				if (typeof usi_js !== 'undefined' && typeof usi_js.cleanup === 'function') {
					usi_js.cleanup();
				}
				if (usi_app.is_suppressed) {
					return usi_commons.log("usi_app.load is suppressed");
				}
				${is(
					o.split_test,
					`
				var control_site_id = 'CONTROL_SITE_ID';
				var group = usi_app.force_group || (Math.random() < 0.10 ? 0 : 1);
				usi_split_test.instantiate(control_site_id, group);
				if (usi_split_test.get_group(control_site_id) == '1') {
					usi_commons.log("Split Group: USI");
				} else {
					usi_commons.log("Split Group: Control");
				}`
				)}
				// In Page - usi_load.jsp
				// TODO: check form options to see if usi_load or view
				${get_lines(forms.filter(form => !form.form.type.match("pc|em")), "inpage")}

				// Abandonment - view.jsp
				${get_lines(forms.filter(form => !form.form.type.match("pc|em")))}

				// Email Remarketing - monitor.jsp
				${get_lines(forms.filter(form => form.form.type.match("pc|em")))}
				${is(
					o.load_presubmit,
					`var checkout_button = document.querySelector("#SUBMIT_BUTTON");
				if (usi_app.is_enabled && checkout_button != null) {
					usi_commons.load_precapture("", "", function(){
						if (typeof usi_js_monitor != "undefined") {
							usi_dom.attach_event("click", function() {
								usi_commons.load_script("//www.upsellit.com/hound/pixel.jsp?companyID=" + usi_app.company_id);
							}, checkout_button);
						}
					});
				}`
				)}

				${is(o.get_social_proof, `
				if (usi_app.is_enabled) {
						usi_app.load_social_proof(usi_app.product.pid, function () {
							if (usi_app.product_data && usi_app.product_data[0]) {
								usi_commons.load_view("QXiSmkYrLDxSqYdRSTXMOxf", "44923", usi_commons.device);
							}
						});
				}`)}
				${is(
					o.load_postsubmit,
					`var checkout_button = document.querySelector("#SUBMIT_BUTTON");
				if (usi_app.is_enabled && checkout_button != null) {
					usi_commons.load_precapture("", "", function(){
						if (typeof usi_js_monitor != "undefined") {
							usi_dom.attach_event("click", function(){
								// add usi_post_submit to required fields
								usi_js_monitor.USI_LoadDynamics("1", "usi_post_submit", usi_js_monitor.USI_getASession());
							}, checkout_button);
						}
					});
				}`
				)}
			} catch(err) {
				usi_commons.report_error(err);
			}
		};
		${is(o.get_social_proof, `
		usi_app.load_social_proof = function(pid, callback){
			try {
				usi_app.social_proof_callback = function(product_data){
					try {
						usi_commons.log(product_data);
						callback(product_data);
					} catch (err) {
						usi_commons.report_error(err);
					}
				};
				usi_commons.load_script(usi_commons.cdn + "/utility/pid_lookup.jsp?pid=" + pid +
					"&orderID=" + usi_app.recommendation_site_cart +
					"&viewID=" + usi_app.recommendation_site_product +
					"&companyID=" + usi_app.company_id +
					"&custom_callback=usi_app.social_proof_callback");
			} catch (err) {
				usi_commons.report_error(err);
			}
		}`)}
		${is(
			o.post_purchase,
			`
		usi_app.load_post_purchase = function(callback){
			try {
				if (usi_cookies.get("usi_sale")) {
					window.usi_force = 1;
					callback();
				} else {
					setTimeout(function(){
						usi_app.load_post_purchase(callback);
					}, 1000);
				}
			} catch(err) {
				usi_commons.report_error(err);
			}
		}`
		)}
		${is(
			o.load_rec_filter,
			`
		usi_app.filter_recs = function(rows, callback){
			try {
				// create array of recs
				var recs = []
				for (var i = 1; i < rows && usi_app.product_rec["product" + i]; i++) {
					if (usi_app.product_rec["product" + i]) {
						recs.push(usi_app.product_rec["product" + i])
					}
				}
				// filter data
				var total = Number(usi_cookies.get("usi_subtotal"));
				var threshold = 100;
				var togo = threshold - total;
				var filtered = recs.sort(function(a, b){
					return Number(a.price.replace(/[^0-9.]/g, "")) > Number(b.price.replace(/[^0-9.]/g, "")) ? 1 : -1;
				}).filter(function(a){
					return Number(a.price.replace(/[^0-9.]/g, "")) >= togo;
				});
				// reset rec data
				for (var i = 0; i <= rows; i++) {
					if (filtered[i]) {
						usi_app.product_rec["product" + (i + 1)] = filtered[i];
					}
				}
				callback();
			} catch(err) {
				usi_commons.report_error(err);
			}
		};`
		)}
		${is(
			o.load_rec_options,
			`
		usi_app.get_options = function(data, callback){
			try {
				// store recs
				usi_app.recs = data;
				// build list of pids
				var usi_pid = "";
				for (var i in data) {
					if (data[i] && data[i].link) {
						usi_pid += data[i].link.substring(0, data[i].lastIndexOf("-")) + ",";
					}
				}
				usi_app.load_product_data({
					siteID: usi_app.recommendation_site_product,
					pid: "options",
					match: usi_pid,
					nomatch: "OUTOFSTOCK",
					allow_dupe_names: "1",
					rows: 200,
					callback: function() {
						for (var i in usi_app.recs) {
							if (usi_app.recs[i] && usi_app.recs[i].productname) {
								usi_app.recs[i].urls = [];
								usi_app.recs[i].options = [];
								for (var o in usi_app.product_rec) {
									// check if it has the same name but is not a duplicate
									if (usi_app.product_rec[o].productname == usi_app.recs[i].productname && usi_app.recs[i].urls.indexOf(usi_app.product_rec[o].url) == -1) {
										usi_app.recs[i].urls.push(usi_app.product_rec[o].url);
										// add matches to an options object on the original rec
										usi_app.recs[i].options.push(usi_app.product_rec[o]);
									}
								}
							}
						}
						if (typeof (usi_app.recs.product4) != "undefined") {
							callback();
						}
					}
				});
			} catch (err) {
				usi_commons.report_error(err);
			}
		};`
		)}
		${is(
			o.link_injection && !o.boost_bar,
			`
		usi_app.link_injection = function (src, callback) {
			try { 
				usi_cookies.del("usi_clicked");
				var iframe = document.createElement("iframe");
				iframe.src = src;
				iframe.style.width = "1px";
				iframe.style.height = "1px";
				if (callback != null) iframe.onload = callback;
				document.getElementsByTagName('body')[0].appendChild(iframe);
				usi_commons.log("[ link_injection ] Link Injection Success");
			} catch(err) {
				usi_commons.report_error(err);
			}
		};`
		)}
		${is(
			o.coupon_monitor,
			`
		usi_app.monitor_coupons = function(){
			try {
				var coupon_monitor_client = usi_coupon.client_builder()
					.setCompanyId(-1)
					.addPage("cart")
					.setDebug(true)
					.shouldScrapePage(function () {
						return false
					})
					.findButton(function () {
						return null
					})
					.findInputField(function () {
						return null
					})
					.scrapeDiscount(function () {
						return 0
					})
					.isCouponInvalid(function () {
						return false
					})
					.scrapeFailedMessage(function () {
						return ""
					})
					.isCouponValid(function () {
						return false
					})
					.scrapeSuccessMessage(function () {
						return ""
					})
					.build();
				//coupon_monitor_client.monitor();
			} catch(err) {
				usi_commons.report_error(err);
			}
		};`
		)}
		${is(
			o.coupon,
			`
		usi_app.apply_coupon = function() {
			try {
				usi_commons.log("usi_app.apply_coupon()");
				var coupon_input = document.querySelector("#INPUT_SELECTOR");
				var coupon_button = document.querySelector("#BUTTON_SELECTOR");
				if (coupon_input !== null && coupon_button !== null) {
					coupon_input.value = usi_app.coupon;
					${is(o.boost_bar, `usi_app.boostbar.close();`)}
					usi_cookies.set("usi_coupon_applied", usi_app.coupon, usi_cookies.expire_time.week);
					usi_cookies.del("usi_coupon");
					usi_app.coupon = "";
					coupon_button.disabled = false;
					var evt = document.createEvent('HTMLEvents');
					evt.initEvent("change", false, true);
					coupon_input.dispatchEvent(evt);
					coupon_button.click();
					usi_commons.log("Coupon applied");
				} else {
					if (usi_app.coupon_attempts == undefined) {
						usi_app.coupon_attempts = 0;
					} else if (usi_app.coupon_attempts >= 5) {
						usi_commons.report_error("Coupon elements not found");
						return;
					}
					usi_app.coupon_attempts++;
					usi_commons.log("Coupon elements missing, trying again. Tries: " + usi_app.coupon_attempts);
					setTimeout(usi_app.apply_coupon, 1000);
				}
			} catch(err) {
				usi_commons.report_error(err);
			}
		};`
		)}
		${is(
			o.preserve_params,
			`
		usi_app.preserve_original_qs = function () {
			try {
				// Set in click_cta: usi_cookies.set("usi_click", "1", usi_cookies.expire_time.hour);
				if (usi_commons.gup("usi_email_id") !== "") {
					return;
				}
				var usi_click = usi_cookies.get("usi_click") != null;
				var usi_original_utm = "";
				if (usi_cookies.get("usi_redirected") == null && usi_cookies.get("usi_original_utm") != null && usi_cookies.get("usi_original_utm") != "" && usi_click) {
					usi_cookies.del("usi_click");
					usi_original_utm = usi_cookies.get("usi_original_utm");
					usi_cookies.set("usi_original_utm", "", usi_cookies.expire_time.day);
					var usi_current_url = usi_app.url;
					if (usi_current_url.indexOf("?") != -1) usi_current_url = usi_current_url.substring(0, usi_current_url.indexOf("?"));
					usi_cookies.set("usi_redirected", "1", usi_cookies.expire_time.hour);
					window.location = usi_current_url + "?" + usi_original_utm;
				} else if (usi_cookies.get("usi_original_utm") == null) {
					if (usi_app.url.indexOf("?") != -1) {
						usi_original_utm = usi_app.url.substring(usi_app.url.indexOf("?") + 1, usi_app.url.length);
					}
					usi_cookies.set("usi_original_utm", usi_original_utm, usi_cookies.expire_time.day);
				}
			} catch (err) {
				usi_commons.report_error(err);
			}
		};`
		)}
		${is(
			o.boost_bar,
			`
		usi_app.boostbar = {
			load: function(text){
				text = text || 'Your coupon will be applied at checkout!';
				usi_commons.log("usi_app.boostbar.load()");
				if (usi_cookies.get("usi_boostbar") == "closed") return;

				var usi_boost_css = [
					'#usi_boost_close {height:100%; width:5%; right:5%; top:0; bottom:0; position:absolute; color:#fff; font-size:2em; text-decoration:none;}',
					'#usi_boost_container {position:fixed; bottom:0; left:0; right:0; width:100%; text-align:center; font-size:1.25em; background:#010101; color:#fff; padding:1em 2.5em; line-height: 1; z-index:2147483647;}'
				].join('');
				usi_app.boostbar.place_css(usi_boost_css);

				var usi_boost_container = document.createElement('div');
				usi_boost_container.innerHTML = [
					'<div id="usi_boost_container">',
					'<a id="usi_boost_close" href="javascript:usi_app.boostbar.close();" aria-label="close">&times;</a>',
					text,
					'</div>'
				].join('');
				document.body.appendChild(usi_boost_container);
			},
			place_css:function(css) {
				usi_commons.log("usi_app.boostbar.place_css()");
				var usi_css = document.createElement("style");
				usi_css.type = "text/css";
				if (usi_css.styleSheet) usi_css.styleSheet.cssText = css;
				else usi_css.innerHTML = css;
				document.getElementsByTagName('head')[0].appendChild(usi_css);
			},
			close: function(){
				usi_commons.log("usi_app.boostbar.close()");
				var bar = document.getElementById('usi_boost_container');
				usi_cookies.set("usi_boostbar", "closed", usi_cookies.expire_time.week);
				if (bar != null) {
					bar.parentNode.removeChild(bar);
				}
			}
		};`
		)}
		${is(
			o.page_visits,
			`
		usi_app.get_page_visits = function () {
			try { 
				usi_commons.log("usi_app.get_page_visits()");
				var page_visits = Number(usi_cookies.get("usi_page_visits")) + 1;
				usi_cookies.set("usi_page_visits", page_visits, usi_cookies.expire_time.day);
				usi_commons.log("Page visit: " + page_visits);
				return page_visits;
			} catch(err) {
				usi_commons.report_error(err);
			}
		};`
		)}
		
		${is(
			o.get_weather,
			`
		usi_app.lookup_weather = function (city, country, callback) {
			try {
				callback = callback || "usi_app.weather_callback";
				usi_commons.load_script(usi_commons.domain + "/utility/weather.jsp?city=" + city + "&country=" + country + "&callback=" + callback)
			} catch (err) {
				usi_commons.report_error(err)
			}
		}
		usi_app.weather_callback = function (condition) {
			try {
				var is_snowing = condition.match(/snow|sleet|freez|flurries/i) !== null || usi_commons.gup("usi_force_weather") == "snow";
				usi_app.current_weather = condition;
				usi_cookies.set("usi_weather", condition, usi_cookies.expire_time.day);
				usi_commons.log("Current Weather:" + condition);
			} catch (err) {
				usi_commons.report_error(err)
			}
		}
		usi_app.get_weather = function () {
			try { 
				usi_commons.log("usi_app.get_weather()");
				var weather = usi_cookies.get("usi_weather");
				if (!weather) {
					return usi_app.lookup_weather(usi_app.city, usi_app.country);
				}
				usi_commons.log("weather: " + weather);
				return weather;
			} catch(err) {
				usi_commons.report_error(err);
			}
		};`
		)}
		${is(
			o.new_visitor,
			`
		usi_app.check_if_logged_in = function() {
			try {
				usi_commons.log("usi_app.check_if_logged_in()");
				var element = document.querySelector("#LOGGEDIN")
				if (element != null) {
					usi_cookies.set("usi_visitor", "loggedin", usi_cookies.expire_time.day, true);
				}
				var logged_in = usi_cookies.value_exists("usi_visitor");
				usi_commons.log("Logged In: " + logged_in);
				return logged_in;
			} catch(err) {
				usi_commons.report_error(err);
			}
		};`
		)}
		${is(
			o.return_visitor,
			`
		usi_app.check_if_return_visitor = function() {
			try {
				usi_commons.log("usi_app.check_if_return_visitor()");
				var return_visitor = false;
				var cookie_name = "usi_return_visitor";
				var now = usi_date.set_date();
				
				try {
					if (usi_cookies.value_exists(cookie_name) === false) {
						usi_cookies.set(cookie_name, now, usi_cookies.expire_time.never, true);
					}
					var previous = new Date(usi_cookies.get(cookie_name));
					if (usi_date.is_date(previous)) {
						return_visitor = usi_date.diff(previous, now, 'hour', 2) > 24;
					}
				} catch(err) {
					usi_commons.report_error(err);
				}
				return return_visitor;
			} catch(err) {
				usi_commons.report_error(err);
			}
		};`
		)}
		${is(
			o.ip_return_visitor,
			`
		usi_app.customer_ip = function(last_purchase) {
			try {
				if (last_purchase != -1) {
					usi_cookies.set("usi_suppress", "1", usi_cookies.expire_time.never);
				} else {
					usi_app.main();
				}
			} catch(err) {
				usi_commons.report_error(err);
			}
		};`
		)}
		${is(
			o.recommendations || o.availability,
			`
		usi_app.scrape_product_page = function() {
			usi_commons.log("usi_app.scrape_product_page()");
			try {
				var product = {};
				product.link = location.protocol + '//' + location.host + location.pathname;
				product.pid = product.link.substring(product.link.lastIndexOf("/") + 1);
				product.image = document.querySelector('meta[property="og:image"]').content;
				product.name = document.querySelector('meta[property="og:title"]').content;
				product.price = document.querySelector('meta[property="og:price:amount"], meta[property="product:price:amount"]').content;
				product.in_stock = document.querySelector(".INSTOCK") !== null && document.querySelector(".INSTOCK").textContent.match(/ADD TO CART/i);
				product.extra = JSON.stringify({
					stock: product.in_stock ? "INSTOCK" : "OUTOFSTOCK"
				});
				return product;
			} catch (err) {
				usi_commons.report_error(err);
			}
		};`
		)}
		${is(
			o.recommendations,
			`
		usi_app.load_product_data = function(options) {
			usi_commons.log("usi_app.load_product_data()");
			try {
				var queryStr = "";
				var params = ['siteID', 'association_siteID', 'pid', 'less_expensive', 'rows', 'days_back', 'match', 'nomatch', 'force_exact'];
				params.forEach(function(name, index){
					if (options[name]) {
						queryStr += (index == 0 ? "?" : "&") + name + '=' + options[name];
					}
				});
				usi_commons.load_script(usi_commons.cdn + '/utility/product_recommendations.jsp' + queryStr, function(){
					if (typeof options.callback === 'function' && typeof usi_app.product_rec !== 'undefined') {
						options.callback(usi_app.product_rec);
					}
				});
			} catch (err) {
				usi_commons.report_error(err);
			}
		};`
		)}
		${is(
			o.rec_reporting,
			`
		usi_app.seen = function(id, site_id, config_id, seenProducts, targetProduct) {
			try {
				usi_prodrec.report_product_view2(id, site_id, config_id, seenProducts, targetProduct);
			} catch (err) {
				usi_commons.report_error(err);
			}
		};
		usi_app.clicked = function (id, site_id, config_id, clickedProducts, targetProduct, usi_callback) {
			try {
				usi_prodrec.report_product_click2(id, site_id, config_id, clickedProducts, targetProduct, usi_callback);
			} catch (err) {
				usi_commons.report_error(err);
			}
		};`
		)}
		${is(
			o.iframe,
			`
		usi_app.iframe = {
			load: function(source, callback) {
				try {
					usi_commons.log("usi_app.iframe.load()");
					// Listen for the cart iFrame to send a message
					usi_dom.attach_event('message', usi_app.iframe.callback, window);
	
					// Load iframe
					var iframe = document.createElement("iframe");
					iframe.src = source;
					iframe.style.visibility = "hidden";
					iframe.id = "usi_iframe";
					document.body.appendChild(iframe);
				} catch(err) {
					usi_commons.report_error(err);
				}
			},
			callback: function(message) {
				try {
					usi_commons.log("usi_app.iframe.callback()");
					if (message.data.is_usi !== true) return;
	
					// Stop listening for the cart iFrame to send a message
					usi_dom.detach_event('message', usi_app.iframe.callback, window);
	
					// Remove the iframe
					var cartIFrameElement = document.querySelector('#usi_iframe');
					if (cartIFrameElement != null) {
						document.body.removeChild(cartIFrameElement);
					}
	
					// Load a campaign if applicable
					usi_app.main();
				} catch(err) {
					usi_commons.report_error(err);
				}
			},
			send_message: function(msg){
				try {
					usi_commons.log("usi_app.iframe.send_message()");
					msg = msg || {};
					msg.is_usi = true;
					window.parent.postMessage(msg, '*');
				} catch(err) {
					usi_commons.report_error(err);
				}
			}
		};`
		)}
		${is(
			o.ajax_cart,
			`
		usi_app.post_listen = function(url, callback) {
			try {
				usi_commons.log("usi_app.post_listen()");
				if (!usi_app.ajax) usi_app.ajax = {};
				usi_app.ajax[url] = new usi_ajax.listener();
				usi_app.ajax[url].register('POST', url, callback);
				usi_app.ajax[url].listen();
			} catch(err) {
				usi_commons.report_error(err);
			}
		};
		usi_app.is_json = function(str) {
			try {
				JSON.parse(str);
			} catch(err) {
				return false
			}
			return true;
		};
		usi_app.handle_ajax_response = function(err, response) {
			try {
				usi_commons.log("usi_app.handle_ajax_response()");
				if (err) {
					return usi_commons.report_error(err);
				} else if (!response || !response.responseText || !usi_app.is_json(response.responseText)) {
					return usi_commons.report_error('Incorrect response');
				}
				var data = JSON.parse(response.responseText);
				// Handle response
			} catch(err) {
				usi_commons.report_error(err);
			}
		};`
		)}
		${is(
			o.cart_monitor,
			`
		usi_app.start_cart_monitor = function(){
			try {
				usi_commons.log("usi_app.start_cart_monitor()");
				var monitor_cart = function() {
					var total = usi_app.scrape_subtotal();
					// Only update if cart subtotal changes
					if (total && Number(total) > 0 && usi_app.total != total && usi_app.scrape_cart()) {
						usi_app.total = total;
						usi_app.save_cart();
						// Check if data has changed and load campaigns
						usi_app.main();
					}
				};
				monitor_cart();
				if (!usi_app.cart_timeout) {
					usi_app.cart_timeout = setTimeout(monitor_cart, 1000);
				}
			} catch(err) {
				usi_commons.report_error(err);
			}
		};`
		)}
		${is(
			o.cart || o.cart_monitor,
			`
		usi_app.save_cart = function(){
			try {
				usi_commons.log("usi_app.save_cart()");
				var cart_prefix = "usi_prod_";
				usi_cookies.flush("usi_subtotal");
				usi_cookies.flush(cart_prefix);
				usi_app.cart = {
					items: usi_app.scrape_cart(),
					subtotal: usi_app.scrape_subtotal()
				}
				usi_commons.log(usi_app.cart);

				if (typeof usi_app.cart.items != "undefined" && typeof usi_app.cart.subtotal != "undefined") {
					usi_cookies.set("usi_subtotal", usi_app.cart.subtotal, usi_cookies.expire_time.week);
					${is(
						o.cart_rebuilder,
						`var rebuild_data = usi_app.cart.items.map(function(item) {
						// Update to return required data
						return item.pid;
					});
					usi_app.rebuild.save(rebuild_data);`
					)}
					usi_app.cart.items.forEach(function(product, index){
						var prop;
						${is(o.recommendations, `usi_commons.send_prod_rec(usi_app.recommendation_site_cart, product);`)}
						if (index >= 3) return;
						for (prop in product) {
							if (product.hasOwnProperty(prop)) {
								usi_cookies.set(cart_prefix + prop + "_" + (index + 1), product[prop], usi_cookies.expire_time.week);
							}
						}
					});
				}
			} catch(err) {
				usi_commons.report_error(err);
			}
		};
		usi_app.scrape_subtotal = function() {
			try {
				var subtotal = document.querySelector("#SUBTOTAL");
				if (subtotal != null) {
					subtotal = subtotal.textContent;
					return subtotal.replace(/[^0-9.]/g, '');
				}
			} catch (err) {
				usi_commons.report_error(err);
			}
		};
		usi_app.scrape_cart = function() {
			try {
				return usi_dom.get_elements('.CART_ROW').map(function(container){
					var product = {};
					product.link = container.querySelector(".LINK a").href;
					product.pid = product.link.substring(product.link.lastIndexOf("/") + 1);
					product.image = container.querySelector(".IMAGE img").src;
					product.name = container.querySelector(".NAME").textContent.trim();
					product.price = container.querySelector(".PRICE").textContent.trim();
					return product;
				});
			} catch (err) {
				usi_commons.report_error(err);
			}
		};`
		)}
		${is(
			o.cart_rebuilder,
			`
		usi_app.rebuild = {
			save: function(obj){
				try {
					var string = JSON.stringify(obj);
					var usi_cart_split = string.match(/.{1,900}/g);
					usi_cart_split.forEach(function(chunk, i){
						usi_cookies.set("usi_cart" + (i + 1), chunk, usi_cookies.expire_time.week);
					});
				} catch(err) {
					usi_commons.report_error(err);
				}
			},
			send: function(){
				try {
					var send_data = function(name, value){
						// usi_js_monitor.USI_LoadDynamics(value, name, usi_js_monitor.USI_getASession());
						usi_js.send_data(name, value);
					};
					for (var i = 1; i < 100; i++) {
						if (usi_cookies.value_exists("usi_cart" + i)) {
							var temp_chunk = usi_cookies.get("usi_cart" + i);
							send_data("usi_cart" + i, temp_chunk);
						} else {
							break;
						}
					}
				} catch(err) {
					usi_commons.report_error(err);
				}
			},
			get: function(response){
				try {
					var cart_string = "";
					for (var i = 1; i < 100; i++) {
						if (typeof response["usi_cart" + i] == "string") {
							cart_string += response["usi_cart" + i];
						} else if (typeof response["usi_cart" + i] != "undefined") {
							cart_string +=  JSON.stringify(response["usi_cart" + i]);
						} else {
							break;
						}
					}
					return JSON.parse(cart_string);
				} catch(err) {
					usi_commons.report_error(err);
				}
			},
			start: function(err, response){
				try {
					usi_commons.log("usi_app.rebuild.start()");
					if (response) {
						// Save required data as JSON in session as 'usi_cart'
						var usi_cart = usi_app.rebuild.get(response);
						// Show message while rebuilding
						var alert = document.querySelector(".EMPTY_CART_MESSAGE");
						if (alert != null) alert.innerHTML = "One moment while we rebuild your cart...";
						else return usi_commons.log("Items in cart, don't rebuild");
						usi_app.rebuild.add_items(usi_cart);
						usi_commons.log(response);
					} else {
						usi_commons.report_error(err);
					}
				} catch(err) {
					usi_commons.report_error(err);
				}
			},
			add_items: function(usi_cart) {
				try {
					usi_commons.log("usi_app.rebuild.add_items()");
					if (usi_cart.length === 0) {
						location.href = "https://www.EXAMPLE.com/CARTPAGE";
						return;
					}
		
					var item = usi_cart.shift();
					usi_commons.log("Adding product");
					usi_ajax.post_with_options({
						url: "https://www.EXAMPLE.com/AddProduct",
						params: item
					}, function () {
						usi_app.rebuild.add_items(usi_cart);
					});
				} catch(err) {
					usi_commons.report_error(err);
				}
			}
		}`
		)}
		
		${
			o.geolocation
				? `usi_app.session_data_callback = function() {
			usi_app.country = usi_app.session_data.country;
			if (usi_app.country !== "US" || usi_app.session_data.ip == "IP_TO_SUPPRESS") {
				return;
			}
			usi_dom.ready(function () {
				try {
					usi_app.main();
				} catch (err) {
					usi_commons.report_error(err);
				}
			});
		};
		usi_commons.load_session_data();`
				: o.spa
				? `usi_app.check_for_change = function(){
			if (usi_app.current_page == undefined || usi_app.current_page != location.href) {
				usi_app.current_page = location.href;
				usi_app.main();
				usi_commons.log("USI: running main");
			}
			setTimeout(usi_app.check_for_change, 1000);
		};
		if (!usi_app.check_for_change_timeout_id) {
			usi_app.check_for_change_timeout_id = setTimeout(usi_app.check_for_change, 1000);
		}`
				: o.dom_utils
				? `usi_dom.ready(function(){
			try {
				usi_app.main();
			} catch (err) {
				usi_commons.report_error(err);
			}
		});`
				: `usi_app.main();`
		}
	} catch(err) {
		usi_commons.report_error(err);
	}
}
`);
		},
		css: (o) =>
		o.usi_load ? usi_code.usi_load_css(o) : usi_code.update(`.usi_display * {
	font-size: 1em;
	line-height: 1.2;
	box-sizing: border-box;
	color: inherit;
	font-family: inherit;
}
.usi_display {
	color: #000;
	font-family: inherit;
}
${is(
	o.css,
	`
#usi_content {
	background: #dedede;
}
.usi_head {
	text-align: center;
	font-size: 2em;
	padding: 5%;
	width: 65%;
	position: absolute;
	top: 15%;
	left: 35%;
}
`
)}
${is(
	o.sidebar,
	`@media screen and (max-height:1080px) {
	#usi_display {
		height:100vh;
		width:42.6vh; /*width/height*/
		font-size:1.5vh;
		top: 0;
	left: auto;
		margin: 0;
	}
}
.usi_display {
	position: fixed !important;
}
`
)}
${is(
	o.close || o.css,
	`#usi_close {
	width: 2em;
	height: 2em;
	text-align: center;
	font-size: 1.5em;
	left: auto;
	right: 0;
	background: none;
	cursor: pointer;
}
#usi_close:after {
	content: "\\d7";
}
`
)}
${is(
	o.boxshadow,
	`#usi_content {
	box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.33);
}
`
)}
${is(
	o.responsive,
	`/* Make display responsive: Set max-width query to the graphic width, and padding-top to height/width */
@media screen and (max-width: 750px) {
	.usi_display {
		top: 0;
		left: 0;
		margin: 0;
		width: 100% !important;
		height: auto !important;
		padding-top: 84%;
		font-size: 2vw;
	}
}
`
)}
${is(
	o.css,
	`/* Submit Button */
.usi_submitbutton:hover, .usi_submitbutton:active, .usi_submitbutton:focus {
	background: #fff;
	border: none;
	outline: none;
	box-shadow: none;
}
.usi_submitbutton {
	position: absolute;
	${
		o.mobile
			? `top: 75%;
	left: 0%;
	width: 100%;
	height: 25%;`
			: `top: 70%;
	left: 45%;
	width: 45%;
	height: 15%;`
	}
	margin: 0;
	padding: 0;
	display: inline-block;
	outline: none;
	background: #fff;
	border: none;
	cursor: pointer;
	text-align: center;
	box-shadow: none;
}
`
)}
${is(
	!o.recommendations_view && !o.css,
	`/* Submit Button */
.usi_submitbutton:hover, .usi_submitbutton:active, .usi_submitbutton:focus {
	background: none;
	border: none;
	outline: none;
}
.usi_submitbutton {
	position: absolute;
	${
		o.mobile
			? `top: 75%;
	left: 0%;
	width: 100%;
	height: 25%;`
			: `top: 70%;
	left: 45%;
	width: 45%;
	height: 15%;`
	}
	margin: 0;
	padding: 0;
	display: inline-block;
	outline: none;
	background: none;
	border: none;
	cursor: pointer;
	text-align: center;
}
`
)}
${is(
	o.survey,
	`/* Survey */
.usi_question {
	padding:0.5em;
	padding-left:1.1em;
	position:absolute;
	top:25%;
	left:0;
	width:100%;
}
.usi_question label, .usi_question textarea, .usi_question input {
	color:#333;
}
.usi_question label {
	margin-left:1em;
	margin-top:0.68em;
	display: inline-block;
}
.usi_block label {
	display:block;
}
.usi_required:after {
	content: "*";
	color: #ff9012;
	padding-left: 0.25em;
}
`
)}
${is(
	o.countdown,
	`/* Countdown */
.usi_countdown {
	position: absolute;
	text-align: center;
	${
		o.mobile
			? `top: 20%;
	left: 0%;
	width: 100%;
	left: 0%;`
			: ` top: 20%;
	left: 45%;
	width: 45%;`
	}
}
`
)}
${is(
	o.no_thanks,
	`/* No Thanks Button */
.usi_nothanks {
	position: absolute;
	${
		o.mobile
			? `top: 50%;
	height: 5%;
	width: 100%;
	left: 0%;`
			: `top: 90%;
	height: 5%;
	width: 45%;
	left: 45%;`
	}
	text-align: center;
	cursor: pointer;
	outline: none;
	background: none;
	border: none;
}
`
)}
${is(
	o.lc || o.availability,
	`/* Lead Capture */
#usi_email_container {
	position: absolute;
	${
		o.mobile
			? `top: 65%;
	height: 10%;
	width: 100%;
	left: 0%;`
			: `top: 55%;
	left: 45%;
	width: 45%;
	height: 10%;`
	}
	${is(o.css, `background: #fff;`)}
}
#usi_email {
	font-style: italic;
	box-shadow: none;
	padding: 0 0 0 5%;
	width: 100%;
	height: 100%;
	border: none;
	outline: none;
	background-color: transparent;
	color: #666;
	text-align: left;
}
#usi_email::-ms-clear {
	display: none;
}
#usi_email_good_container {
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
	right: 2%;
	width: 14px;
	height: 14px;
}
`
)}
${is(
	o.phone,
	`/* Phone Capture */
#usi_phone_container {
	position: absolute;
	${
		o.mobile
			? `top: 65%;
	height: 10%;
	width: 100%;
	left: 0%;`
			: `top: 55%;
	left: 45%;
	width: 45%;
	height: 10%;`
	}
}
#usi_phone {
	font-style: italic;
	box-shadow: none;
	padding: 0 0 0 5%;
	width: 100%;
	height: 100%;
	border: none;
	outline: none;
	background-color: transparent;
	color: #666;
	text-align: left;
}
#usi_phone::-ms-clear {
	display: none;
}
#usi_phone_good_container {
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
	right: 2%;
	width: 14px;
	height: 14px;
}
`
)}

${is(
	o.lc_opt_in,
	`/* Opt In */
.usi_optin {
	position: absolute;
	${
		o.mobile
			? `top: 60%;
	left: 0%;
	width: 100%;`
			: `top: 48%;
	left: 0%;
	width: 100%;`
	}
	text-align: center;
}
.usi_disclaimer label {
}
`
)}
${is(
	o.disclaimer || o.lc || o.phone || o.availability,
	`/* Disclaimer */
.usi_disclaimer {
	position: absolute;
	${
		o.mobile
			? `top: 60%;
	left: 0%;
	width: 100%;`
			: `top: 91%;
	left: 0%;
	width: 100%;`
	}
	text-align: center;
	color: #666;
	font-size: .7em;
}
.usi_disclaimer a {
	text-align: center;
	color: inherit;
	font-size: 1em;
	text-decoration: underline !important;
}
`
)}
${is(
	o.bottom_slider,
	`/* Bottom Slider */
.usi_display {
	transition: bottom 0.5s ease-in-out;
	top:auto;
}
#usi_tab {
	position: absolute;
	top: -30px;
}
#usi_tab img {
	height: 30px;
}
`
)}
${is(
	o.side_slider,
	`/* Side Slider */
.usi_display {
	transition: left 0.5s ease-in-out;
}
#usi_tab {
	position: absolute;
	right: -50px;
}
#usi_tab img {
	width: 50px;
}
`
)}
${is(
	o.one_prod,
	`/* 1 Product Info */
.usi_product_info {
	position: absolute;
	padding: 0 5%;
	${
		o.mobile
			? `top: 25%;
	left: 50%;
	width: 38%;`
			: `top: 70%;
	left: 0;
	width: 38%;`
	}
}
.usi_product_image {
	position: absolute;
	padding: 0 5%;
	max-width: 100%;
	text-align: center;
	${
		o.mobile
			? `top: 25%;
	left: 5%;
	width: 30%;
	height: 30%;`
			: `top: 16%;
	left: 0;
	width: 38%;
	height: 50%;`
	}
}
.usi_product_image img {
	max-width: 100%;
	max-height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	margin: auto;
}
.usi_price_table {
	width: 100%;
	border-spacing: 0;
}
.usi_price_table td {
	padding: 0.25em 0;
}
.usi_product_name {
	height: 3.6em;
	overflow: hidden;
	font-size: 1.25em;
	text-align: center;
	line-height: 1;
	padding-bottom: 1em;
}
.usi_price td {
	color: #666;
}
.usi_new_price td {
	font-size: 1.2em;
}
.usi_value {
	text-align: right;
}
`
)}
${is(
	o.three_prod && !o.mobile,
	`/* 3 Product Info */
.usi_product_info {
	position: absolute;
	padding: 0 5%;
	top: 10%;
	left: 0;
	width: 38%;
	height: 66%;
}
.usi_product {
	border-bottom: 1px solid #eee;
	height: 27%;
	margin: 8% 0;
}
.usi_product_image {
	float: left;
	position: relative;
	height: 100%;
	max-width: 100%;
	text-align: center;
	margin-right: 1em;
	width: 38%;
}
.usi_product_price {
	padding-top: 0.5em;
}
.usi_product_image img {
	max-width: 100%;
	max-height: 100%;
	margin: auto;
}
.usi_price_table {
	position: absolute;
	border-spacing: 0;
	top: 80%;
	left: 0;
	width: 28%;
	margin: 0 5%;
}
.usi_price_table td {
	padding: 0.25em 0;
}
.usi_product_name {
	color: #666;
	height: 3.6em;
	overflow: hidden;
}
.usi_new_price td {
	font-weight: bold;
}
.usi_value {
	text-align: right;
}
`
)}
${is(
	o.three_prod && o.mobile,
	`/* 3 Product Info, mobile */
.usi_product_info {
	position: absolute;
	padding: 0 5%;
	top: 30%;
	left: 0;
	width: 100%;
	height: 30%;
	text-align: center;
}
.usi_product {
	width: 33.333%;
	display: inline-block;
	vertical-align:middle;
	padding: 0 1em;
}
.usi_product_image {
	width: 100%;
	text-align: center;
}
.usi_product_image img {
	max-width: 100%;
	max-height: 100%;
	margin: auto;
}
.usi_price_table {
	position: absolute;
	border-spacing: 0;
	top: 65%;
	left: 10%;
	width: 80%;
}
.usi_price_table td {
	padding: 0.25em 0;
}
.usi_new_price td {
	font-weight: bold;
}
.usi_value {
	text-align: right;
}
`
)}
${is(
	o.spinner,
	`/* Spinner */
#usi_background img {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	outline: none !important;
}
.usi_discount {
	position: absolute;
	top: 50%;
	left: 35%;
	width: 30%;
	height: 10%;
	font-size: 2em;
	text-align: center;
	margin: auto;
	font-weight: bold;
}
.usi_rotate_waiting {
	-webkit-animation: usi_rotate_waiting 2s ease-out infinite;
	animation: usi_rotate_waiting 2s ease-out infinite;
}
.usi_rotate_spinning {
	-webkit-animation: usi_rotate_spinning 0.5s linear infinite;
	animation: usi_rotate_spinning 0.5s linear infinite;
}
.usi_rotate_stopping {
	-webkit-animation: usi_rotate_stopping 4s cubic-bezier(0.1, 0.45, 0.57, 1.03) forwards;
	animation: usi_rotate_stopping 4s cubic-bezier(0.1, 0.45, 0.57, 1.03) forwards;
}
.usi_rotate_stopped {
	-webkit-transform: rotate(720deg);
	transform: rotate(720deg);
}
@-webkit-keyframes usi_rotate_waiting {
	0% {-webkit-transform: rotate(-10deg);transform: rotate(-10deg);}
	50% {-webkit-transform: rotate(10deg);transform: rotate(10deg);}
	100% {-webkit-transform: rotate(-10deg); transform: rotate(-10deg);}
}
@keyframes usi_rotate_waiting {
	0% {-webkit-transform: rotate(-10deg);transform: rotate(-10deg);}
	50% {-webkit-transform: rotate(10deg);transform: rotate(10deg);}
	100% {-webkit-transform: rotate(-10deg);transform: rotate(-10deg);}
}
@-webkit-keyframes usi_rotate_spinning {
	0% {-webkit-transform: rotate(0deg);transform: rotate(0deg);}
	100% {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}
@keyframes usi_rotate_spinning {
	0% {-webkit-transform: rotate(0deg);transform: rotate(0deg);}
	100% {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}
@-webkit-keyframes usi_rotate_stopping {
	0% {-webkit-transform: rotate(0deg);transform: rotate(0deg);}
	100% {-webkit-transform: rotate(720deg);transform: rotate(720deg);}
}
@keyframes usi_rotate_stopping {
	0% {-webkit-transform: rotate(0deg);transform: rotate(0deg);}
	100% {-webkit-transform: rotate(720deg);transform: rotate(720deg);}
}
`
)}
${is(
	o.recommendations_view,
	`/* Recommended Products */
.usi_products {
	position: absolute;
	top: 37%;
	left: 5%;
	overflow: hidden;
	width: 90%;
	height: 60%;
}
`
)}
${is(
	o.recommendations_view && !o.sidebar,
	`.usi_product {
	width: 33%;
	height: 100%;
	display: inline-block;
	vertical-align: top;
}
.usi_prod_image_link {
	text-align: center;
	display: block;
	height: 40%;
	vertical-align: top;
	cursor: pointer;
}
.usi_prod_image {
	max-width: 100%;
	max-height: 100%;
}
.usi_name {
	text-align: center;
	height: 3.6em;
	margin-top: 1em;
	font-weight: bold;
	overflow: hidden;
}
.usi_price {
	font-weight: bold;
	padding: 0.5em 0;
	text-align: center;
}
.usi_link {
	background: #000;
	color: #fff;
	padding: 0.5em;
	text-align: center;
	margin: 0.5em;
	display: block;
	text-decoration: none;
	cursor: pointer;
}
.usi_link:hover {
	color: #fff;
	text-decoration: none;
}
`
)}
${is(
	o.recommendations_view && o.sidebar,
	`.usi_product {
	height: 33.3333%;
}
.usi_product_info {
	display: inline-block;
	width: 70%;
	padding-left: 5%;
	vertical-align: top;
}
.usi_prod_image_link {
	text-align: center;
	display: inline-block;
	width: 30%;
	height: 100%;
	vertical-align: top;
	cursor: pointer;
}
.usi_prod_image {
	max-width: 100%;
	max-height: 100%;
}
.usi_name {
	height: 3.6em;
	font-weight: bold;
	overflow: hidden;
}
.usi_price {
	padding: 0.5em 0;
}
.usi_link {
	background: #005599;
	color: #fff;
	padding: 0.5em;
	text-align: center;
	display: block;
	text-decoration: none;
	cursor: pointer;
}
.usi_link:hover {
	color: #fff;
	text-decoration: none;
}
`
)}
${is(
	o.progress_bar,
	`/* Progress Bar */
.usi_progress_bar {
	background: #fff;
	border-radius: 100px;
	position: absolute;
	top: 50%;
	left: 25%;
	width: 50%;
	height: 3em;
	line-height: 3em;
}
.usi_progress_made, .usi_progress_made span {
	color: red;
	font-weight: bold;
}
.usi_progress_made {
	background: #999;
	text-align: center;
	border-radius: 100px;
	height: 100%;
	width: 0%;
	line-height: 3em;
	white-space: nowrap;
	padding: 0 1em;
	font-weight: bold;
}
.usi_progress_end {
	background: red;
	color: #fff;
	text-align: center;
	position: absolute;
	right: -0.5em;
	border-radius: 100px;
	width: 2.5em;
	height: 2.5em;
	top: -0.5em;
	line-height: 2.5em;
	font-size: 2em;
}
`
)}
${is(
	o.carousel,
	`/* Carousel */
#usi_carousel {
	position: absolute;
	top: 10%;
	left: 5%;
	width: 35%;
	height: 80%;
	background-size: cover;
	background-position: center;
	transition: background-image 0.5s ease-in-out;
}
`
)}
`),
		view: (o) =>
			o.usi_load ? usi_code.usi_load_view(o) : usi_code.update(`${is(
				o.in_page,
				`var target_element = document.body;
if (target_element != null) {
	var usi_container = document.createElement("div");
	usi_container.setAttribute("id", "usi_container");
	usi_container.appendChild(usi_js.display_div);
	target_element.appendChild(usi_container);
	//target_element.parentNode.insertBefore(usi_container, target_element);
	//target_element.insertAdjacentElement("afterend", usi_container);
} else {
	usi_js.suppress = true;
}
`
			)}
${is(o.use_srcset, `usi_js.use_srcset = true;`)}
${is(
	o.one_prod || o.three_prod || o.lc || o.availability || o.progress_bar || o.availability,
	`try {
	usi_js.product = {};
	${is(
		o.availability,
		`
	usi_js.product = usi_app.product;
	usi_js.product.usi_pid_required = usi_app.product.pid + "_" + usi_js.campaign.company_id;`
	)}
	${is(
		o.one_prod,
		`
	usi_js.product.image = usi_cookies.get("usi_prod_image_1");
	usi_js.product.name = usi_cookies.get("usi_prod_name_1");
	usi_js.product.price = usi_cookies.get("usi_prod_price_1");
	usi_js.product.discount = (Number(usi_js.product.price) * 0.10).toFixed(2);
	usi_js.product.new_price = (Number(usi_js.product.price) - Number(usi_js.product.discount)).toFixed(2);
	if (isNaN(usi_js.product.discount)) throw new Error("discount is NaN");
	if (isNaN(usi_js.product.new_price)) throw new Error("new_price is NaN");`
	)}
	${is(
		o.progress_bar,
		`
	usi_js.product.total = usi_cookies.get("usi_subtotal");`
	)}
	${is(
		o.three_prod,
		`
	usi_js.product.subtotal = usi_cookies.get("usi_subtotal");
	usi_js.product.discount = (Number(usi_js.product.subtotal) * 0.15).toFixed(2);
	usi_js.product.new_price = (Number(usi_js.product.subtotal) - Number(usi_js.product.discount)).toFixed(2);
	if (isNaN(usi_js.product.subtotal)) throw new Error("subtotal is NaN");
	if (isNaN(usi_js.product.discount)) throw new Error("discount is NaN");
	if (isNaN(usi_js.product.new_price)) throw new Error("new_price is NaN");`
	)}
} catch (err) {
	usi_commons.report_error(err);
	usi_js.launch.enabled = false;
	usi_js.launch.suppress = true;
}`
)}
${is(
	o.pull_coupon && !o.lc && !o.availability,
	`
usi_js.promo_callback = function(usi_promo){
	usi_js.trace('promo_callback(' + usi_promo + ')');
	usi_cookies.set("usi_coupon", usi_promo, usi_cookies.expire_time.day, true);
	usi_js.link(${is(o.pull_coupon && !o.lc && !o.availability && o.recommendations_view, `usi_js.destination`)});
};`
)}
${is(o.rec_reporting, `usi_js.products_seen = [];`)}
usi_js.click_cta = function(${is(o.recommendations_view, `product`)}){
	try {
		${is(
			o.lc || o.survey || o.phone || o.availability,
			`if (usi_js.post_close == usi_js.click_cta) {
			usi_js.post_close = function() {};
		}`
		)}
		${is(
			o.needs_link,
			`// load affiliate link from app file on next page load
		usi_cookies.set("usi_needs_link", 1);`
		)}
		${is(
			o.link_injection,
			`usi_js.link_injection(usi_js.campaign.link, function(){
			usi_js.close();
		});`
		)}
		${is(
			o.shopify_coupon,
			`var origin = location.protocol + "//" + location.host;
		usi_js.deep_link(origin + "/discount/" + usi_js.campaign.coupon + "?redirect=" + location.href.replace(origin, ""));`
		)}
		${is(
			o.shopify_coupon_checkout,
			`var origin = location.protocol + "//" + location.host;
		usi_js.deep_link(origin + "/checkout/?discount=" + usi_js.campaign.coupon);`
		)}
		${is(o.set_coupon && !o.lc && !o.availability, `usi_js.set_coupon();`)}
		${is(
			o.rec_reporting,
			`if (typeof(usi_app.clicked) !== "undefined") {
			usi_app.clicked(usi_js.campaign.id, usi_js.campaign.site_id, usi_js.campaign.config_id, product || {}, usi_app.product_rec.product0 || {}, function(){
				${is(
					o.pull_coupon && !o.lc && !o.availability,
					`${is(o.recommendations_view, `usi_js.destination = product ? product.url : location.href`)}
				usi_js.pull_coupon();`
				)}
				${is(!(o.pull_coupon || o.mobile_phone) && o.recommendations_view, `usi_js.deep_link_new_window(product.url);`)}
			});
		}`
		)}
		${is(
			o.pull_coupon && !o.lc && !o.availability && !o.rec_reporting,
			`${is(o.recommendations_view, `usi_js.destination = product ? product.url : location.href`)}
		usi_js.pull_coupon();`
		)}
		${is(
			!(o.pull_coupon || o.mobile_phone) && !o.rec_reporting && o.recommendations_view,
			`usi_js.deep_link_new_window(product.url);`
		)}
		${is(
			!(
				o.link_injection ||
				o.shopify_coupon_checkout ||
				o.shopify_coupon ||
				o.pull_coupon ||
				o.mobile_phone ||
				o.recommendations_view ||
				o.rec_reporting
			) ||
				o.lc ||
				o.availability,
			`usi_js.deep_link();`
		)}
		${is(
			o.mobile_phone,
			`setTimeout(function(){
			usi_js.send_data("usi_short_code", usi_js.short_code);
			usi_js.deep_link();
		}, 1000);`
		)}
	} catch(err) {
		usi_commons.report_error(err);
	}
};
${is(
	o.lc || o.survey || o.phone || o.availability,
	`usi_js.submit_success = function() {
	${is(o.lc_opt_in, `usi_js.send_data("optedin", "1");`)}
	${is(o.phone, `usi_js.phone.send_data();`)}
	${is(
		o.recommendations_view,
		`/* Send Recommended Products */
	try {
		var item_num, usi_product, prop;
		for (var i = 1; i <= 3; i++) {
			var this_prod = usi_app.product_rec["product" + i];
			if (this_prod != undefined) {
				for (var j in this_prod) {
					if (["productname", "url", "image", "price"].indexOf(j) != -1	&& this_prod.hasOwnProperty(j)) {
						usi_js.send_data('usi_rec_' + j + '_' + i, this_prod[j]);
					}
				}
			}
		}
	} catch (e) {}
`
	)}
	usi_js.post_close = usi_js.click_cta;
}`
)}
${is(
	o.rec_reporting,
	`usi_js.post_display = function(){
	if (typeof(usi_app.seen) !== "undefined") {
		usi_app.seen(usi_js.campaign.id, usi_js.campaign.site_id, usi_js.campaign.config_id, usi_js.products_seen || [], usi_app.product_rec.product0 || {});
	}
};`
)}
${is(
	o.phone,
	`usi_js.post_display = function(){
	if (!usi_js.timers.verify_phone_loop_id) {
		usi_js.timers.verify_phone_loop_id = setTimeout(usi_js.phone.verify_phone_loop, 1000);
	}
};
usi_js.phone = {
	send_data: function(){
		try {
			var chars = "abcdefghjkmnpqrstuvwxyz23456789";
			var string_length = 7;
			var randomstring = '';
			for (var i=0; i<string_length; i++) {
				var rnum = Math.floor(Math.random() * chars.length);
				randomstring += chars.substring(rnum,rnum+1);
			}
			usi_js.send_data("usi_short_code", randomstring);
			usi_js.send_data("usi_phone", document.getElementById("usi_phone").value);
		} catch(err) {
			usi_commons.report_error(err);
		}
	},
	submit: function() {
		try {
			var num = document.getElementById("usi_phone").value.replace(/\\D/g,'');
			if (num.length === 10) {
				usi_js.submit();
			} else {
				alert("Please enter a valid phone number");
			}
		} catch(err) {
			usi_commons.report_error(err);
		}
	},
	format: function(usi_phone){
		try {
			var input = usi_phone.value;
			input = input.replace(/\\D/g,'');
			input = input.substring(0,10);
			var size = input.length;
			if (size == 0){
				input = input;
			} else if (size < 4) {
				input = '('+input;
			} else if (size < 7) {
				input = '('+input.substring(0,3)+') '+input.substring(3,6);
			} else {
				input = '('+input.substring(0,3)+') '+input.substring(3,6)+' - '+input.substring(6,10);
			}
			usi_phone.value = input;
		} catch(err) {
			usi_commons.report_error(err);
		}
	},
	verify_phone_loop: function() {
		try {
			usi_js.trace('verify_phone_loop()');
			if (document.getElementById("usi_phone") != null) {
				var phoneInput = document.getElementById("usi_phone");
				var phone = phoneInput.value;
				if (usi_js.page_status.phone_last != phone && phone != phoneInput.title) {
					usi_js.page_status.phone_last = phone;
					usi_js.phone.verify_loop_result(usi_js.phone.validate(phone.trim()));
				}
				return
			}
			setTimeout(usi_js.phone.verify_phone_loop, 1000);
		} catch(err) {
			usi_commons.report_error(err);
		}
	},
	verify_loop_result: function(isokay) {
		try {
			var phoneCheck = document.getElementById("usi_phone_good");
			if (phoneCheck != null) {
				if (!isokay) {
					phoneCheck.src = usi_js.campaign.images + usi_js.display_vars.emailerror;
				} else {
					phoneCheck.src = usi_js.campaign.images + usi_js.display_vars.emailsuccess;
				}
			}
		} catch(err) {
			usi_commons.report_error(err);
		}
	},
	validate: function(phone) {
		return document.getElementById("usi_phone").value.length == 16;
	}
};`
)}
${is(
	o.mobile_phone,
	`usi_js.get_random_string = function(){
	var chars = "abcdefghjkmnpqrstuvwxyz23456789";
	var string_length = 7;
	var randomstring = '';
	for (var i=0; i<string_length; i++) {
		var rnum = Math.floor(Math.random() * chars.length);
		randomstring += chars.substring(rnum,rnum+1);
	}
	return randomstring;
};
usi_js.short_code = usi_js.get_random_string();`
)}
${is(
	o.carousel,
	`
usi_js.carousel_images = [
	'/chatskins/6440/SimonSezIT-TT-img1.jpg',
	'/chatskins/6440/SimonSezIT-TT-img2.jpg',
	'/chatskins/6440/SimonSezIT-TT-img3.jpg'
];
usi_js.carousel_images.forEach(function(path){
	usi_js.preload_img(usi_js.campaign.images + path);
});
usi_js.start_carousel= function(){
	try {
		var set_image = function(){
			var usi_carousel = document.getElementById("usi_carousel");
			usi_carousel.style.backgroundImage = "url(" + usi_js.campaign.images + usi_js.carousel_images[0] + ")";
			var first_item = usi_js.carousel_images.splice(0, 1);
			usi_js.carousel_images = usi_js.carousel_images.concat(first_item);
			setTimeout(set_image, 4000);
		};
		set_image();
		if (!usi_js.carousel_timeout) {
			usi_js.carousel_timeout = setTimeout(set_image, 4000);
		}
	} catch(err) {
		usi_commons.report_error(err);
	}
};
usi_js.pre_display = function(){
	usi_js.start_carousel();
	return true;
};`
)}
${is(
	o.progress_bar,
	`
usi_js.threshold = 50;
usi_js.pre_display = function(){
	try {
		var usi_progress_made = document.getElementById("usi_progress_made");
		var usi_togo = document.getElementById("usi_togo");
		var total = usi_js.product.total.replace("$", "");
		var perc = (total / usi_js.threshold) * 100;
		var togo = Math.max(Math.ceil(usi_js.threshold - total), 0);
		usi_progress_made.style.width = Math.min(perc, 100) + "%";
		if (togo == 0) {
			usi_progress_made.textContent = "YOU QUALIFY!";
			return true;
		}
		usi_togo.textContent = Math.max(Math.ceil(usi_js.threshold - total), 0);
		return true;
	} catch(err) {
		usi_commons.report_error(err);
	}
}`
)}
${is(
	o.bottom_slider,
	`
usi_js.display_vars.tab = "/chatskins/5692/annebuy-mobile-sidebarLC-tab.png";
usi_js.pre_display = function(){
	usi_js.toggle();
	return true;
}
usi_js.toggle = function(){
	try {
		var usi_display = document.getElementById("usi_display");
		var usi_tab = document.getElementById("usi_tab");
		var tab_name = "usi_tab_closed";
		var tab_position = "0";
		usi_display.style.marginBottom = "-" + usi_js.display_vars.height;
		if (usi_tab.className == "usi_tab_closed") {
			tab_name = "usi_tab_opened";
			tab_position = usi_js.display_vars.height;
		}
		usi_tab.className = tab_name;
		usi_display.style.bottom = tab_position;
	} catch(err) {
		usi_commons.report_error(err);
	}
}
usi_js.pre_close = function(){
	usi_js.suppress();
	return true;
}`
)}
${is(
	o.side_slider,
	`
usi_js.display_vars.tab = "/chatskins/5144/Tulo-LC-SS-tab.png";
usi_js.pre_display = function(){
	usi_js.toggle();
	return true;
}
usi_js.toggle = function(){
	try {
		var usi_display = document.getElementById("usi_display");
		var usi_tab = document.getElementById("usi_tab");
		var tab_name = "usi_tab_closed";
		var tab_position = "0";
		usi_display.style.marginLeft = "-" + usi_js.display_vars.width;
		if (usi_tab.className == "usi_tab_closed") {
			tab_name = "usi_tab_opened";
			tab_position = usi_js.display_vars.width;
		}
		usi_tab.className = tab_name;
		usi_display.style.left = tab_position;
	} catch(err) {
		usi_commons.report_error(err);
	}
}
usi_js.pre_close = function(){
	usi_js.suppress();
	return true;
}`
)}
${is(
	o.spinner,
	`
usi_js.display_vars.spinner = '/chatskins/3812/KidPik-TT-spinner-BG-Lower-centered.png';
usi_js.display_vars.p3 = '/chatskins/3812/KidPik-TT-spinner-PT3.png';
usi_js.spinner = {
	elm: null,
	init: function() {
		var usi_background_img = document.getElementById("usi_background_img");
		usi_js.spinner.elm = document.createElement("img");
		usi_js.spinner.elm.id = "usi_spinner";
		usi_js.spinner.elm.classList = "usi_rotate_waiting";
		usi_js.spinner.elm.src = usi_js.campaign.images + usi_js.display_vars.spinner;
		usi_background_img.parentNode.insertBefore(usi_js.spinner.elm, usi_background_img);
	},
	start: function(){
		usi_js.spinner.elm.className = "usi_rotate_spinning";
		usi_js.load_page(2);
	},
	stopping: function(){
		var usi_submitbutton = document.querySelector(".usi_submitbutton");
		usi_js.spinner.elm.classList = "usi_rotate_stopping";
		usi_submitbutton.style.display = "none";
		setTimeout(usi_js.spinner.stop, 4000);
	},
	stop: function(){
		usi_js.spinner.elm.classList.remove("usi_rotate_stopping");
		usi_js.load_page(3);
	}
};
usi_js.pre_display = function() {
	usi_js.trace('pre_display()');
	usi_js.spinner.init();
	return true;
}`
)}
${is(
	o.lc_opt_in || o.alt_p2,
	`
usi_js.custom_submit = function() {
	${is(
		o.lc_opt_in,
		`var optin = document.getElementById("usi_opt_in");
	if (!optin.checked) {
		return usi_js.custom_alert("Please opt in")
	}`
	)}
	${is(o.lc_opt_in && !o.alt_p2, `usi_js.submit()`)}
	${is(
		o.alt_p2,
		`usi_js.lookup_callback = function(found){
		if (found == 1) {
			usi_js.load_page(3);
		} else {
			usi_js.submit();
		}
	};
	usi_js.load_js("utility/lookup_suppression.jsp?companyID=" + usi_js.campaign.company_id + "&callback=usi_js.lookup_callback&label=subscribers&product=" + encodeURIComponent(usi_email.value));`
	)}
};`
)}
${is(
	o.survey,
	`
usi_js.survey_submit = function() {
	try {
		var required_fields_not_answered = document.querySelectorAll('input[name="question1"]:checked').length === 0;
		if (required_fields_not_answered) {
			alert("Please select an answer.");
			return;
		}
		usi_js.survey_post({
			questionID: "1",
			question: "QUESTION_GOES_HERE",
			answer: document.querySelector('input[name="question1"]:checked').value,
			freetype: "0"
		});
		usi_js.load_page(2);
	} catch(err) {
		usi_commons.report_error(err);
	}
};`
)}
${is(
	o.one_prod,
	`
usi_js.display_vars.product_html = [
	'<div class="usi_product_image">',
			'<img src="', usi_js.product.image, '" alt="', usi_js.escape_quotes(usi_js.product.name), '"/>',
	'</div>',
	'<div class="usi_product_info">',
		'<div class="usi_product_name">',
			usi_js.product.name,
		'</div>',
		'<table class="usi_price_table">',
			'<tr class="usi_price">',
				'<td class="usi_label">Regular Price:</td>',
				'<td class="usi_value">$',usi_js.product.price,'</td>',
			'</tr>',
			'<tr class="usi_new_price">',
				'<td class="usi_label">NOW ONLY:</td>',
				'<td class="usi_value">$',usi_js.product.new_price,'</td>',
			'</tr>',
		'</table>',
	'</div>'
].join('');
`
)}
${is(
	o.three_prod,
	`
usi_js.display_vars.product_html = '<div class="usi_product_info">';
for (var i = 1; i <= 3; i++) {
	usi_js.product["usi_prod_image_" + i] = usi_cookies.get("usi_prod_image_" + i);
	usi_js.product["usi_prod_name_" + i] = usi_cookies.get("usi_prod_name_" + i);
	${is(!o.mobile, `usi_js.product["usi_prod_price_" + i] = usi_cookies.get("usi_prod_price_" + i);`)}
	if (!usi_js.product["usi_prod_image_" + i] || !usi_js.product["usi_prod_name_" + i]${is(
		!o.mobile,
		` || !usi_js.product["usi_prod_price_" + i]`
	)}) {
		break;
	}
	usi_js.display_vars.product_html += [
		'<div class="usi_product usi_product'+i+'">',
			'<div class="usi_product_image">',
					'<img src="', usi_js.product["usi_prod_image_" + i], '" alt="',usi_js.escape_quotes(usi_js.product["usi_prod_name_" + i]),'" />',
			'</div>',
			${is(
				!o.mobile,
				`'<div class="usi_product_name">',usi_js.product["usi_prod_name_" + i],'</div>',
			'<div class="usi_product_price">$',usi_js.product["usi_prod_price_" + i],'</div>',`
			)}
		'</div>'
	].join('');
}
usi_js.display_vars.product_html += '</div>';
usi_js.display_vars.product_html += [
	'<table class="usi_price_table">',
		'<tr class="usi_price">',
			'<td class="usi_label">Subtotal:</td>',
			'<td class="usi_value">$',usi_js.product.subtotal,'</td>',
		'</tr>',
		'<tr class="usi_discount">',
			'<td class="usi_label">Discount:</td>',
			'<td class="usi_value">- $',usi_js.product.discount,'</td>',
		'</tr>',
		'<tr class="usi_new_price">',
			'<td class="usi_label">New Subtotal:</td>',
			'<td class="usi_value">$',usi_js.product.new_price,'</td>',
		'</tr>',
	'</table>'
].join('');
`
)}
${is(
	o.recommendations_view,
	`usi_js.display_vars.product_html = '<div class="usi_products">';
for (var i = 1; i <= 3; i++) {
	if (usi_app.product_rec["product" + i] == undefined) {
		break;
	}
	var name = usi_app.product_rec["product" + i].productname;
	var price = usi_app.product_rec["product" + i].price;
	var image = usi_app.product_rec["product" + i].image;
	${is(o.rec_reporting, `usi_js.products_seen.push(usi_js.product["product" + i]);`)}
	usi_js.display_vars.product_html += [
		'<div class="usi_product usi_product',i,'">',
			'<div role="link" onclick="usi_js.click_cta(usi_app.product_rec.product',i,');" class="usi_prod_image_link" tabindex="0">',
				'<img src="', image, '" border="0" alt="', usi_js.escape_quotes(name), '" class="usi_prod_image" />',
			'</div>',
			'<div class="usi_product_info">',
				'<div class="usi_name">', name, '</div>',
				'<div class="usi_price">$', price, '</div>',
				'<div role="link" onclick="usi_js.click_cta(usi_app.product_rec.product',i,');" class="usi_link" tabindex="0">VIEW ITEM</div>',
			'</div>',
		'</div>'
	].join('');
}
usi_js.display_vars.product_html += '</div>';
`
)}
${is(
	o.lc || o.availability,
	`
usi_js.display_vars.email_html = [
	'<div id="usi_email_container">',
		'<label class="usi_email_label usi_sr_only" for="usi_email">Enter Email</label>',
		'<input placeholder="Enter Email" value="" type="text" name="usi_email" id="usi_email"	 onkeydown="if (event.keyCode == 13) { ${
			o.lc_opt_in || o.alt_p2 ? `usi_js.custom_submit()` : `usi_js.submit()`
		}; return false; }" />',
		'<div id="usi_email_good_container"><img id="usi_email_good" src="', usi_js.campaign.images, '/images/spacer.gif" style="width:14px; height:14px;" border="0" alt="valid" /></div>',
	'</div>'
].join('');`
)}
${is(
	o.phone,
	`usi_js.display_vars.phone_html = [
	'<div id="usi_phone_container">',
		'<label class="usi_phone_label usi_sr_only" for="usi_phone">Enter Your Mobile Phone Number</label>',
		'<input placeholder="Enter Your Mobile Phone Number" type="text" name="usi_phone" id="usi_phone" onkeydown="if (event.keyCode == 13) { usi_js.phone.submit(); return false; }" onkeyup="usi_js.phone.format(this)" />',
		'<div id="usi_phone_good_container"><img id="usi_phone_good" src="', usi_js.campaign.images, '/images/spacer.gif" style="width:14px; height:14px;" border="0" alt="valid" /></div>',
	'</div>'
].join('');`
)}
${is(
	o.spinner,
	`usi_js.display_vars.p1_html = [
	'<div class="usi_head${is(!o.css, ` usi_sr_only`)}">HEADLINE</div>',
	'<button class="usi_submitbutton" onclick="usi_js.spinner.start();" type="button"${is(
		!o.css,
		` aria-label="Redeem Now"`
	)}>${is(o.css, `Redeem Now`)}</button>'
].join('');

usi_js.display_vars.p2_html = [
	'<div class="usi_head${is(!o.css, ` usi_sr_only`)}">HEADLINE</div>',
	'<button class="usi_submitbutton" onclick="usi_js.spinner.stopping();" type="button"${is(
		!o.css,
		` aria-label="Redeem Now"`
	)}>${is(o.css, `Redeem Now`)}</button>'
].join('');

usi_js.display_vars.p3_html = [
	'<div class="usi_head${is(!o.css, ` usi_sr_only`)}">HEADLINE</div>',
	'<button class="usi_submitbutton" onclick="usi_js.click_cta();" type="button"${is(
		!o.css,
		` aria-label="Redeem Now"`
	)}>${is(o.css, `Redeem Now`)}</button>',
	'<div class="usi_discount">', usi_js.campaign.coupon, '</div>'
].join('');`
)}
${is(
	!o.spinner,
	`usi_js.display_vars.p1_html = [
	${is(!o.spinner, `'<div class="usi_head${is(!o.css, ` usi_sr_only`)}">HEADLINE</div>',`)}
	${is(o.carousel, `'<div id="usi_carousel"></div>',`)}
	${is(
		o.countdown,
		`'<div class="usi_countdown"><span id="usi_minutes">5</span>:<span id="usi_seconds">00</span></div>',`
	)}
	${is(
		o.side_slider || o.bottom_slider,
		`'<div role="link" id="usi_tab" class="usi_tab_opened" onclick="usi_js.toggle()">',
		'<img src="', usi_js.campaign.images, usi_js.display_vars.tab,'" border="0" alt="Toggle">',
	'</div>',`
	)}
	${is(o.three_prod || o.one_prod || o.recommendations_view, `usi_js.display_vars.product_html,`)}
	${is(
		o.progress_bar,
		`'<div class="usi_progress_bar"><div id="usi_progress_made" class="usi_progress_made">$<span id="usi_togo">',usi_js.threshold,'</span> MORE TO FREE SHIPPING</div><div class="usi_progress_end">$',usi_js.threshold,'</div></div>',`
	)}
	${is(
		o.survey,
		`'<div class="usi_question usi_block" data-questionID="1">',
		'<label class="usi_container" for="answer1"><input class="usi_answer" type="radio" name="question1" id="answer1" value="ANSWER_GOES_HERE"> ANSWER_GOES_HERE</label>',
		'<label class="usi_container" for="answer2"><input class="usi_answer" type="radio" name="question1" id="answer2" value="ANSWER_GOES_HERE"> ANSWER_GOES_HERE</label>',
		'<label class="usi_container" for="answer3"><input class="usi_answer" type="radio" name="question1" id="answer3" value="ANSWER_GOES_HERE"> ANSWER_GOES_HERE</label>',
	'</div>',`
	)}
	${is(
		o.phone,
		`usi_js.display_vars.phone_html,
	'<div class="usi_disclaimer">By providing your mobile phone number you are consenting to the terms of this <a href="https://labs.upsellit.com/privacy-policy" target="_blank">privacy policy</a>.</div>',`
	)}
	${is(o.lc || o.availability, `usi_js.display_vars.email_html,`)}
	${is(
		o.lc_opt_in,
		`'<label class="usi_optin" for="usi_opt_in"> <input id="usi_opt_in" type="checkbox" checked> OPTIN</label>',`
	)}
	${is(
		o.lc || o.availability,
		`'<div class="usi_disclaimer">By providing your email address you are consenting to the terms of this <a href="https://labs.upsellit.com/privacy-policy" target="_blank">privacy policy</a>.</div>',`
	)}
	${is(
		o.disclaimer && !o.lc && !o.availability,
		`'<div class="usi_disclaimer">We use your information in accordance with our <a href="https://labs.upsellit.com/privacy-policy" target="_blank">privacy policy</a>.</div>',`
	)}
	${is(
		o.no_thanks,
		`'<button class="usi_nothanks" onclick="usi_js.close();"${is(!o.css, ` aria-label="No Thanks"`)}>${is(
			o.css,
			`No Thanks`
		)}</button>',`
	)}
	${is(
		o.lc,
		`'<button class="usi_submitbutton" onclick="${
			o.lc_opt_in || o.alt_p2 ? `usi_js.custom_submit()` : `usi_js.submit()`
		};" type="button"${is(!o.css, ` aria-label="Redeem Now"`)}>${is(o.css, `Redeem Now`)}</button>',`
	)}
	${is(
		o.survey,
		`'<button class="usi_submitbutton" onclick="usi_js.survey_submit();" type="button"${is(
			!o.css,
			` aria-label="Redeem Now"`
		)}>${is(o.css, `Redeem Now`)}</button>',`
	)}
	${is(
		o.mobile_phone,
		`'<a class="usi_submitbutton" onclick="usi_js.click_cta();" href="sms://+18448979384;?&body=',encodeURIComponent("Send this text to get your 10% off coupon code and subscribe to other messages from COMPANY_NAME! (ref: "+usi_js.short_code+")"),'"alt="Click Here"></a>',`
	)}
	${is(
		!(o.recommendations_view || o.lc || o.survey || o.mobile_phone || o.spinner),
		`'<button class="usi_submitbutton" onclick="usi_js.click_cta();" type="button"${is(
			!o.css,
			` aria-label="Redeem Now"`
		)}>${is(o.css, `Redeem Now`)}</button>',`
	)}
].join('');
`
)}
${is(
	o.lc || o.availability || o.survey || o.phone,
	`usi_js.display_vars.p2_html = [
	'<div class="usi_email_confirmation ${is(
		!o.css,
		` usi_sr_only`
	)}">Thank you, your email has been sent. Please allow 2-3 minutes for delivery. Just in case, check your spam folder.</div>',
	${is(o.three_prod || o.one_prod || o.recommendations_view, `usi_js.display_vars.product_html,`)}
	'<button class="usi_submitbutton" onclick="usi_js.click_cta();" type="button"${is(
		!o.css,
		` aria-label="Continue Shopping"`
	)}>${is(o.css, `Continue Shopping`)}</button>'
].join('');`
)}
	${is(
		o.alt_p2,
		`
usi_js.display_vars.p3_html = [
	'<div class="usi_head${is(!o.css, ` usi_sr_only`)}">HEADLINE P3</div>',
	'<button onclick="usi_js.close()" class="usi_submit_button" type="button"></button>'
].join('');`
	)}
`),
		testdata: (o) =>
			usi_code.update(`${is(
				o.one_prod,
				`usi_cookies.set("usi_prod_image_1", "https://plchldr.co/i/400?text=img&bg=000", 86400);
usi_cookies.set("usi_prod_name_1", "Product Name Placeholder Text", 86400);
usi_cookies.set("usi_prod_price_1", "19.95", 86400);`
			)}
${is(
	o.three_prod,
	`usi_cookies.set("usi_prod_image_1", "https://plchldr.co/i/400?text=img&bg=000", 86400);
usi_cookies.set("usi_prod_name_1", "Product Name Placeholder Text", 86400);
usi_cookies.set("usi_prod_price_1", "19.95", 86400);

usi_cookies.set("usi_prod_image_2", "https://plchldr.co/i/400?text=img&bg=000", 86400);
usi_cookies.set("usi_prod_name_2", "Product Name Placeholder Text", 86400);
usi_cookies.set("usi_prod_price_2", "19.95", 86400);

usi_cookies.set("usi_prod_image_3", "https://plchldr.co/i/400?text=img&bg=000", 86400);
usi_cookies.set("usi_prod_name_3", "Product Name Placeholder Text", 86400);
usi_cookies.set("usi_prod_price_3", "19.95", 86400);`
)}
${is(o.three_prod || o.progress_bar, `usi_cookies.set("usi_subtotal", "19.95", 86400);`)}
${is(o.recommendations_view || o.availability, `if (window.usi_app == undefined) usi_app = {};`)}
${is(
	o.recommendations_view,
	`usi_app.product_rec = {};
usi_app.product_rec.pid_viewers = 1234;
usi_app.product_rec.product0 = {
	pid: "12345",
	productname: "Passed-in Product Name",
	image: "https://plchldr.co/i/400?text=img&bg=000",
	price: "44.95",
	url: "https://us.upsellit.com/",
	extra: "Extra"
};
usi_app.product_rec.product1 = {
	pid: "23456",
	productname: "Recommended Product Name 1",
	image: "https://plchldr.co/i/400?text=img&bg=000",
	price: "144.95",
	url: "https://us.upsellit.com/",
	extra: "Extra"
};
usi_app.product_rec.product2 = {
	pid: "34567",
	productname: "Recommended Product Name 2",
	image: "https://plchldr.co/i/400?text=img&bg=000",
	price: "39.95",
	url: "https://us.upsellit.com/",
	extra: "Extra"
};
usi_app.product_rec.product3 = {
	pid: "45678",
	productname: "Recommended Product Name 3",
	image: "https://plchldr.co/i/400?text=img&bg=000",
	price: "9.95",
	url: "https://us.upsellit.com/",
	extra: "Extra"
};`
)}
${is(
	o.availability,
	`usi_app.product = {};
usi_app.product.link = "https://us.upsellit.com/";
usi_app.product.image = "https://plchldr.co/i/400?text=img&bg=000";
usi_app.product.name = "Product Name";
usi_app.product.pid = "12345";
usi_app.product.price = "9.95";
usi_app.product.extra = JSON.stringify({
	category: ""
});`
)}
`),
		usi_load_view: (o) =>
			usi_code.update(`var site = this;
${is(o.rec_reporting, `this.products_seen = [];`)}
this.click_cta = function(${is(o.minicart, `destination`)}${is(o.recommendations_view, `product`)}){
	${is(!o.recommendations_view, `site.deep_link(${is(o.minicart, `destination`)});`)}
	${is(o.recommendations_view && !o.rec_reporting, `site.deep_link(product.url);`)}
	${is(
		o.rec_reporting,
		`if (typeof(usi_app.clicked) !== "undefined") {
		usi_app.clicked(site.id, site.site_id, site.config_id, product || {}, usi_app.product_rec.product0 || {}, function(){
			site.deep_link(product.url);
		});
	}`
	)}
};

${is(
	o.use_srcset,
	`
this.get_srcset = function(url) {
	var img = {"src": "", "srcset": ""};
	try {
		var dppx = 3;
		var sizes = {};
		sizes.intrinsic_width = 5760;// manually set to file width
		sizes.width = sizes.intrinsic_width / dppx;
		for (var i = 2; i <= dppx; i++) {
			if (img.srcset != "") img.srcset += ",";
			var _w = (sizes.width * i);
			img.srcset += this.cdn + "/w:" + _w + "/d" + url.replace('/chatskins', '') + " " + _w + "w";
		}
		img.src = this.cdn + '/w:' + sizes.width + '/d' + url.replace('/chatskins', '');
	} catch (err) {
		if (typeof (usi_commons) !== "undefined") usi_commons.report_error(err);
	}
	return img;
}
var srcsetData = this.get_srcset('/chatskins/1111/EXAMPLE_BG.jpg');
var image = '<img alt="ALT_DESCRIPTION" srcset="' + srcsetData.srcset + '" src="' + srcsetData.src + '">';
`
)}
${is(
	o.close,
	`
this.close = function () {
	try {
		document.getElementById("usi_container").parentNode.removeChild(document.getElementById("usi_container"));
	} catch(err) {
		usi_commons.report_error(err);
	}
}
`
)}
${is(
	!o.recommendations_view && !o.minicart && !o.lc,
	`this.p1_html = [
	${is(
		o.close,
		`'<button type="button" onclick="usi_',this.site_id,'.close();" class="usi_close_button"></button>',`
	)}
	'<div class="usi_inner">',
		'<div class="usi_head">HEADLINE</div>',
		'<button class="usi_submitbutton" onclick="usi_',this.site_id,'.click_cta();" type="button">Redeem Now</button>',
	'</div>',
].join('');`
)}
${is(
	o.minicart,
	`this.p1_html = [
	${is(
		o.close,
		`'<button type="button" onclick="usi_',this.site_id,'.close();" class="usi_close_button"></button>',`
	)}
	'<div class="usi_minicart usi_inner">',
	'<div class="usi_head">In Your Cart</div>',
	'<div id="usi_cart" class="usi_products">'
].join('');
for (var i = 1; i <= 9 && usi_cookies.get("usi_prod_name_" + i) != null; i++) {
	var name = usi_cookies.get("usi_prod_name_" + i);
	var price = usi_cookies.get("usi_prod_price_" + i);
	var image = usi_cookies.get("usi_prod_image_" + i);
	var link = usi_cookies.get("usi_prod_link_" + i);
	this.p1_html += [
		'<div class="usi_product usi_product',i,'">',
			'<div role="link" onclick="usi_',this.site_id,'.click_cta(\\'',link,'\\');" class="usi_prod_image_link">',
				'<img src="', image, '" border="0" alt="product image" class="usi_prod_image"/>',
			'</div>',
			'<div class="usi_product_info">',
				'<div class="usi_name">', name, '</div>',
				'<div class="usi_price">', price, '</div>',
				'<div role="link" onclick="usi_',this.site_id,'.click_cta(\\'',link,'\\');" class="usi_link">VIEW NOW</div>',
			'</div>',
		'</div>'
	].join('');
}
this.p1_html += [
	${is(
		o.close,
		`'<button type="button" onclick="usi_',this.site_id,'.close();" class="usi_close_button"></button>',`
	)}
	'<div class="usi_inner">',
	'<div class="usi_head">Recommended For You</div>',
	'<div id="usi_recommendations" class="usi_products">'
].join('');
this.p1_html += '<div id="usi_recommendations" class="usi_products">';
for (var i = 1; i <= 9; i++) {
	if (usi_app.product_rec["product" + i] == undefined) {
		break;
	}
	var name = usi_app.product_rec["product" + i].productname;
	var price = usi_app.product_rec["product" + i].price;
	var image = usi_app.product_rec["product" + i].image;
	this.p1_html += [
		'<div class="usi_product usi_product',i,'">',
			'<div role="link" onclick="usi_',this.site_id,'.click_cta(usi_app.product_rec.product',i,'.url);" class="usi_prod_image_link">',
				'<img src="', image, '" border="0" alt="product image" class="usi_prod_image"/>',
			'</div>',
			'<div class="usi_product_info">',
				'<div class="usi_name">', name, '</div>',
				'<div class="usi_price">', price, '</div>',
				'<div role="link" onclick="usi_',this.site_id,'.click_cta(usi_app.product_rec.product',i,'.url);" class="usi_link">VIEW NOW</div>',
			'</div>',
		'</div>'
	].join('');
}
this.p1_html += '</div></div></div></div>';
`
)}
${is(
	o.lc,
	`
this.submit = function (name, value) {
	try {
		// check valid email was provided
		var usi_email = document.getElementById("usi_email");
		if (usi_email != null && this.validate_email(usi_email.value.trim())) {
			this.send_data("usi_email", usi_email.value.trim());
		} else if (usi_email != null) {
			alert("Please enter a valid email address");
			usi_email.focus();
			return;
		}
		// load p2
		var usi_container = document.getElementById("usi_lead_capture");
		usi_container.innerHTML = (this.p2_html);
	} catch(err) {
		usi_commons.report_error(err);
	}
}

this.email_html = [
	'<div id="usi_email_container">',
	'<label class="usi_email_label usi_sr_only" for="usi_email">Enter Your Email</label>',
	'<input type="text" name="usi_email" id="usi_email" placeholder="Enter email address" onkeydown="if (event.keyCode == 13) { usi_', this.site_id, '.submit(); return false; }" />',
	'</div>'
].join('');
this.p1_html = [
	${is(
		o.close,
		`'<button type="button" onclick="usi_',this.site_id,'.close();" class="usi_close_button"></button>',`
	)}
	'<div class="usi_inner">',
		'<p id="usi_text">SAVE YOUR CART AND <br> CONTINUE SHOPPING LATER</p>',
		this.email_html,
		'<div class="usi_disclaimer">Yes, please add me to your list. I agree to receiving emails and incentives in accordance with your <a href="https://labs.upsellit.com/privacy-policy" target="_blank">Privacy Policy</a>.</div>',
		'<button class="usi_submitbutton" onclick="usi_', this.site_id, '.submit();" type="button">SAVE MY CART</button>',
	'</div>'
].join('');

this.p2_html = [
	${is(
		o.close,
		`'<button type="button" onclick="usi_',this.site_id,'.close();" class="usi_close_button"></button>',`
	)}
	'<p id="usi_text2">Thank you, your email has been sent. <br> Please allow 2-3 minutes for delivery. Just in case, check your spam folder.</p>',
	'<button class="usi_submitbutton usi_submitbutton2" onclick="usi_', this.site_id, '.click_cta();" type="button">CLOSE MESSAGE</button>'
].join('');
`
)}
${is(
	o.recommendations_view && !o.minicart,
	`this.page = function (direction) {
	var container = document.getElementById("usi_products");
	if (direction == 0) {
		container.insertBefore(container.lastChild, container.firstChild);
	} else if (direction == 1) {
		container.appendChild(container.firstChild);
	}
};
this.p1_html = [
	${is(
		o.close,
		`'<button type="button" onclick="usi_',this.site_id,'.close();" class="usi_close_button"></button>',`
	)}
	'<div class="usi_recommendations usi_inner">',
	'<div class="usi_head">Recommended For You</div>',
	'<button type="button" id="usi_page_left" class="usi_page_arrow usi_page_left" onclick="usi_',this.site_id,'.page(0);" aria-label="Page Left">&lsaquo;</button>',
	'<button type="button" id="usi_page_right" class="usi_page_arrow usi_page_right" onclick="usi_',this.site_id,'.page(1);" aria-label="Page Right">&rsaquo;</button>',
	'<div id="usi_products" class="usi_products">'
].join('');
for (var i = 1; i <= 9; i++) {
	if (usi_app.product_rec["product" + i] == undefined) {
		break;
	}
	var name = usi_app.product_rec["product" + i].productname;
	var price = usi_app.product_rec["product" + i].price;
	var image = usi_app.product_rec["product" + i].image;
	${is(o.rec_reporting, `this.products_seen.push(usi_app.product_rec["product" + i]);`)}
	this.p1_html += [
		'<div class="usi_product usi_product',i,'">',
			'<div role="link" onclick="usi_',this.site_id,'.click_cta(usi_app.product_rec.product',i,');" class="usi_prod_image_link">',
				'<img src="', image, '" border="0" alt="product image" class="usi_prod_image"/>',
			'</div>',
			'<div class="usi_product_info">',
				'<div class="usi_name">', name, '</div>',
				'<div class="usi_price">', price, '</div>',
				'<div role="link" onclick="usi_',this.site_id,'.click_cta(usi_app.product_rec.product',i,');" class="usi_link">VIEW NOW</div>',
			'</div>',
		'</div>'
	].join('');
}
this.p1_html += '</div></div>';
`
)}
this.load_html = function(){
	var target_element = document.querySelector("#NODE_ID");
	if (target_element == null && document.getElementById("usi_load_div") != null) {
		target_element = document.getElementById("usi_load_div");
	}
	if (target_element != null) {
		var usi_container = document.createElement("div");
		${is(!o.lc, `usi_container.setAttribute("id", "usi_container");`)}
		${is(o.lc, `usi_container.setAttribute("id", "usi_container usi_lead_capture");`)}
		usi_container.setAttribute("class", "usi_container");
		usi_container.innerHTML = this.p1_html;
		target_element.parentNode.insertBefore(usi_container, target_element);
	}
	${is(
		o.rec_reporting,
		`if (typeof(usi_app.seen) !== "undefined") {
		usi_app.seen(this.id, this.site_id, this.config_id, this.products_seen || [], usi_app.product_rec.product0 || {});
	}`
	)}
}
this.load_html();`),
		usi_load_css: (o) =>
			usi_code.update(`.usi_container * {
	font-size: 1em;
	line-height: 1;
	box-sizing: border-box;
	color: inherit;
}
.usi_sr_only {
	position: absolute !important;
	clip: rect(1px 1px 1px 1px);
	clip: rect(1px, 1px, 1px, 1px);
}
${is(
	o.modal,
	`
.usi_container {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.75);
	z-index: 2147483647;
}
.usi_inner {
	background: #fff;
	position: fixed !important;
	display: block;
	margin: auto;
	top: 0;
	right: 0;
	bottom: 0;
	${is(
		o.minicart,
		`width: 30em;
	height: 100%;
	overflow-y: auto;
	box-shadow: 1px 1px 5px 0px #000;`
	)}
	${is(
		!o.minicart,
		`width: 50em;
	height: 30em;
	left: 0;`
	)}
}`
)}
${is(
	o.close,
	`.usi_close_button {
	position:absolute;
	top:0;
	right: 0;
}
.usi_close_button:after {
	content: '\\00D7';
}`
)}
${is(
	o.lc,
	`#usi_lead_capture {
	z-index: 10 !important;
	position: relative !important;
	display: block;
	width: 50%;
	height: 200px;
	float: left;
	border: none;
	line-height: 1;
	box-sizing: border-box;
	color: inherit;
}
#usi_lead_capture #usi_close {
	display: none;
}

#usi_lead_capture .usi_sr_only {
	position: absolute !important;
	clip: rect(1px 1px 1px 1px);
	clip: rect(1px, 1px, 1px, 1px);
}

/* Make display responsive: Set max-width query to the graphic width, and padding-top to height/width */
@media screen and (max-width: 750px) {
	#usi_lead_capture {
		width: 100%;
	}
	#usi_lead_capture #usi_text {
		font-size: 20px !important;
	}
	#usi_lead_capture #usi_email_container {
		top: 40% !important;
		width: 50% !important;
	}
	#usi_lead_capture .usi_submitbutton {
		top: 40% !important;
		left: 50% !important;
		width: 50% !important;
	}
	#usi_lead_capture .usi_disclaimer {
		top: 65% !important;
	}
	#usi_text2 {
		font-size: 17px !important;
	}
	#usi_lead_capture .usi_submitbutton2 {
		top: 50% !important;
		left: 20% !important;
		width: 60% !important;
	}
}

#usi_lead_capture #usi_text {
	font-weight: bold;
	font-size: 24px;
	margin-top: 25px;
}
#usi_lead_capture #usi_text2 {
	font-weight: bold;
	font-size: 20px;
	margin-top: 25px;
	text-align: center;
}

/* Submit Button */
#usi_lead_capture .usi_submitbutton:hover, #usi_lead_capture .usi_submitbutton:active, #usi_lead_capture .usi_submitbutton:focus {
	border: none;
	outline: none;
}
#usi_lead_capture .usi_submitbutton {
	position: absolute;
	top: 50%;
	left: 40%;
	width: 35%;
	height: 20%;
	margin: 0;
	padding: 0;
	display: inline-block;
	outline: none;
	background: #0082c8;
	border: none;
	cursor: pointer;
	text-align: center;
	color: #fff;
	font-weight: 500;
	font-size: 20px;
}

#usi_lead_capture .usi_submitbutton2 {
	left: 27%;
	width: 45%;
}

/* Lead Capture */
#usi_lead_capture #usi_email_container {
	position: absolute;
	top: 50%;
	left: 0%;
	width: 40%;
	height: 20%;
	border: 1px solid #d3ced2;
}
#usi_lead_capture #usi_email {
	font-style: italic;
	box-shadow: none;
	padding: 0 0 0 5%;
	width: 100%;
	height: 100%;
	border: none;
	outline: none;
	background-color: transparent;
	color: #666;
	text-align: left;
}
#usi_lead_capture #usi_email::-ms-clear {
	display: none;
}
#usi_lead_capture #usi_email_good_container {
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
	right: 2%;
	width: 14px;
	height: 14px;
}

/* Disclaimer */
#usi_lead_capture .usi_disclaimer {
	position: absolute;
	top: 80%;
	left: 0%;
	width: 100%;
	text-align: left;
	color: #666;
	font-size: .7em;
}
#usi_lead_capture .usi_disclaimer a {
	text-align: center;
	color: #666;
	font-size: 1em;
	text-decoration: underline !important;
}`
)}
${is(
	o.minicart,
	`.usi_head {
	padding:1em 0; 
	font-weight: bold;
	text-align: center;
}
.usi_products {
}
.usi_product {
}
.usi_prod_image_link {
	padding: 1em;
	text-align: center;
	display: inline-block;
	width: 50%;
	vertical-align: top;
	cursor: pointer;
}
.usi_prod_image {
	max-width: 100%;
}
.usi_product_info {
	display: inline-block;
	width: 50%;
	vertical-align: top;
}
.usi_name {
	height: 2em;
	overflow:hidden;
}
.usi_price {
	padding: 0.5em 0;
	text-align: center;
}
.usi_msrp {
	text-decoration: line-through;
	color: #817e7e;
}
.usi_link {
	background-color: #000;
	color: #fff;
	padding: 0.5em;
	text-align: center;
	margin: auto;
	display: block;
	text-decoration: none;
	border: 1px solid #000;
	max-width: 10em;
	width: 100%;
}
.usi_link:hover {
	background-color: #000;
	color: #fff;
	text-decoration: none;
}`
)}
${is(
	o.recommendations_view && !o.minicart,
	`.usi_recommendations * {
	font-size: 1em;
	line-height: 1;
	box-sizing: border-box;
	color: inherit;
}
.usi_recommendations {
	position: relative !important;
	width: 100% !important;
	height: auto !important;
	z-index: 1 !important;
}
.usi_head {
	padding:1em 0; 
	font-weight: bold;
}
.usi_page_arrow {
	background: none;
	position: absolute;
	margin: auto;
	font-size: 4em;
	padding: 0;
	cursor: pointer;
	font-family: monospace;
	border: none;
	bottom: 0
}
.usi_page_left {
	left: 0;
}
.usi_page_right {
	right:0;
}
/* Recommended Products */
.usi_products {
	padding: 0 2em;
}
.usi_product {
	display: inline-block;
	width: 25%;
	vertical-align: top;
}
.usi_product {
	display:none;
}
.usi_product:nth-child(-n + 4) {
	display:inline-block;
}
.usi_prod_image_link {
	padding: 1em;
	text-align: center;
	display: block;
	cursor: pointer;
}
.usi_prod_image {
	max-width: 100%;
	max-height: 100%;
}
.usi_product_info {
}
.usi_name {
	text-align: center;
	height: 2em;
	overflow:hidden;
}
.usi_price {
	font-weight: bold;
	padding: 0.5em 0;
	text-align: center;
}
.usi_msrp {
	text-decoration: line-through;
	color: #817e7e;
}
.usi_link {
	background-color: #15456e;
	color: #fff;
	padding: 0.5em;
	text-align: center;
	margin: auto;
	display: block;
	text-decoration: none;
	border: 1px solid #000;
	max-width: 10em;
	width: 100%;
}
.usi_link:hover {
	background-color: #15456e;
	color: #fff;
	text-decoration: none;
}

#usi_container {
	padding: 10px 100px;
}

@media screen and (max-width:768px) {
	.usi_product:nth-child(-n + 3) {
		display:block;
	}
	.usi_product {
		width:100%;
	}
	.usi_page_arrow {
		display:none;
	}
}`
)}`),
		component: {
			logo: (o) => `<mj-section padding="0">
	<mj-column>
		<mj-image alt="" width="100px" src="https://plchldr.co/i/100x50?text=logo&bg=000" href="{{LINK1}}" />
	</mj-column>
</mj-section>`,
			navbar: (o) => `<mj-section background-color="#333">
	<mj-column>
		<mj-navbar base-url="https://mjml.io" hamburger="hamburger" ico-color="#ffffff">
			<mj-navbar-link href="/gettings-started-onboard" color="#ffffff">LINK1</mj-navbar-link>
			<mj-navbar-link href="/try-it-live" color="#ffffff">LINK2</mj-navbar-link>
			<mj-navbar-link href="/templates" color="#ffffff">LINK3</mj-navbar-link>
			<mj-navbar-link href="/components" color="#ffffff">LINK4</mj-navbar-link>
		</mj-navbar>
	</mj-column>
</mj-section>`,
			mj_hero: (o) => `<mj-hero
	mode="fixed-height"
	height="469px"
	background-width="600px"
	background-height="469px"
	background-url="https://plchldr.co/i/600x300?text=hero&bg=000"
	background-color="#2a3448"
	padding="100px 0px">
	<mj-text
		padding="20px"
		align="center"
		font-size="45px"
		line-height="45px"
		font-weight="900">
		INCENTIVE
	</mj-text>
	<mj-button href="{{LINK1}}" align="center">Redeem Now</mj-button>
</mj-hero>`,
			email_hero: (o) => `<mj-section padding="0">
	<mj-column>
		<mj-image alt="" src="https://plchldr.co/i/600x300?text=hero&bg=000" href="{{LINK1}}" padding="0" />
	</mj-column>
</mj-section>`,
			carousel: (o) => `<mj-section padding="0">
	<mj-column>
		<mj-carousel>
			<mj-carousel-image src="https://plchldr.co/i/600x300?text=1&bg=000" />
			<mj-carousel-image src="https://plchldr.co/i/600x300?text=2&bg=000" />
			<mj-carousel-image src="https://plchldr.co/i/600x300?text=3&bg=000" />
		</mj-carousel>
	</mj-column>
</mj-section>`,
			overlap_hero1: (
				o
			) => `<mj-wrapper padding="0px 30px" background-url="https://plchldr.co/i/600x500?text=hero&bg=000" background-color="#eee" background-repeat="no-repeat" background-size="100%">
	<mj-section padding="0" background-color="transparent">
		<mj-column>
			<mj-table padding="0">
				<tr>
					<td class="hero"><a href="{{LINK1}}" style="height:100%;width:100%;display:inline-block;" class="hero"></a></td>
				</tr>
			</mj-table>
		</mj-column>
	</mj-section>
	<mj-section background-color="#fff" padding="25px 0px 0px 0px">
		<mj-column>
			<mj-text align="left" padding-bottom="0px" font-size="36px" color="#000">
				Hi,
			</mj-text>
		</mj-column>
	</mj-section>
</mj-wrapper>`,
			overlap_hero2: (
				o
			) => `<mj-wrapper padding="0px 30px" background-url="https://plchldr.co/i/600x500?text=hero&bg=000" background-color="#eee" background-repeat="no-repeat" background-size="100%">
	<mj-section padding="0" background-color="transparent">
		<mj-column>
			<mj-image alt="" src="https://plchldr.co/i/300x50?text=cta&bg=000" href="{{LINK1}}"	/>
		</mj-column>
	</mj-section>
	<mj-section background-color="#fff" padding="25px 0px 0px 0px">
		<mj-column>
			<mj-text align="left" padding-bottom="0px" font-size="36px" color="#000">
				Hi,
			</mj-text>
		</mj-column>
	</mj-section>
</mj-wrapper>`,
			table: (o) => `<mj-section>
	<mj-column>
		<mj-table>
			<tr>
				<th>Year</th>
				<th>Language</th>
				<th>Inspired from</th>
			</tr>
			<tr align="center">
				<td>1995</td>
				<td>PHP</td>
				<td>C, Shell Unix</td>
			</tr>
			<tr align="center">
				<td>1995</td>
				<td>JavaScript</td>
				<td>Scheme, Self</td>
			</tr>
		</mj-table>
	</mj-column>
</mj-section>`,
			accordion: (o) => `<mj-section>
	<mj-column>
		<mj-accordion border="1px solid #333">
			<mj-accordion-element>
				<mj-accordion-title background-color="#eee">Why use an accordion?</mj-accordion-title>
				<mj-accordion-text>
					<span style="line-height:20px">
						Because emails with a lot of content are most of the time a very bad experience on mobile, mj-accordion comes handy when you want to deliver a lot of information in a concise way.
					</span>
				</mj-accordion-text>
			</mj-accordion-element>
			<mj-accordion-element>
				<mj-accordion-title background-color="#eee">How it works</mj-accordion-title>
				<mj-accordion-text>
					<span style="line-height:20px">
						Content is stacked into tabs and users can expand them at will. If responsive styles are not supported (mostly on desktop clients), tabs are then expanded and your content is readable at once.
					</span>
				</mj-accordion-text>
			</mj-accordion-element>
		</mj-accordion>
	</mj-column>
</mj-section>`,
			email_cart: (o) => `<mj-raw><!--{{REQUIRE_START:usi_prod_name_1}}--></mj-raw>
<mj-section padding="0">
	<mj-column>
		<mj-image alt="Items in your cart" width="200" src="https://plchldr.co/i/200x50?text=your cart&bg=000" href="{{LINK1}}" />
	</mj-column>
</mj-section>
<mj-section css-class="product-row" border-bottom="1px solid #eeeeee">
	<mj-column width="20%">
		<mj-image alt="{{usi_prod_name_1}}" src="{{usi_prod_image_1}}" href="{{LINK1}}" />
	</mj-column>
	<mj-column width="50%">
		<mj-text css-class="product-name" align="left" padding-top="15px">
			{{usi_prod_name_1}}
		</mj-text>
	</mj-column>
	<mj-column width="30%">
		<mj-text css-class="product-price" align="left" padding-top="15px">
			{{usi_prod_price_1}}
		</mj-text>
	</mj-column>
</mj-section>
<mj-raw><!--{{REQUIRE_START:usi_prod_name_2}}--></mj-raw>
<mj-section css-class="product-row" border-bottom="1px solid #eeeeee">
	<mj-column width="20%">
		<mj-image alt="{{usi_prod_name_2}}" css-class="product-image" src="{{usi_prod_image_2}}" href="{{LINK1}}" />
	</mj-column>
	<mj-column width="50%">
		<mj-text css-class="product-name" align="left" padding-top="15px">
			{{usi_prod_name_2}}
		</mj-text>
	</mj-column>
	<mj-column width="30%">
		<mj-text css-class="product-price" align="left" padding-top="15px">
			{{usi_prod_price_2}}
		</mj-text>
	</mj-column>
</mj-section>
<mj-raw><!--{{REQUIRE_STOP:usi_prod_name_2}}--></mj-raw>
<mj-raw><!--{{REQUIRE_START:usi_prod_name_3}}--></mj-raw>
<mj-section css-class="product-row" border-bottom="1px solid #eeeeee">
	<mj-column width="20%">
		<mj-image alt="{{usi_prod_name_3}}" css-class="product-image" src="{{usi_prod_image_3}}" href="{{LINK1}}" />
	</mj-column>
	<mj-column width="50%">
		<mj-text css-class="product-name" align="left" padding-top="15px">
			{{usi_prod_name_3}}
		</mj-text>
	</mj-column>
	<mj-column width="30%">
		<mj-text css-class="product-price" align="left" padding-top="15px">
			{{usi_prod_price_3}}
		</mj-text>
	</mj-column>
</mj-section>
<mj-raw><!--{{REQUIRE_STOP:usi_prod_name_3}}--></mj-raw>

${is(o.subtotal, `<mj-section css-class="totals">
	<mj-column>
	<mj-text font-size="18px">
		Subtotal: 
	</mj-text>
</mj-column>

	<mj-column>
		<mj-text font-size="18px">
			{{usi_subtotal}}
		</mj-text>
	</mj-column>
</mj-section>`)}
${is(o.discount, `<mj-section css-class="totals">
	<mj-column>
		<mj-text font-size="18px">
			New Total: 
		</mj-text>
	</mj-column>
	<mj-column>
		<mj-text font-size="18px">
			{{usi_newsubtotal}}
		</mj-text>
	</mj-column>
</mj-section>`)}

<mj-section css-class="totals">
	<mj-column>
		<mj-image alt="" width="250px" src="https://plchldr.co/i/300x50?text=cta&bg=000" href="{{LINK1}}" />
	</mj-column>
</mj-section>
<mj-raw><!--{{REQUIRE_STOP:usi_prod_name_1}}--></mj-raw>`,
			email_recommendations: (o) => `<mj-raw><!--{{REQUIRE_START:usi_rec_productname_3}}--></mj-raw>
<mj-section padding="0">
	<mj-column>
		<mj-image alt="You may also like"  src="https://plchldr.co/i/500x50?text=you may like&bg=000" href="{{LINK1}}" />
	</mj-column>
</mj-section>

<mj-section css-class="product-row" border-bottom="1px solid #cccccc">
	<mj-column>
		<mj-image alt="{{usi_rec_productname_1}}" src="{{usi_rec_image_1}}" href="{{LINK2:usi_rec_url_1}}" />
		<mj-text css-class="product-name" padding-top="15px" height="30px">
			<b>{{usi_rec_productname_1}}</b>
		</mj-text>
		<mj-text css-class="product-price" padding-top="15px" color="#999">
			<b>{{usi_rec_price_1}}</b>
		</mj-text>
		<mj-image alt="View" src="https://plchldr.co/i/150x50?text=view&bg=000" href="{{LINK2:usi_rec_url_1}}" width="150px" />
	</mj-column>

	<mj-column>
		<mj-image alt="{{usi_rec_productname_2}}" src="{{usi_rec_image_2}}" href="{{LINK2:usi_rec_url_2}}" />
		<mj-text css-class="product-name" padding-top="15px" height="30px">
			<b>{{usi_rec_productname_2}}</b>
		</mj-text>
		<mj-text css-class="product-price" padding-top="15px" color="#999">
			<b>{{usi_rec_price_2}}</b>
		</mj-text>
		<mj-image alt="View" src="https://plchldr.co/i/150x50?text=view&bg=000" href="{{LINK2:usi_rec_url_2}}" width="150px" />
	</mj-column>

	<mj-column>
		<mj-image alt="{{usi_rec_productname_3}}" src="{{usi_rec_image_3}}" href="{{LINK2:usi_rec_url_3}}" />
		<mj-text css-class="product-name" padding-top="15px" height="30px">
			<b>{{usi_rec_productname_3}}</b>
		</mj-text>
		<mj-text css-class="product-price" padding-top="15px" color="#999">
			<b>{{usi_rec_price_3}}</b>
		</mj-text>
		<mj-image alt="View" src="https://plchldr.co/i/150x50?text=view&bg=000" href="{{LINK2:usi_rec_url_3}}" width="150px" />
	</mj-column>

</mj-section>
<mj-raw><!--{{REQUIRE_STOP:usi_rec_productname_3}}--></mj-raw>`,
			social: (o) => `<mj-section background-color="#eee">
	<mj-column>
		<mj-text align="center">
			<a href="" style="text-decoration: none;padding: 15px;">
				<img alt="" src="https://plchldr.co/i/25?text=S&bg=000" width="25px" />
			</a>
			<a href="" style="text-decoration: none;padding: 15px;">
				<img alt="" src="https://plchldr.co/i/25?text=S&bg=000" width="25px" />
			</a>
			<a href="" style="text-decoration: none;padding: 15px;">
				<img alt="" src="https://plchldr.co/i/25?text=S&bg=000" width="25px" />
			</a>
			<a href="" style="text-decoration: none;padding: 15px;">
				<img alt="" src="https://plchldr.co/i/25?text=S&bg=000" width="25px" />
			</a>
		</mj-text>
	</mj-column>
</mj-section>`,
			sharing: (o) => `<mj-section background-color="#eee">
	<mj-column>
		<mj-social font-size="15px" icon-size="30px" mode="horizontal">
			<mj-social-element alt="facebook" name="facebook-noshare" href=""></mj-social-element>
			<mj-social-element alt="twitter" name="twitter-noshare" href=""></mj-social-element>
			<mj-social-element alt="pinterest" name="pinterest-noshare" href=""></mj-social-element>
			<mj-social-element alt="linkedin" name="linkedin-noshare" href=""></mj-social-element>
			<mj-social-element alt="tumblr" name="tumblr-noshare" href=""></mj-social-element>
			<mj-social-element alt="youtube" name="youtube-noshare" href=""></mj-social-element>
			<mj-social-element alt="instagram" name="instagram-noshare" href=""></mj-social-element>
		</mj-social>
	</mj-column>
</mj-section>`,
			footer: (o) => `<mj-section background-color="#eee" padding="0">
	<mj-column>
		<mj-text font-size="12px" css-class="footer_info">
			<p>&copy; {{date_now:yyyy}} ${window.c ? c.companyName : "COMPANY"} | ADDRESS<br />
			<a href="https://www.upsellit.com/hound/nomore.jsp?{{SESSIONID}}" style="color:#333">Unsubscribe or Delete Data</a></p>
		</mj-text>
	</mj-column>
</mj-section>`
		},
		email: (o) =>
			usi_code.update(`<mjml>
	<mj-head>
		<!--Optional title element-->
		<mj-title></mj-title>
		<!--Optional hidden preview text-->
		<mj-preview></mj-preview>
		<!--Fix for iphone 10-->
		<mj-raw><meta name="x-apple-disable-message-reformatting" /></mj-raw>
		<!--Set a custom breakpoint for the mobile size-->
		<mj-breakpoint width="480px" />
		<mj-font name="Open Sans" href="https://fonts.googleapis.com/css?family=Open+Sans" />
		<mj-attributes>
			<!--Any default attribute can be overwritten here-->
			<mj-text align="center" color="Open Sans" font-size="18px" line-height="140%" />
			<mj-body background-color="#efefef" />
			<mj-section background-color="#ffffff" />
			<mj-all font-family="Open Sans, Helvetica, Arial, sans-serif" />
		</mj-attributes>
		<mj-style inline="inline">
			/*Fix so that alt text shows for images*/
			img {
				color: #000000;
				font-size: 14px;
				line-height: 1;
				text-align: center;
			}
			.product-price div {
				font-size: 12px !important;
				text-align:right !important;
			}
			.product-name div {
				font-size: 12px !important;
			}
		</mj-style>
		${is(
			o.email_cart,
			`<mj-style>
			@media screen and (max-width: 480px) {
				.product-name div, .product-price div {
					text-align:center !important;
				}
			}
		</mj-style>`
		)}
		${is(
			o.overlap_hero1,
			`<!--Update the heights so it works with the image. You may need more/different break points-->
		<mj-style inline="inline">
			.hero { height: 425px !important;}
		</mj-style>
		<mj-style>
			@media screen and (max-width: 500px) {
				.hero { height: 350px !important; }
			}
			@media screen and (max-width: 400px) {
				.hero { height: 250px !important; }
			}
		</mj-style>`
		)}
	</mj-head>
	<mj-body>
		
		${is(o.logo, usi_code.get_component("logo", o))}
		${is(o.navbar, usi_code.get_component("navbar", o))}
		${is(o.mj_hero, usi_code.get_component("mj_hero", o))}
		${is(o.email_hero, usi_code.get_component("email_hero", o))}
		${is(o.carousel, usi_code.get_component("carousel", o))}
		${is(o.overlap_hero1, usi_code.get_component("overlap_hero1", o))}
		${is(o.overlap_hero2, usi_code.get_component("overlap_hero2", o))}
		${is(
			o.text,
			`${
				o.overlap_hero1 || o.overlap_hero2
					? `<mj-section padding="0 30px" background-color="#eee">
			<mj-column background-color="#fff">
		`
					: `<mj-section>
			<mj-column>`
			}
				<mj-text align="left">
					<p>Hi {{NAME}},</p>
					<p><a href="{{LINK1}}">Return to our site</a> to complete your purchase.</p>
					<p>Sincerely,<br/>
					<b>${window.c ? c.companyName : "COMPANY"}</b></p>
				</mj-text>
				<mj-divider border-width="1px" border-color="#ccc"></mj-divider>
				<mj-button background-color="#333" href="{{LINK1}}">Checkout Now</mj-button>
			</mj-column>
		</mj-section>
		`
		)}
		${is(o.table, usi_code.get_component("table", o))}
		${is(o.accordion, usi_code.get_component("accordion", o))}
		${is(o.email_cart, usi_code.get_component("email_cart", o))}
		${is(o.email_recommendations, usi_code.get_component("email_recommendations", o))}
		${is(o.social, usi_code.get_component("social", o))}
		${is(o.sharing, usi_code.get_component("sharing", o))}
		${is(o.footer, usi_code.get_component("footer", o))}
	</mj-body>
</mjml>`),
		pcjs: (o) =>
			usi_code.update(`//	Register listeners
USI_registerThePage();
${is(
	o.visitor_id,
	`
// Save Visitor ID cookie
usi_js_monitor.USI_emailSeen = function(usi_email) {
	usi_cookies.create_cookie("usi_email", encodeURIComponent(usi_email), usi_cookies.expire_time.year);
}`
)}
${is(
	o.email_cart || o.optin || o.subtotal || o.discount || o.recommendations,
	`
// Send data to email
usi_js_monitor.USI_reportAllItems = function() {
	try {
		var send_data = function(value, name){
			usi_js_monitor.USI_LoadDynamics(value, name, usi_js_monitor.USI_getASession());
		};
		var item_num, usi_product, prop;
		${is(o.subtotal || o.discount, `var usi_subtotal = usi_cookies.get("usi_subtotal");`)}
		${is(o.discount, `var usi_newsubtotal;`)}
		${is(
			o.optin,
			`usi_js_monitor.USI_displayOptIn({
			language: document.documentElement.lang,
			element: usi_js_monitor.lastElement,
			yes_color: "#BC1F36"
		});`
		)}
		${is(
			o.subtotal || o.discount,
			`if (usi_subtotal != null) {
			${is(o.subtotal, `send_data(usi_subtotal, 'usi_subtotal');`)}
			${is(
				o.discount,
				`usi_newsubtotal = Number(usi_subtotal * 0.85).toFixed(2);
			send_data(usi_newsubtotal, 'usi_newsubtotal');`
			)}
		}`
		)}
		${is(
			o.email_cart,
			`
		for (item_num = 1; item_num <= 3; item_num++) {
			usi_product = {
				image: usi_cookies.get("usi_prod_image_" + item_num),
				name: usi_cookies.get("usi_prod_name_" + item_num),
				price: usi_cookies.get("usi_prod_price_" + item_num)
			};
			for (prop in usi_product) {
				if (usi_product.hasOwnProperty(prop) && usi_product[prop] != null) {
					send_data(decodeURIComponent(usi_product[prop]), 'usi_prod_' + prop + '_' + item_num);
				}
			}
		}`
		)}
		${is(
			o.recommendations,
			`
		for (var i = 1; i <= 3; i++) {
			var this_prod = usi_app.product_rec["product" + i];
			if (this_prod != undefined) {
				for (var j in this_prod) {
					// Only collect what we need
					if ("productname,url,image,price".indexOf(j) != -1	&& this_prod.hasOwnProperty(j)) {
						send_data(this_prod[j], 'usi_rec_' + j + '_' + i);
					}
				}
			}
		}`
		)}
	} catch (e) {}
}`
)}
`),
		pixel: (o) =>
			usi_code.update(`<%@ include file="/utility/app_header.jsp" %><%@ page contentType="application/x-javascript" %><%
response.setHeader("Content-type", "application/x-javascript");
response.addHeader("Pragma", "No-cache");
response.setHeader("Cache-Control", "no-cache,no-store");
response.setDateHeader("Expires",0);
String orderID = request.getParameter("orderID");
String orderAmt = request.getParameter("orderAmt");
String currency = request.getParameter("currency");
%><%@ include file="/utility/app_commons.jsp" %>
<%@ include file="/utility/pixel_commons.jsp" %>
<%if (orderID != null && !orderID.equals("")) { %>USI_orderID = "<%=orderID%>";<% } %>
<%if (orderAmt != null && !orderAmt.equals("")) { %>USI_orderAmt = "<%=orderAmt%>";<% } %>
<%if (currency != null && !currency.equals("")) { %>USI_currency = "<%=currency%>";<% } %>
if (typeof (USI_orderID) == "undefined") USI_orderID = "";
if (typeof (USI_orderAmt) == "undefined") USI_orderAmt = "";
if (typeof (USI_append) == "undefined") USI_append = "";
if (typeof(USI_currency) == "undefined" || USI_currency == null || USI_currency == "InsertCurrency") USI_currency = "";
if (typeof (usi_pixel) === "undefined") {
	<% staticInclude(pageContext, utilityFile("cookie_functions", true)); %>
	${is(o.coupon_monitor, `<%@ include file="/utility/upsell_couponv4.js" %>`)}
	${is(o.rec_reporting, `<% staticInclude(pageContext, utilityFile("upsell_prodrec", false)); %>`)}
	try {
		usi_pixel = {};
		usi_pixel.site_id = 'PIXEL_SITE_ID';
		usi_pixel.suppression_time = usi_cookies.get("usi_sale_suppress") || usi_cookies.expire_time.year;
		usi_pixel.main = function () {
			try {
				usi_pixel.reporting_currency = "USD";
				${is(
					o.get_datalayer,
					`
				usi_pixel.info = usi_pixel.get_order_info("ecommerce", "purchase");`
				)}
				${is(
					o.manual_scraping,
					`
				if (window.ORDERID != undefined) {
					USI_orderID = window.ORDERID;
				}
				if (window.ORDERAMOUNT != undefined) {
					USI_orderAmt = window.ORDERAMOUNT;
				}
				if (window.CURRENCY != undefined) {
					USI_currency = window.CURRENCY;
				}`
				)}
				${is(
					o.get_datalayer,
					`
				try {
					if (typeof(usi_pixel.info) != "undefined" && typeof(usi_pixel.info.purchase) != "undefined" && typeof(usi_pixel.info.purchase.actionField) != "undefined") {
						// Record order ID
						if (typeof(usi_pixel.info.purchase.actionField.id) != "undefined") {
							USI_orderID = usi_pixel.info.purchase.actionField.id;
						}
						// Record order currency
						if (typeof(usi_pixel.info.currencyCode) != "undefined") {
							USI_currency = usi_pixel.info.currencyCode;
						}
						// Record order coupon
						if (typeof(usi_pixel.info.purchase.actionField.coupon) != "undefined") {
							USI_append += "&coupon=" + usi_pixel.info.purchase.actionField.coupon;
						}
						// Record order amount
						if (typeof(usi_pixel.info.purchase.actionField.revenue) != "undefined") {
							USI_orderAmt = usi_pixel.info.purchase.actionField.revenue;
							if (typeof(usi_pixel.info.purchase.actionField.shipping) != "undefined") {
								USI_orderAmt -= usi_pixel.info.purchase.actionField.shipping;
							}
							if (typeof(usi_pixel.info.purchase.actionField.tax) != "undefined") {
								USI_orderAmt -= usi_pixel.info.purchase.actionField.tax;
							}
							USI_orderAmt = USI_orderAmt.toFixed(2);
						}
					}
				} catch (err) {
					usi_commons.report_error(err);
				}`
				)}
				${is(
					o.decimal,
					`
				// Fix USI_orderAmt missing decimal
				if (USI_orderAmt != "" && USI_orderAmt.indexOf(".") == -1 && Number(USI_orderAmt) > 0 && USI_orderAmt.length >= 3) {
					USI_orderAmt = USI_orderAmt.substring(0, USI_orderAmt.length - 2) + "." + USI_orderAmt.substring(USI_orderAmt.length - 2);
				}`
				)}
				${is(
					o.id_fallback,
					`
				// No client order ID available, create 30 day ID cookie
				if (USI_orderID == "") {
					USI_orderID = usi_commons.get_id();
				}`
				)}
				if (usi_cookies.get("usi_click_id") != null) USI_append += "&click_id=" + usi_cookies.get("usi_click_id");
				if (usi_cookies.get("usi_email_id") != null) USI_append += "&email_id=" + usi_cookies.get("usi_email_id");
				if (usi_cookies.get("usi_sale_notes") != null)	USI_append += "&notes=" + encodeURIComponent(usi_cookies.get("usi_sale_notes"));
				if (USI_currency != "") USI_append += "&currency=" + USI_currency;
				${is(
					o.anonymous,
					`usi_cookies.del("usi_id2");
				usi_cookies.del("usi_visit2");`
				)}
				usi_commons.load_script('https://www.upsellit.com/pixel.jsp?siteID=' + usi_pixel.site_id +
					'&orderID=' + encodeURIComponent(USI_orderID) +
					'&orderAmt=' + encodeURIComponent(USI_orderAmt) +
					'&cookie_suppression=' + usi_pixel.suppression_time +
					'&desired_currency=' + usi_pixel.reporting_currency +
					'&url=' + encodeURIComponent(location.href) +
					USI_append);
				${is(
					o.rec_reporting,
					`
				if (usi_cookies.get("usi_click_id") != null) {
					setTimeout(usi_pixel.report_sale, 2000);
				}`
				)}
				${is(o.coupon_monitor, `usi_coupon.report_coupon_order(-1, USI_orderAmt, USI_orderID);`)}
				${is(
					o.check_vars,
					`
				// Collect variable from confirmation page for research
				if (!USI_orderAmt || !USI_orderID) {
					usi_pixel.get_var(window.dataLayer);
				}`
				)}
				if (!USI_orderAmt || !USI_orderID) {
					usi_pixel_commons.report_data_layer();
				}
				var affiliate_data = usi_pixel_commons.report_affiliates();
				if (affiliate_data) {
					usi_commons.load_script('https://www.upsellit.com/launch/blank.jsp?siteID=' + usi_pixel.site_id +
						'&orderID=' + encodeURIComponent(USI_orderID) +
						'&orderAmt=' + encodeURIComponent(USI_orderAmt) +
						'&cookie_suppression=' + usi_pixel.suppression_time +
						'&desired_currency=' + usi_pixel.reporting_currency +
						'&url=' + encodeURIComponent(location.href) +
						USI_append + affiliate_data);
				}
			} catch (err) {
				usi_commons.report_error(err);
			}
		};
		${is(
			o.rec_reporting,
			`
		usi_pixel.report_sale = function () {
			try {
				var items = usi_pixel_commons.grab_items();
				var purchased = [];
				for (var i = 0; i < items.length; i++) {
					var bought = {};
					bought.pid = items[i].id;
					bought.name = items[i].name;
					bought.quantity = items[i].quantity;
					bought.price = items[i].price.replace(/[^0-9.]+/g, "");
					purchased.push(bought);
				}
				usi_prodrec.report_product_sale2(usi_cookies.get("usi_click_id"), purchased, USI_orderID, USI_orderAmt);
			} catch (err) {
				usi_commons.report_error(err);
			}
		};`
		)}
		${is(
			o.get_datalayer,
			`
		usi_pixel.get_order_info = function (property, property2) {
			try {
				if (window.dataLayer != undefined) {
					for (var i in dataLayer) {
						if (dataLayer[i][property] != undefined && dataLayer[i][property][property2] != undefined) {
							return dataLayer[i][property];
						}
					}
				}
				return {};
			} catch(err) {
				usi_commons.report_error(err);
			}
		};`
		)}
		${is(
			o.check_vars,
			`
		usi_pixel.get_var = function (json) {
			try {
				usi_commons.load_script("//www.upsellit.com/launch/blank.jsp?" + usi_pixel.site_id + "=" + encodeURIComponent(JSON.stringify(json)));
			} catch (e) {
				usi_commons.load_script("//www.upsellit.com/launch/blank.jsp?" + usi_pixel.site_id + "=INVALID");
			}
		};`
		)}
		${
			!o.wait
				? `usi_pixel.main();`
				: `
		usi_pixel.tries = 0;
		usi_pixel.check_for_var = function(){
			try {
				if (typeof dataLayer != "undefined" || usi_pixel.tries > 6) {
					usi_pixel.main();
				} else {
					usi_pixel.tries++
					setTimeout(usi_pixel.check_for_var, 500);
				}
			} catch(err) {
				usi_commons.report_error(err);
			}
		}
		usi_pixel.check_for_var();`
		}
	} catch (err) {
		usi_commons.report_error(err);
	}
}`)
	};
	return usi_code;
}

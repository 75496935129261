import styled from "styled-components";

export const FieldRevisionsWrapperEl = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  right: -100%;
  z-index: 1000;

  &.show {
    animation: field-revisions-show-ani 0.1s ease-out 1 forwards;
    -webkit-animation: field-revisions-show-ani 0.1s ease-out 1 forwards;
    -moz-animation: field-revisions-show-ani 0.1s ease-out 1 forwards;
    -o-animation: field-revisions-show-ani 0.1s ease-out 1 forwards;
  }

  &.hide {
    display: none;
  }

  @keyframes field-revisions-show-ani {
    0% {
      right: -10%;
      opacity: 0.5;
    }
    100% {
      right: 0;
      opacity: 1 !important;
    }
  }

  & .revisions-wrapper {
    padding-top: 4em;
    height: 100vh;
    width: 100%;
    box-shadow: 0 0 37px 0 rgba(0, 0, 0, 0.75);
    background: var(--bg-d1);
    overflow: auto;
    font-size: 0.75em
  }

  & .revisions-wrapper .revisions-header-wrapper {
    border-bottom: 1px solid var(--bg-d2);
    width: 100%;
    padding: 12px 40px;
  }

  & .revisions-wrapper .revisions-header-wrapper .cancel-btn {
    height: 30px;
    width: 30px;
    position: absolute;
    top: 73px;
    left: 5px;
    text-align: center;
  }

  & .revisions-wrapper .revisions-header-wrapper .cancel-btn:hover {
    cursor: pointer;
  }

  & .revisions-wrapper .revisions-header-wrapper .cancel-btn:hover > span {
    color: white;
  }

  & .revisions-wrapper .revisions-header-wrapper .cancel-btn span {
    color: var(--txt-dg);
    font-size: 15px;
    pointer-events: none;
  }

  & .revisions-wrapper .revisions-header-wrapper h1 {
    color: var(--txt-dg);
    font-size: 12px;
    margin-bottom: 5px;
    text-align: center;
  }

  & .revisions-wrapper .revisions-header-wrapper p {
    color: white;
    font-size: 12px;
    text-align: center;
  }

  & .revisions-wrapper .revisions-body-wrapper {
    padding: 5px 0 20px 0;
  }

  & .revisions-wrapper .revision-field-wrapper {
    margin: 10px 0;
    padding: 0 10px;
  }

  & .revisions-wrapper .revision-field-wrapper p {
    color: white;
    font-size: 12px;
  }

  & .revisions-wrapper .revision-field-wrapper .revision-version-wrapper {
    padding: 10px 0;
    display: inline-block;
    vertical-align: top;
    width: 20px;
    margin-right: 5px;
  }

  & .revisions-wrapper .revision-field-wrapper .revision-version-wrapper p {
    text-align: center;
  }

  & .revisions-wrapper .revision-field-wrapper .revision-content-wrapper {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 30px);
    border: 1px solid var(--bg-d2);
  }

  & .revisions-wrapper .revision-field-wrapper .revision-content {
    padding: 10px;
    background: var(--bg-d2);
    border-bottom: 1px solid var(--bg-d2);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .revisions-wrapper .revision-field-wrapper .revision-content.blank {
    color: transparent;
  }

  & .revisions-wrapper .revision-field-wrapper .revision-time-wrapper {
    padding: 5px 10px;
  }

  & .revisions-wrapper .revision-field-wrapper .revision-time-wrapper p {
    font-size: 11px;
  }

  & .revisions-wrapper .revision-field-wrapper .revision-time-wrapper .green {
    color: var(--green);
  }

  & .revisions-wrapper .revision-field-wrapper .revision-time-wrapper .yellow {
    color: var(--yellow);
  }

  & .revisions-wrapper .revision-field-wrapper .revision-time-wrapper .orange {
    color: var(--orange);
  }
  
`;
// Resources
import React, { Component } from "react";
import Moment from "react-moment";
import "moment-timezone";

// Components
import AuthHelperMethods from "../auth/AuthHelperMethods";
import TopNavWrapper from "./TopNavWrapper";

// Styles
import { ActivityWrapperEl } from "./styles/ActivityWrapper";

// Images
import clear from "../../images/icons/v1/clear.svg";

class ActivityWrapper extends Component {
	Auth = new AuthHelperMethods();

	constructor(props) {
		super(props);
		this.state = {
			activities: [],
			activityPrompt: "",
			myActivities: [],
			myActivityPrompt: ""
		};
	}

	_generateTimeStyles = () => {
		return "green";
	};

	quickFetchActivity = (id) => {
		this.Auth.getActivitiesQuick(id)
			.then((res) => {
				if (res.error) {
					console.error(res.error);
				} else if (res) {
					this.setState({
						myActivities: res.myActivities,
						myActivityPrompt:
							res.myActivities.length === 0 ? "Your newly created activities will be listed here" : "",
						activities: res.activities,
						activityPrompt:
							res.activities.length === 0 ? "All newly created activities will be listed here" : ""
					});
				} else {
					console.error("Unknown Error");
				}
			})
			.catch((err) => {
				console.log("[ quickFetchActivity ] POST error:", err);
			});
	};

	componentDidMount() {
		if (this.props.page === "app") {
			this.quickFetchActivity(this.props.user._id);
		}
	}

	_handleActivityClicked = (e) => {
		const id = e.target.getAttribute("data-id");
		if (id) {
			const temp = id.split(":");
			if (temp.length === 2) {
				this.props.handleActivityClick(temp[0], temp[1]);
			}
		}
	};

	renderMenuItems(type, myActivity) {
		Moment.globalTimezone = Intl.DateTimeFormat()["resolvedOptions"]()["timeZone"];
		let items = "";
		if (type === "activity") {
			let list = myActivity ? this.state.myActivities : this.state.activities;
			items = list.map((item) => {
				let icon_src = "activity";
				if (item.type === "company") {
					icon_src = "company";
				} else if (item.type === "user") {
					icon_src = "profile";
				} else if (item.type === "form") {
					icon_src = "form";
				}
				const firstDot = item.type_name && item.type_id ? " • " : "";
				const secondDot = " • ";
				return (
					<div className={"card activity-row " + item.action} key={item._id}>
						<div className={"icon-wrapper"}>
							<img className={"icon " + icon_src} src={clear} alt="icon" />
						</div>
						<div className="text">
							<p className="title">
								<span className={this._generateTimeStyles(new Date(item.createdAt))}>
									<Moment fromNow>{item.createdAt}</Moment>
								</span>
								{secondDot}
								<span className={"name"}>{item.user_name}</span> {item.message}
								{item.type_name && item.type_id && (
									<span>
										{firstDot}
										<span
											className="link"
											data-id={item.type + ":" + item.type_id}
											onClick={this._handleActivityClicked}
										>
											{item.type_name}
										</span>
									</span>
								)}
							</p>
						</div>
					</div>
				);
			});
		}
		return items;
	}

	render() {
		return (
			<React.Fragment>
				<ActivityWrapperEl>
					<div className={"header-wrapper activity" + (this.props.page === "app" ? " app" : "")}>
						<TopNavWrapper page={this.props.page} user={this.props.user} />
					</div>
					<div className="body-wrapper">
						<div className={"dashboard-column activity-wrapper"}>
							<h1>
								<img className="activity" src={clear} alt={"activity"} />
								My Activity • <span>{this.state.myActivities.length}</span>
							</h1>
							{this.renderMenuItems("activity", true)}
							{this.state.myActivityPrompt.length > 0 && (
								<div className="section-prompt">{this.state.myActivityPrompt}</div>
							)}
						</div>
						<div className="dashboard-column activity-wrapper">
							<h1>
								<img className="globe" src={clear} alt={"activity"} />
								All Activity • <span>{this.state.activities.length}</span>
							</h1>
							{this.renderMenuItems("activity")}
							{this.state.activityPrompt.length > 0 && (
								<div className="section-prompt">{this.state.activityPrompt}</div>
							)}
						</div>
					</div>
				</ActivityWrapperEl>
			</React.Fragment>
		);
	}
}

export default ActivityWrapper;

import styled from "styled-components";

export const ProfileWrapperEl = styled.div`
  display: block;
  height: 60px;
  width: 100%;
  background: var(--bg-d1);
  border-bottom: 1px solid var(--bg-d2);
  margin-top:0;

  img {
    font-size: 0;
  }

  & > .profile-img-body-wrapper {
    height: 100%;
    width: 50px;
    margin: 0;
    display: inline-block;
    vertical-align: top;
  }

  & > .profile-img-body-wrapper .profile-img-wrapper {
    height: 30px;
    width: 30px;
    margin-top: 15px;
    margin-left: 19px;
    background: transparent;
    text-align: center;
    // transition: margin-top 0.5s, margin-left 0.5s, -moz-transform ease-in-out 0.05s;
  }

  & .profile-img {
    border-radius: 16px;
    overflow: hidden;
  }

  & > .profile-info-body-wrapper {
    display: inline-block;
    vertical-align: top;
    height: 100%;
    width: calc(100% - 50px);
    padding: 13px 0 0 7px;
    //outline: 1px dotted red;
  }

  & > .profile-info-body-wrapper .name-wrapper {
    display: block;
  }

  & > .profile-info-body-wrapper .name-wrapper p {
    display: inline-block;
    vertical-align: top;
    color: white;
    font-size: 12px;
  }

  & > .profile-info-body-wrapper .name-wrapper .admin-badge {
    display: inline-block;
    vertical-align: top;
    font-size: 9px;
    font-weight: 500;
    background: var(--bg-d2);
    margin: 0 0 0 4px;
    padding: 0 8px;
    border-radius: 8px;
    color: var(--txt-lg);
  }

  & > .profile-info-body-wrapper .name-wrapper .admin-badge.sudo:hover {
    cursor: pointer;
    color: white;
    background: var(--blue);
  }

  & > .profile-info-body-wrapper .email-wrapper {
    display: block;
    position: relative;
    top: -1px;
  }

  & > .profile-info-body-wrapper .email-wrapper p {
    color: var(--txt-lg);
    font-size: 12px;
  }

  @media screen and (max-width: 1200px) {

    & .profile-img-body-wrapper {
      width: 80px;
      text-align: center;
    }

    & .profile-img-body-wrapper.legacy {
      width: 50px;
      text-align: left;
    }

    & .profile-img-body-wrapper .profile-img-wrapper {
      height: 30px;
      width: 30px;
      margin-left: 0;
      display: inline-block;
    }

    & .profile-img-body-wrapper.legacy .profile-img-wrapper {
      height: 30px;
      width: 30px;
      margin-top: 13px;
      margin-left: 17px;
    }

    & .profile-info-body-wrapper {
      display: none;
    }

    & .profile-info-body-wrapper.legacy {
      display: inline-block;
    }

  }

@media screen and (max-width: 480px) {

& .profile-info-body-wrapper {
  display: inline-block;
  width: calc(100% - 80px);
}

}
`;
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Cookies from "universal-cookie";
import { withRouter, Redirect, BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AuthHelperMethods from "./components/auth/AuthHelperMethods";
import withAuth from "./components/auth/withAuth";
import { createStore } from "redux";
import rootReducer from "./reducers";
import { WikiWrapperEl, GlobalStyle } from "./components/body/styles/WikiWrapper";
import logo from "./images/logo.svg";

const store = createStore(rootReducer);

class AdminWrapper extends Component {
	Auth = new AuthHelperMethods();
	constructor(props) {
		super(props);
		this.state = {
			logout: false,
			redirect: "",
			title: "",
			value: "",
			settings: [],
			form: []
		};
	}

	fetchAdmin = () => {
		this.fetchAdminAll();
		this.Auth.fetchAdmin({ user: this.state.user._id })
			.then((res) => {
				if (res.error) {
					alert(res.error);
				} else if (res.data.settings) {
					this.setState({ form: res.data.settings });
				}
			})
			.catch((err) => console.error("[ fetchAdmin ] POST error:", err));
	};

	fetchAdminAll = () => {
		this.Auth.fetchAdmin({ user: this.state.user._id })
			.then((res) => {
				if (res.error) {
					alert(res.error);
				} else if (res.data.settings) {
					let obj = {};
					res.data.settings.forEach((item) => (obj[item.title] = item.value));
					obj.tags = obj.tags.split("\n");
					obj.departments = JSON.parse(obj.departments);
					obj.copy_data = JSON.parse(obj.copy_data);
					this.setState({ settings: obj });
				}
			})
			.catch((err) => console.error("[ fetchAdminAll ] POST error:", err));
	};

	edit = (event) => {
		event.preventDefault();
		const post_data = {
			id: event.target.id.value,
			title: event.target.title.value,
			value: event.target.value.value,
			description: event.target.description.value,
			user: this.state.user
		};

		this.Auth.editAdmin(post_data)
			.then((res) => {
				if (res.error) {
					alert(res.error);
				} else {
					this.fetchAdmin();
				}
			})
			.catch((err) => console.error("[ edit ] POST error:", err));
	};

	handleValueFormChange = (event) => {
		const { name, value } = event.target;
		// Extract the keys from the input name attribute
		const keys = name.split("--");
		// Copy the current settings state to modify
		let updatedSettings = [...this.state.form];
		// Find the specific setting and update its nested values recursively
		let data = updatedSettings.find((setting) => setting.title === keys[0]);
		if (data) {
			// Nested JSON path update
			let schema = data.value ? JSON.parse(data.value) : {};
			let temp = schema;
			// Navigate through the schema except for the last key
			keys.slice(1, -1).forEach((key, index) => {
				// Check if navigating an array or object
				if (!isNaN(parseInt(key)) && Array.isArray(temp)) {
					temp = temp[parseInt(key)];
				} else if (!temp[key]) {
					// Initialize as an object or array based on the next key, if missing
					temp[key] = isNaN(parseInt(keys[index + 1])) ? {} : [];
				} else {
					temp = temp[key];
				}
			});
			const finalKey = keys[keys.length - 1];
			if (!isNaN(parseInt(finalKey)) && Array.isArray(temp)) {
				// If finalKey is an index and temp is an array, replace the whole item at this index
				temp[parseInt(finalKey)] = value;
			} else {
				// Otherwise, treat it as a key of an object
				temp[finalKey] = value;
			}
			// Update the setting's value with the new JSON string
			data.value = JSON.stringify(schema, null, "\t");
			const formElement = event.target.form;
			const valueInput = formElement.querySelector('[name="value"]');
			if (valueInput) {
				valueInput.value = data.value;
			}
		}
		// Update the form state with new data
		this.setState({
			form: updatedSettings
		});
	};

	add = (event) => {
		event.preventDefault();
		const post_data = {
			title: this.state.title,
			value: this.state.value,
			description: this.state.description,
			user: this.state.user
		};

		this.Auth.addAdmin(post_data)
			.then((res) => {
				if (res.error) {
					alert(res.error);
				} else {
					this.fetchAdmin();
				}
			})
			.catch((err) => console.error("[ add ] POST error:", err));
	};

	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	handleLogout = () => {
		if (!window.location.href.includes("localhost")) {
			this.Auth.logout();
			this.setState({ logout: true }, () => {
				this.props.history.replace("/login");
			});
		}
	};

	componentDidMount() {
		let cookies = new Cookies();
		let theme = cookies.get("theme") || "dark";
		document.documentElement.id = theme === "light" ? "light" : "dark";

		if (this.Auth.loggedIn()) {
			this.Auth.getProfile()
				.then((res) => {
					if (res.success && res.data && res.data.profile) {
						store.dispatch({
							type: "PROFILE",
							profile: res.data.profile
						});
						this.setState(
							{
								user: res.data,
								profile: res.data.profile,
								userId: res.data.id,
								ws: res.data.ws
							},
							this.fetchAdmin
						);
					} else {
						this.handleLogout();
					}
				})
				.catch((err) => {
					console.error("[ componentDidMount ] Error:", err);
					this.handleLogout();
				});
		} else {
			this.handleLogout();
		}
	}

	render() {
		let cookies = new Cookies();
		if (this.state.redirect) {
			return <Redirect to={this.state.redirect} />;
		}
		const isManager =
			this.state.user && this.state.settings && this.state.user.profile.email.match(this.state.settings.managers);
		const isEditor =
			this.state.user && this.state.settings && this.state.user.profile.email.match(this.state.settings.editors);

		return (
			<React.Fragment>
				<Helmet>
					<title>Upsellit Docs Admin</title>
				</Helmet>
				<GlobalStyle />
				<WikiWrapperEl>
					<header className={"header-wrapper"}>
						<nav className="top-navigation">
							<div className="menu-item" style={{ minWidth: "10em", marginRight: "auto" }}>
								<button className="btn btn-link btn-logo">
									<a href={origin + "/"}>
										<img src={logo} alt={"logo"} className={"icon"} />
									</a>{" "}
									<a
										href={origin + "/admin"}
										style={{ fontSize: "1.5em", paddingLeft: "1em", letterSpacing: "2px" }}
									>
										Docs Admin {this.angleIcon}
									</a>
								</button>
								<div className="sub-menu">
									<ul style={{ paddingLeft: "1em" }}>
										<li>
											<a href={origin + "/wiki"} className="btn btn-link btn-block">
												Wiki
											</a>
										</li>
										<li>
											<a href={origin + "/"} className="btn btn-link btn-block">
												CSD
											</a>
										</li>
										<li>
											<a
												href="https://www2.upsellit.com/admin/control/edit/index.jsp"
												target="_blank"
												rel="noreferrer"
												className="btn btn-link btn-block"
											>
												Admin
											</a>
										</li>
										<li>
											<a
												href="https://upsellit.lightning.force.com/lightning"
												target="_blank"
												rel="noreferrer"
												className="btn btn-link btn-block"
											>
												Salesforce
											</a>
										</li>
									</ul>
								</div>
							</div>
							{this.state.user && (
								<div style={{ marginRight: "1em", minWidth: "5em" }}>
									<div className="menu-item">
										<div className="">
											<button
												className="profile-button btn btn-link"
												style={{ backgroundImage: `url(${this.state.user.profile.picture})` }}
											></button>{" "}
											<span style={{ marginTop: "1.25em", display: "inline-block" }}>
												{this.angleIcon}
											</span>
										</div>
										<div className="sub-menu">
											<ul>
												<li>
													<button
														className="btn btn-link btn-block"
														onClick={(e) => {
															let theme = cookies.get("theme") || "dark";
															if (theme === "light") {
																cookies.set("theme", "dark", { path: "/" });
																document.documentElement.id = "dark";
															} else {
																cookies.set("theme", "light", { path: "/" });
																document.documentElement.id = "light";
															}
														}}
													>
														<span
															className="icon"
															style={{
																background: "var(--icon-moon-g)"
															}}
														></span>
														Theme
													</button>
												</li>
												<li>
													{this.state.settings && isEditor && (
														<a href="/admin/" className="btn btn-link btn-block">
															<span
																className="icon"
																style={{
																	background: "var(--icon-team-lg)"
																}}
															></span>
															Docs Admin
														</a>
													)}
												</li>
												<li>
													<a
														href="/wiki/wiki_browsing_and_editing"
														className="btn btn-link btn-block"
													>
														<span className="icon" style={{}}>
															?
														</span>
														Help
													</a>
												</li>
												<li>
													{this.state.user && (
														<button
															onClick={() => this._handleLogout()}
															className="btn btn-link btn-block"
														>
															<span
																className="icon"
																style={{
																	background: "var(--icon-logout-g)"
																}}
															></span>
															Logout
														</button>
													)}
												</li>
											</ul>
										</div>
									</div>
								</div>
							)}
						</nav>
					</header>
					<Router>
						<Switch>
							<Route exact path="/admin">
								<div className="admin-wrapper">
									{this.state.user && (
										<div className={"body-wrapper"}>
											{this.state.user.profile.email.match("broche|cwampler") && (
												<details style={{ border: "1px solid", padding: "1em" }}>
													<summary>Add Setting +</summary>
													<form className="input-wrapper admin-field" onSubmit={this.add}>
														<label>New Setting Title</label>
														<input
															type="text"
															name="title"
															value={this.state.title}
															onChange={this.handleChange}
														/>
														<label>New Setting Value</label>
														<textarea
															name="value"
															value={this.state.value}
															onChange={this.handleChange}
														></textarea>
														<button type="submit" className="btn btn-primary">
															Add Setting
														</button>
													</form>
												</details>
											)}
											<main>
												{this.state.form.map((setting, index) => (
													<form
														className="input-wrapper admin-field"
														key={setting._id}
														onSubmit={this.edit}
													>
														<details open={index === 0}>
															<summary>{setting.title} &rsaquo;</summary>
															<input type="hidden" name="id" value={setting._id} />
															<div
																className="card"
																style={{ border: "1px solid", padding: "1em" }}
															>
																<label>Title</label>
																<input
																	type="text"
																	name="title"
																	defaultValue={setting.title}
																	readOnly={
																		!this.state.user.profile.email.match(
																			"broche|cwampler"
																		)
																	}
																/>

																<label>Description</label>
																<input
																	name="description"
																	defaultValue={setting.description || ""}
																	readOnly={!isManager}
																></input>

																<label>Value</label>
																<textarea
																	name="value"
																	defaultValue={setting.value}
																	readOnly={!isManager}
																></textarea>
															</div>
															{setting.value && setting.title === "departments" && (
																<div>
																	{Object.keys(JSON.parse(setting.value)).map(
																		(dept) => (
																			<div
																				className="btn tag-btn"
																				key={dept}
																				style={{
																					backgroundColor: `rgb(${
																						JSON.parse(setting.value)[dept]
																							.color
																					})`
																				}}
																			>
																				{dept}
																			</div>
																		)
																	)}
																</div>
															)}
															{setting.value && setting.title === "tags" && (
																<ul>
																	{setting.value.split("\n").map((tag) =>
																		tag.match(/[$0-9]\./) == null ? (
																			<li className="btn tag-btn" key={tag}>
																				{tag}
																			</li>
																		) : (
																			<h5 key={tag} value={tag}>
																				{tag}
																			</h5>
																		)
																	)}
																</ul>
															)}
															{setting.value && setting.title === "field_data" && (
																<div>
																	{Object.keys(JSON.parse(setting.value)).map(
																		(field_type_id) => (
																			<details
																				className=""
																				key={field_type_id}
																				style={{
																					borderBottom: "1px solid"
																				}}
																			>
																				<summary>{field_type_id}</summary>
																				<ul>
																					<li>
																						<span>title: </span>
																						<input
																							onBlur={
																								this
																									.handleValueFormChange
																							}
																							name={
																								setting.title +
																								"--" +
																								field_type_id +
																								"--title"
																							}
																							defaultValue={
																								JSON.parse(
																									setting.value
																								)[field_type_id].title
																							}
																						></input>
																					</li>
																					<li>
																						<span>description: </span>
																						<input
																							onChange={
																								this
																									.handleValueFormChange
																							}
																							name={
																								setting.title +
																								"--" +
																								field_type_id +
																								"--description"
																							}
																							defaultValue={
																								JSON.parse(
																									setting.value
																								)[field_type_id]
																									.description
																							}
																						></input>
																					</li>
																					<li>
																						<span>placeholder: </span>
																						<input
																							onChange={
																								this
																									.handleValueFormChange
																							}
																							name={
																								setting.title +
																								"--" +
																								field_type_id +
																								"--placeholder"
																							}
																							defaultValue={
																								JSON.parse(
																									setting.value
																								)[field_type_id]
																									.placeholder
																							}
																						></input>
																					</li>
																					<li>
																						<span>selections: </span>
																						<textarea
																							onChange={
																								this
																									.handleValueFormChange
																							}
																							name={
																								setting.title +
																								"--" +
																								field_type_id +
																								"--selections"
																							}
																							defaultValue={JSON.stringify(
																								JSON.parse(
																									setting.value
																								)[field_type_id]
																									.selections
																							)}
																						></textarea>
																					</li>
																					<li>
																						<span>wiki: </span>
																						<input
																							onChange={
																								this
																									.handleValueFormChange
																							}
																							name={
																								setting.title +
																								"--" +
																								field_type_id +
																								"--wiki"
																							}
																							defaultValue={
																								JSON.parse(
																									setting.value
																								)[field_type_id].wiki
																							}
																						></input>
																					</li>
																					<li>
																						<span>estimate_time: </span>
																						<input
																							onChange={
																								this
																									.handleValueFormChange
																							}
																							name={
																								setting.title +
																								"--" +
																								field_type_id +
																								"--estimate_time"
																							}
																							defaultValue={
																								JSON.parse(
																									setting.value
																								)[field_type_id]
																									.estimate_time
																							}
																						></input>
																					</li>
																					<li>
																						<span>validate: </span>
																						<input
																							onChange={
																								this
																									.handleValueFormChange
																							}
																							name={
																								setting.title +
																								"--" +
																								field_type_id +
																								"--validate"
																							}
																							defaultValue={
																								JSON.parse(
																									setting.value
																								)[field_type_id]
																									.validate
																							}
																						></input>
																					</li>
																				</ul>
																				<hr />
																			</details>
																		)
																	)}
																</div>
															)}
															{setting.value && setting.title === "copy_data" && (
																<div style={{ padding: "1em" }}>
																	{Object.keys(JSON.parse(setting.value)).map(
																		(cat) => (
																			<details
																				className=""
																				key={cat}
																				style={{
																					padding: "1em",
																					border: "1px solid",
																					marginBottom: "1em"
																				}}
																			>
																				<summary>{cat}</summary>
																				{JSON.parse(setting.value)[cat].map(
																					(email, index) => (
																						<ul
																							key={email.title}
																							className="card"
																							style={{
																								padding: "1em",
																								border: "1px solid",
																								marginBottom: "1em"
																							}}
																						>
																							<li>
																								<span>Title: </span>
																								<input
																									onBlur={
																										this
																											.handleValueFormChange
																									}
																									name={
																										setting.title +
																										"--" +
																										cat +
																										"--" +
																										index +
																										"--title"
																									}
																									defaultValue={
																										email.title
																									}
																									readOnly={
																										!isManager &&
																										!isEditor
																									}
																								></input>
																							</li>
																							<li>
																								<span>Subject: </span>
																								<input
																									onChange={
																										this
																											.handleValueFormChange
																									}
																									name={
																										setting.title +
																										"--" +
																										cat +
																										"--" +
																										index +
																										"--subject"
																									}
																									defaultValue={
																										email.subject
																									}
																									readOnly={
																										!isManager &&
																										!isEditor
																									}
																								></input>
																							</li>
																							<li>
																								<span>Preheader: </span>
																								<input
																									onChange={
																										this
																											.handleValueFormChange
																									}
																									name={
																										setting.title +
																										"--" +
																										cat +
																										"--" +
																										index +
																										"--preheader"
																									}
																									defaultValue={
																										email.preheader
																									}
																									readOnly={
																										!isManager &&
																										!isEditor
																									}
																								></input>
																							</li>
																							<li>
																								<span>Hero: </span>
																								<input
																									onChange={
																										this
																											.handleValueFormChange
																									}
																									name={
																										setting.title +
																										"--" +
																										cat +
																										"--" +
																										index +
																										"--hero"
																									}
																									defaultValue={
																										email.hero
																									}
																									readOnly={
																										!isManager &&
																										!isEditor
																									}
																								></input>
																							</li>
																							<li>
																								<span>
																									Introduction:{" "}
																								</span>
																								<textarea
																									onChange={
																										this
																											.handleValueFormChange
																									}
																									name={
																										setting.title +
																										"--" +
																										cat +
																										"--" +
																										index +
																										"--introduction"
																									}
																									defaultValue={
																										email.introduction
																									}
																									readOnly={
																										!isManager &&
																										!isEditor
																									}
																								></textarea>
																							</li>
																							<li>
																								<span>CTA: </span>
																								<input
																									onChange={
																										this
																											.handleValueFormChange
																									}
																									name={
																										setting.title +
																										"--" +
																										cat +
																										"--" +
																										index +
																										"--CTA"
																									}
																									defaultValue={
																										email.CTA
																									}
																									readOnly={
																										!isManager &&
																										!isEditor
																									}
																								></input>
																							</li>
																							<li>
																								<span>
																									CTA Button:{" "}
																								</span>
																								<input
																									onChange={
																										this
																											.handleValueFormChange
																									}
																									name={
																										setting.title +
																										"--" +
																										cat +
																										"--" +
																										index +
																										"--cta_button"
																									}
																									defaultValue={
																										email.cta_button
																									}
																									readOnly={
																										!isManager &&
																										!isEditor
																									}
																								></input>
																							</li>
																						</ul>
																					)
																				)}
																				<hr />
																			</details>
																		)
																	)}
																</div>
															)}
															{setting.value && setting.title.match("_tests") && (
																<div
																	className="card"
																	style={{
																		border: "1px solid",
																		padding: "1em",
																		marginBottom: "1em"
																	}}
																>
																	{JSON.parse(setting.value).map((test, index) => (
																		<details
																			key={test.case}
																			style={{
																				border: "1px solid",
																				padding: "1em",
																				marginBottom: "1em"
																			}}
																		>
																			<summary>{test.case}</summary>
																			<ul
																				style={{
																					border: "1px solid",
																					padding: "1em",
																					marginBottom: "1em"
																				}}
																			>
																				<li>
																					<span>case: </span>
																					<input
																						onBlur={
																							this.handleValueFormChange
																						}
																						name={
																							setting.title +
																							"--" +
																							index +
																							"--case"
																						}
																						defaultValue={test.case}
																						readOnly={
																							!isManager && !isEditor
																						}
																					></input>
																				</li>
																				<li>
																					<span>section: </span>
																					<input
																						onChange={
																							this.handleValueFormChange
																						}
																						name={
																							setting.title +
																							"--" +
																							index +
																							"--section"
																						}
																						defaultValue={test.section}
																						readOnly={
																							!isManager && !isEditor
																						}
																					></input>
																				</li>
																				<li>
																					<span>tooltip: </span>
																					<input
																						onChange={
																							this.handleValueFormChange
																						}
																						name={
																							setting.title +
																							"--" +
																							index +
																							"--tooltip"
																						}
																						defaultValue={test.tooltip}
																						readOnly={
																							!isManager && !isEditor
																						}
																					></input>
																				</li>
																			</ul>
																			<hr />
																		</details>
																	))}
																</div>
															)}
															<button type="submit" className="btn btn-primary">
																Save
															</button>
														</details>
													</form>
												))}
											</main>
										</div>
									)}
								</div>
							</Route>
							<Route path="*">
								<p>Route not found</p>
							</Route>
						</Switch>
					</Router>
				</WikiWrapperEl>
			</React.Fragment>
		);
	}
}

export default withRouter(withAuth(AdminWrapper));

// noinspection JSUnresolvedVariable

// Resources
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import { io } from "socket.io-client";
import { ConfigProvider, theme } from 'antd';

// Components
import SideBarWrapper from "./components/sideBar/SideBarWrapper";
import BodyWrapper from "./components/body/BodyWrapper";
import AddCompanyWrapper from "./components/forms/AddCompanyWrapper";

// Auth
import AuthHelperMethods from "./components/auth/AuthHelperMethods";
import withAuth from "./components/auth/withAuth";

// Redux
import { createStore } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./reducers";

// Globals
let socket;
const store = createStore(rootReducer);
const allowedNav = ["dashboard", "legacy", "activity", "team", "dev"];

// Export - Protected
class App extends Component {
	Auth = new AuthHelperMethods();

	constructor(props) {
		super(props);
		this.state = {
			// Title
			title: "Dashboard",
			// User
			profile: {},
			// Add
			showMask: false,
			addCompany: false,
			// Sockets
			ws: "",
			isConnected: false,
			// Misc
			logout: false,
			search: ""
		};
	}

	// <editor-fold desc="* * * EXIT METHODS * * *">
	componentWillUnmount() {
		if (this.state.logout) this.Auth.logout();
		this._leaveRoom();
	}

	_leaveRoom = () => {
		console.log("========================");
		console.log("=== LEAVE ROOM (APP) ===");
		console.log("========================");
		store.dispatch({ type: "INIT_SOCKETS", socket: undefined });
		store.dispatch({ type: "NAV", nav: undefined, page: undefined });
		store.dispatch({ type: "APP_ROOM", users: [], online: 0 });
		this.setState({ isConnected: false });
		if (socket) socket.disconnect();
	};

	_handleLogout = () => {
		this.setState({ logout: true }, function () {
			this.props.history.replace("/login");
		});
	};

	_handleCompanyClick = (id) => {
		if (!id) return;
		this.props.history.push({
			pathname: "/company",
			search: "?id=" + id
		});
	};

	_handleActivityClick = (type, id) => {
		if (!id) return;
		if (type === "company") {
			this.props.history.push({
				pathname: "/company",
				search: "?id=" + id
			});
		} else if (type === "form") {
			this.props.history.push({
				pathname: "/form",
				search: "?id=" + id
			});
		} else {
			console.log("[ App - _handleActivityClick ] Unknown type:", type);
		}
	};
	// </editor-fold>

	// <editor-fold desc="* * * PAGE METHODS * * *">
	_handleNavChange = (selected) => {
		store.dispatch({
			type: "NAV",
			nav: selected,
			page: "app"
		});
		if (selected === "wiki") {
			this.props.history.push({
				pathname: "/wiki/"
			});
		} else {
			this.props.history.push({
				pathname: "/",
				search: "?nav=" + selected
			});
		}
		this.setState({
			title: selected.charAt(0).toUpperCase() + selected.slice(1)
		});
	};

	_forceReloadPage = () => {
		window.location.reload();
	};

	_handleMaskClick = () => {
		// console.log('[ App - _handleMaskClick ] Mask clicked');
	};

	_handleAddCompany = () => {
		this.setState({
			showMask: true,
			addCompany: true
		});
	};

	_handleSudoAction = (action) => {
		this.Auth.sudoAction({ action: action })
			.then((res) => {
				console.log(res);
				if (res.success) this._forceReloadPage();
			})
			.catch((err) => {
				console.error(err);
			});
	};

	_handleAddCompanyCancel = (reload, redirectId) => {
		// We are always adding when on the app page
		this.setState(
			{
				showMask: false,
				addCompany: false
			},
			function () {
				if (reload) {
					window.location.reload();
				} else if (redirectId) {
					this._handleCompanyClick(redirectId);
				}
			}
		);
	};
	// </editor-fold>

	// <editor-fold desc="* * * SOCKET METHODS * * *">
	initSockets = () => {
		if (this.state.profile && this.state.profile.email) {
			console.log("[ App - initSockets ] Init -> profile:", this.state.profile.email);
			socket = io({ transports: ["polling"] });

			// Init sockets
			socket.on("connected", (data) => {
				console.log("[ App - initSockets - connected ] message:", data.message);

				// Enter room
				socket.emit("enterRoom", {
					initChat: false,
					room: "APP",
					url: window.location.href,
					profile: this.state.profile,
					pageType: "APP",
					companyName: "",
					formName: ""
				});
			});

			// Process room info
			socket.on("handleRoomUpdate", (data) => {
				console.log("[ App - initSockets - handleRoomUpdate ] Room data:", data);
				store.dispatch({
					type: "APP_ROOM",
					users: data.users || [],
					online: data.online || 0
				});
				this.setState({ isConnected: true });
			});

			// Save socket obj in redux
			store.dispatch({
				type: "INIT_SOCKETS",
				socket: socket
			});
		}
	};

	_printRooms() {
		socket.emit("printRooms");
	}

	// </editor-fold>

	// <editor-fold desc="* * * COMPONENT METHODS * * *">
	componentDidMount() {
		if (this.Auth.getToken()) {
			// Save nav selection in redux
			const params = queryString.parse(this.props.history.location.search);
			let nav = params && params.nav ? params.nav : "dashboard";
			if (allowedNav.indexOf(nav) === -1) nav = "dashboard";
			const search = params && params.search ? params.search : "";
			this.setState({
				search: search
			});
			store.dispatch({
				type: "NAV",
				nav: nav,
				page: "app"
			});

			this.Auth.getProfile()
				.then((res) => {
					if (res.success && res.data && res.data.profile) {
						// Set redux
						res.data.profile._id = res.data.id;
						store.dispatch({
							type: "PROFILE",
							profile: res.data.profile
						});

						// Set state
						this.setState(
							{
								profile: res.data.profile,
								ws: res.data.ws
							},
							function () {
								// Init socket connection
								this.initSockets();
							}
						);
					} else {
						console.log("[ componentDidMount ] Server error:", res);
						this._handleLogout();
					}
				})
				.catch((err) => {
					console.log("[ componentDidMount ] POST error:", err);
					this._handleLogout();
				});
		} else {
			console.log("[ componentDidMount ] Token not found");
			this._handleLogout();
		}
	}

	// </editor-fold>

	// Render protected component
	render() {
		const ant_theme = {
			algorithm: document.documentElement.id === "dark" ? theme.darkAlgorithm : theme.defaultAlgorithm,
			token: {
				fontFamily: "Geologica",
				colorPrimary: "#816aae",
				fontSize: 16,
				borderRadius: 8,
				wireframe: true
			}
		};
		return (
			<React.Fragment>
				<Helmet>
					<title>CSD • {this.state.title}</title>
				</Helmet>
				<Provider store={store}>
					<ConfigProvider theme={ant_theme}>
						<React.StrictMode>
							{this.state.profile && this.state.profile.email && (
								<div id="sidebar">
									<SideBarWrapper
										user={this.props.user}
										handleNavChange={this._handleNavChange}
										handleLogout={this._handleLogout}
										printRooms={this._printRooms}
										forceReloadPage={this._forceReloadPage}
									/>
								</div>
							)}
							<div id="body" className="app-body">
								<BodyWrapper
									handleAddCompany={this._handleAddCompany}
									handleCompanyClick={this._handleCompanyClick}
									handleSudoAction={this._handleSudoAction}
									handleActivityClick={this._handleActivityClick}
									search={this.state.search}
								/>
							</div>
							{this.state.showMask && (
								<div id="mask" onClick={this._handleMaskClick}>
									{this.state.addCompany && (
										<AddCompanyWrapper handleAddCompanyCancel={this._handleAddCompanyCancel} />
									)}
								</div>
							)} 

						</React.StrictMode>
					</ConfigProvider>
				</Provider>
			</React.Fragment>
		);
	}
}

// In order for this component to be protected, we must wrap it with a 'Higher Order Component'
export default withAuth(App);

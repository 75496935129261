// noinspection HttpUrlsUsage
// Resources
import React, { Component } from "react";
import TextareaAutosize from "react-textarea-autosize";

// Redux
import { connect } from "react-redux";

// Components
import AuthHelperMethods from "../auth/AuthHelperMethods";

// Styles
import { AddCompanyWrapperEl } from "./styles/AddCompanyWrapper";
import { Prompt } from "./Prompt";

const mapStateToProps = function (state) {
	return state;
};

class AddCompanyWrapper extends Component {
	Auth = new AuthHelperMethods();

	constructor(props) {
		super(props);
		this.state = {
			// Prompt
			prompt: "",
			promptColor: "red",
			// Url
			urlInit: "",
			isValidURL: false,
			isValidForm: false,
			hideGenerate: true,
			loading: false,
			manual: false,
			// Form
			url: "",
			title: "",
			favicon: "",
			description: "",
			adminId: "",
			salesforceId: "",
			// Edit
			_id: "",
			snapshot: "",
			// Misc
			redirectId: "",
			disableSubmit: false
		};
	}

	_handleAddCompanyCancel = () => {
		this.props.handleAddCompanyCancel();
	};

	_handleCompanyRedirect = () => {
		this.props.handleAddCompanyCancel(false, this.state.redirectId);
	};

	_handleCompanySubmit = () => {
		// Initial validations
		if (!this.state.url) return this.displayPrompt("Company URL is required");
		let url = this.state.url;
		if (url.indexOf("http") !== 0) {
			url = "https://" + url;
		}
		if (!this.isValidHttpUrl(url)) return this.displayPrompt("Please enter a valid Company URL");
		// Get url host
		const newUrl = new URL(url);
		const host = newUrl.protocol + "//" + newUrl.hostname;
		// Final validations
		if (!host) return this.displayPrompt("Please enter a valid Company URL");
		if (!this.isValidHttpUrl(host)) return this.displayPrompt("Please enter a valid Company URL");
		let companyData = {
			url: host,
			title: this.state.title,
			description: this.state.description,
			favicon: this.state.favicon,
			_id: this.state._id,
			adminId: this.state.adminId,
			salesforceId: this.state.salesforceId
		};
		console.log("[ _handleCompanySubmit ] companyData:", companyData);

		if (!this.state.disableSubmit) {
			this.setState(
				{
					disableSubmit: true
				},
				function () {
					this.Auth.createCompany(companyData)
						.then((res) => {
							if (res.error) {
								this.displayPrompt(res.error);
							} else {
								this.props.handleAddCompanyCancel(true);
							}
						})
						.catch((err) => {
							console.log(err);
						});
				}
			);
		}
	};

	_handleManualEntryClicked = () => {
		this.setState(
			{
				manual: true,
				loading: false,
				isValidURL: false,
				hideGenerate: true,
				url: "",
				title: "",
				description: "",
				favicon: ""
			},
			function () {
				this.validateForm();
			}
		);
	};

	_handleURLSubmit = () => {
		// Set loading state
		let url = this.state.urlInit;
		if (url.indexOf("http") !== 0) {
			url = "https://" + url;
		}
		this.setState({
			loading: true
		});

		// Get url host
		const newUrl = new URL(url);
		let host = newUrl.protocol + "//" + newUrl.hostname;
		if (!host) return this.displayPrompt("Invalid URL");
		if (host[host.length - 1] === "/") host = host.slice(0, -1);

		this.Auth.querySalesforce("select Account.Id, Account.Name, Account.Account_ID__c, Account.Website, Account.PhotoUrl, Account.Industry from Account where Account.Website like '%" + this.state.urlInit + "%'")
			.then((res) => {
				let account = res.data.records[0];
				if (!account) {
					// Fetch link preview
					this.Auth.getLinkPreview(host)
						.then((res) => {
							if (res.error) {
								// Server returned an error. Display the error
								this.setState(
									{
										loading: false,
										isValidURL: false,
										redirectId: res.id
									},
									function () {
										this.displayPrompt(res.error);
										if (res.manual) {
											this.setState(
												{
													manual: true,
													loading: false,
													isValidURL: true,
													hideGenerate: true,
													url: host,
													title: "",
													description: "",
													favicon: ""
												},
												function () {
													this.validateForm();
												}
											);
										}
									}
								);
							} else {
								console.log("[ _handleURLSubmit ] companyData:", res);
								if (res.url[res.url.length - 1] === "/") res.url = res.url.slice(0, -1);
								this.setState(
									{
										loading: false,
										isValidURL: true,
										hideGenerate: true,
										url: res.url,
										title: res.title,
										description: res.description,
										favicon: res.favicons && res.favicons.length > 0 ? res.favicons[0] : ""
									},
									function () {
										this.validateForm();
									}
								);
							}
						})
						.catch((err) => {
							console.log("[ _handleURLSubmit ] POST error:", err);
						});
				}
				this.setState(
					{
						loading: false,
						isValidURL: true,
						hideGenerate: true,
						url: account.Website,
						title: account.Name,
						adminId: account.Account_ID__c || "",
						salesforceId: account.Id,
						description: account.Industry,
						favicon: account.PhotoUrl ? "https://upsellit.lightning.force.com" + account.PhotoUrl : ""
					},
					function () {
						this.validateForm();
					}
				);
			})
			.catch((err) => {
				console.log("[ componentDidMount ] POST error:", err);
			});
	};

	displayPrompt = (prompt, color) => {
		this.setState({
			prompt: prompt,
			promptColor: color || "red"
		});
	};

	isValidHttpUrl = (str) => {
		//if (str.indexOf("http://") === -1 && str.indexOf("https://") === -1) return false;
		let pattern = new RegExp(
			"^(https?:\\/\\/)?" + // protocol
				"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
				"((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
				"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
				"(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
				"(\\#[-a-z\\d_]*)?$",
			"i"
		); // fragment locator
		return !!pattern.test(str);
	};

	// Fired off every time the user enters something into the input fields
	_handleChange = (e) => {
		// Process input
		let cleanValue = e.target.value;
		if (e.target.name === "adminId") cleanValue = cleanValue.replace(/\D/g, ""); // Numeric
		if (e.target.name === "salesforceId") cleanValue = cleanValue.replace(/\W/g, ""); // Alpha-numeric

		// Set input
		this.setState(
			{
				[e.target.name]: cleanValue
			},
			function () {
				if (e.target.name === "url") {
					this.setState(
						{
							isValidURL: this.isValidHttpUrl(e.target.value)
						},
						this.validateForm
					);
				} else if (e.target.name === "urlInit") {
					this.setState(
						{
							isValidURL: this.isValidHttpUrl(e.target.value),
							hideGenerate: !this.isValidHttpUrl(e.target.value)
						},
						this.validateForm
					);
				} else {
					setTimeout(() => {
						this.validateForm();
					}, 100);
				}
			}
		);
	};

	validateForm = () => {
		if (this.state._id && this.state.snapshot) {
			// Editing - Create snapshot of post-edited data
			const snapshot = JSON.stringify({
				url: this.state.url,
				title: this.state.title,
				favicon: this.state.favicon,
				description: this.state.description,
				adminId: this.state.adminId,
				salesforceId: this.state.salesforceId
			});
			this.setState({
				isValidForm:
					this.state.isValidURL && this.state.url && this.state.title && this.state.snapshot !== snapshot
			});
		} else {
			// Creating
			this.setState({
				isValidForm: this.state.isValidURL && this.state.url && this.state.title,
				prompt: []
			});
		}
	};

	componentDidMount() {
		// Now has access to data like this.props.something, which is from store
		// Now has access to dispatch actions like this.props.getSomething
		if (this.props.company && this.props.company._id) {
			// Editing - Create snapshot of pre-edited data
			const snapshot = JSON.stringify({
				url: this.props.company.url,
				title: this.props.company.title,
				favicon: this.props.company.favicon,
				description: this.props.company.description,
				adminId: this.props.company.adminId,
				salesforceId: this.props.company.salesforceId
			});

			this.setState({
				_id: this.props.company._id,
				url: this.props.company.url,
				title: this.props.company.title,
				favicon: this.props.company.favicon,
				description: this.props.company.description,
				adminId: this.props.company.adminId,
				salesforceId: this.props.company.salesforceId,
				isValidURL: true,
				isValidForm: false,
				snapshot: snapshot
			});
		}
	}

	render() {
		return (
			<React.Fragment>
				<AddCompanyWrapperEl>
					<div className={"modal-header-wrapper"}>
						<div className="cancel-btn" onClick={this._handleAddCompanyCancel}>
							<span>&#10005;</span>
						</div>
						<div className="title-wrapper">
							<h1>{this.state._id ? "Edit Company" : "Create New Company"}</h1>
							{this.props.company.host && <p>{this.props.company.host}</p>}
						</div>
						{!this.state._id && !this.state.manual && (
							<div className="url-wrapper">
								<label>Paste company homepage url into the field below</label>
								<p>e.g. autodesk.com</p>
								<input
									className={"form-item" + (this.state.isValidURL ? " valid" : "")}
									placeholder=""
									name="urlInit"
									type="url"
									onChange={this._handleChange}
									autoComplete={"false"}
									autoFocus={true}
								/>
								{this.state.isValidURL && !this.state.hideGenerate && (
									<button
										className={
											"btn btn-block check-url-btn" + (this.state.loading ? " loading" : "")
										}
										onClick={this._handleURLSubmit}
										disabled={this.state.loading}
									>
										{/* <img
											className={"loading-img" + (this.state.loading ? " show" : "")}
											src={loading}
											alt={"loading"}
										/> */}
										{this.state.loading ? "One moment" : "Generate Company Data"}
									</button>
								)}
								{this.state.url.length === 0 &&
									!this.state.isValidURL &&
									this.state.prompt.length === 0 && (
										<h2 className={"manual-entry-btn"} onClick={this._handleManualEntryClicked}>
											Enter Data Manually
										</h2>
									)}
							</div>
						)}
						{this.state.prompt.length > 0 && (
							<div className="login-error-wrapper">
								<Prompt message={this.state.prompt} color={this.state.promptColor} />
								{this.state.redirectId && (
									<h2 className="company-redirect-btn" onClick={this._handleCompanyRedirect}>
										Go To Existing Company &#x02197;
									</h2>
								)}
							</div>
						)}
					</div>
					{(this.state.manual || this.state.url) && (
						<div className="company-data-wrapper">
							<div
								className={
									"company-data-section" +
									(!this.state.url || !this.state.isValidURL ? " red" : " green")
								}
							>
								<label>Company URL</label>
								<p>*Required</p>
								<TextareaAutosize
									placeholder="e.g. https://www.autodesk.com"
									name="url"
									value={this.state.url}
									onChange={this._handleChange}
								/>
							</div>
							<div
								className={"company-data-section" + (this.state.title.length === 0 ? " red" : " green")}
							>
								<label>Company Name (Please match Admin company name)</label>
								<p>*Required</p>
								<TextareaAutosize
									placeholder="e.g. Autodesk"
									name="title"
									value={this.state.title}
									onChange={this._handleChange}
								/>
							</div>
							<div className="company-data-section">
								<label>Company Icon URL</label>
								<img className="favicon" src={this.state.favicon} alt="" />
								<TextareaAutosize
									placeholder="e.g. https://www.autodesk.com/favicon.ico"
									name="favicon"
									value={this.state.favicon}
									onChange={this._handleChange}
								/>
							</div>
							<div className="company-data-section">
								<label>Company Description</label>
								<TextareaAutosize
									placeholder="e.g. Autodesk builds software that helps people imagine, design, and make a better world"
									name="description"
									value={this.state.description}
									onChange={this._handleChange}
								/>
							</div>
							<div className="company-data-section">
								<label>
									<a
										href="https://www2.upsellit.com/admin/control/edit/index.jsp"
										target="_blank"
										rel="noreferrer"
									>
										Admin Company ID &#x02197;
									</a>
								</label>
								<TextareaAutosize
									placeholder="e.g. 3614"
									name="adminId"
									value={this.state.adminId}
									onChange={this._handleChange}
								/>
							</div>
							<div className="company-data-section">
								<label>
									<a
										href="https://upsellit.lightning.force.com/lightning/o/Account/list?filterName=Recent"
										target="_blank"
										rel="noreferrer"
									>
										Salesforce Company ID &#x02197;
									</a>
								</label>
								<TextareaAutosize
									placeholder="e.g. 0011I00000JdpoPQAR"
									name="salesforceId"
									value={this.state.salesforceId}
									onChange={this._handleChange}
								/>
							</div>
							{this.state.isValidForm && (
								<button
									className="btn btn-block company-submit-btn"
									onClick={this._handleCompanySubmit}
								>
									{this.state._id ? "Save Company" : "Create New Company"}
								</button>
							)}
						</div>
					)}
				</AddCompanyWrapperEl>
			</React.Fragment>
		);
	}
}

export default connect(mapStateToProps)(AddCompanyWrapper);

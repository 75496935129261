import React from "react";
import { createRoot } from 'react-dom/client';
import Root from "./Root";

import "./css/vars.css";
import "./css/reset.css";
import "./css/index.css";
import "./css/admin.css";

const container = document.getElementById('root');
const root = createRoot(container); 
root.render(<Root />);
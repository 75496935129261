// Resources
import React, { Component } from "react";
import Cookies from "universal-cookie";

// Globals
let cookies;

class FourOhFour extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	_goHome = () => {
		this.props.history.push({
			pathname: "/",
			search: "?nav=dashboard"
		});
	};

	componentDidMount() {
		cookies = new Cookies();
		cookies.set("forceRedirectHomepage", "1", { path: "/", maxAge: 60 * 5 }); // 5 min
	}

	render() {
		return (
			<React.Fragment>
				<div id="fof">
					<h1>404</h1>
					<h2>Page Not Found</h2>
					<p>
						<a href="/">Go home</a>
					</p>
				</div>
			</React.Fragment>
		);
	}
}

export default FourOhFour;

import React, { Component } from "react";
import { Wrapper, Text } from "./styles/Prompt";

export class Prompt extends Component {
	render() {
		// Set default theme
		let theme = {
			mainBorder: "#F4485B",
			mainBackground: "rgba(244,72,91,0.2)",
			mainColor: "#F4485B"
		};
		// Update theme
		if (this.props.color === "yellow") {
			theme.mainBorder = "#DFBA0A";
			theme.mainBackground = "rgba(223,186,10,0.2)";
			theme.mainColor = "#DFBA0A";
		} else if (this.props.color === "green") {
			theme.mainBorder = "#14C76F";
			theme.mainBackground = "rgba(20,199,111,0.2)";
			theme.mainColor = "#14C76F";
		}
		return (
			<Wrapper theme={theme}>
				<Text>{this.props.message}</Text>
			</Wrapper>
		);
	}
}

const revision = (state = {}, action) => {
	if (action.type === "FIELD_REVISION") {
		return {
			fields: action.fields
		};
	} else {
		return state;
	}
};

export default revision;

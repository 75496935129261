// Resources
import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

// Components
import ActivityWrapper from "./ActivityWrapper";
import FieldRevisionsWrapper from "./FieldRevisionsWrapper";
//import ChatWrapper from "./ChatWrapper";

// Styles
import { RightSideBarWrapperEl } from "./styles/RightSideBarWrapper";
import QAWrapper from "./QAWrapper";

const mapStateToProps = function (state) {
	return state;
};

class RightSideBarWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			displayType: "activity",
			type: "",
			typeID: "",
			data: {},
			formSidebarNav: ""
		};
	}

	static getDerivedStateFromProps(props, state) {
		if (props.type !== state.type || props.typeID !== state.typeID) {
			return {
				type: props.type,
				typeID: props.typeID
			};
		} else if (props.data !== state.data) {
			return {
				data: props.data
			};
		}
		return null;
	}

	handleFormNavButtonClicked = (e) => {
		if (e && e.target && e.target.getAttribute("data-id")) {
			this.setState({ formSidebarNav: e.target.getAttribute("data-id") }, function () {
				if (e.target.getAttribute("data-id") !== "qa") this.props.updateQAParams(this.props.params.id);
			});
		}
	};

	initQa = () => {
		if (this.props.type === "form" && this.props.form_type && this.props.updateQAParams) {
			this.setState({
				formSidebarNav: "qa"
			});
		} else {
			setTimeout(
				function () {
					this.initQa();
				}.bind(this),
				1000
			);
		}
	};

	componentDidMount() {
		this.initQa();
	}

	render() {
		return (
			<React.Fragment>
				<RightSideBarWrapperEl>
					<div className="rsb-body-container">
						{this.props.type === "company" && (
							<div className="sidebar-title">
								<h1>Company Activity</h1>
							</div>
						)}
						{this.props.type === "company" && (
							<ActivityWrapper
								handleActivityClick={this.props.handleActivityClick}
								type={this.props.type}
								id={this.props.id}
							/>
						)}
						{this.props.type === "form" && this.state.formSidebarNav === "qa" && (
							<QAWrapper
								handleImageZoom={this.props.handleImageZoom}
								params={this.props.params}
								form_type={this.props.form_type}
								updateQAParams={this.props.updateQAParams}
							/>
						)}
						{this.props.type === "form" && (
							<FieldRevisionsWrapper handleFieldRevisionsClear={this.props.handleFieldRevisionsClear} />
						)}
					</div>
				</RightSideBarWrapperEl>
			</React.Fragment>
		);
	}
}

export default connect(mapStateToProps)(RightSideBarWrapper);

// Resources
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { useGoogleLogin } from "@react-oauth/google";
import Cookies from "universal-cookie"; // https://github.com/reactivestack/cookies/tree/master/packages/universal-cookie

// Components
import { Prompt } from "./components/forms/Prompt";

// Auth
import AuthHelperMethods from "./components/auth/AuthHelperMethods";

// Styles
import "./css/login.css";
import logo from "./images/Upsellit-logo-WhiteText-500w-transparent.png";

// Globals
let cookies;

// Export - Public

function LoginButton(props) {
	const login = useGoogleLogin({
		onSuccess: props.loginSuccess,
		onFail: props.loginFail
	});
	return (
		<button className="btn btn-block" style={{ fontSize: "1.1em" }} onClick={() => login()}>
			Sign in with Google
		</button>
	);
}

export default class Login extends Component {
	Auth = new AuthHelperMethods();

	constructor(props) {
		super(props);
		this.state = {
			prompt: "",
			promptColor: "red",
			allowGoogleOauth: false,
			googleOauthLastUsed: "",
		};
		// This is here to prevent memory leaks
		this._isMounted = false;
	}

	_goToLandingPage() {
		window.location.href = this.state.domain;
	}

	displayPrompt = (prompt, color) => {
		if (this._isMounted) {
			this.setState({
				prompt: prompt,
				promptColor: color || "red"
			});
		}
	};

	_allowGoogleOauth = () => {
		if (this._isMounted) this.setState({ allowGoogleOauth: true });
	};

	loginSuccess = (response) => {
		console.log("[ loginSuccess ] Login allowed, processing response:", response);
		if (response.access_token) {
			// Save token id in localStorage (so we can revoke access later if needed)
			localStorage.setItem("google_oauth_token", response.access_token);
			this.Auth.getIP().then((res) => {
				const ip = res ? res.ipAddress : "";
				this.Auth.login(response.access_token, ip)
					.then((res) => {
						if (res.success && res.data && res.data.token) {
							// Login successful, continue to dashboard
							localStorage.setItem("google_oauth_last_used", new Date().toLocaleDateString());
							this.Auth.setToken(res.data.token, true);
							let entryURL = cookies.get("entryURL");
							if (entryURL) {
								// Redirect to entry page
								window.location = decodeURIComponent(entryURL);
							} else {
								// Redirect to home page
								this.props.history.replace("/");
							}
						} else {
							this.displayPrompt(res.message || "Unknown Server Error");
							this.Auth.logout();
						}
					})
					.catch((err) => {
						console.log(err);
						this.displayPrompt("Google Authentication Failed");
						this.Auth.logout();
					});
			});
		} else {
			this.displayPrompt("Login Failed: Missing required data");
			this.Auth.logout();
		}
	};

	loginFail = (response) => {
		console.log("[ loginFail ] response:", response);
		if (response && response.error) {
			this.displayPrompt("Login Failed: " + response.error);
			this.Auth.logout();
		}
	};

	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidMount() {
		this._isMounted = true;

		// Init cookies
		// noinspection JSValidateTypes
		cookies = new Cookies();

		// Redirect someone who is already logged in to the protected route
		if (this.Auth.loggedIn()) this.props.history.replace("/");
		if (localStorage.getItem("google_oauth_last_used") && this._isMounted) {
			this.setState({
				googleOauthLastUsed: localStorage.getItem("google_oauth_last_used")
			});
		}
	}

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>CSD • Login</title>
				</Helmet>
				<div className="login-body-wrapper">
					<div className="login-wrapper">
						<div className="login-logo-wrapper">
							<img className="logo-img" src={logo} alt={"logo"} onClick={this._goToLandingPage} />
							<p className="login-prompt">Please sign in with your @upsellit.com Google account</p>
						</div>
						<div onClick={this._allowGoogleOauth} className="google-login-wrapper">
							<LoginButton loginSuccess={this.loginSuccess} loginFail={this.loginFail}></LoginButton>
							{this.state.googleOauthLastUsed.length > 0 && (
								<p className="google-last-login">Last used on {this.state.googleOauthLastUsed}</p>
							)}
						</div>
					</div>
					<div className="login-error-wrapper">
						{this.state.prompt.length > 0 && (
							<Prompt message={this.state.prompt} color={this.state.promptColor} />
						)}
					</div>
				</div>
			</React.Fragment>
		);
	}
}

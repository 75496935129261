import styled from "styled-components";
import { keyframes } from "styled-components";

const PromptWrapperAnimation = keyframes`
  0% {
     transform:scaleY(0);
  }
  100% {
     transform:scaleY(1);
  }
`;

const PromptTextAnimation = keyframes`
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

export const Wrapper = styled.div`
	padding: 0 15px;
	border-radius: 3px;
	margin: 0 0 20px 0;
	transform-origin: top;
	pointer-events: auto;
	animation: ${PromptWrapperAnimation} 0.3s ease-in-out 1 forwards;
	// border: 1px solid ${(props) => props.theme.mainBorder};
	background: ${(props) => props.theme.mainBackground};
	color: ${(props) => props.theme.mainColor};
`;

export const Text = styled.h1`
	font-size: 12px;
	opacity: 0;
	display: inline-block;
	margin: 10px 0;
	padding-top: 1px;
	animation: ${PromptTextAnimation} 0.3s ease-in-out 1 forwards;
`;

export const Link = styled.span`
	text-decoration: underline;
	&:hover {
		cursor: pointer;
	}
`;

// Set a default theme
Wrapper.defaultProps = {
	theme: {
		mainBorder: "#F4485B",
		mainBackground: "rgba(244,72,91,0.1)",
		mainColor: "#ED3448"
	}
};

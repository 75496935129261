// Resources
import React, { Component } from "react";
import Moment from "react-moment";
import DraftWrapper from "../body/DraftWrapper";

// Redux
import { connect } from "react-redux";

// Components
import { FieldRevisionsWrapperEl } from "./styles/FieldRevisionsWrapper";

const mapStateToProps = function (state) {
	return state;
};

class FieldRevisionsWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	_generateTimeStyles = (date) => {
		if (!date) return "";
		const ONE_DAY = 60 * 60 * 1000 * 24; /* ms */
		const ONE_WEEK = 60 * 60 * 1000 * 24 * 7; /* ms */
		const ONE_MONTH = 60 * 60 * 1000 * 24 * 30; /* ms */
		const now = new Date();
		if (now - date < ONE_DAY) {
			return "green";
		} else if (now - date < ONE_WEEK) {
			return "yellow";
		} else if (now - date < ONE_MONTH) {
			return "orange";
		}
		return "";
	};

	_handleCancelButtonClick = () => {
		if (typeof this.props.handleFieldRevisionsClear === "function") {
			this.props.handleFieldRevisionsClear();
		}
	};

	renderRevisions() {
		Moment.globalTimezone = Intl.DateTimeFormat()["resolvedOptions"]().timeZone;
		return this.props.revision.fields.map((item) => {
			return (
				<div className="revision-field-wrapper" key={item._id}>
					<div className="revision-version-wrapper">
						<p>v{item.version}</p>
					</div>
					<div className="revision-content-wrapper">
						<DraftWrapper
							item={item}
							reportDraftChange={(value) => console.log(value)}
							content={item.content}
							className={"revision-content" + (!item.content ? " blank" : "")}
							placeholder={"Enter task details"}
						/>
						<div className="revision-time-wrapper">
							<p>
								{item.created_by} •{" "}
								<span className={this._generateTimeStyles(new Date(item.createdAt))}>
									<Moment fromNow>{item.createdAt}</Moment>
								</span>
							</p>
						</div>
					</div>
				</div>
			);
		});
	}

	render() {
		return (
			<React.Fragment>
				{this.props.revision && this.props.revision.fields && this.props.revision.fields.length > 0 && (
					<FieldRevisionsWrapperEl
						className={
							this.props.revision && this.props.revision.fields && this.props.revision.fields.length > 0
								? "show"
								: "hide"
						}
					>
						<div className="revisions-wrapper">
							<div className="revisions-header-wrapper">
								<div className="cancel-btn" onClick={this._handleCancelButtonClick}>
									<span>&#10005;</span>
								</div>
								<h1>Revision History For:</h1>
								<p className="dont-break-out">{this.props.revision.fields[0].title}</p>
							</div>
							<div className="revisions-body-wrapper">{this.renderRevisions()}</div>
						</div>
					</FieldRevisionsWrapperEl>
				)}
			</React.Fragment>
		);
	}
}

export default connect(mapStateToProps)(FieldRevisionsWrapper);

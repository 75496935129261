import React from "react";
import Dropzone from "react-dropzone";
import cat from "../../images/animated/cat.webp";

export function Images(props) {
	return (
		<div className={"image-uploader-wrapper"}>
			{!props.reader && (
				<div className={"upload-wrapper"}>
					{props.processing && (
						<div className="processing-wrapper">
							<img src={cat} alt={"cat"} />
							<p>{props.processingMsg}</p>
						</div>
					)}
					{!props.processing && (
						<Dropzone onDrop={props._onDrop}>
							{({ getRootProps, getInputProps }) => (
								<section className="dz-container">
									<div
										{...getRootProps({
											className: "dropzone"
										})}
									>
										<input {...getInputProps()} />
										<h5 className="noselect">Images</h5>
										<p className="noselect">Drag & drop<br />or click to upload</p>
										<p className="noselect warning">JPG or PNG</p>
									</div>
								</section>
							)}
						</Dropzone>
					)}
				</div>
			)}
			{props.images && props.length > 0 && (
				<div className={"images-wrapper" + (props.reader ? " reader" : "")}>
					{props.images.map((img) => (
						<div
							key={img.url}
							className="formv-image"
							onClick={() => props.handleImageZoom(img, props.vid, "formv")}
						>
							<div className="formv-image-inner">
								<img src={img.url} alt={img.url} />
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	);
}

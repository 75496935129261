import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import Switch from "react-switch";
import DatePicker from "react-date-picker";
import DateTimePicker from "react-datetime-picker";
import * as linkify from "linkifyjs";
import linkifyHtml from "linkify-html";
import DOMPurify from "dompurify";
import Moment from "react-moment";
import moment from "moment";
import "moment-timezone";
import clear from "../../images/icons/v1/clear.svg";
import editing from "../../images/animated/editing.svg";
import DraftWrapper from "../body/DraftWrapper";
//import EditorWrapper from "../body/EditorWrapper";
import { CopyForm } from "./CopyForm";

export function Field(props) {
	let item = props.item;
	const hasLink = props.reader && linkify.find(props.getFieldValue(item)).length > 0;
	const field_type = item.field_type_id.replace(/_[2-9]\+/, "_1+");
	let { dropboxPreviewSrc, reader_content, siteid_links, highlight_color, figmaPreviewSrc } =
		props.getFieldData(item);
	var placeholder =
		props.field_data[field_type] && props.field_data[field_type].placeholder
			? props.field_data[field_type].placeholder
			: "";
	let isHidden =
		(props.getFieldValue(item, "isHidden") && !item.force_show) ||
		(props.reader && (!item.content || (item.content.length < 135 && item.content.indexOf('"text":""') !== -1))); // TODO: better way to check if empty draft

	if (placeholder && isHidden) {
		reader_content = placeholder;
		isHidden = false;
	}
	let hourOptions, minuteOptions, displayDate;

	if (item.field_type_id === "start_time" || item.field_type_id === "end_time") {
		let dateStr = item.field_type_id === "start_time" ? "start_date" : "end_date";
		displayDate = props.form.fields["Engagement"].fields.find((f) => f.field_type_id === dateStr);
		hourOptions = [
			["00 (12 AM - Midnight)", "00"],
			["01 (1 AM)", "01"],
			["02 (2 AM)", "02"],
			["03 (3 AM)", "03"],
			["04 (4 AM)", "04"],
			["05 (5 AM)", "05"],
			["06 (6 AM)", "06"],
			["07 (7 AM)", "07"],
			["08 (8 AM)", "08"],
			["09 (9 AM)", "09"],
			["10 (10 AM)", "10"],
			["11 (11 AM)", "11"],
			["12 (12 PM - Noon)", "12"],
			["13 (1 PM)", "13"],
			["14 (2 PM)", "14"],
			["15 (3 PM)", "15"],
			["16 (4 PM)", "16"],
			["17 (5 PM)", "17"],
			["18 (6 PM)", "18"],
			["19 (7 PM)", "19"],
			["20 (8 PM)", "20"],
			["21 (9 PM)", "21"],
			["22 (10 PM)", "22"],
			["23 (11 PM)", "23"]
		];
		minuteOptions = [...Array(60).keys()];
		if (!item.content || item.content.indexOf(":") === -1) item.content = "00:00";
	}
	const validationPrompt =
		props.field_data[field_type] && typeof props.field_data[field_type].validate === "function"
			? props.field_data[field_type].validate(item.content, props.form.fields)
			: "";
	const filterTemplates = (s) => {
		if (props.form.form.type === "em" || props.form.form.type === "tt" || props.form.form.type === "lc")
			return s.toLowerCase().indexOf(props.form.form.type) === 0;
		return true;
	};
	return (
		<div
			className={
				"field-row" +
				(isHidden ? " hidden" : "") +
				(props.reader ? " reader" : "") +
				(props.show ? " show" : "")
			}
			data-validation={validationPrompt}
			key={item._id}
			style={{
				background: highlight_color
			}}
			data-field_type_id={item.field_type_id}
		>
			<div className={"title-wrapper" + (props.reader ? " reader" : "")}>
				{props.field_data[field_type] &&
					props.field_data[field_type].wiki &&
					!props.reader &&
					!props.getFieldValue(item, "isHidden") && (
						<a
							className="btn help-btn"
							target="_blank"
							href={props.field_data[field_type].wiki}
							rel="noreferrer"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20px"
								height="20px"
								viewBox="0 0 24 24"
								fill="none"
							>
								<path
									d="M10 16.584V18.9996C10 20.1042 10.8954 20.9996 12 20.9996C13.1046 20.9996 14 20.1042 14 18.9996L14 16.584M12 3V4M18.3643 5.63574L17.6572 6.34285M5.63574 5.63574L6.34285 6.34285M4 12H3M21 12H20M17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12Z"
									stroke="currentColor"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</a>
					)}
				<div className={"title" + (props.show && !props.reader ? " show" : "")}>
					{item.description && !props.reader && !props.getFieldValue(item, "isHidden") ? (
						<div className={"description-hover-wrapper"}>
							<p>?</p>
						</div>
					) : (
						""
					)}
					{props.field_data[field_type] ? props.field_data[field_type].title : "(missing title)"}
					{props.field_data[field_type] &&
					props.field_data[field_type].description &&
					!props.reader &&
					!props.getFieldValue(item, "isHidden") ? (
						<p className={"dont-break-out description-hover-show"}>
							{props.field_data[field_type].description}
						</p>
					) : (
						""
					)}
				</div>
				{props.show && !props.reader && (
					<div
						className={"force-btn force-show" + (item.force_show ? " selected" : "")}
						data-id={item._id}
						data-ty="show"
						onClick={props._handleForceShowHideClicked}
					/>
				)}
				{props.show && !props.reader && (
					<div
						className={"force-btn force-hide" + (item.force_hide ? " selected" : "")}
						data-id={item._id}
						data-ty="hide"
						onClick={props._handleForceShowHideClicked}
					/>
				)}

				{!props.reader && (
					<div className={"options-wrapper"}>
						<div className={"details-wrapper"}>
							<p id={item.field_type_id + "_editing_prompt"} className={"editing_prompt"}>
								<span className="editing_text" />
								<span>
									<img src={editing} alt={"editing"} />
								</span>
							</p>
							<p
								title={moment(item.createdAt).format("MM/DD/YY, h:mm a")}
								className={props.getFieldValue(item, "saved") ? " saved" : ""}
								data-id={item.field_id}
								onClick={props._handleRevisionHistoryClicked}
							>
								<span>
									v{item.version} • {item.created_by} •{" "}
								</span>
								<span className={props._generateTimeStyles(new Date(item.createdAt))}>
									{<Moment fromNow>{item.createdAt}</Moment>}
								</span>
							</p>
						</div>
					</div>
				)}
			</div>
			<div className={"input-wrapper" + (props.reader ? " reader" : "")}>
				{item.input_type &&
					(item.input_type === "text" || item.input_type === "textarea" || item.input_type === "draft") && (
						<div className="text-wrapper">
							{item.input_type === "draft" && (
								<DraftWrapper
									item={item}
									editorState={props.editorState}
									reportDraftChange={(value) => props.reportDraftChange(item, value)}
									handleKeyCommand={props.handleKeyCommand}
									content={props.getFieldValue(item)}
									className={
										"dont-break-out " +
										item.input_type +
										(props.getFieldValue(item, "hasChanges") ? " changed" : "") +
										(props.getFieldValue(item, "saved") ? " saved" : "")
									}
									readOnly={
										(document.activeElement.contentEditable !== "true" && props.readOnly) ||
										props.reader
									}
									placeholder={placeholder}
								/>
							)}
							{!props.reader && (item.input_type === "text" || item.input_type === "textarea") && (
								<TextareaAutosize
									className={
										"dont-break-out " +
										item.input_type +
										(props.getFieldValue(item, "hasChanges") ? " changed" : "") +
										(props.getFieldValue(item, "saved") ? " saved" : "")
									}
									id={item.field_type_id} /* id and name swapped compared to others */
									name={item.field_id}
									defaultValue={props.getFieldValue(item)}
									onBlur={props._handleFieldBlur}
									onKeyDown={props._handleFieldKeyDown}
									onKeyUp={props._handleFieldKeyUp}
									onFocus={props._handleFieldFocused}
									onPaste={props._handleFieldKeyUp}
								/>
							)}

							{props.reader && siteid_links && siteid_links.length === 0 && (
								<div className={"reader-text-box-wrapper"}>
									{(item.input_type === "text" || item.input_type === "textarea") && !hasLink && (
										<p className="reader-text-box dont-break-out">{reader_content}</p>
									)}
									{(item.input_type === "text" || item.input_type === "textarea") && hasLink && (
										<p
											className="reader-text-box dont-break-out"
											dangerouslySetInnerHTML={{
												__html: DOMPurify.sanitize(
													linkifyHtml(reader_content, { defaultProtocol: "https" }),
													{ USE_PROFILES: { html: true } }
												)
											}}
										/>
									)}
									{figmaPreviewSrc && (
										<iframe
											src={
												"https://www.figma.com/embed?embed_host=share&url=" +
												encodeURIComponent(figmaPreviewSrc)
											}
											title={"figma_" + item.field_type_id}
										/>
									)}
									{dropboxPreviewSrc && (
										<img className={"preview"} src={dropboxPreviewSrc} alt="dropbox preview" />
									)}
								</div>
							)}

							{siteid_links && siteid_links.length > 0 && (
								<div className={"reader-text-box-wrapper"}>
									{(item.input_type === "text" || item.input_type === "textarea") && props.reader && (
										<p className="reader-text-box dont-break-out">{reader_content}</p>
									)}
									<p
										className={
											"reader-text-box dont-break-out" +
											(props.reader ? " links-reader-mode" : " links-edit-mode")
										}
									>
										{siteid_links.map((item) => {
											return (
												<a
													className={"siteid-link" + (props.reader ? "" : " edit-mode")}
													key={item[0]}
													href={item[1]}
													target="_blank"
													rel="noreferrer"
												>
													{item[0]} &#8599;
												</a>
											);
										})}
									</p>
								</div>
							)}

							{!props.reader &&
								(item.input_type === "text" || item.input_type === "textarea") &&
								linkify.find(item.content).length > 0 && (
									<div className="display-links-box">
										{linkify.find(item.content).map((link, idx) => {
											if (link.isLink && link.type === "url") {
												return (
													<div className={"link-row"} key={link.href + "_" + idx}>
														<div className={"link-wrapper"}>
															<a
																className={"dont-break-out"}
																href={link.href}
																target="_blank"
																rel="noreferrer"
															>
																{link.href}
															</a>
														</div>
													</div>
												);
											} else {
												return "";
											}
										})}
									</div>
								)}

							{!props.reader && item.field_type_id === "copy" && (
								<CopyForm
									handleCreateCopyDoc={props.handleCreateCopyDoc}
									company={props.form.form._company.title}
									title={props.form.form.title}
									settings={props.settings}
								/>
							)}
							{!props.reader &&
								props.field_data[field_type] &&
								props.field_data[field_type].selections &&
								props.field_data[field_type].selections.length > 0 && (
									<div className="select-wrapper">
										{item.field_type_id.match(
											/strategy_type|language|launch_method|pages|stages|visitors|dynamic_elements|tags|templates|blocker_monitor|blocker_block/
										) !== null ? (
											<select
												multiple
												onBlur={(e) => props._optionSelected(e)}
												data-id={item.field_id}
												id={item.field_id}
												name={item.field_type_id}
												size={
													props.field_data[field_type].selections.filter(
														item.field_type_id === "templates" ? filterTemplates : Boolean
													).length
												}
											>
												<option
													style={{ display: "none" }}
													key="blank"
													value={props.getFieldValue(item)}
												/>
												{props.field_data[field_type].selections
													.filter(
														item.field_type_id === "templates" ? filterTemplates : Boolean
													)
													.map((option) => (
														<option key={option} value={option}>
															{option}
														</option>
													))}
											</select>
										) : (
											<select
												onChange={(e) => props._optionSelected(e)}
												id={item.field_id}
												name={item.field_type_id}
												data-id={item.field_id}
											>
												<option
													style={{ display: "none" }}
													key="blank"
													value={props.getFieldValue(item)}
												/>
												{props.field_data[field_type].selections.map((option) => (
													<option key={option} value={option}>
														{option}
													</option>
												))}
											</select>
										)}
									</div>
								)}
						</div>
					)}

				{item.input_type &&
					item.input_type === "select_static" &&
					props.field_data[field_type] &&
					props.field_data[field_type].selections && (
						<div className="select-static-wrapper">
							<div className="select-static">
								{!props.reader && (
									<select
										onChange={(e) => props._optionSelectedStatic(e)}
										data-id={item.field_id}
										id={item.field_id}
										name={item.field_type_id}
										value={props.getFieldValue(item)}
									>
										{props.field_data[field_type].selections.map((option) => (
											<option key={option} value={option}>
												{option}
											</option>
										))}
									</select>
								)}
								{props.reader && (
									<p className={"select-static-label" + (props.reader ? " reader" : "")}>
										{props.getFieldValue(item) || "n/a"}
									</p>
								)}
							</div>
						</div>
					)}

				{item.input_type && item.input_type === "range" && (
					<div className="range-wrapper">
						<label>
							{!props.reader && (
								<input
									id={item.field_type_id}
									name={item.field_id}
									data-id={item.field_id}
									type="range"
									min={props.field_data[field_type].selections[0]}
									max={
										props.field_data[field_type].selections[
											props.field_data[field_type].selections.length - 1
										]
									}
									step={1}
									onChange={props._optionSelectedStatic}
									style={{ width: "90%", marginRight: "5%" }}
									list="range"
									value={props.getFieldValue(item)}
								/>
							)}
							<span>{props.getFieldValue(item)}</span>
							<datalist id="range">
								{props.field_data[field_type].selections.map((s) => (
									<option label={s} value={s} key={s}>
										{s}
									</option>
								))}
							</datalist>
						</label>
					</div>
				)}

				{item.input_type && item.input_type === "boolean" && (
					<div className="boolean-wrapper">
						<label htmlFor="normal-switch">
							{!props.reader && (
								<Switch
									onChange={props._toggleChanged}
									checked={!!props.getFieldValue(item)}
									id={item.field_id}
									name={item.field_type_id}
									height={20}
									width={40}
									className="toggle-switch"
									onColor="#1f85de"
									offColor="#9eb2c6"
								/>
							)}
							<p className={"toggle-label" + (props.reader ? " reader" : "")}>
								{props.getFieldValue(item) ? "YES" : "NO"}
							</p>
						</label>
					</div>
				)}

				{item.input_type && item.input_type === "datetime" && (
					<div className={"date-wrapper" + (props.getFieldValue(item, "hasChanges") ? " changed" : "")}>
						<div className="date-picker-wrapper">
							{!props.reader && (
								<label htmlFor="datetime-picker">
									<DateTimePicker
										id={item.field_id}
										name={item.field_type_id}
										locale="en-US"
										format="MM / dd / yyyy, h:mm a"
										calendarIcon={null}
										onChange={props._datetimeChanged(item.field_id)}
										value={props.getFieldValue(item) ? new Date(props.getFieldValue(item)) : null}
										className="datetime-picker"
										clearIcon={<img className={"clear-icon"} src={clear} alt={"clear"} />}
									/>
								</label>
							)}
							{/* {!props.reader && (
								<label htmlFor="datetimezone-picker">
									<select id="datetimezone-picker">
										<option>Pacific Time (PST/PDT)</option>
										<option>Mountain Time (MST/MDT)</option>
										<option>Central Time (CST/CDT)</option>
										<option>Eastern Time (EST/EDT)</option>
										<option>British Time (BST/BDT)</option>
									</select>
								</label>
							)} */}
							{item.content && (
								<p
									className={"date-label" + (props.reader ? " reader" : "")}
									data-id={item.field_type_id}
								>
									<Moment format="yyyy-MM-DDTHH:mm:ssZ" tz="America/Los_Angeles">
										{props.getFieldValue(item)
											? moment.tz(props.getFieldValue(item), "America/Los_Angeles")
											: null}
									</Moment>
								</p>
							)}
						</div>
					</div>
				)}

				{item.input_type && item.input_type === "date" && (
					<div className={"date-wrapper" + (props.getFieldValue(item, "hasChanges") ? " changed" : "")}>
						<div className="date-picker-wrapper">
							{!props.reader && (
								<label htmlFor="datetime-picker">
									<DatePicker
										id={item.field_id}
										name={item.field_type_id}
										locale="en-US"
										calendarIcon={null}
										onChange={props._dateChanged(item.field_id)}
										value={props.getFieldValue(item) ? new Date(props.getFieldValue(item)) : null}
										className="datetime-picker"
										clearIcon={<img className={"clear-icon"} src={clear} alt={"clear"} />}
									/>
								</label>
							)}
							<p
								className={"date-label" + (props.reader ? " reader" : "")}
								data-id={item.field_type_id}
							></p>
						</div>
					</div>
				)}

				{item.input_type && item.input_type === "time" && (
					<div className={"date-wrapper" + (props.getFieldValue(item, "hasChanges") ? " changed" : "")}>
						<div className="time-picker-wrapper">
							{!props.reader && (
								<div className="time-select-wrapper-outer">
									<div className="time-select-wrapper">
										<select
											onChange={(e) => props._optionSelectedStaticHour(e)}
											data-id={item.field_id}
											id={item.field_id}
											name={item.field_type_id}
											defaultValue={item.content.split(":")[0]}
											data-content={item.content}
										>
											{hourOptions.map((option) => (
												<option key={option[1]} value={option[1]}>
													{option[0]}
												</option>
											))}
										</select>
									</div>
									<div className="time-select-wrapper">
										<select
											onChange={(e) => props._optionSelectedStaticMinute(e)}
											data-id={item.field_id}
											id={item.field_id}
											name={item.field_type_id}
											defaultValue={item.content.split(":")[1]}
											data-content={item.content}
										>
											{minuteOptions.map((option) => (
												<option key={option} value={option < 10 ? "0" + option : "" + option}>
													{option < 10 ? "0" + option : "" + option}
												</option>
											))}
										</select>
									</div>
								</div>
							)}
							<p className={"time-label" + (props.reader ? " reader" : "")}>
								{displayDate && displayDate.content && displayDate.content.indexOf("n/a") === -1 && (
									<span>
										{displayDate.content +
											"T" +
											(props.getFieldValue(item) ? props.getFieldValue(item) : "n/a")}
									</span>
								)}
							</p>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

// Resources
import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

import App from "./App";
import Login from "./Login";
import Company from "./Company";
import Form from "./Form";
import FourOhFour from "./404";
import Image from "./Image";
import Legacy from "./Legacy";
import Opportunity from "./Opportunity";
import Wiki from "./Wiki";
import Admin from "./Admin";
import ErrorBoundary from "./components/body/ErrorBoundary";

// Auth
import AuthHelperMethods from "./components/auth/AuthHelperMethods";

export default class Root extends Component {
	Auth = new AuthHelperMethods();

	constructor(props) {
		super(props);
		this.state = {
			domain: "",
			googleClientId: ""
		};
	}

	componentDidMount() {
		// Fetch login data
		this.Auth.loginInit()
			.then((res) => {
				console.log("[ Login - componentDidMount ] res:", res);
				if (res.domain && res.google_client_id) {
					this.setState({
						domain: res.domain,
						googleClientId: res.google_client_id
					});
				}
			})
			.catch((err) => {
				console.log("[ Login - componentDidMount ] err:", err);
			});
	}

	render() {
		return (
			<Router>
				<ErrorBoundary>
					{this.state.googleClientId && (
						<GoogleOAuthProvider clientId={this.state.googleClientId}>
							<Switch>
								<Route exact path="/" component={App} />
								<Route exact path="/404" component={FourOhFour} />
								<Route exact path="/image" component={Image} />
								<Route exact path="/login" component={Login} />
								<Route exact path="/company" component={Company} />
								<Route exact path="/form" component={Form} />
								<Route exact path="/legacy" component={Legacy} />
								<Route exact path="/opportunity" component={Opportunity} />
								<Route path="/wiki" component={Wiki} />
								<Route path="/admin" component={Admin} />
								<Route component={FourOhFour} />
							</Switch>
						</GoogleOAuthProvider>
					)}
				</ErrorBoundary>
			</Router>
		);
	}
}

import styled, { createGlobalStyle } from "styled-components";
export const GlobalStyle = createGlobalStyle`
	#light body {
		color: var(--txt-dg);
		#right-sidebar {
			background: #fff;
			color: var(--txt-lg);
			border-left: 1px solid var(--bg-l2);
			.qa_wrapper {
				color: var(--txt-dg);
			}
			.qa_form textarea, .qa_form input {
				background: #fff;
				border: 1px solid var(--txt-lg);
				color: var(--txt-dg);
			}
			label.test-archive {
				color: var(--txt-lg);
			}
			.test-archive.checked {
				color: #fff;
			}
			.qa_area {
				color: var(--txt-dg);
			}
			.test {
				border-bottom: 1px solid var(--bg-l2);
			}
			.bugs-toggle {
				border-top: 1px solid var(--bg-l2);
				border-bottom: 1px solid var(--bg-l2);
			}
			.bug-item.open {
				color: var(--txt-lg);
			}
			.qa-item {
				color: var(--txt-dg);
				background: #fff;
				border-bottom: 1px solid var(--bg-l2);
				&:hover {
					background: var(--bg-lg);
					color: #000;
				}
			}
			.sidebar-nav-btn.selected {
				color: #000;
				border-bottom: 1px solid;
			}
			.form-sidebar-nav-wrapper {
				border: none;
			}
			.sidebar-nav-btn:hover {
				color: var(--txt-dg);
			}

			.revisions-wrapper {
				background: #fff;
				color: var(--txt-dg);
				p {
					color: var(--txt-dg);
				}
			}
			.report {
				background: #fff;
				input, select, textarea {
					color: var(--txt-dg);
					background: #fff;
					border-bottom: 1px solid var(--bg-l2);
				}
			}
		}
		.body-wrapper {
			color: var(--txt-dg);
			.reader-text-box, .toggle-label {
				color: var(--txt-dg);
			}
			h1, h1, h3, h4{
				color: var(--txt-dg);
			}
			.help-text {
				font-size: 12px;
				padding: 1em 0;
				font-style: italic;
			}
			.section-wrapper {
				background: #fff;
				border: 1px solid transparent;
				box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
			}
			.select-wrapper select{
				background: rgba(255, 255, 255, 0.75) !important;
				backdrop-filter: blur(2px);
			}
			.input-wrapper  {
				color: var(--txt-dg);
				textarea,  select, .react-date-picker__wrapper {
					color: var(--txt-dg);
					background: transparent;
					border: 1px solid transparent;
					:focus {
						border-color: var(--darkblue)
					}
				}
				.textarea, .text {
					background: var(--bg-lg);
					color: var(--txt-dg);
				}
				.select-static select {
					background: var(--bg-lg) !important;
				}
				.select-static-label {
					color: var(--txt-dg);
				}
				input[type="range"] {
					color: #000;
				}
				.date-picker-wrapper .react-datetime-picker__inputGroup, .date-picker-wrapper .react-date-picker__inputGroup {
					background: var(--bg-lg);
					color: var(--txt-dg);
				}
			}
			.group-title {
				background: #fff;
				color: var(--txt-dg);
				border-bottom: 1px solid;
				font-weight: 500;
			}
			.title {
				color: var(--txt-dg);
			}
			.dz-container {
				background: #fff;
				color: var(--txt-dg);
				border: 1px solid var(--bg-l2);
			}
			p.completed {
				color: var(--lt-green);
			}
			p.pending {
				color: var(--lt-orange);
				font-weight: 700;
			}
			p span {
				color: var(--txt-lg);
			}
			.delete-task-btn {
				color: var(--txt-dg);
			}
			p.Completed {
				color: var(--lt-green);
			}
			p.Pending {
				color: var(--lt-orange);
			}
			.task-row textarea, .task-row select {
				background: var(--bg-lg);
				color: var(--txt-dg);
				border: 1px solid transparent;
				&.Completed {
					color: var(--lt-green);
				}
				&.Pending {
					color: var(--lt-orange);
					border: 2px solid;
				}
			}
			#company_field_wrapper .desc-wrapper {
				color: var(--txt-dg);
			}
			.section-wrapper h3 span img {
				background-image: var(--icon-up-g);
			}
			.react-date-picker__wrapper input, .datetime-picker span{
				background: transparent;
				color: inherit;
			}
			.react-date-picker__wrapper {
				color: var(--txt-d);
				border-color: var(--bg-l2);
			}
			.datetime-picker input {
				color: var(--txt-dg);
				&::placeholder {
					color: var(--txt-dg);
				}
			}
			.description-hover-show {
				background: rgba(255, 255, 255, 0.75);
				backdrop-filter: blur(2px);
				border: 1px solid var(--bg-l2);
				color: var(--txt-dg);
				font-weight: 500;
			}
			.description-hover-wrapper {
				background: rgba(255, 255, 255, 0.75);
				border: 1px solid var(--txt-lg);
				color: var(--txt-dg);
				backdrop-filter: blur(2px);
			}
		}
	}
`;
export const FormWrapperEl = styled.div`
	display: block;
	position: relative;
	font-size: 1em;
	iframe {
    	width: 100%;
		aspect-ratio: 1;
	}
	.badge {
		background: #000;
		border-radius: 1em;
		color: #fff;
		padding: 0.125em 0.375em;
		font-size: 0.75em;
		margin: 0 0.5em;
		text-transform: uppercase;
		font-weight: 900;
		text-decoration: none !important;
		&.salesforce {
			background: var(--salesforce-blue);
			color: #fff !important;
		}
	}
	.form-footer {
		padding: 2em 4em;
	}
	& .hidden {
		display: none !important;
	}
	.form-nav-wrap {
		overflow-x: auto;
		display: flex;
		flex-wrap: nowrap;
	}
	& .header-wrapper .form-header-right-wrapper {
		justify-self: end;
		flex: 0 0 auto;
		margin-left: auto;
	}
	& .header-wrapper .form-header-right-wrapper .save-all-btn {
		height: 40px;
		margin: 10px 8px 0 10px;
		text-align: center;
		padding: 0 20px;
		border-radius: 3px;
		border: 1px solid transparent;
		display: none;
		transition: box-shadow 0.05s, -moz-transform ease-in-out 0.05s;
	}
	& .header-wrapper .form-header-right-wrapper .save-all-btn p {
		font-size: 13px;
		color: var(--txt-lg);
	}
	& .header-wrapper .form-header-right-wrapper .save-all-btn.allowed {
		display: block;
		color: var(--orange);
		border: 1px solid;
		background: var(--highlight-orange-d);
		font-weight: 700;
	}
	& .header-wrapper .form-header-right-wrapper .save-all-btn.allowed p {
		color: white;
	}
	& .header-wrapper .form-header-right-wrapper .save-all-btn:hover {
		cursor: not-allowed;
	}
	& .header-wrapper .form-header-right-wrapper .save-all-btn.allowed:hover {
		cursor: pointer;
		-webkit-box-shadow: 0 0 8px 0 rgba(250, 121, 37, 0.45);
		-moz-box-shadow: 0 0 8px 0 rgba(250, 121, 37, 0.45);
		box-shadow: 0 0 8px 0 rgba(250, 121, 37, 0.45);
	}
	.related-opp-wrapper {
		color: var(--txt-lg);
		h4 {
			color: white;
			margin: 1em 0;
		}
		th {
			font-size: 14px;
			padding: 5px 10px;
		}
		td {
			font-size: 12px;
			padding: 5px 10px;
		}
		a {
		}
	}
	& .body-wrapper {
		padding: 6em 19em !important;
	}
	& .body-content-wrapper::-webkit-scrollbar {
		width: 10px;
		background: transparent;
	}
	& .body-content-wrapper::-webkit-scrollbar-thumb {
		background-color: var(--bg-d0-dash);
	}
	& .body-content-wrapper::-webkit-scrollbar-track {
		background-color: transparent;
	}
	& .body-content-wrapper {
		overflow-y: auto;
		height: 100%;
	}
	& .body-content-wrapper.ce [data-sectionName="coupon_analytics"] .title-container {
		background: var(--blue) !important;
		h3 {
			color: #fff !important;
		}
	}
	& .body-content-wrapper.ce [data-sectionName="ad_extension_blocker"] .title-container {
		background: var(--darkyellow) !important;
		h3 {
			color: #fff !important;
		}
	}
	& .body-content-wrapper.ce [data-sectionName="desktop_launch"] .title-container {
		background: var(--violet) !important;
		&:before {
			content: "Coupon Corrector: ";
			font-size: 0.8em;
		}
		h3 {
			color: #fff !important;
		}
	}
	& .body-content-wrapper.ce [data-sectionName="mobile_launch"] .title-container {
		background: var(--violet) !important;
		&:before {
			content: "Coupon Corrector: ";
			font-size: 0.8em;
		}
		h3 {
			color: #fff !important;
		}
	}
	& .body-content-wrapper.ce [data-sectionName="engagement"] .title-container {
		background: var(--violet) !important;
		&:before {
			content: "Coupon Corrector: ";
			font-size: 0.8em;
		}
		h3 {
			color: #fff !important;
		}
	}
	& .body-content-wrapper.ce [data-sectionName="onsite_design"] .title-container {
		background: var(--violet) !important;
		&:before {
			content: "Coupon Corrector: ";
			font-size: 0.8em;
		}
		h3 {
			color: #fff !important;
		}
	}
	& .body-content-wrapper.ce [data-sectionName="tracking__links"] .title-container {
		background: var(--violet) !important;
		&:before {
			content: "Coupon Corrector: ";
			font-size: 0.8em;
		}
		h3 {
			color: #fff !important;
		}
	}
	& .body-content-wrapper.ce [data-sectionName="reengagement_rules"] .title-container {
		background: var(--violet) !important;
		&:before {
			content: "Coupon Corrector: ";
			font-size: 0.8em;
		}
		h3 {
			color: #fff !important;
		}
	}
	& .body-content-wrapper.ce [data-sectionName="assets"] .title-container {
		background: var(--violet) !important;
		&:before {
			content: "Coupon Corrector: ";
			font-size: 0.8em;
		}
		h3 {
			color: #fff !important;
		}
	}
	& .loading-wrapper {
		position: absolute;
		top: 100px;
		width: 100px;
		left: calc(50% - 50px);
		text-align: center;
	}
	& .loading-wrapper img {
		height: 28px;
		width: 28px;
		background: var(--icon-spinner-l);
		background-size: contain;
	}
	& .body-wrapper .fields-body-wrapper {
		text-align: left;
		margin: auto;
		vertical-align: top;
		width: 100%;
		min-width: 380px;
		max-width: 1200px;
		padding: 0 20px 16px 20px;
		margin-top: 0;
		opacity: 0;
		position: relative;
		top: 10px;
		display: flex;
		flex-wrap: wrap;
	}
	.company-info-wrapper {
		width: 100%;
	}
	& .body-wrapper .fields-body-wrapper.show {
		animation: fields-body-wrapper-show-ani 0.25s ease-in-out 1 forwards;
		-webkit-animation: fields-body-wrapper-show-ani 0.25s ease-in-out 1 forwards;
		-moz-animation: fields-body-wrapper-show-ani 0.25s ease-in-out 1 forwards;
		-o-animation: fields-body-wrapper-show-ani 0.25s ease-in-out 1 forwards;
	}
	@keyframes fields-body-wrapper-show-ani {
		0% {
			opacity: 0;
			top: 10px;
		}
		100% {
			opacity: 1;
			top: 0;
		}
	}
	& .body-wrapper .fields-body-wrapper .section-wrapper {
		border: 1px solid var(--bg-d1);
		box-shadow: 0 0 10px rgba(255, 255, 255, 0.04);
		background: var(--bg-d1);
		border-radius: 3px;
		position: relative;
		padding: 0;
		width: 100%;
		margin-bottom: 1em;
	}
	& .body-wrapper .fields-body-wrapper .section-wrapper.collapse {
		border: 1px solid transparent;
		background: transparent;
	}
	& .body-wrapper .fields-body-wrapper .section-wrapper h1 {
		color: white;
		background: var(--bg-d0-dash);
		padding: 0 10px;
		font-size: 12px;
		text-align: left;
		border-top-left-radius: 4px;
	}
	& .body-wrapper .fields-body-wrapper .section-wrapper .title-container {
		color: white;
		padding: 0.5em 1em 0.5em 1em;
		font-size: 16px;
		text-align: left;
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
	}
	& .section-wrapper h3 {
		display: inline-block;
		vertical-align: top;
		padding-right: 10px;
		padding-left: 8px;
	}
	& .section-wrapper .section-group-attempt-wrapper {
		position: relative;
		border-left: 3px solid transparent;
		margin: 10px 0 10px -1px;
		padding: 0 0 0 20px;
	}
	& .section-wrapper .section-group-attempt-wrapper h3 {
		font-weight: 600;
		width: 130px;
		height: 20px;
		position: absolute;
		top: 130px;
		left: 0;
		font-size: 11px;
		text-align: center;
		color: black;
		border-bottom-left-radius: 3px;
		border-bottom-right-radius: 3px;
		transform-origin: 0 0;
		transform: rotate(270deg);
		pointer-events: none;
	}
	& .section-wrapper .section-group-attempt-wrapper.attempt1 {
		border-left: 3px solid var(--green);
	}
	& .section-wrapper .section-group-attempt-wrapper.attempt1 h3 {
		background: var(--green);
	}
	& .section-wrapper .section-group-attempt-wrapper.attempt1 .order {
		color: var(--green);
	}
	& .section-wrapper .section-group-attempt-wrapper.attempt2 {
		border-left: 3px solid var(--blue);
	}
	& .section-wrapper .section-group-attempt-wrapper.attempt2 h3 {
		background: var(--blue);
	}
	& .section-wrapper .section-group-attempt-wrapper.attempt2 .order {
		color: var(--blue);
	}
	& .section-wrapper .section-group-attempt-wrapper.attempt3 {
		border-left: 3px solid var(--red);
	}
	& .section-wrapper .section-group-attempt-wrapper.attempt3 h3 {
		background: var(--red);
	}
	& .section-wrapper .section-group-attempt-wrapper.attempt3 .order {
		color: var(--red);
	}
	& .section-wrapper .section-group-attempt-wrapper.attempt4 {
		border-left: 3px solid var(--teal);
	}
	& .section-wrapper .section-group-attempt-wrapper.attempt4 h3 {
		background: var(--teal);
	}
	& .section-wrapper .section-group-attempt-wrapper.attempt4 .order {
		color: var(--teal);
	}
	& .section-wrapper .section-group-attempt-wrapper.attempt5 {
		border-left: 3px solid var(--violet);
	}
	& .section-wrapper .section-group-attempt-wrapper.attempt5 h3 {
		background: var(--violet);
	}
	& .section-wrapper .section-group-attempt-wrapper.attempt5 .order {
		color: var(--violet);
	}
	& .section-wrapper .section-group-attempt-wrapper.attempt6 {
		border-left: 3px solid var(--orange);
	}
	& .section-wrapper .section-group-attempt-wrapper.attempt6 h3 {
		background: var(--orange);
	}
	& .section-wrapper .section-group-attempt-wrapper.attempt6 .order {
		color: var(--orange);
	}
	& .section-wrapper .section-group-wrapper {
		margin: 10px;
		border-radius: 3px;
		border: 1px solid var(--bg-d0-dash);
		position: relative;
		min-height: 130px;
	}
	& .section-wrapper .section-group-wrapper .group-title {
		font-size: 12px;
		text-align: left;
		color: white;
		background: var(--bg-d0-dash);
		border-bottom: 1px solid var(--bg-d0-dash);
		padding: 5px 10px;
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
	}
	& .section-wrapper .section-group-wrapper .group-content {
		padding: 1em;
	}
	& .section-wrapper .add-group-wrapper {
		position: absolute;
		top: 5px;
		right: 4px;
		height: 20px;
		padding: 0 8px 0 11px;
		text-align: center;
		background: transparent;
		border-radius: 10px;
		transition: background 0.15s, -moz-transform ease-in-out 0.15s;
	}
	& .section-wrapper .add-group-wrapper:hover {
		cursor: pointer;
		background: var(--darkgreen2);
		color: var(--txt-d);
	}
	& .section-wrapper .add-group-wrapper p {
		pointer-events: none;
		font-size: 12px;
		display: inline-block;
		vertical-align: top;
		margin-right: 5px;
		transition: color 0.15s, -moz-transform ease-in-out 0.15s;
	}
	& .section-wrapper .add-group-wrapper span {
		pointer-events: none;
		font-size: 18px;
		position: relative;
		top: -1px;
	}
	& .section-group-wrapper .delete-group-wrapper {
		position: absolute;
		top: 5px;
		right: 5px;
		height: 20px;
		padding: 0 8px 0 11px;
		text-align: center;
		background: transparent;
		border-radius: 10px;
		transition: background 0.15s, -moz-transform ease-in-out 0.15s;
	}
	& .section-group-wrapper .delete-group-wrapper:hover {
		cursor: pointer;
		background: var(--red);
	}
	& .section-wrapper .delete-group-wrapper p {
		pointer-events: none;
		font-size: 12px;
		display: inline-block;
		vertical-align: top;
		margin-right: 5px;
		transition: color 0.15s, -moz-transform ease-in-out 0.15s;
	}
	& .section-group-wrapper .delete-group-wrapper span {
		pointer-events: none;
		font-size: 18px;
		position: relative;
		top: -1px;
	}
	& .section-wrapper .section-fields-wrapper {
		margin: 12px 0 10px 0;
	}
	& .section-wrapper .section-fields-wrapper.collapse {
		height: 0;
		overflow: hidden;
		padding: 0;
	}
	& .field-row {
		padding: 5px 10px 5px 0;
		text-align: left;
	}
	.field-row[data-validation]:after {
		content: attr(data-validation);
		display: block;
		text-align: left;
		padding: 0 1em 1em 25%;
		color: var(--darkyellow);
		font-size: 10px;
	}
	.field-row.reader {
		padding: 1px 0;
		.textarea,
		.text {
			background: transparent !important;
			padding: 0 !important;
		}
	}
	& .field-row.hidden {
		display: none;
	}
	& .field-row.hidden.show {
		display: block;
		outline: 1px solid var(--red);
		background: var(--highlight-red-d);
		margin: 10px 0;
	}
	& .field-row .title-wrapper {
		display: inline-block;
		vertical-align: top;
		width: 25%;
		position: relative;
		text-align: right;
		padding-top: 4px;
	}
	& .field-row .title-wrapper.reader {
		padding-top: 0;
		top: -1px;
	}
	& .field-row .title-wrapper .title {
		display: inline-block;
		vertical-align: top;
		font-size: 12px;
		text-align: right;
		padding: 0 10px;
	}
	& .field-row .title-wrapper.reader .title {
		color: var(--txt-lg);
	}
	& .field-row .title-wrapper .title.show {
		margin-right: -4px;
	}
	& .field-row .title-wrapper .force-btn {
		display: inline-block;
		vertical-align: top;
		height: 16px;
		width: 16px;
		opacity: 0.25;
		margin: 2px 4px 0 0;
	}
	& .field-row .title-wrapper .force-btn:hover {
		cursor: pointer;
	}
	& .field-row .title-wrapper .force-show {
		background: var(--icon-force-show-lg);
	}
	& .field-row .title-wrapper .force-show:hover {
		background: var(--icon-force-show-l);
		opacity: 0.5;
	}
	& .field-row .title-wrapper .force-show.selected {
		background: var(--icon-force-show-l);
		opacity: 1;
	}
	& .field-row .title-wrapper .force-hide {
		background: var(--icon-force-hide-lg);
	}
	& .field-row .title-wrapper .force-hide:hover {
		background: var(--icon-force-hide-l);
		opacity: 0.5;
	}
	& .field-row .title-wrapper .force-hide.selected {
		background: var(--icon-force-hide-l);
		opacity: 1;
	}
	& .field-row .description-hover-wrapper {
		display: inline-block;
		vertical-align: top;
		background: var(--bg-d0-dash);
		border-radius: 10px;
		margin-right: 5px;
		text-align: center;
		margin-top: -1px;
		color: var(--txt-lg);
		border: 1px solid var(--bg-d0-dash);
	}
	& .field-row .description-hover-wrapper p {
		height: 20px;
		width: 20px;
		pointer-events: none;
		font-size: 12px;
		position: relative;
		text-align: center;
	}
	& .title-wrapper:hover {
		cursor: pointer;
		color: white;
	}
	& .title-wrapper:hover .description-hover-show {
		display: block;
	}
	& .field-row:focus-within .description-hover-show {
		display: block;
	}
	.description-hover-show {
		font-size: 12px;
		text-align: center;
		color: white;
		background: rgba(0, 0, 0, 0.5);
		backdrop-filter: blur(2px);
		padding: 15px;
		border-radius: 3px;
		position: absolute;
		top: 40px;
		left: 10px;
		width: calc(100% - 20px);
		display: none;
		z-index: 100;
	}
	& .field-row .input-wrapper {
		display: inline-block;
		vertical-align: top;
		width: 75%;
		position: relative;
		padding-left: 2px;
		font-weight: 500;
		.public-DraftStyleDefault-block a {
			color: var(--blue);
		}
	}
	& .field-row .input-wrapper.reader {
		width: 75%;
	}
	& .field-row .input-wrapper .reader-text-box-wrapper {
	}
	& .field-row .input-wrapper .reader-text-box-wrapper .preview {
		margin: 5px 0;
		border: 1px solid var(--bg-d0-dash);
		border-radius: 3px;
	}
	& .field-row .input-wrapper .reader-text-box {
		width: 100%;
		border: 1px solid transparent;
		border-radius: 3px;
		color: white;
		font-size: 12px;
		padding: 0;
		height: auto;
		white-space: pre-line;
	}
	& .field-row .input-wrapper .reader-text-box a {
		color: var(--lightblue);
	}
	& .field-row .input-wrapper .reader-text-box a::selection {
		background: var(--blue);
		color: white;
		text-shadow: none;
	}
	& .field-row .input-wrapper .reader-text-box a:hover {
		text-decoration: underline;
	}
	& .field-row .input-wrapper .reader-text-box a.siteid-link {
		color: var(--green);
		margin-right: 5px;
		background: var(--highlight-green-d);
		border-radius: 3px;
		padding: 2px 4px 2px 6px;
	}
	& .field-row .input-wrapper .reader-text-box a.siteid-link::selection {
		background: var(--green);
		color: white;
		text-shadow: none;
	}
	& .field-row .input-wrapper .reader-text-box.links-edit-mode {
		position: relative;
		top: 2px;
		margin-bottom: 6px;
	}
	& .field-row .input-wrapper .reader-text-box.links-edit-mode a.siteid-link {
		padding: 2px 7px 2px 9px;
	}
	& .field-row .input-wrapper .reader-text-box.links-reader-mode {
		padding: 5px 0 4px 0;
		position: relative;
		left: -6px;
		margin-bottom: 4px;
	}
	& .field-row .input-wrapper {
		.textarea,
		.text {
			width: 100%;
			border-radius: 3px;
			color: white;
			font-size: 12px;
			padding: 8px 10px;
			height: auto;
			resize: none;
			overflow: hidden;
		}
	}
	
	& .field-row .react-date-picker__wrapper {
		background: transparent !important;
		& input,
		& select {
			background: transparent !important;
		}
	}
	& .field-row .input-wrapper {
		.textarea,
		.text,
		input,
		select {
			background: var(--bg-dg);
			border: 1px solid transparent;
			&:focus,
			&:focus-within {
				border-color: var(--darkblue);
			}
		}
		input[type="range"] {
			color: #fff;
		}
	}
	.react-datetime-picker__calendar {
		z-index: 2;
	}
	& .date-picker-wrapper {
		background: none;
		textarea,
		input,
		select {
			background: none !important;
			border: 1px solid transparent;
		}
		&:focus {
			border-color: var(--darkblue);
		}
		.react-datetime-picker__inputGroup,
		.react-date-picker__inputGroup {
			background: var(--bg-dg);
			border-radius: 3px;
			padding: 0px 10px;
		}
	}
	& .field-row .input-wrapper .textarea::placeholder {
		color: var(--txt-lg);
		font-size: 12px;
	}
	& .field-row .input-wrapper .textarea.changed {
		background: var(--highlight-orange-d);
		border: 1px solid var(--orange);
	}
	& .field-row .input-wrapper .textarea.saved {
		background: rgba(0, 192, 109, 0.03);
		border: 1px solid var(--green);
		animation: field-saved-ani 1s ease-in-out 1 forwards;
		-webkit-animation: field-saved-ani 1s ease-in-out 1 forwards;
		-moz-animation: field-saved-ani 1s ease-in-out 1 forwards;
		-o-animation: field-saved-ani 1s ease-in-out 1 forwards;
	}
	@keyframes field-saved-ani {
		0% {
			background: rgba(0, 192, 109, 0.03);
			border: 1px solid var(--green);
		}
		100% {
			background: var(--bg-d0-dash);
			border: 1px solid transparent;
		}
	}
	.select-wrapper {
		position: absolute;
		top: 5px;
		right: 10px;
		z-index: 200;
		&:focus-within {
			z-index: 300;
		}
	}
	& .field-row .select-wrapper select[multiple]::before {
		content: "\u203a";
		font-size: 24px;
		position: absolute;
		border: 0;
		left: 0;
		right: 0;
		text-align: center;
		margin: auto;
		line-height: 0.5;
	}
	& .field-row .select-wrapper select[multiple]:focus::before {
		display: none;
	}
	& .field-row .select-wrapper select {
		background: rgba(0, 0, 0, 0.5) !important;
		backdrop-filter: blur(2px);
		color: white;
		border: 1px solid !important;
		outline: none;
		font-size: 12px;
		padding-left: 16px;
		margin: 0;
		height: 18px;
		width: 12px;
		border-radius: 9px;
		overflow: hidden;
		z-index: 300;
	}
	& .field-row .select-wrapper select[multiple] {
		appearance: none;
	}
	& .field-row .select-wrapper select:hover {
		cursor: pointer;
	}
	& .field-row .select-wrapper select[multiple]:focus {
		width: auto;
		padding: 1em;
	}
	& .field-row .select-wrapper select:focus {
		max-width: 30em;
		position: absolute;
		height: auto;
		max-height: 90vh;
		max-width: 90vw;
		right: 0;
		z-index: 400;
		overflow-y: auto;
		& :after {
			content: "";
		}
	}
	& .field-row .input-wrapper .select-static-wrapper {
		min-height: 30px !important;
	}
	& .field-row .input-wrapper.reader .select-static-wrapper {
		min-height: unset !important;
	}
	& .field-row .input-wrapper .select-static {
	}
	& .field-row .input-wrapper .select-static-wrapper select {
		background: var(--bg-d0-dash);
		height: 30px;
		border-radius: 3px;
		border: 1px solid transparent;
		color: white;
		padding: 0 10px;
		font-size: 12px;
		display: inline-block;
		vertical-align: top;
	}
	& .field-row .input-wrapper .select-static-wrapper select:focus {
		outline: none;
	}
	& .field-row .input-wrapper .select-static-label {
		margin-left: 0;
		margin-top: 1px;
		color: white;
		font-size: 12px;
		display: inline-block;
		vertical-align: top;
	}
	& .field-row .input-wrapper .boolean-wrapper {
		min-height: 30px !important;
	}
	& .field-row .input-wrapper.reader .boolean-wrapper {
		min-height: unset !important;
	}
	& .field-row .input-wrapper .boolean-wrapper .toggle-switch {
		margin-top: 6px;
		display: inline-block;
		vertical-align: top;
	}
	& .field-row .input-wrapper .boolean-wrapper .toggle-label {
		margin-left: 10px;
		margin-top: 9px;
		color: white;
		font-size: 12px;
		display: inline-block;
		vertical-align: top;
	}
	& .field-row .input-wrapper .boolean-wrapper .toggle-label.reader {
		margin-left: 0;
		margin-top: 1px;
	}
	& .field-row .input-wrapper .date-wrapper {
		min-height: 30px !important;
	}
	& .field-row .input-wrapper.reader .date-wrapper {
		min-height: unset !important;
	}
	& .field-row .input-wrapper .date-picker-wrapper {
		display: inline-block;
		vertical-align: top;
		height: 100%;
	}
	& .field-row .input-wrapper .time-picker-wrapper {
		display: inline-block;
		vertical-align: top;
		min-width: 100px;
		height: 100%;
		position: relative;
		top: 1px;
	}
	& .field-row .input-wrapper .time-picker-wrapper .time-select-wrapper-outer {
		display: inline-block;
		vertical-align: top;
	}
	& .field-row .input-wrapper .time-picker-wrapper .time-select-wrapper {
		display: inline-block;
		vertical-align: top;
		margin-right: 10px;
	}
	& .field-row .input-wrapper .time-picker-wrapper select {
		background: var(--bg-d0-dash);
		border: 1px solid transparent;
		height: 30px;
		border-radius: 3px;
		color: white;
		padding: 0 10px;
		font-size: 12px;
		display: inline-block;
		vertical-align: top;
	}
	& .field-row .input-wrapper .time-picker-wrapper select:focus {
		outline: none;
	}
	& .field-row .input-wrapper .date-wrapper .time-label {
		display: inline-block;
		vertical-align: top;
		font-size: 12px;
		margin-left: 0;
		margin-top: 8px;
	}
	& .field-row .input-wrapper .date-wrapper .time-label span {
		color: var(--violet);
		margin-left: 10px;
	}
	& .field-row .input-wrapper .date-wrapper .time-label span::selection {
		background: var(--violet);
		color: white;
	}
	& .field-row .input-wrapper .date-wrapper .time-label.reader {
		margin-left: 0;
		margin-top: 0;
	}
	& .field-row .input-wrapper .date-wrapper .datetime-picker {
		display: inline-block;
		vertical-align: top;
	}
	& .field-row .input-wrapper .date-wrapper .date-label {
		display: inline-block;
		vertical-align: top;
		font-size: 12px;
		margin-left: 10px;
		margin-top: 9px;
	}
	& .field-row .input-wrapper .date-wrapper .date-label.reader {
		margin-left: 0;
		margin-top: 1px;
	}
	& .field-row .input-wrapper .date-wrapper .datetime-picker input {
		color: white;
		font-size: 12px;
		&::placeholder {
			color: white;
		}
	}
	& .field-row .input-wrapper .date-wrapper .datetime-picker span {
		color: white;
		font-size: 12px;
	}
	& .field-row .input-wrapper .date-wrapper .datetime-picker select {
		background: transparent;
		color: white;
		font-size: 12px;
	}
	& .field-row .input-wrapper .date-wrapper .datetime-picker .clear-icon {
		height: 12px;
		width: 12px;
		margin-top: 4px;
		margin-right: 5px;
		margin-left: 5px;
	}
	& .react-date-picker__clear-button img {
		background-image: var(--icon-x-l);
	}
	& .react-datetime-picker__clear-button img {
		background-image: var(--icon-x-l);
	}
	& .react-datetime-picker__wrapper {
		background: transparent;
		border: none;
		margin-top: 3px;
		height: 30px;
		border-radius: 3px;
	}
	& .input-wrapper .react-date-picker__wrapper {
		margin-top: 1px;
		height: 30px;
		border-radius: 3px;
		border: none !important;
		input {
			border: none !important;
		}
	}
	& .react-calendar {
		color: black !important;
	}
	& .react-calendar span {
		color: black !important;
	}
	& .field-row .input-wrapper .date-wrapper.changed > .date-picker-wrapper .react-date-picker__wrapper {
		background: var(--highlight-orange-d);
		border: 1px solid var(--orange);
	}
	& .field-row .input-wrapper .date-wrapper.changed > .date-picker-wrapper .react-datetime-picker__wrapper {
		background: var(--highlight-orange-d);
		border: 1px solid var(--orange);
	}
	& .field-row .display-links-box {
		width: 100%;
		color: white;
		padding: 10px 10px 5px 10px;
		height: auto;
		white-space: pre-wrap;
		margin-top: -6px;
	}
	& .field-row .display-links-box .link-wrapper {
		display: inline-block;
		vertical-align: top;
		text-align: left;
		padding: 3px 10px 2px 0;
	}
	& .field-row .display-links-box a {
		display: inline-block;
		vertical-align: top;
		color: var(--lightblue);
		font-size: 0.7em;
	}
	& .field-row .display-links-box a:hover {
		cursor: pointer;
		text-decoration: underline;
	}
	& .field-row .display-links-box a::selection {
		background: var(--blue);
		color: white;
		text-shadow: none;
	}
	& .field-row .display-links-box a::-moz-selection {
		background: var(--blue);
		color: white;
		text-shadow: none;
	}
	@keyframes field-copied-ani {
		0% {
			color: var(--green);
		}
		100% {
			color: var(--txt-lg);
		}
	}
	& .field-row .options-wrapper {
		padding: 0 10px;
	}
	& .field-row .options-wrapper .save-btn {
		height: 34px;
		width: 50px;
		background: var(--bg-d0-dash);
		border-radius: 3px;
		display: inline-block;
		vertical-align: top;
		border: 1px solid transparent;
		transition: box-shadow 0.05s, -moz-transform ease-in-out 0.05s;
	}
	& .field-row .options-wrapper .save-btn.hide {
		visibility: hidden;
	}
	& .field-row .options-wrapper .save-btn p {
		pointer-events: none;
		color: var(--txt-lg);
		text-align: center;
		font-size: 12px;
	}
	& .field-row .options-wrapper .save-btn:hover {
		cursor: not-allowed;
	}
	& .field-row .options-wrapper .save-btn.changed {
		background: var(--highlight-orange-d);
		border: 1px solid var(--orange);
	}
	& .field-row .options-wrapper .save-btn.changed:hover {
		cursor: pointer;
		-webkit-box-shadow: 0 0 8px 0 rgba(250, 121, 37, 0.45);
		-moz-box-shadow: 0 0 8px 0 rgba(250, 121, 37, 0.45);
		box-shadow: 0 0 8px 0 rgba(250, 121, 37, 0.45);
	}
	& .field-row .options-wrapper .save-btn.changed p {
		color: white;
	}
	& .field-row .options-wrapper .details-wrapper {
		position: relative;
	}
	& .field-row .options-wrapper .details-wrapper p {
		font-weight: 400;
		color: var(--txt-lg);
		text-align: right;
		font-size: 10px;
		transition: color 0.05s, -moz-transform ease-in-out 0.05s;
	}
	& .field-row .options-wrapper .details-wrapper p span {
		pointer-events: none;
	}
	& .field-row .options-wrapper .details-wrapper p time {
		pointer-events: none;
	}
	& .field-row .options-wrapper .details-wrapper p:hover {
		cursor: pointer;
		text-decoration: underline;
	}
	& .field-row .options-wrapper .details-wrapper p.saved {
		color: var(--green);
		animation: field-saved-details-ani 1s ease-in-out 1 forwards;
		-webkit-animation: field-saved-details-ani 1s ease-in-out 1 forwards;
		-moz-animation: field-saved-details-ani 1s ease-in-out 1 forwards;
		-o-animation: field-saved-details-ani 1s ease-in-out 1 forwards;
	}
	@keyframes field-saved-details-ani {
		0% {
			color: var(--green);
		}
		100% {
			color: var(--txt-lg);
		}
	}
	& .field-row .options-wrapper .details-wrapper .editing_prompt {
		display: none;
	}
	& .field-row .options-wrapper .details-wrapper .editing_prompt.show {
		display: block;
	}
	& .field-row .options-wrapper .details-wrapper .editing_prompt .editing_text {
		color: var(--teal);
	}
	& .field-row .options-wrapper .details-wrapper .editing_prompt img {
		width: 20px;
		margin-left: 5px;
		position: relative;
		top: -1px;
	}
	& .field-row .options-wrapper .details-wrapper p span.green {
		color: var(--green) !important;
	}
	& .field-row .options-wrapper .details-wrapper p span.yellow {
		color: var(--yellow) !important;
	}
	& .field-row .options-wrapper .details-wrapper p span.orange {
		color: var(--orange) !important;
	}
	& .field-row .options-wrapper .details-wrapper .revisions-modal-wrapper {
		position: absolute;
		top: calc(100% + 10px);
		left: 0;
		min-height: 100px;
		width: 100%;
		background: white;
		z-index: 10000;
		border-radius: 3px;
	}
	& .fields-body-wrapper {
		[data-popper-placement] {
			z-index: 9999999999;
		}
		.RichEditor-root .draft-toolbar-wrap{
			display: none;
		}
		.RichEditor-root:focus-within .draft-toolbar-wrap{
			display: block;
		}
	}
	& .fields-body-wrapper .task-wrapper {
		margin: 0;
		width: 100%;
		.textarea {
			border-radius: 3px;
			color: white;
			font-size: 12px;
			padding: 8px 10px;
			height: auto;
			resize: none;
			overflow: hidden;
			background: var(--bg-lg);
			color: var(--txt-dg);
			margin: 1em 0;
		}
	}
	& .fields-body-wrapper .task-wrapper .add-task-wrapper p {
		color: white;
		font-size: 12px;
		text-align: center;
	}
	& .fields-body-wrapper .task-wrapper .task-menu-wrapper {
		border-radius: 3px;
		width: 100%;
		position: relative;
		margin: 1em 0;
	}
	& .fields-body-wrapper .task-wrapper .task-menu-wrapper .task-completed-wrapper {
		height: 100%;
		display: inline-block;
		vertical-align: middle;
		width: 50%;
	}
	& .fields-body-wrapper .task-wrapper .task-menu-wrapper .task-completed-wrapper p {
		display: inline-block;
		vertical-align: middle;
		font-size: 12px;
		margin-left: 14px;
	}
	& .fields-body-wrapper .task-wrapper .task-menu-wrapper .task-completed-wrapper p span {
		color: white;
	}
	& .fields-body-wrapper .task-wrapper .task-menu-wrapper .task-completed-wrapper p.completed {
		color: var(--green);
	}
	& .fields-body-wrapper .task-wrapper .task-menu-wrapper .task-completed-wrapper p.pending {
		color: var(--orange);
	}
	& .fields-body-wrapper .task-wrapper .task-menu-wrapper .task-toggle-wrapper {
		height: 100%;
		min-width: 50px;
		float: right;
	}
	& .fields-body-wrapper .task-wrapper .task-menu-wrapper .task-toggle-wrapper .task-toggle-label {
		display: inline-block;
		vertical-align: middle;
		font-size: 12px;
		margin-right: 10px;
	}
	& .fields-body-wrapper .task-wrapper .task-menu-wrapper .task-toggle-wrapper .task-toggle-label:hover {
		cursor: default;
	}
	& .fields-body-wrapper .task-wrapper .task-menu-wrapper .task-toggle-wrapper .toggle-switch {
		margin: 6px 10px 0 0;
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper {
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper.reader {
		width: 100%;
		margin-left: 0;
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-prompt {
		font-size: 12px;
		color: var(--txt-lg);
		padding: 1em;
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row {
		border-radius: 3px;
		background: var(--bg-d1);
		padding: 10px 10px 8px 10px;
		margin-top: 10px;
		position: relative;
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper.reader .task-row {
		padding: 10px 10px 6px 10px;
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row.last {
		margin-bottom: 0em;
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row.updated {
		/* border: 1px solid var(--green);
		animation: task-saved-ani 1s ease-in-out 1 forwards;
		-webkit-animation: task-saved-ani 1s ease-in-out 1 forwards;
		-moz-animation: task-saved-ani 1s ease-in-out 1 forwards;
		-o-animation: task-saved-ani 1s ease-in-out 1 forwards; */
	}
	#company_field_wrapper {
		.desc-wrapper {
			color: var(--txt-lg);
			font-size: 14px;
			white-space: pre-wrap;
			padding: 1em 0;
		}
		.title-wrapper {
			width: 100%;
			text-align: left;
			color: var(--txt-lg);
			font-size: 14px;
		}
		a:not(.btn) {
			color: dodgerblue;
			text-decoration: underline;
			font-size: 12px;
		}
		.field-row {
			padding: 5px 10px;
		}
	}
	@keyframes task-saved-ani {
		0% {
			border: 1px solid var(--green);
		}
		100% {
			border: 1px solid transparent;
		}
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row .type-wrapper {
		color: white;
		font-size: 12px;
		width: 50%;
		display: inline-block;
		vertical-align: top;
		padding-left: 3px;
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row p {
		display: inline-block;
		vertical-align: top;
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row .reader-type-wrapper {
		color: white;
		font-size: 12px;
		width: 50%;
		display: inline-block;
		vertical-align: top;
		padding-left: 3px;
		margin-top: -5px;
		margin-bottom: 5px;
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row .reader-type-wrapper p {
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row .reader-type-wrapper span {
		color: var(--txt-lg);
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row select {
		color: white;
		font-size: 12px;
		height: 30px;
		background: transparent;
		outline: none;
		padding: 0 10px;
		border: none;
		padding: 0 10px;
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row .task-select-wrapper {
		display: inline-block;
		vertical-align: top;
		margin-left: 6px;
		background: var(--bg-d1);
		border-radius: 3px;
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row .status-wrapper {
		color: white;
		font-size: 12px;
		width: 50%;
		display: inline-block;
		vertical-align: top;
		text-align: right;
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row .status-wrapper .status-select-wrapper {
		display: inline-block;
		vertical-align: top;
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row .status-wrapper .show-select-wrapper {
		display: inline-block;
		vertical-align: top;
		position: relative;
	}
	&
		.fields-body-wrapper
		.task-wrapper
		.task-body-wrapper
		.task-row
		.status-wrapper
		.show-select-wrapper
		.force-show-icon {
		height: 20px;
		width: 20px;
		margin: 5px 5px 5px 10px;
	}
	&
		.fields-body-wrapper
		.task-wrapper
		.task-body-wrapper
		.task-row
		.status-wrapper
		.show-select-wrapper
		.force-show-icon:hover {
		cursor: pointer;
	}
	&
		.fields-body-wrapper
		.task-wrapper
		.task-body-wrapper
		.task-row
		.status-wrapper
		.show-select-wrapper
		.force-show-icon.on {
		background-image: var(--icon-force-show-l);
	}
	&
		.fields-body-wrapper
		.task-wrapper
		.task-body-wrapper
		.task-row
		.status-wrapper
		.show-select-wrapper
		.force-show-icon.off {
		background-image: var(--icon-force-show-d2);
	}
	&
		.fields-body-wrapper
		.task-wrapper
		.task-body-wrapper
		.task-row
		.status-wrapper
		.show-select-wrapper
		.prompt-force-show {
		position: absolute;
		top: calc(100% + 15px);
		width: 350px;
		right: -20px;
		padding: 20px;
		background: var(--blue);
		z-index: 100;
		border-radius: 3px;
		-webkit-box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.45);
		-moz-box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.45);
		box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.45);
		text-align: center;
	}
	&
		.fields-body-wrapper
		.task-wrapper
		.task-body-wrapper
		.task-row
		.status-wrapper
		.show-select-wrapper
		.prompt-force-show-tip {
		position: absolute;
		top: -5px;
		right: 25px;
		height: 20px;
		width: 20px;
		background: var(--blue);
		transform: rotate(45deg);
	}
	&
		.fields-body-wrapper
		.task-wrapper
		.task-body-wrapper
		.task-row
		.status-wrapper
		.show-select-wrapper
		.prompt-force-show
		p {
		font-size: 13px;
		color: white;
		text-align: center;
	}
	&
		.fields-body-wrapper
		.task-wrapper
		.task-body-wrapper
		.task-row
		.status-wrapper
		.show-select-wrapper
		.prompt-force-show
		h1 {
		font-size: 13px;
		color: white;
		text-align: center;
		margin-top: 20px;
		display: inline-block;
		padding: 5px 25px;
		background: rgba(0, 0, 0, 0.3);
		border-radius: 3px;
		transition: background 0.25s, -moz-transform ease-in-out 0.25s;
	}
	&
		.fields-body-wrapper
		.task-wrapper
		.task-body-wrapper
		.task-row
		.status-wrapper
		.show-select-wrapper
		.prompt-force-show
		h1:hover {
		cursor: pointer;
		background: rgba(0, 0, 0, 0.6);
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row .status-wrapper select.Pending {
		color: var(--orange);
		border: 2px solid;
		border-radius: 3px;
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row .status-wrapper select.Completed {
		color: var(--green);
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row .reader-status-wrapper {
		color: white;
		font-size: 12px;
		width: 50%;
		display: inline-block;
		vertical-align: top;
		text-align: right;
		margin-top: -5px;
		margin-bottom: 5px;
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row .reader-status-wrapper p {
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row .reader-status-wrapper p.Completed {
		color: var(--green);
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row .reader-status-wrapper p.Pending {
		color: var(--orange);
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row .reader-status-wrapper span {
		color: var(--txt-lg);
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row textarea {
		width: 100%;
		background: var(--bg-dg);
		border: 1px solid transparent;
		border-radius: 3px;
		color: white;
		font-size: 12px;
		padding: 8px 10px;
		height: auto;
		resize: none;
		min-height: 30px !important;
		margin-top: 10px;
		overflow: hidden;
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row textarea.reader {
		margin-top: 1px;
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row textarea::placeholder {
		color: var(--txt-lg);
		font-size: 12px;
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row textarea:focus {
		border: 1px solid var(--blue);
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row .task-breadcrumb-wrapper {
		margin-top: 3px;
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row .task-breadcrumb {
		color: var(--txt-lg);
		font-size: 10px;
		margin-left: 3px;
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row .task-breadcrumb .green {
		color: var(--green);
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row .task-breadcrumb .yellow {
		color: var(--yellow);
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row .task-breadcrumb .orange {
		color: var(--orange);
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row .delete-task-btn {
		position: absolute;
		right: 15px;
		bottom: 12px;
		font-size: 12px;
		color: var(--txt-lg);
	}
	& .fields-body-wrapper .task-wrapper .task-body-wrapper .task-row .delete-task-btn:hover {
		cursor: pointer;
		color: var(--red);
		text-decoration: underline;
	}
	.field-left-wrapper {
		/* width:66.66666%; */
		width: 100%;
	}
	& .image-uploader-wrapper {
		/* width: 33.33333%; */
		width: 100%;
		margin: 10px 0;
		padding: 10px 10px 0 10px;
		text-align: center;
	}
	& .image-uploader-wrapper .upload-wrapper {
		position: relative;
		display: inline-block;
		vertical-align: top;
		.dropzone {
			padding: 1em;
		}
	}
	& .image-uploader-wrapper .upload-wrapper .processing-wrapper {
		height: 100%;
		width: 100%;
		text-align: center;
		background: rgba(244, 128, 36, 0.1);
		border: 1px solid rgba(244, 128, 36, 0.45);
		border-radius: 3px;
		position: relative;
		padding: 0;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	& .image-uploader-wrapper .upload-wrapper .processing-wrapper p {
		text-align: center;
		font-size: 12px;
		color: white;
		background: rgba(0, 0, 0, 0.9);
		font-weight: 500;
		position: relative;
		z-index: 2;
		padding: 0 12px;
		height: 24px;
		border-radius: 12px;
	}
	& .image-uploader-wrapper .upload-wrapper .processing-wrapper img {
		position: absolute;
		bottom: 0;
		left: 0;
		max-height: 100%;
		max-width: 100%;
		z-index: 1;
	}
	& .image-uploader-wrapper .upload-wrapper .dz-container {
		background: var(--bg-d1);
		border: 1px dashed var(--bg-d0-dash);
		border-radius: 3px;
		min-height: 100px;
		min-width: 100px;
	}
	& .image-uploader-wrapper .upload-wrapper .dz-container:hover {
		border: 1px dashed var(--blue) !important;
	}
	& .image-uploader-wrapper .upload-wrapper .dz-container p {
		text-align: center;
		font-size: 12px;
	}
	& .image-uploader-wrapper .upload-wrapper .dz-container p.warning {
		color: var(--txt-lg);
	}
	& .image-uploader-wrapper .upload-wrapper .dropzone:hover {
		cursor: pointer;
	}
	& .image-uploader-wrapper .images-wrapper {
		display: inline-block;
		vertical-align: top;
		min-height: 100px;
		margin-left: 10px;
		text-align: center;
	}
	& .image-uploader-wrapper .images-wrapper.reader {
		width: 100%;
		margin-left: 0;
	}
	& .image-uploader-wrapper .images-wrapper .formv-image {
		width: 110px;
		height: 110px;
		position: relative;
		display: inline-block;
		vertical-align: top;
		background: var(--bg-d1);
		border: 1px solid var(--bg-d0-dash);
		margin-right: 10px;
		margin-bottom: 10px;
		border-radius: 3px;
		overflow: hidden;
	}
	& .image-uploader-wrapper .images-wrapper .formv-image .formv-image-inner {
		height: 100%;
		width: 100%;
		padding: 0;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	& .image-uploader-wrapper .images-wrapper .formv-image img {
		max-width: 100%;
		max-height: 100%;
	}
	& .image-uploader-wrapper .images-wrapper .formv-image:hover {
		cursor: pointer;
		border: 1px solid var(--blue);
	}
	& .prompt-formv-images {
		width: 100%;
		background: var(--blue);
		padding: 10px;
		border-radius: 3px;
		text-align: left;
		margin-bottom: 10px;
	}
	& .prompt-formv-images .prompt-text-wrapper {
		display: inline-block;
		vertical-align: top;
		width: calc(100% - 150px);
	}
	& .prompt-formv-images p {
		color: white;
		font-size: 13px;
		padding-left: 8px;
	}
	& .prompt-formv-images p.first {
		margin-bottom: 2px;
		position: relative;
		top: -2px;
	}
	& .prompt-formv-images .got-it-btn-wrapper {
		display: inline-block;
		vertical-align: top;
		width: 140px;
		height: 40px;
		background: rgba(0, 0, 0, 0.3);
		border-radius: 3px;
		color: white;
		font-size: 13px;
		text-align: center;
		margin-left: 10px;
		transition: background 0.25s, -moz-transform ease-in-out 0.25s;
	}
	& .prompt-formv-images .got-it-btn-wrapper:hover {
		cursor: pointer;
		background: rgba(0, 0, 0, 0.6);
	}
	.menu-hover-wrapper {
		position: relative;
		width: 20px;
		border-radius: 3px;
		z-index: 2;
		text-align: right;
		justify-self: end;
		margin: 0;
		flex: 0 0 50px;
		z-index: 99;
	}
	.menu-hover-wrapper .menu-icon {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		height: 30px;
		z-index: 10;
		color: var(--txt-lg2);
		font-size: 20px;
		text-align: center;
	}
	.menu-hover-wrapper:hover {
		/* color: var(--txt-lg2);
		padding: 4px 0;
		width: auto;
		height: auto !important;
		z-index: 100 !important; */
	}
	.menu-hover-wrapper:hover > .menu-icon {
		/* color: var(--bg-l1); */
	}
	.menu-hover-wrapper .menu-wrapper {
		display: none;
		height: auto;
	}
	.menu-hover-wrapper .menu-btn {
		cursor: pointer;
		font-size: 12px;
		color: var(--txt-d);
		text-align: left;
		margin: 0 4px;
		border-radius: 2px;
		padding: 2px 60px 0 10px;
	}
	.menu-hover-wrapper .menu-btn:hover {
		color: #fff;
	}
	.menu-hover-wrapper .menu-btn.edit:hover {
		background: var(--blue);
	}
	.menu-hover-wrapper .menu-btn.clone:hover {
		background: var(--lt-green-bg);
	}
	.menu-hover-wrapper .menu-btn.delete:hover {
		background: var(--red);
	}
	.menu-hover-wrapper:hover > .menu-wrapper {
		display: block;
		position: absolute;
		width: 200px;
		right: 0;
		padding: 8px;
		background: #fff;
		top: 55px;
		border-radius: 5px;
		text-align: left;
		box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.45);
		p {
			font-size: 12px;
			color: var(--txt-d);
			margin: 0 4px;
			border-radius: 2px;
			padding: 2px 10px;
		}
		h4 {
			padding: 10px;
			color: var(--txt-d);
		}
	}
	.delete-btn:hover > span {
		opacity: 1;
	}
	@media screen and (max-width: 1200px) {
		.body-wrapper {
			padding: 4em 2em 6em 5em !important;
		}
	}
	@media screen and (max-width: 480px) {
		.fields-body-wrapper .task-wrapper .task-menu-wrapper .task-toggle-wrapper {
			float: none;
		}
	}
`;

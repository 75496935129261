// Resources
import React, { Component } from "react";

// Styles
import { NavWrapperEl } from "./styles/NavWrapper";
import { NavButtonWrapperEl } from "./styles/NavButtonWrapper";

class NavWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		window.addEventListener("load", this._handleLoad);
	}

	componentWillUnmount() {
		window.removeEventListener("load", this._handleLoad);
	}

	_handleLoad = () => {
		setTimeout(() => {
			this.props.setHeight("app", this.navWrapper.clientHeight + 150);
		}, 200);
	};

	render() {
		return (
			<React.Fragment>
				<NavWrapperEl ref={(ref) => (this.navWrapper = ref)}>
					{/* <NavButtonWrapperEl
						onClick={() => this.props.handleNavChange("wiki")}
						className={"noselect " + (this.props.nav.nav === "wiki" ? "selected" : "")}
					>
						<div className={"icon wiki"} />
						<p>Wiki</p>
					</NavButtonWrapperEl> */}
					<NavButtonWrapperEl
						onClick={() => this.props.handleNavChange("activity")}
						className={"noselect " + (this.props.nav.nav === "activity" ? "selected" : "")}
					>
						<div className={"icon activity"} />
						<p>Activity</p>
					</NavButtonWrapperEl>

					<NavButtonWrapperEl
						onClick={() => this.props.handleNavChange("team")}
						className={"noselect " + (this.props.nav.nav === "team" ? "selected" : "")}
					>
						<div className={"icon team"} />
						<p>Team</p>
					</NavButtonWrapperEl>

					<NavButtonWrapperEl
						onClick={() => this.props.handleNavChange("dev")}
						className={"noselect " + (this.props.nav.nav === "dev" ? "selected" : "")}
					>
						<div className={"icon dev"} />
						<p>Dev</p>
					</NavButtonWrapperEl>

					<NavButtonWrapperEl
						onClick={() => this.props.handleNavChange("legacy")}
						className={"noselect " + (this.props.nav.nav === "legacy" ? "selected" : "")}
					>
						<div className={"icon legacy"} />
						<p>Legacy</p>
					</NavButtonWrapperEl>
				</NavWrapperEl>
			</React.Fragment>
		);
	}
}

export default NavWrapper;

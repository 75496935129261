import React, { Component } from "react";
import AuthHelperMethods from "./AuthHelperMethods";

export default function withAuth(AuthComponent) {
	const Auth = new AuthHelperMethods();

	return class AuthWrapped extends Component {
		state = {
			confirm: null,
			loaded: false
		};
		componentDidMount() {
			if (!Auth.loggedIn()) {
				if (this.props.history) {
					this.props.history.replace("/login");
				}
			} else {
				try {
					// Check for JWT
					const confirm = Auth.getConfirm();
					this.setState({
						confirm: confirm,
						loaded: true
					});
				} catch (err) {
					// Print error and log the user out for security reasons.
					console.log(err);
					Auth.logout();
					if (this.props.history) {
						this.props.history.replace("/login");
					}
				}
			}
		}

		render() {
			if (this.state.loaded === true) {
				// Prevent component from loading if no token found
				if (this.state.confirm) {
					return (
						<AuthComponent  history={this.props.history} confirm={this.state.confirm}  {...this.props} />
					);
				} else {
					console.log("[ withAuth ] not confirmed!");
					return null;
				}
			} else {
				return null;
			}
		}
	};
}

import styled from "styled-components";

export const RightSideBarWrapperEl = styled.div`
  width: 100%;
  min-height: 100px;

  & .rsb-body-container {
    height: 100vh;
    overflow-y: auto;
    padding-top: 60px;
  }

  & .rsb-body-container .sidebar-title {
    text-align: center;
    color: white;
    font-size: 13px;
    margin-bottom: -10px;
  }

  .rsb-body-container::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  .rsb-body-container::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  .rsb-body-container::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .form-sidebar-nav-wrapper {
    /* height: 60px; */
    width: 100%;
    //outline: 1px dotted red;
    border-bottom: 1px solid var(--bg-d2);
    text-align: center;
  }

  .form-sidebar-nav-wrapper .sidebar-nav-btn {
    display: inline-block;
    vertical-align: top;
    color: var(--txt-lg);
    padding: 2px 0;
    font-size: 13px;
    margin: 20px 20px 0 20px;
  }
  
  .form-sidebar-nav-wrapper .sidebar-nav-btn.selected {
    color: white;
    border-bottom: 1px solid white;
  }
  
  .form-sidebar-nav-wrapper .sidebar-nav-btn:hover {
    cursor: pointer;
    color: white;
  }

`;
import styled from "styled-components";

export const AddCSDWrapperEl = styled.div`
	position: absolute;
	top: 50px;
	width: 500px;
	left: calc(50% - 250px);
	min-height: 200px;
	background: white;
	border-radius: 3px;
	transition: height 0.15s, -moz-transform ease-in-out 0.15s;
	padding-bottom: 25px;

	// ----------------------
	// ----- CANCEL BTN -----
	// ----------------------
	& .cancel-btn {
		height: 2em;
		width: 2em;
		line-height: 1;
		padding: 0.5em;
		position: absolute;
		top: 16px;
		right: 25px;
		background: rgba(240, 63, 77, 0.15);
		text-align: center;
		border-radius: 3em;
		transition: background 0.05s, color 0.05s, -moz-transform ease-in-out 0.05s;
	}

	& .cancel-btn:hover {
		cursor: pointer;
		background: var(--red);
	}

	& .cancel-btn:hover > span {
		color: white;
	}

	& .cancel-btn span {
		font-size: 14px;
		font-weight: 700;
		color: var(--red);
	}

	// ---------------------
	// ----- TOP TITLE -----
	// ---------------------
	& .title-wrapper {
		display: block;
		margin-bottom: 50px;
		//outline: 1px dotted red;
		padding-right: 60px;
	}

	& .title-wrapper h1 {
		color: var(--txt-d);
		font-size: 18px;
		padding-left: 25px;
		margin-top: 22px;
	}

	& .title-wrapper h1 span {
		color: var(--blue);
		font-size: 16px;
		font-weight: 900;
	}

	// ---------------------
	// ----- CSD TITLE -----
	// ---------------------
	& .csd-title-wrapper {
		color: var(--txt-d);
		margin-top: 20px;
		display: block;
		position: relative;
		padding: 0 24px;
		summary {
			font-size: 12px;
			text-transform: uppercase;
			letter-spacing: 1px;
			margin-bottom: 1em;
			:after {
				content: " \u203a";
			}
		}
	}

	& .csd-title-wrapper label {
		display: block;
		font-size: 13px;
		margin-bottom: 2px;
	}

	& .csd-title-wrapper p {
		display: block;
		font-size: 13px;
		margin-bottom: 10px;
		color: var(--txt-dg);
	}

	& .csd-title-wrapper input {
		display: block;
		font-size: 14px;
		padding:0.5em;
		margin-bottom: 15px;
		width: 100%;
		border-radius: 3px;
		border: 1px solid var(--bg-l3);
		background: white;
		color: black;
		box-sizing: border-box;
	}

	& .csd-title-wrapper input.valid {
		border: 1px solid var(--green);
		background: rgba(31, 198, 105, 0.1) !important;
	}

	& .csd-title-wrapper input::placeholder {
		color: var(--txt-lg);
	}

	// --------------------
	// ----- CSD TYPE -----
	// --------------------
	& .csd-type-wrapper {
		//outline: 1px dotted red;
		margin: 20px 0;
		display: block;
		position: relative;
		text-align: center;
		padding: 0 10px;
	}

	& .csd-type-btn {
		display: inline-block;
		vertical-align: top;
		margin: 1%;
		background: var(--bg-l2);
		border-radius: 3px;
		padding: 10px 0;
		width: 18%;
		transition: background 0.05s, -moz-transform ease-in-out 0.05s;
	}

	& .csd-type-btn:hover {
		cursor: pointer;
	}

	& .csd-type-btn h1 {
		font-size: 14px;
		letter-spacing: 1px;
		margin-bottom: 5px;
		color: var(--txt-dg);
		transition: color 0.15s, -moz-transform ease-in-out 0.15s;
	}

	& .csd-type-btn.sel h1 {
		color: white;
	}

	& .csd-type-btn p {
		font-size: 11px;
		color: var(--txt-dg);
		transition: color 0.15s, -moz-transform ease-in-out 0.15s;
	}

	& .csd-type-btn.sel p {
		color: white;
	}

	& .csd-type-btn.nsel:hover > h1 {
		color: var(--txt-d);
	}

	& .csd-type-btn.nsel:hover > p {
		color: var(--txt-d);
	}

	& .csd-type-btn.tt {
		background: var(--darkgreen);
	}

	& .csd-type-btn.lc {
		background: var(--violet);
	}

	& .csd-type-btn.pc {
		background: var(--blue);
	}

	& .csd-type-btn.em {
		background: var(--blue);
	}

	& .csd-type-btn.nc {
		background: var(--gray);
	}

	& .csd-type-btn.ce {
		background: var(--red);
	}

	// ----------------------
	// ----- SUBMIT BTN -----
	// ----------------------

	& .submit-csd-btn {
		font-size: 1.25em;
	}

	& .login-error-wrapper {
		width: 100%;
		padding: 0 20px;
	}
`;

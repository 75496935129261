import styled from "styled-components";
export const MjmlWrapperEl = styled.div`
	& nav {
		padding: 1em;
		& a {
			padding: 1em;
			color: dodgerblue;
			text-transform: uppercase;
			letter-spacing: 1px;
			font-size: 0.9em;
		}
	}
	& main {
		padding: 1em;
		h1 {
			font-size: 2em;
		}
		h2 {
			font-size: 1.5em;
		}
		.button-group {
			button {
				padding: 0.5em 1em;
				background: dodgerblue;
				border: 1px solid #fff;
				color: #fff;
				text-transform: uppercase;
				letter-spacing: 1px;
				font-size: 0.9em;
			}
		}
		.flex-row {
			display: flex;
			padding-top: 1em;
		}
		.code-container,
		.preview-container {
			flex: 1 1 50%;
			width: 50%;
			margin-bottom: 10%;
		}
		.code-editor {
			height: 90vh;
			overflow: auto;
		}
		.iframe-container {
			height: 100%;
			padding: 1em;
			background-position: 0 0, 10px 10px;
			background-size: 20px 20px;
			background-image: linear-gradient(45deg, #333 25%, transparent 25%, transparent 75%, #333 75%, #333 100%),
				linear-gradient(45deg, #333 25%, #555 25%, #555 75%, #333 75%, #333 100%);
		}
		iframe {
			width: 100%;
			height: 100%;
			transition: width 500ms ease;
		}
		textarea {
			padding: 1em;
			width: 100%;
			height: 100%;
		}
	}
`;

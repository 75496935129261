// noinspection JSUnresolvedVariable

// Resources
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import Cookies from "universal-cookie";

// Components
import OpportunityWrapper from "./components/body/OpportunityWrapper";

// Auth
import AuthHelperMethods from "./components/auth/AuthHelperMethods";
import withAuth from "./components/auth/withAuth";

// Redux
import { createStore } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./reducers";

// Globals
let cookies;
const store = createStore(rootReducer);

// Export - Protected
class Opportunity extends Component {
	Auth = new AuthHelperMethods();

	constructor(props) {
		super(props);
		this.state = {
			// Title
			title: "Opportunity",
			id: "",
			// User
			userId: "",
			profile: {},
			company: {},
			settings: {},
			// Misc
			logout: false
		};
	}

	goTo404 = () => {
		const destination = cookies.get("forceRedirectHomepage") ? "/" : "/404";
		cookies.remove("forceRedirectHomepage");
		this.props.history.push({
			pathname: destination,
			search: ""
		});
	};
	
	fetchAdminAll = () => {
		this.Auth.fetchAdmin({ user: this.state.user.id })
			.then((res) => {
				if (res.error) {
					alert(res.error);
				} else if (res.data.settings) {
					let obj = {};
					res.data.settings.forEach((item) => (obj[item.title] = item.value));
					obj.tags = obj.tags.split("\n");
					obj.departments = JSON.parse(obj.departments);
					obj.copy_data = JSON.parse(obj.copy_data);
					this.setState({ settings: obj });
				}
			})
			.catch((err) => console.error("[ fetchAdminAll ] POST error:", err));
	};

	componentDidMount() {
		// Init cookies
		// noinspection JSValidateTypes
		cookies = new Cookies();

		// Set company id
		const params = queryString.parse(this.props.history.location.search);
		const id = params && params.id ? params.id : "";
		const opp = params && params.opp ? params.opp : "";
		if (!id || !opp) {
			this.goTo404();
			return;
		}

		this.setState({
			id: id,
			opp: opp,
			company: {},
			isConnected: false
		});

		if (this.Auth.getToken()) {
			this.Auth.getProfile()
				.then((res) => {
					if (res.success && res.data && res.data.profile) {
						// Save profile data in redux
						res.data.profile._id = res.data.id;
						store.dispatch({
							type: "PROFILE",
							profile: res.data.profile
						});

						// Save profile data in state
						this.setState(
							{
								user: res.data,
								profile: res.data.profile,
								userId: res.data.id,
								ws: res.data.ws
							},
							this.fetchAdminAll
						);
					} else {
						console.log("[ componentDidMount ] Server error:", res);
						this._handleLogout();
					}
				})
				.catch((err) => {
					console.log("[ componentDidMount ] POST error:", err);
					this._handleLogout();
				});
		} else {
			console.log("[ componentDidMount ] Token not found");
			this._handleLogout();
		}

		// Get company data
		this.Auth.getCompany({ id: id })
			.then((res) => {
				if (res.error) {
					console.error("[ componentDidMount ] err:", res.error);
					this.goTo404();
				} else if (res.company) {
					// Set redux
					store.dispatch({
						type: "COMPANY",
						company: res.company
					});

					// Set state
					this.setState({
						company: res.company,
						title: "Company | " + res.company.title
					});
				} else {
					console.error("[ componentDidMount ] Company not found:", res);
					this.goTo404();
				}
			})
			.catch((err) => {
				console.log("[ componentDidMount ] POST error:", err);
			});
	}
	
	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>Opportunity • {this.state.title}</title>
				</Helmet>
				<Provider store={store}>
					<React.StrictMode>
						<div id="body" className="company-body">
							{this.state.opp && (
								<OpportunityWrapper
									settings={this.state.settings}
									company={this.state.company}
									opp={this.state.opp}
									goTo404={this.goTo404}
								/>
							)}
						</div>
					</React.StrictMode>
				</Provider>
			</React.Fragment>
		);
	}
}

// In order for this component to be protected, we must wrap it with a 'Higher Order Component'
export default withAuth(Opportunity);
